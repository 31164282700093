const LocalConfig = {
    clientId: 'ce6862fb-94ef-45a3-b9c2-8c8bc7a0ec3a',
    authority: `${'https://login.microsoftonline.com/microsoft.onMicrosoft.com/'}`,
    graphRedirectUri: 'https://localhost:44302/',
    apiScopes: ['api://1a7cb9b6-32a1-4fdd-bf61-638ae1aaae11/.default'],
    copilotApiScope: ['api://dd95f4e6-1439-46f7-a5af-29d9d54801cd' + '/.default'],
    copilotApiURL: 'https://api.buddy.virtuoso.microsoft.com/copilot/api/VwwCopilot/Question',
    apiEndPoint: 'http://localhost:44301',
    instrumentationKey: 'fa8d8576-8345-402f-9e49-2280140449ef',
    solutionMethodIconsUrl: 'https://sdmplusstoragedev.z19.web.core.windows.net',
    sdm1URL: 'https://devsdmplus.azurewebsites.net/sdm/Index#/',
    sdm2URL: 'https://localhost:44302',
    appInsightsAPIKey: 'wnhyzg7gjgtysxq6fhixm0g7jqw85rzlx24vh3u0',
    appInsightsAppId: '1b6132c5-3428-4520-94ee-139bd32be5e1',
    yammerGroupId: '142500216832',
    yammerInstance: 'Dev',
    yammerAppId: 'yvr8LbQxiZ72AQEDTTfuDA',
    browserTitle: 'SDMPlus 2.0 - Local',
    ocvFeedbackAppId: 2622,
    ocvFeedbackCentroUrlProd: 'https://admin.microsoft.com',
    ocvFeedbackCentroUrlCI: 'https://admin-ignite.microsoft.com',
    ocvFeedbackForumUrl: 'https://aka.ms/Virtuoso/Ideas',
    ocvMyFeedbackUrl: 'https://mcapsideas.powerappsportals.com/d365community/mycontent',
    ocvPrivacyUrl: 'https://privacy.microsoft.com/en-US/data-privacy-notice',
    ocvIsProduction: 'false',
    ocvFeatureArea: 'SDMPlus_Copilot',
    ocvTenantId: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    videoTutorailURL: 'https://microsoftapc.sharepoint.com/teams/SDMPlusTutorials_Test/Shared%20Documents/Forms/AllItems.aspx',
    ckEdKEY: 'CKEditorLicenseKey',
    irisCopilotApiUrl: 'https://iris-api-dev.azurewebsites.net',
};

export default LocalConfig;
