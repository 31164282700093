import {
  bootstrapFeature,
  ConfigUpdatedListener,
  DataSourceSetter,
  initialize,
  preloadFeature,
  RequestFunction
} from '@ms/centro-hvc-loader/dist/hvc-loader/index'
import { IRuntimeHvcStaticConfig } from '@ms/centro-hvc-loader/dist/runtime/configuration/runtimeDataSources'
import { IFeedbackInitOptions } from './FeedbackTypes'

const InAppFeedbackInitOptionsListener = (() => {
  const feedbackData: IFeedbackInitOptions = {}

  // register listener to Centro to enable dynamic data passing
  const register = (
    listener?: ConfigUpdatedListener<IFeedbackInitOptions>
  ): Readonly<Partial<IFeedbackInitOptions>> => {
    if (listener) {
      listeners.push(listener)
    }
    return feedbackData
  }

  // unregister listener from Centro
  const unregister = (listener: ConfigUpdatedListener<IFeedbackInitOptions>): void => {
    const index = listeners.indexOf(listener)
    if (index > -1) {
      listeners.splice(index, 1)
    }
  }

  // list of function provided to update Feedback configuration
  const listeners: ConfigUpdatedListener<IFeedbackInitOptions>[] = []
  const updateFeedbackObject = (updatedInAppFeedbackInitOptions: IFeedbackInitOptions) => {
    Object.assign(feedbackData, updatedInAppFeedbackInitOptions)
    listeners.forEach((listener: ConfigUpdatedListener<IFeedbackInitOptions>) => listener(feedbackData))
  }

  const configObject = {
    register,
    unregister,
    updateFeedbackObject
  }

  return configObject
})()

/**
 * This is function you call from outside
 * You can change the signature to include appName and language dynamically
 *
 * Details of the data objects and functions can be found here
 * https://projectcentro.azurewebsites.net/docs/#/consume/adapter
 */

export interface InAppFeedBackInfo {
  hostName: string // name of your app that has onboarded with Centro
  language: string // localev of the HVC to be loaded, in BCP-47 format
  inAppFeedbackRef?: string // a referrence to DOM element, can pass in as a DOM object to render the hvc
}

function getDivToMountHvc(inAppFeedbackRef?: string) {
  let hvcDiv

  if (inAppFeedbackRef) {
    hvcDiv = document.getElementById(inAppFeedbackRef)
  }

  if (!hvcDiv) {
    hvcDiv = document.createElement('div')
    hvcDiv.id = 'inapp-feedback-ref'
    document.body.appendChild(hvcDiv)
  }

  return hvcDiv
}

export function initializeInAppFeedback(feedbackInfo: InAppFeedBackInfo, centroUrlProd: any) {
  ;(async () => {
    const OcvInAppFeedback = 'ocv-inapp-feedback'
    const { hostName, language, inAppFeedbackRef } = feedbackInfo

    const runtimeStaticConfig: IRuntimeHvcStaticConfig = {
      hostName: hostName,
      locale: language,

      // All perf log will be prefixed with this string.
      // So that when we analysis perf logs, we know that those logs are coming from HVC running in your environment.
      perfPrefix: `OcvFeedback${hostName}`
    }

    const centroParams = {
      // Please use Centro Prod Url even for development to get the latest released of the HVC
      // Centro CI Url can also be used for unreleased features
      centroEnvironmentBaseUrl: centroUrlProd,
      centroAdapters: {
        runtimeStaticConfig,
        request: ((() => {}) as unknown) as RequestFunction
      },
      centroHvcData: '',
      forHostVersion: 'v2', // current stable major verion
      hvcVersion: '' // getting the latest version
    }

    await initialize(centroParams).catch(e => {
      console.error('In-App Feedback Initilization Failed: ' + e)
    })

    await preloadFeature({ featureName: OcvInAppFeedback })

    const dataSources = (sd: DataSourceSetter) => {
      sd('centro.hvc.feedback.initOptions', InAppFeedbackInitOptionsListener as any)
    }

    const bootstrapper = await bootstrapFeature({
      featureName: OcvInAppFeedback,
      setAdditionalDataSource: dataSources
    })

    await bootstrapper.main(getDivToMountHvc())

  })()
}

export const updateFeedbackOptions = InAppFeedbackInitOptionsListener.updateFeedbackObject