const DevConfig = {
    clientId: 'ce6862fb-94ef-45a3-b9c2-8c8bc7a0ec3a',
    authority: `${'https://login.microsoftonline.com/microsoft.onMicrosoft.com/'}`,
    graphRedirectUri: 'https://devsdmplus2.azurewebsites.net/',
    apiScopes: [
        'https://microsoft.onmicrosoft.com/d62bed22-c61b-4eb5-9fb1-26bbdbd6368a/.default',
    ],
    copilotApiScope: ['api://dd95f4e6-1439-46f7-a5af-29d9d54801cd' + '/.default'],
    copilotApiURL: 'https://api.buddy.virtuoso.microsoft.com/copilot/api/VwwCopilot/Question',
    apiEndPoint: 'https://devapisdmplus.azurewebsites.net/',
    instrumentationKey: 'fa8d8576-8345-402f-9e49-2280140449ef',
    solutionMethodIconsUrl: 'https://sdmplusstoragedev.z19.web.core.windows.net',
    sdm1URL: 'https://devsdmplus.azurewebsites.net/sdm/Index#/',
    sdm2URL: 'https://devsdmplus2.azurewebsites.net',
    appInsightsAPIKey: 'dlpqjmtcachg12db2iuplrep1b3ek4dv2btlodei',
    appInsightsAppId: '2e0cac10-4c71-46e6-9132-8beaf5415e13',
    yammerGroupId: '142500216832',
    yammerInstance: 'Test',
    yammerAppId: 'yvr8LbQxiZ72AQEDTTfuDA',
    browserTitle: 'SDMPlus 2.0 - Dev',
    ocvFeedbackAppId: 2622,
    ocvFeedbackCentroUrlProd: 'https://admin.microsoft.com',
    ocvFeedbackCentroUrlCI: 'https://admin-ignite.microsoft.com',
    ocvFeedbackForumUrl: 'https://aka.ms/Virtuoso/Ideas',
    ocvMyFeedbackUrl: 'https://mcapsideas.powerappsportals.com/d365community/mycontent',
    ocvPrivacyUrl: 'https://privacy.microsoft.com/en-US/data-privacy-notice',
    ocvIsProduction: 'false',
    ocvFeatureArea: 'SDMPlus_Copilot',
    ocvTenantId: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    videoTutorailURL: 'https://microsoftapc.sharepoint.com/teams/SDMPlusTutorials_Test/Shared%20Documents/Forms/AllItems.aspx',
    ckEdKEY: 'CKEditorLicenseKey',
    irisCopilotApiUrl: 'https://iris-api-dev.azurewebsites.net',
};
export default DevConfig;
