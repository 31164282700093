import React, { useEffect, useState } from 'react';
import { NavLink, Link, useHistory} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';
import dompurify from 'dompurify';

import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import fileIcons from '../../static/TemplateIcons';
import FileDropModal from './FileDropModal';
import PreviewModal from './PreviewModal';
import SampleTemplateModal from './SampleTemplateModal';
import LocalizedTemplateModal from './LocalizedTemplateModal';
import ComplexityTemplateModal from './ComplexityTemplateModal';
import FeedbackModal from './FeedbackModal';
import TemplateContributionModal from './TemplateContributionModal';
import SingleContributionModal from './TemplateSingleContributionModal';
import LocalizedTemplateContributionModal from './LocalizedTemplateContributionModal';
import HashTagsModalForTemplate from './HashtagsModalForTemplate';
import TemplateDeleteModal from './TemplateDeleteModal';

import './TemplateCard.css';
import TemplateActivityEditBuilder from '../TemplateActivityLink/TemplateActivityEdit';

function TemplateCard({
	data,
	index,
	enableSearchSuggestion,
	searchSuggestionId,
	setSearchSuggestionId,
	searchTemplateCard,
	changeLastFiveDownload,
	setChangeLastFiveDownload,
	currentPage,
	complexityTag,
	getTemplates,
}) {
	const history = useHistory();
	const { track } = useAppInsights();
	const { axiosPostService, axiosGetService } = useAxios();
	const { isAdmin, endUserView } = useLeftMenuCollapse();
	const htmlSanitizer = dompurify.sanitize;
	const [templateData, setTemplateData] = useState(() => {
		return data;
	});

	// showMore Overlay
	const [enableShowMoreTemplates, setEnableShowMoreTemplates] = useState(false);

	// template Preview Data
	const [templatePreviewData, setTemplatePreviewData] = useState(null);
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);

	// showMore options Modal
	const [enableShowMoreOptionModal, setEnableShowMoreOptionModal] =
		useState(false);
	const [showMoreOptionModal, setShowMoreOptionModal] = useState(null);

	// fileDrop modal
	const [enableFileDropModal, setEnableFileDropModal] = useState(false);

	// feedbackModal
	const [enableFeedbackModal, setEnableFeedbackModal] = useState(false);
	const [showText, setShowText] = useState(false);

	//contribution modal
	const [enableTemplateContributionModal, setEnableTemplateContributionModal] =
		useState(false);
	const [enableLocalizedTemplate, setEnableLocalizedTemplate] = useState(false);
	//search suggestion data for search results page
	const [searchSuggestionData, setSearchSuggestionData] = useState(null);
	const [showSearchSuggestion, setShowSearchSuggestion] = useState(
		enableSearchSuggestion
	);
	const [enableTemplateDeleteModal, setEnableTemplateDeleteModal] =
		useState(false);
	const [enableHashtagsForTemplate, setEnableHashtagsForTemplate] =
		useState(false);
	const [
		enableSingleTemplateContribution,
		setEnableSingleTemplateContribution,
	] = useState(false);
	const [selectedTemplateInfo, setSelectedTemplateInfo] = useState({});
	const [showTemplateEdit, setShowTemplateEdit] = useState(false);

	const [existingHashtags, setExistingHashtags] = useState([]);
	useEffect(() => {
		if (data.hashTagsList !== null && data.hashTagsList !== undefined && data.hashTagsList.length > 0) {
			setExistingHashtags(
				data.hashTagsList.map(({ name }) => {
					return name; 
				})
			)
		}
	}, [data.hashTagsList]);

	const templateShowMoreCollapse = (e) => {
		const icon = document.getElementById(`templateShowMore_${index}`);
		if (icon !== null && !icon.contains(e.target)) {
			setEnableShowMoreTemplates(false);
		}
	};

	const handleTemplatePreview = (data) => {
		setTemplatePreviewData(data);
		setEnableTemplatePreview(true);
	};

	const [isFavorite, setIsFavorite] = useState(templateData.isFavorite);

	const handleFavoriteClick = async () => {
		if (isFavorite) {
			const body = {
				url: templateData.favoriteURL,
			};
			await axiosPostService('/api/sdmfavorite/delete', body).then(
				(response) => {
					setIsFavorite(false);
				}
			);
		} else {
			const body = {
				url: templateData.favoriteURL,
				title: templateData.title,
			};
			await axiosPostService('/api/sdmfavorite/insert', body).then(
				(response) => {
					setIsFavorite(true);
				}
			);
		}
	};
	const updateRecentlyDownloadedTemplate = () => {
		setChangeLastFiveDownload((prevState) => prevState + 1);
	};
	// search suggestion
	const getSearchSuggestions = () => {
		axiosGetService(
			`api/sdmuser/getSearchRecommendations?artifactTypeName=Templates&artifactId=${data.id}&activityIdentifier=0&complexityId=${data.complexityId}`
		).then((res) => {
			setSearchSuggestionData(res.data);
		});
	};

	useEffect(() => {
		if (searchSuggestionId) {
			setShowSearchSuggestion(
				searchSuggestionId === `search_template_${data.id}_${data.complexityId}`
			);
		} else if (enableSearchSuggestion) {
			setSearchSuggestionId(`search_template_${data.id}_${data.complexityId}`);
		}
	}, [searchSuggestionId]);

	useEffect(() => {
		if (showSearchSuggestion) {
			getSearchSuggestions();
		}
	}, [showSearchSuggestion]);

	// show More popup
	useEffect(() => {
		if (!enableShowMoreOptionModal) {
			setShowMoreOptionModal(null);
		}
	}, [enableShowMoreOptionModal]);

	useEffect(() => {
		const addAppInsight = () => {
			track(1, 114, templateData.id, templateData.title);
		};
		let timeOutId;
		if (
			enableShowMoreTemplates &&
			!(
				enableTemplatePreview ||
				enableShowMoreOptionModal ||
				enableFileDropModal ||
				enableFeedbackModal ||
				enableTemplateContributionModal ||
				enableLocalizedTemplate ||
				enableSingleTemplateContribution
			)
		) {
			timeOutId = setTimeout(addAppInsight, 1000);
		}
		return () => {
			clearTimeout(timeOutId);
		};
	}, [enableShowMoreTemplates]);

	useEffect(() => {
		if (showMoreOptionModal !== null) {
			setEnableShowMoreOptionModal(true);
		}
	}, [showMoreOptionModal]);

	useEffect(() => {
		window.addEventListener('click', templateShowMoreCollapse);
		return () => {
			window.removeEventListener('click', templateShowMoreCollapse);
		};
	}, []);

	const modalMapper = {
		Samples: (
			<SampleTemplateModal
				id={templateData.id}
				complexityId={templateData.complexityId}
				handleTemplatePreview={handleTemplatePreview}
				setEnableFileDropModal={setEnableFileDropModal}
				enableFileDropModal={enableFileDropModal}
				closeModal={() => setEnableShowMoreOptionModal(false)}
				complexityTag={complexityTag}
			/>
		),
		Localization: (
			<LocalizedTemplateModal
				id={templateData.id}
				complexityId={templateData.complexityId}
				handleTemplatePreview={handleTemplatePreview}
				setEnableTemplateContributionModal={setEnableTemplateContributionModal}
				enableTemplateContributionModal={enableTemplateContributionModal}
				closeModal={() => setEnableShowMoreOptionModal(false)}
				enableLocalizedTemplate={enableLocalizedTemplate}
				setEnableLocalizedTemplate={setEnableLocalizedTemplate}
				complexityTag={complexityTag}
			/>
		),
		Complexity: (
			<ComplexityTemplateModal
				id={templateData.id}
				handleTemplatePreview={handleTemplatePreview}
				closeModal={() => setEnableShowMoreOptionModal(false)}
			/>
		),
	};
	return (
		<div
			className="tabsWrapper templateTabsSearch"
			id={
				searchTemplateCard
					? `search_template_${data.id}_${data.complexityId}`
					: ''
			}
			onClick={() => {
				if (searchTemplateCard) {
					setSearchSuggestionId(
						`search_template_${data.id}_${data.complexityId}`
					);
				}
			}}
			tabIndex={searchTemplateCard ? 0 : null}
			onKeyPress={(e) => {
				if (searchTemplateCard && e.which === 13) {
					setSearchSuggestionId(
						`search_template_${data.id}_${data.complexityId}`
					);
				}
			}}
		>
			<div className="tabsImageWrapper">
				<div className="imageOfTemplateDiv">
					<img
						src={
							templateData.inProgress === "true"
								? fileIcons['inProgress']
								: templateData.fileType !== null
									? fileIcons[templateData.fileType.toLowerCase()] ||
									fileIcons['defaultTemplate']
									: fileIcons['defaultTemplate']
						}
						className="imageOfSearchResult"
						alt="File Type"
					/>
				</div>
				<div className="favoriteAndLikeDiv">
					<div
						className="favoriteDiv"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								handleFavoriteClick();
							}
						}}
						onClick={handleFavoriteClick}
						role="button"
						aria-label="Favorite"
						title="Favorite"
					>
						{!isFavorite ? (
							<span className="favoriteStar" />
						) : (
							<span className="favoriteSolid" />
						)}
					</div>
					<div
						className="likeDiv"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setEnableFeedbackModal(true);
							}
						}}
						onClick={() => setEnableFeedbackModal(true)}
						title="Feedback"
						aria-label="Feedback"
						role="button"
					>
						<Icon iconName="LikeSolid" />
					</div>
				</div>
			</div>
			<div className="tabsDescriptionWrapper">
				<div className="tabTitle">
					{templateData.inProgress === "true" ? (
						<span className="inProgressTemplate">{templateData.title}</span>
					) : (
						<a
							href={templateData.fileLocationNew}
							key={templateData.title}
							target="_blank"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									track(
										1,
										searchTemplateCard ? 403 : 112,
										templateData.id,
										templateData.title,
										{
											phaseName: templateData.phaseName,
											complexityId: templateData.complexityId,
											pageNumber: currentPage,
										}
									);
									setTimeout(updateRecentlyDownloadedTemplate, 3000);
								}
							}}
							onClick={() => {
								track(
									1,
									searchTemplateCard ? 403 : 112,
									templateData.id,
									templateData.title,
									{
										phaseName: templateData.phaseName,
										complexityId: templateData.complexityId,
										pageNumber: currentPage,
									}
								);
								setTimeout(updateRecentlyDownloadedTemplate, 3000);
							}}
						>
							{templateData.title}
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								<span>Download</span>
							</div>
						</a>
					)}
					{['zip', 'aspx', 'html', null].includes(templateData.fileType) ||
						templateData.inProgress === "true" ? (
						<button tabIndex={-1} title="Preview Disabled">
							<span className="previewIconDisabled"></span>
							<div className="tabsTitleToolTip tabsTitleToolTipLatest">
								<span className="tabsToolTipArrow"></span>
								<span>Preview Disabled</span>
							</div>
						</button>
					) : (
						<button
							tabIndex={0}
							aria-label="Preview"
							onKeyPress={(e) => {
								if (e.which === 13) {
									handleTemplatePreview(templateData);
									track(
										1,
										searchTemplateCard ? 407 : 126,
										templateData.id,
										templateData.title,
										{
											phaseName: templateData.phaseName,
											complexityId: templateData.complexityId,
											pageNumber: currentPage,
										}
									);
								}
							}}
							onClick={() => {
								handleTemplatePreview(templateData);
								track(
									1,
									searchTemplateCard ? 407 : 126,
									templateData.id,
									templateData.title,
									{
										phaseName: templateData.phaseName,
										complexityId: templateData.complexityId,
										pageNumber: currentPage,
									}
								);
							}}
						>
							<span className="previewIcon"></span>
							<span className="previewIconBlack"></span>
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								<span>Preview</span>
							</div>
						</button>
					)}
					<div className="ComplexityShowContainer">
						{templateData.fileLocationNew &&
							templateData.allAvailableComplexities &&
							templateData.searchComplexities &&
							templateData.allAvailableComplexities.map((complexity, index) => {
								return (
									<span
										className={
											templateData.searchComplexities.includes(complexity.id)
												? 'SearchComplexity'
												: ''
										}
									>
										<a
											target="_blank"
											href={templateData.allComplexityFileLocations[index]}
										>
											{complexity.name}
										</a>
									</span>
								);
							})}
					</div>
				</div>
				{!searchTemplateCard && existingHashtags.length > 0 ? (
					<div class="existingHashTagsOfTopic">
						{showText
							? existingHashtags.map((name) => (
								<span
									key={name}
									onClick={() => {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}
									}}
									role="button"
									aria-label="Search Hastag"
								>
									{name}
								</span>
							))
							: existingHashtags.slice(0, 4).map((name) => (
								<span
									key={name}
									onClick={() => {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											history.push(
												`/search?searchParam=${encodeURIComponent(name)}`
											);
										}
									}}
									role="button"
									aria-label="Search Hastag"
								>
									{name}
								</span>
							))}
						<div className="addHashtagsIconContainer">
							<span
								className="addHashtagsIcon"
								onClick={() => {
									setEnableHashtagsForTemplate(true);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setEnableHashtagsForTemplate(true);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label="Add Hastag"
							></span>
							<div class="tabsTitleToolTip">
								<span class="tabsToolTipArrow"></span>
								<span>Add Hashtags</span>
							</div>
						</div>
					</div>
				) : (
					<>
						{searchTemplateCard && existingHashtags != undefined ? (
							<div class="existingHashTagsOfTopic">
								{showText &&
									existingHashtags.map((name) => (
										<span
											key={name}
											onClick={() => {
												history.push(
													`/search?searchParam=${encodeURIComponent(name)}`
												);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													history.push(
														`/search?searchParam=${encodeURIComponent(name)}`
													);
												}
											}}
											role="button"
											aria-label="Search Hastag"
										>
											{name}
										</span>
									))}
								{!showText &&
									existingHashtags.slice(0, 4).map((name) => (
										<span
											key={name}
											onClick={() => {
												history.push(
													`/search?searchParam=${encodeURIComponent(name)}`
												);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													history.push(
														`/search?searchParam=${encodeURIComponent(name)}`
													);
												}
											}}
										>
											{name}
										</span>
									))}
							</div>
						) : (
							<p className="noHashtagsDescription">
								No hashtags have been added to this template{' '}
								<Link
									to="/"
									onClick={(e) => {
										e.preventDefault();
										setEnableHashtagsForTemplate(true);
									}}
								>
									Would you like to add?
								</Link>
							</p>
						)}
					</>
				)}

				{enableHashtagsForTemplate ? (
					<HashTagsModalForTemplate
						enableHashtagsForTemplate={enableHashtagsForTemplate}
						closeModal={() => setEnableHashtagsForTemplate(false)}
						template={data}
						complexityTag={complexityTag}
						existingHashtags={existingHashtags}
						setExistingHashtags={setExistingHashtags}
						type="templates"
						pageType={'templateHashtags'}
					/>
				) : (
					<></>
				)}
				{showText ? (
					<>
						<div className="descriptionOfTabs">
							<p
								dangerouslySetInnerHTML={{
									__html:
										templateData.tileDescription === null ||
											templateData.tileDescription.length === 0
											? htmlSanitizer('<b>Description: N/A</b>')
											: htmlSanitizer(templateData.tileDescription),
								}}
							></p>
						</div>
						<div className="typeAndPubDataOfTemplate">
							<div className="typeOfTemplate">
								<span>Type: </span>
								<span>{data?.templateType ? data?.templateType : <b>N/A</b>}</span>
							</div>
							<div className="publishedDateOfTemplate">
								<span>Published Date: </span>
								<span>{data.date}</span>
							</div>
							<div className='hidingConsultingSupport'>
								<span>Project Type: </span>
								<span>{data.projectType}</span>
							</div>
						</div>
					</>
				) : (
					<></>
				)}
				<div className="detailsOfTabs">
					<div className="otherDetailsOfTab">
						<div className="phaseDetails">
							<span className="phaseLabel">Activity: </span>
							<span className="phaseName">{templateData.phaseName}</span>
							<div
								className="tabExpandedViewIcon"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowText(!showText);
									}
								}}
								onClick={() => setShowText(!showText)}
								aria-expanded={showText}
								title={showText ? 'Collapse Template' : 'Expand Template'}
								aria-label={
									showText
										? 'Click to Collapse Template information'
										: 'Click to expand  Template Template information'
								}
							>
								{showText ? (
									<Icon iconName="ChevronUpMed" />
								) : (
									<Icon iconName="ChevronDownMed" />
								)}
							</div>
						</div>
					</div>
					<div className="tabsRightWrapper">
						{templateData.inProgress === "true" ? (
							<div className="tabContributer">
								<div
									className="contributerButton disabledContributorButton"
									tabIndex={0}
									title="You cannot contribute for the output template"
									aria-disabled={true}
									role="button"
									aria-label="Disabled Contribute Template"
									aria-haspopup="false"
								>
									<Icon iconName="ReleaseGate" />
									<div className="tabsTitleToolTip">
										<span className="tabsToolTipArrow"></span>
										<span>You cannot contribute for the output template</span>
									</div>
								</div>
							</div>
						) : (
							<div className="tabContributer">
								<div
									className={
										templateData.isApproverGroupExist &&
											templateData.isReviewerGroupExist
											? 'contributerButton'
											: 'contributerButton disabledContributorButton'
									}
									tabIndex={0}
									onClick={() => {
										if (
											templateData.isApproverGroupExist &&
											templateData.isReviewerGroupExist
										) {
											setEnableSingleTemplateContribution(
												!enableSingleTemplateContribution
											);
										}
									}}
									onKeyPress={(e) => {
										if (
											e.which === 13 &&
											templateData.isApproverGroupExist &&
											templateData.isReviewerGroupExist
										) {
											setEnableSingleTemplateContribution(
												!enableSingleTemplateContribution
											);
										}
									}}
									aria-label="Enabled Contribute Template"
									title="Enabled Contribute Template"
									aria-haspopup={true}
									role="button"
								>
									<Icon iconName="ReleaseGate" />
									<div className="tabsTitleToolTip">
										<span className="tabsToolTipArrow"></span>
										<span>
											{templateData.isApproverGroupExist &&
												templateData.isReviewerGroupExist
												? 'Contribute Template'
												: 'Security Group Not Available'}
										</span>
									</div>
								</div>
							</div>
						)}
						{isAdmin && (
							<div className="tabContributer">
								<div
									className="templateEditIconDiv"
									tabIndex={0}
									onClick={() => {
										templateData.isEditAvailable && setShowTemplateEdit(true);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											templateData.isEditAvailable && setShowTemplateEdit(true);
										}
									}}
									aria-haspopup={true}
									role="button"
									aria-label="Edit Template"
								>
									<span
										className={
											templateData.isEditAvailable
												? 'templateEditIcon'
												: 'templateEditIconInActive'
										}
									></span>

									<div className="tabsTitleToolTip">
										<span className="tabsToolTipArrow"></span>
										<span>
											{templateData.isEditAvailable
												? 'Edit Template'
												: 'Template Edit Not Available'}
										</span>
									</div>
								</div>
							</div>
						)}
						{isAdmin && !enableSearchSuggestion && !endUserView && (
							<div className="templateDeleteContainer">
								<span
									className="templateDeleteIcon"
									onClick={() => {
										setEnableTemplateDeleteModal(true);
										setSelectedTemplateInfo(templateData);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											templateData.isEditAvailable && setShowTemplateEdit(true);
										}
									}}
									tabIndex={0}
									aria-haspopup={true}
									role="button"
									aria-label="Delete Template"
								></span>
								<div className="tabsTitleToolTip">
									<span className="tabsToolTipArrow"></span>
									<span>Delete Template</span>
								</div>
							</div>
						)}

						<div
							className={`tabsShowMore ${enableShowMoreTemplates
								? 'showMoreExpanded'
								: 'showMoreCollapsed'
								}`}
						>
							<div
								className="showMoreButtonTabs"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setEnableShowMoreTemplates(!enableShowMoreTemplates);
										track(1, 114, templateData.id, templateData.title);
									}
								}}
								onClick={() => {
									setEnableShowMoreTemplates(!enableShowMoreTemplates);
								}}
								id={`templateShowMore_${index}`}
								aria-haspopup={true}
								role="button"
								aria-label="Show More Templates"
							>
								<div className="tabsMoreIcon">
									<Icon iconName="MoreVertical" />
									{enableShowMoreTemplates ? (
										<>
											<div className="tabsShowMoreMenu">
												<span className="tabsShowMoreArrow" />
												<ul className="menuItemUL">
													<li
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setShowMoreOptionModal('Samples');
																track(
																	1,
																	115,
																	templateData.id,
																	templateData.title
																);
															}
														}}
														onClick={() => {
															setShowMoreOptionModal('Samples');
															track(
																1,
																115,
																templateData.id,
																templateData.title
															);
														}}
														role="button"
														aria-label="Samples"
													>
														<span className="samplesImage" />
														<span>Samples</span>
													</li>
													<li
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setShowMoreOptionModal('Localization');
																track(
																	1,
																	116,
																	templateData.id,
																	templateData.title
																);
															}
														}}
														onClick={() => {
															setShowMoreOptionModal('Localization');
															track(
																1,
																116,
																templateData.id,
																templateData.title
															);
														}}
														role="button"
														aria-label="Localization"
													>
														<span className="localizationImage" />
														<span>Localization</span>
													</li>
													<li className="curatedIpLi">
														<a
															href={`https://cpsui.azurewebsites.net/Pages/CampusSingleSearch?scope=content&k=${templateData.title}`}
															target="_blank"
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	track(
																		1,
																		117,
																		templateData.id,
																		templateData.title
																	);
																}
															}}
															onClick={() =>
																track(
																	1,
																	117,
																	templateData.id,
																	templateData.title
																)
															}
															role="button"
															aria-label="Curated IP"
														>
															<span className="curatedIpImage" />
															<span>Curated IP</span>
														</a>
													</li>
													<li
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setShowMoreOptionModal('Complexity');
																track(
																	1,
																	118,
																	templateData.id,
																	templateData.title
																);
															}
														}}
														onClick={() => {
															setShowMoreOptionModal('Complexity');
															track(
																1,
																118,
																templateData.id,
																templateData.title
															);
														}}
														role="button"
														aria-label="Complexity"
													>
														<span className="complexityImage" />
														<span>Complexity</span>
													</li>
												</ul>
											</div>
										</>
									) : (
										<></>
									)}
									{enableTemplateDeleteModal && (
										<TemplateDeleteModal
											enableTemplateDeleteModal={enableTemplateDeleteModal}
											setEnableTemplateDeleteModal={
												setEnableTemplateDeleteModal
											}
											templateInfo={selectedTemplateInfo}
											setTemplateInfo={setSelectedTemplateInfo}
											getTemplates={getTemplates}
										/>
									)}
									{showTemplateEdit && (
										<TemplateActivityEditBuilder
											showTemplateEditBuilder={showTemplateEdit}
											setShowTemplateEditBuilder={setShowTemplateEdit}
											templateId={templateData.id}
											complexityId={complexityTag.id}
											getTemplates={getTemplates}
										/>
									)}

									<Modal
										show={enableShowMoreOptionModal}
										onHide={() => {
											setEnableShowMoreOptionModal(false);
											setShowMoreOptionModal(null);
										}}
										centered
										dialogClassName="templateModal"
									>
										{enableShowMoreOptionModal ? (
											modalMapper[showMoreOptionModal]
										) : (
											<></>
										)}
									</Modal>
									{enableTemplatePreview ? (
										<PreviewModal
											enableTemplatePreview={enableTemplatePreview}
											closeModal={() => setEnableTemplatePreview(false)}
											data={templatePreviewData}
										/>
									) : (
										<></>
									)}
									{enableFileDropModal ? (
										<FileDropModal
											enableFileDropModal={enableFileDropModal}
											closeModal={() => setEnableFileDropModal(false)}
											data={templateData}
										/>
									) : (
										<></>
									)}
									{enableFeedbackModal ? (
										<FeedbackModal
											enableFeedbackModal={enableFeedbackModal}
											closeModal={() => setEnableFeedbackModal(false)}
											data={templateData}
										/>
									) : (
										<></>
									)}
									{enableTemplateContributionModal ? (
										<TemplateContributionModal
											data={templateData}
											enableTemplateContributionModal={
												enableTemplateContributionModal
											}
											closeModal={() =>
												setEnableTemplateContributionModal(false)
											}
											existingHashtags={existingHashtags}
											setExistingHashtags={setExistingHashtags}
										/>
									) : (
										<></>
									)}
									{enableLocalizedTemplate ? (
										<LocalizedTemplateContributionModal
											data={templateData}
											closeModal={() => setEnableLocalizedTemplate(false)}
											enableLocalizedTemplate={enableLocalizedTemplate}
										/>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{searchSuggestionData && showSearchSuggestion ? (
				<div className="relatedSearchSuggestion">
					<span className="tabsbeakArrow"></span>
					<h6>
						Activities that produce this template
						<br />
						<span>{`"${templateData.title}"`}</span>
					</h6>
					{searchSuggestionData.activities &&
						searchSuggestionData.activities.length > 0 ? (
						<>
							<div className="titleOfSuggestionWrap"></div>
							<ul className="activityRecommandations">
								{searchSuggestionData.activities.map((activity) => (
									<li
										tabIndex="0"
										key={activity.id + '_' + activity.complexityId}
									>
										<div className="relatedTitles">
											<img
												src={fileIcons.activity}
												alt="File Type"
												className="searchSuggestionImage"
											/>
											<NavLink
												onClick={() => {
													track(
														1,
														165,
														activity.activityIdentifier,
														activity.activityName,
														{
															suggestedItemName: 'Activity',
															suggestedItemId: 2,
															suggestedComplexity: activity.complexityName,
														}
													);
												}}
												title={activity.activityName}
												aria-label={activity.activityName}
												to={`/project/${activity.projectTypeId}/method/${activity.methodId
													}/phase/${activity.phaseId ? activity.phaseId : 'null'
													}/parentPhase/${activity.parentPhaseId
														? activity.parentPhaseId
														: 'null'
													}/activity/${activity.activityIdentifier}?complexity=${activity.complexityId
													}`}
											>
												<span>{activity.activityName}</span>
											</NavLink>
										</div>
										<div className="relatedPhaseName">
											Phase:{' '}
											{activity.solutionMethodName +
												'-' +
												activity.activityPhaseName}
										</div>
									</li>
								))}
							</ul>
						</>
					) : (
						<span className="noSearchSuggestions">
							No Activities produce this Template
						</span>
					)}
				</div>
			) : null}
			{enableSingleTemplateContribution &&
				!enableFileDropModal &&
				!enableTemplateContributionModal &&
				!enableLocalizedTemplate ? (
				<SingleContributionModal
					enableFileDropModal={enableFileDropModal}
					setEnableFileDropModal={setEnableFileDropModal}
					setEnableSingleTemplateContribution={
						setEnableSingleTemplateContribution
					}
					enableTemplateContributionModal={enableTemplateContributionModal}
					setEnableTemplateContributionModal={
						setEnableTemplateContributionModal
					}
					enableLocalizedTemplate={enableLocalizedTemplate}
					setEnableLocalizedTemplate={setEnableLocalizedTemplate}
					title={data.title}
				/>
			) : null}
		</div>
	);
}
export default TemplateCard;
