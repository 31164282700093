import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import dompurify from 'dompurify';

import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import fileIcons from '../../static/TemplateIcons';
import FileDropModal from './FileDropModal';
import PreviewModal from './PreviewModal';
import SampleTemplateModal from './SampleTemplateModal';
import LocalizedTemplateModal from './LocalizedTemplateModal';
import ComplexityTemplateModal from './ComplexityTemplateModal';
import FeedbackModal from './FeedbackModal';
import TemplateContributionModal from './TemplateContributionModal';
import SingleContributionModal from './TemplateSingleContributionModal';
import LocalizedTemplateContributionModal from './LocalizedTemplateContributionModal';
import DeletedEntityModal from '../DeletedEntity';
import HashTagsModalForTemplate from './HashtagsModalForTemplate';
import {TabTitle} from '../../utils/GeneralFunctions'

import './TemplateCard.css';

function TemplateCard() {
	const history = useHistory();
	const { track } = useAppInsights();
	const { axiosGetService, axiosPostService } = useAxios();
	const { templateId } = useParams();
	const searchParam = new URLSearchParams(useLocation().search);
	const complexityId = searchParam.get('complexityId');
	const { breadcrumbs, setBreadcrumbs } = useLeftMenuCollapse();
	const htmlSanitizer = dompurify.sanitize;
	const [templateData, setTemplateData] = useState(null);

	// showMore Overlay
	const [enableShowMoreTemplates, setEnableShowMoreTemplates] = useState(false);

	// template Preview Data
	const [templatePreviewData, setTemplatePreviewData] = useState(null);
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);

	// showMore options Modal
	const [enableShowMoreOptionModal, setEnableShowMoreOptionModal] =
		useState(false);
	const [showMoreOptionModal, setShowMoreOptionModal] = useState(null);

	// fileDrop modal
	const [enableFileDropModal, setEnableFileDropModal] = useState(false);

	// feedbackModal
	const [enableFeedbackModal, setEnableFeedbackModal] = useState(false);
	const [isFavorite, setIsFavorite] = useState(false);

	const [enableTemplateContributionModal, setEnableTemplateContributionModal] =
		useState(false);
	const [enableLocalizedTemplate, setEnableLocalizedTemplate] = useState(false);

	const [
		enableSingleTemplateContribution,
		setEnableSingleTemplateContribution,
	] = useState(false);

	const [enableHashtagsForTemplate, setEnableHashtagsForTemplate] =
		useState(false);
	const [existingHashtags, setExistingHashtags] = useState([]);

	const loadBreadcrumb = async (title) => {
		await axiosGetService(
			`api/sdmuser/getbreadcrumb?itemId=${templateId}&itemTypeId=1
			&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0
			&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
		).then((response) => {
			const breadcrumbTitle = response.data
				.split('>')
				.map((item) => item.trim());
			const templateBreadcrumb = {
				title: breadcrumbTitle[1],
				type: 'templates',
				path: `/template`,
				parent: ['home', 'search'],
			};
			const templateDetailBreadcrumb = {
				title: title,
				type: 'template',
				path: window.location.pathname,
				parent: 'templates',
			};
			const newBreadcrumbs = AddBreadCrumb(
				breadcrumbs.slice(
					0,
					breadcrumbs.length > 1 && breadcrumbs[1].type === 'search' ? 2 : 1
				),
				[templateBreadcrumb, templateDetailBreadcrumb]
			);
			setBreadcrumbs(newBreadcrumbs);
			TabTitle(breadcrumbTitle[1]);
		});
	};

	const getTemplateData = async () => {
		await axiosGetService(
			`api/sdmtemplate/getTemplateById/${templateId}/${complexityId}`
		)
			.then((response) => {
				if (!response.data.isDeleted) {
					const hashtags = response.data.hashTagsList.map(({ name }) => {
						return name;
					});
					setExistingHashtags(hashtags);
					setTemplateData(response.data);
					setIsFavorite(response.data.isFavorite);
					loadBreadcrumb(response.data.title);
				} else {
					setTemplateData(response.data);
				}
				track(2, 1, templateId, response.data.title, {
					complexityId: complexityId,
				});
			})
			.catch((err) => console.log(err));
	};

	const templateShowMoreCollapse = (e) => {
		const icon = document.getElementById(`templateShowMore_${0}`);
		if (icon !== null && !icon.contains(e.target)) {
			setEnableShowMoreTemplates(false);
		}
	};

	const handleTemplatePreview = (data) => {
		setTemplatePreviewData(data);
		setEnableTemplatePreview(true);
	};

	const handleFavoriteClick = async () => {
		if (isFavorite) {
			const body = {
				url: templateData.favoriteURL,
			};
			await axiosPostService('/api/sdmfavorite/delete', body).then(
				(response) => {
					setIsFavorite(false);
				}
			);
		} else {
			const body = {
				url: templateData.favoriteURL,
				title: templateData.title,
			};
			await axiosPostService('/api/sdmfavorite/insert', body).then(
				(response) => {
					setIsFavorite(true);
				}
			);
		}
	};

	const validateUrl = () => {
		if (
			!Number(templateId) ||
			!searchParam.has('complexityId') ||
			!Number(complexityId)
		) {
			history.push('/pageNotFound');
			return false;
		}
		return true;
	};

	useEffect(() => {
		validateUrl();
	}, []);

	useEffect(() => {
		if (!enableShowMoreOptionModal) {
			setShowMoreOptionModal(null);
		}
	}, [enableShowMoreOptionModal]);

	useEffect(() => {
		if (showMoreOptionModal !== null) {
			setEnableShowMoreOptionModal(true);
		}
	}, [showMoreOptionModal]);

	useEffect(() => {
		window.addEventListener('click', templateShowMoreCollapse);
		return () => {
			window.removeEventListener('click', templateShowMoreCollapse);
		};
	}, []);

	useEffect(() => {
		if (validateUrl()) {
			getTemplateData();
		}
	}, [templateId, complexityId]);

	if (templateData && templateData.isDeleted) {
		return <DeletedEntityModal errorMsg={templateData.errorMsg} />;
	}

	if (templateData === null) {
		return <></>;
	}

	const modalMapper = {
		Samples: (
			<SampleTemplateModal
				id={templateData.id}
				handleTemplatePreview={handleTemplatePreview}
				complexityId={templateData.complexityId}
				setEnableFileDropModal={setEnableFileDropModal}
				enableFileDropModal={enableFileDropModal}
				closeModal={() => setEnableShowMoreOptionModal(false)}
				complexityTag={{ name: templateData.complexityName }}
			/>
		),
		Localization: (
			<LocalizedTemplateModal
				id={templateData.id}
				complexityId={templateData.complexityId}
				handleTemplatePreview={handleTemplatePreview}
				setEnableTemplateContributionModal={setEnableTemplateContributionModal}
				enableTemplateContributionModal={enableTemplateContributionModal}
				closeModal={() => setEnableShowMoreOptionModal(false)}
				enableLocalizedTemplate={enableLocalizedTemplate}
				setEnableLocalizedTemplate={setEnableLocalizedTemplate}
				complexityTag={{ name: templateData.complexityName }}
			/>
		),
		Complexity: (
			<ComplexityTemplateModal
				id={templateData.id}
				handleTemplatePreview={handleTemplatePreview}
				closeModal={() => setEnableShowMoreOptionModal(false)}
			/>
		),
	};

	return (
		<div role="main" className="tabsWrapper templateTabsSearch">
			<div className="tabsImageWrapper">
				<div className="imageOfTemplateDiv">
					<img
						src={
							templateData.inProgress === "true"
								? fileIcons['inProgress']
								: templateData.fileType !== null
								? fileIcons[templateData.fileType.toLowerCase()]
								: fileIcons['defaultTemplate']
						}
						className="imageOfSearchResult"
						alt="File Type"
					/>
				</div>
				<div className="favoriteAndLikeDiv">
					<div
						className="favoriteDiv"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								handleFavoriteClick();
							}
						}}
						onClick={handleFavoriteClick}
						title="Favorite"
					>
						{!isFavorite ? (
							<span className="favoriteStar" />
						) : (
							<span className="favoriteSolid" />
						)}
					</div>
					<div
						className="likeDiv"
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setEnableFeedbackModal(true);
							}
						}}
						onClick={() => setEnableFeedbackModal(true)}
						title="Feedback"
					>
						<Icon iconName="LikeSolid" />
					</div>
				</div>
			</div>

			<div className="tabsDescriptionWrapper">
				<div className="tabTitle">
					{templateData.inProgress === "true" ? (
						<span className="inProgressTemplate">{templateData.title}</span>
					) : (
						<a
							href={templateData.fileLocationNew}
							key={templateData.title}
							target="_blank"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									track(1, 112, templateData.id, templateData.title, {
										phaseName: templateData.phaseName,
										complexityId: templateData.complexityId,
									});
								}
							}}
							onClick={() =>
								track(1, 112, templateData.id, templateData.title, {
									phaseName: templateData.phaseName,
									complexityId: templateData.complexityId,
								})
							}
						>
							{templateData.title}
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								<span>Download</span>
							</div>
						</a>
					)}
					{['zip', 'aspx', 'html', null].includes(templateData.fileType) ||
					templateData.inProgress === "true" ? (
						<button role="presentation">
							<span className="previewIconDisabled"></span>
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								<span>Disabled</span>
							</div>
						</button>
					) : (
						<button
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									handleTemplatePreview(templateData);
								}
							}}
							onClick={() => {
								handleTemplatePreview(templateData);
							}}
							role="presentation"
						>
							<span className="previewIcon"></span>
							<span className="previewIconBlack"></span>
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								<span>Preview</span>
							</div>
						</button>
					)}
				</div>
				{existingHashtags.length > 0 ? (
					<div class="existingHashTagsOfTopic">
						{existingHashtags.map((name) => (
							<span
								key={name}
								onClick={() => {
									history.push(
										`/search?searchParam=${encodeURIComponent(name)}`
									);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										history.push(
											`/search?searchParam=${encodeURIComponent(name)}`
										);
									}
								}}
							>
								{name}
							</span>
						))}
						<div className="addHashtagsIconContainer">
							<span
								className="addHashtagsIcon"
								onClick={() => {
									setEnableHashtagsForTemplate(true);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setEnableHashtagsForTemplate(true);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label="Add hashtags"
								aria-expanded={enableHashtagsForTemplate ? true : false}
							></span>
							<div class="tabsTitleToolTip">
								<span class="tabsToolTipArrow"></span>
								<span>Add Hashtags</span>
							</div>
						</div>
					</div>
				) : (
					<p className="noHashtagsDescription">
						No hashtags have been added to this template{' '}
						<Link
							to="/"
							onClick={(e) => {
								e.preventDefault();
								setEnableHashtagsForTemplate(true);
							}}
						>
							Would you like to add?
						</Link>
					</p>
				)}

				{enableHashtagsForTemplate ? (
					<HashTagsModalForTemplate
						enableHashtagsForTemplate={enableHashtagsForTemplate}
						closeModal={() => setEnableHashtagsForTemplate(false)}
						template={templateData}
						complexityTag={{ name: templateData.complexityName }}
						existingHashtags={existingHashtags}
						setExistingHashtags={setExistingHashtags}
						type="templates"
					/>
				) : (
					<></>
				)}
				<div className="descriptionOfTabs">
					<p
						dangerouslySetInnerHTML={{
							__html:
								templateData.tileDescription === null ||
								templateData.tileDescription.length === 0
									? htmlSanitizer('To be updated')
									: htmlSanitizer(templateData.tileDescription),
						}}
					></p>
				</div>
				<div className="typeAndPubDataOfTemplate">
					<div className="typeOfTemplate">
						<span>Type: </span>
						<span>{templateData.templateType}</span>
					</div>
					<div className="publishedDateOfTemplate">
						<span>Published Date: </span>
						<span>{templateData.date}</span>
					</div>
					<div className="publishedDateOfTemplate">
						<span>Project Type: </span>
						<span>{templateData.projectType}</span>
					</div>
				</div>
				<div className="detailsOfTabs">
					<div className="otherDetailsOfTab">
						<div className="phaseDetails">
							<span className="phaseLabel">Activity: </span>
							<span className="phaseName">{templateData.phaseName}</span>
						</div>
					</div>
					<div className="tabsRightWrapper">
						{/* <div
							className="tabSubmitSampleButton"
							tabIndex={0}
							onClick={() => setEnableFileDropModal(true)}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setEnableFileDropModal(true);
								}
							}}
						>
							<div className="contributerSubmitSampleButton">
								<Icon iconName="DoubleBookmark" />
								<div className="tabsTitleToolTip">
									<span className="tabsToolTipArrow"></span>
									<span>Submit Sample</span>
								</div>
							</div>
						</div> */}
						{templateData.inProgress === "true" ? (
							<div className="tabContributer">
								<div
									className="contributerButton disabledContributorButton"
									tabIndex={0}
									title="disabled contribute template"
									aria-haspopup={false}
									role="button"
									aria-disabled={true}
									aria-label="You cannot contribute for the output template"
								>
									<Icon iconName="ReleaseGate" />
									<div className="tabsTitleToolTip">
										<span className="tabsToolTipArrow"></span>
										<span>You cannot contribute for the output template</span>
									</div>
								</div>
							</div>
						) : (
							<div
								className="tabContributer"
								tabIndex={0}
								onClick={() =>
									setEnableSingleTemplateContribution(
										!enableSingleTemplateContribution
									)
								}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setEnableSingleTemplateContribution(
											!enableSingleTemplateContribution
										);
									}
								}}
								title="Contribute Template"
								aria-haspopup={true}
								role="button"
								aria-disabled={false}
								aria-label="Click to Contribute Template"
							>
								<div className="contributerButton">
									<Icon iconName="ReleaseGate" />
									<div className="tabsTitleToolTip">
										<span className="tabsToolTipArrow"></span>
										<span>Contribute Template</span>
									</div>
								</div>
							</div>
						)}
						<div
							className={`tabsShowMore ${
								enableShowMoreTemplates
									? 'showMoreExpanded'
									: 'showMoreCollapsed'
							}`}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									setEnableShowMoreTemplates(!enableShowMoreTemplates);
									track(1, 114, templateData.id, templateData.title);
								}
							}}
							onClick={() => {
								setEnableShowMoreTemplates(!enableShowMoreTemplates);
								track(1, 114, templateData.id, templateData.title);
							}}
							id={`templateShowMore_${0}`}
							aria-haspopup={true}
							role="button"
							aria-label="Show More Templates"
						>
							<div className="showMoreButtonTabs">
								<div className="tabsMoreIcon">
									<Icon iconName="MoreVertical" />
									{enableShowMoreTemplates ? (
										<>
											<div className="tabsShowMoreMenu">
												<span className="tabsShowMoreArrow" />
												<ul className="menuItemUL">
													<li
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setShowMoreOptionModal('Samples');
																track(
																	1,
																	115,
																	templateData.id,
																	templateData.title
																);
															}
														}}
														onClick={() => {
															setShowMoreOptionModal('Samples');
															track(
																1,
																115,
																templateData.id,
																templateData.title
															);
														}}
													>
														<span className="samplesImage" />
														<span>Samples</span>
													</li>
													<li
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setShowMoreOptionModal('Localization');
																track(
																	1,
																	116,
																	templateData.id,
																	templateData.title
																);
															}
														}}
														onClick={() => {
															setShowMoreOptionModal('Localization');
															track(
																1,
																116,
																templateData.id,
																templateData.title
															);
														}}
													>
														<span className="localizationImage" />
														<span>Localization</span>
													</li>
													<li className="curatedIpLi">
														<a
															href={`https://cpsui.azurewebsites.net/Pages/CampusSingleSearch?scope=content&k=${templateData.title}`}
															target="_blank"
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	track(
																		1,
																		117,
																		templateData.id,
																		templateData.title
																	);
																}
															}}
															onClick={() =>
																track(
																	1,
																	117,
																	templateData.id,
																	templateData.title
																)
															}
														>
															<span className="curatedIpImage" />
															<span>Curated IP</span>
														</a>
													</li>
													<li
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setShowMoreOptionModal('Complexity');
																track(
																	1,
																	118,
																	templateData.id,
																	templateData.title
																);
															}
														}}
														onClick={() => {
															setShowMoreOptionModal('Complexity');
															track(
																1,
																118,
																templateData.id,
																templateData.title
															);
														}}
													>
														<span className="complexityImage" />
														<span>Complexity</span>
													</li>
												</ul>
											</div>
										</>
									) : (
										<></>
									)}
									<Modal
										show={enableShowMoreOptionModal}
										onHide={() => {
											setEnableShowMoreOptionModal(false);
											setShowMoreOptionModal(null);
										}}
										centered
										dialogClassName="templateModal"
									>
										{enableShowMoreOptionModal ? (
											modalMapper[showMoreOptionModal]
										) : (
											<></>
										)}
									</Modal>
									{enableTemplatePreview ? (
										<PreviewModal
											enableTemplatePreview={enableTemplatePreview}
											closeModal={() => setEnableTemplatePreview(false)}
											data={templatePreviewData}
										/>
									) : (
										<></>
									)}
									{enableFileDropModal ? (
										<FileDropModal
											enableFileDropModal={enableFileDropModal}
											closeModal={() => setEnableFileDropModal(false)}
											data={templateData}
										/>
									) : (
										<></>
									)}
									{enableFeedbackModal ? (
										<FeedbackModal
											enableFeedbackModal={enableFeedbackModal}
											closeModal={() => setEnableFeedbackModal(false)}
											data={templateData}
										/>
									) : (
										<></>
									)}
									{enableTemplateContributionModal ? (
										<TemplateContributionModal
											data={templateData}
											enableTemplateContributionModal={
												enableTemplateContributionModal
											}
											closeModal={() =>
												setEnableTemplateContributionModal(false)
											}
											existingHashtags={existingHashtags}
											setExistingHashtags={setExistingHashtags}
										/>
									) : (
										<></>
									)}
									{enableLocalizedTemplate ? (
										<LocalizedTemplateContributionModal
											data={templateData}
											closeModal={() => setEnableLocalizedTemplate(false)}
											enableLocalizedTemplate={enableLocalizedTemplate}
										/>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{enableSingleTemplateContribution &&
			!enableFileDropModal &&
			!enableTemplateContributionModal &&
			!enableLocalizedTemplate ? (
				<SingleContributionModal
					enableFileDropModal={enableFileDropModal}
					setEnableFileDropModal={setEnableFileDropModal}
					setEnableSingleTemplateContribution={
						setEnableSingleTemplateContribution
					}
					enableTemplateContributionModal={enableTemplateContributionModal}
					setEnableTemplateContributionModal={
						setEnableTemplateContributionModal
					}
					enableLocalizedTemplate={enableLocalizedTemplate}
					setEnableLocalizedTemplate={setEnableLocalizedTemplate}
					title={templateData.title}
				/>
			) : null}
		</div>
	);
}
export default TemplateCard;
