import React from 'react';

const RaciType = ({ label, rolesData, id }) => {
	return (
		<div className="activityRaciDetails col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
			<h3 className="subHeading" id={id}>
				{label}:
			</h3>
			<div className="activityChildContainer orphanRole">
			<ul>
				{rolesData.map((role) => (	
					<li>
						<div
							className="activityChildTitle"
							aria-label={role}
							title={role}
							key={role}
						>
							<span className="titleOfActivity">{role}</span>
						</div>
					</li>
				))}
			</ul>
			</div>
		</div>
	);
};

export default RaciType;
