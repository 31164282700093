import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@fluentui/react";
import StarRatings from "react-star-ratings";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../helpers/CrowdSourcingSpinner";
import { useAxios } from "../../context/AxiosContext";
import { useAppInsights } from "../../context/TelemetryContext";
import { hashtagValidator } from "../../helpers/Validation";
import HashtagSuggestions from "../HashtagSuggestions";
import Dropdown from "../Dropdown";
import $ from "jquery";
import { useConfig } from '../../context/ConfigContext';

import MultiSelectDropdown from "./ToolsDropdown";

const TutorialUploadModal = ({
  showTutorialUploadModal,
  setShowTutorialUploadModal,
  setShowApiResponseModal,
  getConsultingOrSupportTopics,
}) => {
  const { track } = useAppInsights();
  const { axiosGetService, axiosPostService, axiosDeleteService } = useAxios();
  const [showLoader, setShowLoader] = useState(false);
  const [tutorialName, setTutorialName] = useState("");
  const [tutorialCategory, setTutorialCategory] = useState("");
  const [tutorialMinutes, setTutorialMinutes] = useState("");
  const [tutorialSeconds, setTutorialSeconds] = useState("");
  const [tutorialUrl, setTutorialUrl] = useState("");
  const [error, setError] = useState(null);
  const [sectionId, setSectionId] = useState(1);
  const [showStepsToFollow, setShowStepsToFollow] = useState(true);
  const [showStepsValue, setShowStepsValue] = useState(false);
  const [userContribution, setUserContribution] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedTutorialId, setSelectedTutorialId] = useState(0);
  const [hashTagInput, setHashTagInput] = useState("");
  const [addedHashTag, setAddedHashTag] = useState([]);
  const [hashtagError, setHashtagError] = useState(false);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
  const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState("");
  const [userAddedHashtags, setUserAddedHashtags] = useState([]);
  const [artifactsList, setArtifactsList] = useState([]);
  const [categories, setcategories] = useState([]);
  const [updateApiCall, setUpdateApiCall] = useState(false);
  const [categoryName, setCategoryName] = useState({
    name: "",
    type: "option",
    id: "",
  });
  const hashtagsAutoComplete = async () => {
    await axiosGetService(
      `api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
        hashtagSuggestionInput
      )}`
    ).then((response) => {
      setHashtagSuggestions(response.data);
    });
  };

  const validateInputField = () => {
    if (tutorialName.trim().length === 0) {
      setError("Title is required");
      return false;
    }
    if (tutorialMinutes > 60) {
      setError("Minutes value should be less than or equal to 60");
      return false;
    }
    if (tutorialSeconds > 60) {
      setError("Seconds value should be less than or equal to 60");
      return false;
    }
    if (tutorialUrl.trim().length === 0) {
      setError("Video Tutorial link is required");
      return false;
    } else {
      const url = tutorialUrl.split("/");
      if (
        url[0] === "<iframe src=\"https:" &&
        !url[1] &&
        url[2].includes("sharepoint.com") &&
        (url[3] === "teams" || url[3] === "personal") &&
        url[8] === "iframe>"
      ) {
        return true;
      } else {
        setError("Invalid Video tutorial link");
        return false;
      }
    }

  };

  const validateUrl = () => {
    const url = tutorialUrl.split("/");
    if (
      url[0] === "<iframe src=\"https:" &&
      !url[1] &&
      url[2].includes("sharepoint.com") &&
      (url[3] === "teams" || url[3] === "personal") &&
      url[8] === "iframe>"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const removeDuplicateHashtag = (data) => {
    return data.filter((value, index) => data.indexOf(value) === index);
  };

  const updateHashTag = (type) => {
    setAddedHashTag([...userAddedHashtags, ...addedHashTag]);
    setHashtagSuggestionInput("");
    setUserAddedHashtags([]);
  };

  const getEmbededUrl = () => {
    return (
      tutorialUrl
    );
  };

  const submitTutorial = async () => {
    const validation = validateInputField() && validateUrl();
    if (validation) {
      const postData = {
        name: tutorialName,
        url: getEmbededUrl(),
        // projectType: selectedTab === "Consulting" ? 1 : 2,
        hashTags: addedHashTag.toString(),
        pageTypeId: 11,
        isVisible: true,
        category: categoryName.id === "" ? 1 : categoryName.id,
        durationMM: tutorialMinutes === "" ? 0 : tutorialMinutes,
        durationSS: tutorialSeconds === "" ? 0 : tutorialSeconds,
        linkedArtifactIds: artifactsList
          .reduce((filteredaritfactList, artifactType) => {
            if (
              artifactType.artifactData.some(({ isSelected }) => isSelected)
            ) {
              const selectedArtifacts = artifactType.artifactData.filter(
                ({ isSelected }) => isSelected
              );
              filteredaritfactList.push(
                `${artifactType.artifactTypeId}-${selectedArtifacts
                  .map(({ id }) => id)
                  .toString()}`
              );
            }
            return filteredaritfactList;
          }, [])
          .join("|"),
      };
      setShowLoader(true);
      if (editMode) {
        postData.id = selectedTutorialId;
      }
      await axiosPostService(
        `api/sdmtutorials/videoTutorials/${editMode ? "update" : "insert"}`,
        postData
      ).then((response) => {
        if (response.data.status) {
          track(
            1,
            editMode ? 1101 : 1100,
            tutorialName,
            editMode ? selectedTutorialId : "Tutorials"
          );
          getConsultingOrSupportTopics();
          setShowApiResponseModal(true);
          setShowTutorialUploadModal(false);
        } else {
          setError(response.data.errorMsg);
        }
        setShowLoader(false);
      });
    }
  };

  const getUserSettings = async () => {
    await axiosGetService(`api/sdmuser/getbyemail`).then((response) => {
      setShowStepsToFollow(response.data.showInstructions);
    });
    setShowLoader(false);
  };

  const getVideoTutorialCategories = async () => {
    const response = await axiosGetService(
      `api/sdmtutorials/videoTutorials/getbyid?videoTutorialId=${0}`
    );
    const mappedCategoryList = response.data.mappedCategoryList.map(
      (item) => ({
        name: item.name,
        id: item.id,
        option: "type",
      })
    );
    setcategories(mappedCategoryList);
    setCategoryName({
      name: response.data.mappedCategoryList[0].name,
      id: response.data.mappedCategoryList[0].id,
      type: "option",
    });
  };


  const updateUserSetting = async () => {
    const postData = {
      showInstructions: showStepsValue ? false : true,
    };
    await axiosPostService(
      `api/sdmuser/videoTutorials/updateInstructionsFlag`,
      postData
    );
  };

  const getUserContribution = async () => {
    await axiosGetService(
      `api/sdmtutorials/videoTutorials/getcontributions`
    ).then((response) => {
      setUserContribution(response.data);
    });
  };

  const deleteTutorial = async (id, name) => {
    await axiosDeleteService(
      `api/sdmtutorials/videoTutorials/delete/${id}`
    ).then((response) => {
      if (response.data.status) {
        track(1, 1111, id, name);
        getUserContribution();
        getConsultingOrSupportTopics();
        resetInput();
      } else {
        toast.error(response.data.errorMsg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleEditMode = (item) => {
    const url =
      item.url.split("/").slice(0, 3).join("/") +
      "/" +
      item.url.split("/").slice(4, 6).join("/");
    const existingHashTag = item.hashTagsList
      .filter(({ name }) => name.length > 0)
      .map(({ name }) => {
        return name;
      });
    setAddedHashTag(existingHashTag);
    setEditMode(true);
    setTutorialName(item.name);
    setTutorialUrl(url);
    setSelectedTutorialId(item.id);
    track(2, 1109, item.id, item.name);
    setTutorialCategory(item.category);
    setTutorialMinutes(item.durationMM);
    setTutorialSeconds(item.durationSS);
  };
  const resetInput = () => {
    setEditMode(false);
    setTutorialName("");
    setTutorialUrl("");
    setTutorialCategory("1");
    setTutorialMinutes("");
    setTutorialSeconds("");
  };

  const removeArtifact = (artifactTypeIndex, index) => {
    const updatedArtifactList = [...artifactsList];
    updatedArtifactList[artifactTypeIndex].artifactData[
      index
    ].isSelected = false;
    setArtifactsList(updatedArtifactList);
  };

  const updateSelectedArtifacts = (data, artifactTypeIndex) => {
    const updatedArtifactList = [...artifactsList];
    updatedArtifactList[artifactTypeIndex].artifactData = data;
    setArtifactsList(updatedArtifactList);
  };

  const removeHashTag = (item) => {
    const filteredHashTag = addedHashTag.filter((hashTag) => item !== hashTag);
    setAddedHashTag(filteredHashTag);
  };

  const getFilterArtifactsData = async () => {
    const response = await axiosGetService(
      `api/sdmartifactmaster/getdynamicfiltersbypagetype?pageTypeId=11&isFilter=false&artifactId=${selectedTutorialId}&filterIds=0&complexityIds=0&searchValue=0`
    );
    setArtifactsList(response.data);
  };

  const { videoTutorailURL } = useConfig();
  useEffect(() => {
    setShowLoader(true);
    getUserSettings();
    getUserContribution();
    getVideoTutorialCategories();
  }, [])

  useEffect(() => {
    getFilterArtifactsData();
  }, [selectedTutorialId]);

  useEffect(() => {
    resetErrorMessage();
  }, [tutorialUrl]);

  const resetErrorMessage = async () => {
    setError("");
    return true;
  };

  useEffect(() => {
    if (hashTagInput.length > 0) {
      const hashTag =
        hashTagInput[0] === "#" ? hashTagInput : "#" + hashTagInput;
      if (!hashtagValidator.test(hashTag) || hashTag.length > 19) {
        setHashtagError(true);
      }
    }
    return () => {
      setHashtagError(false);
    };
  }, [hashTagInput]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (hashtagSuggestionInput.trim()) {
        hashtagsAutoComplete();
      }
    }, 1000);

    return () => {
      setHashtagSuggestions([]);
      clearTimeout(timeOutId);
    };
  }, [hashtagSuggestionInput]);

  return (
    <>
      {showLoader ? (
        <Spinner />
      ) : (
        <Modal
          show={showTutorialUploadModal}
          onHide={() => {
            // don't hide modal on outside click
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="tutorialUploadScreen"
        >
          <Modal.Header className="p1">
            <Modal.Title id="contained-modal-title-vcenter">
              {sectionId === 1 && showStepsToFollow
                ? "How to add a Video Tutorial"
                : "Add a Video Tutorial"}
            </Modal.Title>
            {showStepsToFollow === false ? (
              <a className="addTutorialLink" href="#"
                onClick={() => {
                  setShowStepsToFollow(true);
                  setSectionId(1);
                  //updateUserSetting();
                }}
              >How to Add a Video Tutorial</a>
            ) : (<></>)}
            <Icon className="p1"
              iconName="Cancel"
              onClick={() => {
                setShowTutorialUploadModal(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            {sectionId === 1 && showStepsToFollow ? (
              <div>
                <div className="stepsToFollowDiv">
                  <div>
                    <p>Steps to follow</p>
                    <ul>

                      <li>
                        Go to Microsoft SharePoint {" "}
                        <a
                          target="_blank"
                          href={videoTutorailURL}
                        >
                          SDMPlus Portal
                        </a>
                        ,{" "}click the "Upload" icon in the menu bar, and select “Files”.
                      </li>
                      <li>
                        Destination Folder will be the current folder where you are uploading. To upload the video to SharePoint, select the video file and click “Open”.{" "}
                      </li>
                      <li>To copy the video link, follow these steps:</li>
                      <p>
                        1. Play the video in SharePoint. <br />
                        2. Click the "Share" button located at the top right corner of the screen.<br />
                        3. From the dropdown menu that appears, click “Embed code.” <br />
                        4. Then click the “Copy embed code” button at the bottom of the pane. <br />
                        5. Use the copied Embed link in the next section.
                      </p>
                    </ul>



                    <span className="dontShowThisMessage">
                      <input
                        type="checkbox"
                        onChange={() => {
                          setShowStepsValue(!showStepsValue);
                        }}
                        checked={showStepsValue}
                      />
                      <span>Don't show this again</span>
                    </span>
                  </div>
                  <div className="howToUpLoadImage"></div>
                </div>
                <div className="tutorialNextButton">
                  <button
                    onClick={() => {
                      setSectionId(2);
                      updateUserSetting();
                      setShowStepsToFollow(false);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <div className="addTutorialPopup">
                <div className="addTutorialDetails">
                  <label className="category-label">Category *</label>
                  <div className="">
                    <Dropdown
                      className="dropDowncontainerVideo wrapHeaderVideo"
                      data={categories}
                      setValue={setCategoryName}
                      value={categoryName}
                      enableDropdown={true}
                    />
                  </div>
                  <div className="tutorialInputFields">
                    <span>
                      Title <span>*</span>
                    </span>
                    <input
                      placeholder="Enter Title"
                      onChange={(e) => setTutorialName(e.target.value)}
                      value={tutorialName}
                    />
                  </div>
                  <div className="tutorialAddFields">
                    <div>
                      <div className="tutorialInputFields">
                        <span>
                          Embed Link <span>*</span>{" "}
                        </span>
                        <input
                          placeholder="Enter Link"
                          onChange={(e) => setTutorialUrl(e.target.value)}
                          value={tutorialUrl}
                        />
                      </div>

                      {artifactsList.map((artifactType, artifactTypeIndex) => (
                        <>
                          <div className="tutorialInputFields">
                            <span>{artifactType.artifactTypeName}</span>
                            <MultiSelectDropdown
                              id={artifactType.artifactTypeName}
                              className="tutorialToolsDropdown"
                              label={artifactType.artifactTypeName}
                              enableDropdown={true}
                              data={artifactType.artifactData}
                              setUpdatedData={(data) =>
                                updateSelectedArtifacts(data, artifactTypeIndex)
                              }
                            />
                          </div>

                          <div className="tutorialToolsTags">
                            {artifactType.artifactData.map(
                              (item, index) =>
                                item.isSelected && (
                                  <span key={item.id}>
                                    {item.name}
                                    <Icon
                                      iconName="cancel"
                                      onClick={() =>
                                        removeArtifact(artifactTypeIndex, index)
                                      }
                                    />
                                  </span>
                                )
                            )}
                          </div>
                        </>
                      ))}
                    </div>

                    <div className="tutorialPreview">
                      <p>Preview</p>
                      <div className="tutorialPreviewBlank">
                        {validateUrl() ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${tutorialUrl}`,
                            }}
                          />
                        ) : (
                          <p>A valid link will play video here</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <label>
                    Duration <span>*</span>
                    <span><div class="tooltipInfo"><Icon className="infoIcon" iconName="Info" />
                      <span class="tooltiptext">Please manually enter the duration of the video in mm:ss format. Example: if the duration is 5 minutes and 15 seconds, please enter 05:15</span>
                    </div></span></label>

                  <div className="tutorialInputFields duration">
                    <div className="minsContainer">
                      <label>Mins</label>
                      <div>
                        <input
                          placeholder="MM"
                          onChange={(e) => setTutorialMinutes(e.target.value.slice(0, 2))}
                          onInput={(e) => setTutorialMinutes(e.target.value.slice(0, 2))}
                          onBlur={(e) => {
                            if (e.target.value.length === 1) {
                              setTutorialMinutes('0' + tutorialMinutes)
                            }
                          }}
                          value={tutorialMinutes}
                          maxLength={2}
                          pattern="[0-9]*"
                          type="number"
                          className="minutes"
                          min="00" max="60"
                        />
                      </div>
                    </div>
                    <span className="timeformat"><div>:</div></span>
                    <div className="secContainer">
                      <label>Secs</label>
                      <div>
                        <input
                          placeholder="SS"
                          onChange={(e) => setTutorialSeconds(e.target.value.slice(0, 2))}
                          onInput={(e) => setTutorialSeconds(e.target.value.slice(0, 2))}
                          onBlur={(e) => {
                            if (e.target.value.length === 1) {
                              setTutorialSeconds('0' + tutorialSeconds)
                            }
                          }}
                          value={tutorialSeconds}
                          maxLength={2}
                          type="number"
                          className="seconds"
                          pattern="[0-9]*"
                          min="00" max="60"
                        /></div>
                    </div>
                  </div>
                  <div className="tutorialsHashTags">
                    <span>Hashtags</span>

                    <HashtagSuggestions
                      className="topicsHashtagsInput"
                      enableDropdown={true}
                      data={hashtagSuggestions}
                      setUpdateApiCall={setUpdateApiCall}
                      setHashtagSuggestions={setHashtagSuggestions}
                      searchValue={hashTagInput}
                      setSearchValue={setHashTagInput}
                      existingHashtags={addedHashTag}
                      userAddedHashtags={userAddedHashtags}
                      setUserAddedHashtags={setUserAddedHashtags}
                      hashtagSuggestionInput={hashtagSuggestionInput}
                      setHashtagSuggestionInput={setHashtagSuggestionInput}
                      closeHashtagForm={() => {
                        setHashTagInput("");
                        setUserAddedHashtags([]);
                      }}
                      submitHashtags={updateHashTag}
                      autoFocus={false}
                      showSubmitButton={true}
                      pageType={'AddVideoTutorial'}
                    />
                  </div>
                  <div className="hashTagsContainer _existingHashTagsOfActivity">
                    {addedHashTag.map((hashTag) => (
                      <span key={hashTag}>
                        {hashTag}
                        <Icon
                          iconName="cancel"
                          onClick={() => removeHashTag(hashTag)}
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              removeHashTag(hashTag);
                            }
                          }}
                          aria-label={`click to Remove ${hashTag} Hashtag`}
                          title="Cancel"
                          role="Button"
                        />
                      </span>
                    ))}
                  </div>
                  {error ? <p className="errorMsg">{error}</p> : <></>}
                  <div className="addTutorialButtons">
                    <div>
                      {showStepsToFollow ? (
                        <button onClick={() => setSectionId(1)}>Back</button>
                      ) : (
                        <></>
                      )}
                      <button onClick={() => submitTutorial()}>Publish</button>
                    </div>
                  </div>
                </div>
                <div className="addTutorialYourContribution">
                  <div class="topicTutorialsVideoSection">
                    <h5 className="TabYourContribution">Your Contributions</h5>
                    <ul>
                      {userContribution.map((item) => (
                        <li key={item.id}>
                          <div class="tutorialThumbnailTop">
                            <div className="tutorialThumbnailTitle">
                              <h5>{item.name}</h5>
                              <Icon
                                onClick={() => {
                                  handleEditMode(item);
                                }}
                                iconName="EditSolid12"
                              />
                              <Icon
                                onClick={() => {
                                  deleteTutorial(item.id, item.name);
                                }}
                                iconName="Delete"
                              />
                            </div>
                            <div className="thumbnailPlayButtonContainer">
                              <div class="thumbnailPlayButton"></div>
                              <span>
                                <Link
                                  to={`/tutorials/${item.id}`}
                                >
                                  Go to Video
                                </Link>
                              </span>
                            </div>
                          </div>
                          <div class="tutorialThumbnailBottom">
                            <span className="sampleTemplateRating">
                              <StarRatings
                                rating={item.rating}
                                starDimension="14px"
                                starRatedColor={"#ffd700"}
                                starEmptyColor={"#fff"}
                                starSpacing="3px"
                                svgIconViewBox="0 0 31.951 32"
                                svgIconPath="M 31.371 13.821 a 1.886 1.886 0 0 0 -0.93 -3.19 L 21.669 9.3 h 0 L 17.948 1.2 a 1.944 1.944 0 0 0 -3.588 0 L 10.373 9.3 h 0 l -8.9 1.329 a 1.886 1.886 0 0 0 -0.93 3.19 l 6.379 6.645 V 20.6 L 5.456 29.5 a 2.062 2.062 0 0 0 2.924 2.259 l 7.442 -4.253 h 0.133 L 23.4 31.63 a 2.062 2.062 0 0 0 2.924 -2.259 l -1.462 -8.9 v -0.133 Z"
                              />
                              <span>({item.ratingCount})</span>
                            </span>
                            <span className="tutorialPublishedDate">
                              <span>
                                Published on&nbsp;:&nbsp;
                                <span>{item.publishedDate}</span>
                              </span>
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default TutorialUploadModal;
