import React, { useState, useEffect,useRef } from "react";
import { Icon } from "@fluentui/react";
import { useRouteMatch, NavLink, Link } from "react-router-dom";
import { v4 as uniqueIdGenerator } from "uuid";
import { useAxios } from "../../context/AxiosContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { useAppInsights } from "../../context/TelemetryContext";
import fileIcons from "../../static/TemplateIcons";
import Spinner from "../../helpers/Spinner";
import { TabTitle } from "../../utils/GeneralFunctions";

const getDynamicFilterData = (phaseDynamicFilterTag, role) => {
  const filterMap = new Map(); // Map to store grouped tags by artifactTypeId
  const filterIds = Object.keys(phaseDynamicFilterTag);

  for (const id of filterIds) {
    const artifactTypeId = Number(id);
    const selectedTagIds = phaseDynamicFilterTag[artifactTypeId]?.selectedTagId || [];

    if (selectedTagIds.length > 0) {
      if (!filterMap.has(artifactTypeId)) {
        filterMap.set(artifactTypeId, new Set(selectedTagIds));
      } else {
        const existingTags = filterMap.get(artifactTypeId);
        selectedTagIds.forEach((tagId) => existingTags.add(tagId));
      }
    }
  }
  if (role.id !== 0) {
    if (!filterMap.has(16)) {
      filterMap.set(16, new Set([role.id]));
    } else {
      filterMap.get(16).add(role.id);
    }
  }

  const selectedFiltersIds = Array.from(filterMap.entries()).map(
    ([artifactTypeId, tagSet]) =>
      `${artifactTypeId}-${Array.from(tagSet).sort((a, b) => a - b).join(",")}`
  );

  return selectedFiltersIds.length > 0 ? selectedFiltersIds.join("|") : "0";
};

const MatrixView = ({
  selectedComplexityId,
  solutionMethodId,
  setShowMatrixView,
  selectedRoleId,
  searchInput,
  projectTypeId,
  solutionMethod,
  coOwners,
  realizeValueToggler,
  isProgramDelivery,
  isPhaseSelected,
  userDataAvailable,
  userActivityFilter,
  isEmpty,
  loading,
  setLoading,
  setUserDataAvailable,
}) => {
  const locationUrl = window.location.pathname;
  const { url } = useRouteMatch();
  const { axiosGetService, axiosPostService, userAlias } = useAxios();
  const {
    phase,
    parentPhase,
    complexity,
    setExpandedActivityGroups,
    expandedActivityGroups,
    phaseHashtags,
    breadcrumbs,
    setBreadcrumbs,
    phaseDynamicFilterTag,
    role,
    endUserView,
    applyfilterTrigger,
    setUserActivityFilter
  } = useLeftMenuCollapse();
  const { track } = useAppInsights();
  const [phases, setPhases] = useState([]);
  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false);
  const isInitialMount = useRef(true);
  const [templateDownload, setTemplateDownload] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [collapsePhases, setCollapsePhases] = useState([]);
  const [solutionMethodsData, setSolutionMethodsData] = useState({});
  const [phasesData, setPhasesData] = useState({});
  const [solutionMethodsExpand, setSolutionMethodsExpand] = useState([]);
  const [filterColourLegend, setFilterColourLegend] = useState([]);
  const [showDefaultStateModal, setShowDefaultStateModal] = useState(false);
  const [isCollapse, setIsCollapse] = useState();
  const [filterColourLegendGroupId, setFilterColourLegendGroupId] = useState(
    []
  );
  const [isFirstAPICallInProgress, setIsFirstAPICallInProgress] = useState(false);
  const prevPhaseDynamicFilterTag = useRef(null);
  const [groupColorLegend, setGroupColorLegend] = useState([]);

  const collapseDefaultStateModal = (e) => {
    const checkContainer = document.getElementById("defaultViewStateContainer");
    if (checkContainer && !checkContainer.contains(e.target)) {
      setShowDefaultStateModal(false);
    }
  };
  const handlePhaseExpand = (phaseId, solutionMethodId) => {
    if (!collapsePhases.includes(phaseId)) {
      setCollapsePhases([...collapsePhases, phaseId]);
    } else {
      const updatedCollapsePhasesIds = collapsePhases.filter(
        (item) => item !== phaseId
      );
      setCollapsePhases(updatedCollapsePhasesIds);
    }
  };
  const handleIsCollapseChange = (newState) => {
    if (newState !== isCollapse) {
      setShowSpinner(true);
      axiosPostService(
        `api/sdmsolutionmethod/setiscollapse/${newState}/${solutionMethodId}`,
        null
      ).then((response) => {
        if (response.data.status === true) {
          setIsCollapse(newState);
          if (newState === 0) ExpandAll();
          else setCollapsePhases([]);
        }
        setShowSpinner(false);
      });
    }
  };
  const handleGroupExpand = (type, phaseName, groupId) => {
    const id = `${phaseName}_${groupId}`;
    if (!expandedActivityGroups.includes(id)) {
      setExpandedActivityGroups((prevState) => [...prevState, id]);
    } else if (type !== "filterUpdate") {
      const newt = expandedActivityGroups.filter((item) => item !== id);
      setExpandedActivityGroups(newt);
    }    
  };
  const handleColourFilterChange = (group) => {
    if (filterColourLegendGroupId.includes(group.groupId)) {
      if (
        !filterColourLegendGroupId.some((colourLegendGroupID) =>
          groupColorLegend
            .filter(
              (colourLegend) =>
                colourLegend.groupId !== group.groupId &&
                colourLegend.colourCode === group.colourCode
            )
            .map((item) => item.groupId)
            .includes(colourLegendGroupID)
        )
      ) {
        setFilterColourLegend((prevState) =>
          prevState.filter((item) => item !== group.colourCode)
        );
      }
      setFilterColourLegendGroupId((prevState) =>
        prevState.filter((item) => item !== group.groupId)
      );
    } else {
      setFilterColourLegendGroupId((prevState) => [
        ...prevState,
        group.groupId,
      ]);
      if (!filterColourLegend.includes(group.colourCode)) {
        setFilterColourLegend((prevState) => [...prevState, group.colourCode]);
      }
    }
  };
  const handleSubgroupExpand = (type, subgroupId, subGroupName, phaseName) => {
    const id = `${phaseName}_${subgroupId}_${subGroupName}`;
    if (!expandedActivityGroups.includes(id)) {
      setExpandedActivityGroups((prevState) => [...prevState, id]);
    } else if (type !== "filterUpdate") {
      const newt = expandedActivityGroups.filter((item) => item !== id);
      setExpandedActivityGroups(newt);
    }
  };
  const ExpandAll = () => {
    setCollapsePhases(phases.map((phase) => phase.phaseId));
  };


  const getSolutionMethodData = async (item) => {
    await axiosGetService(
      `/api/sdmsolutionmethod/getmergedphases/${item.solutionMethodId
      }/${false}/${filterColourLegend.length > 0 ? selectedComplexityId : 0
      }/${0}/${0}/${0}/false/${filterColourLegend.length > 0
        ? encodeURIComponent(filterColourLegend.join())
        : 0
      }`
    ).then((response) => {
      const data = [];
      for (const phase of response.data.phasesList) {
        data.push({
          ...phase,
          groups: cloneGroup(phase.activityGroupsData),
        });
      }
      setSolutionMethodsData({
        ...solutionMethodsData,
        [item.solutionMethodId]: data,
      });
    });
  };

  const getPhaseData = async (phaseId, solutionMethodId) => {
    const encodedHashtags = phaseHashtags.map((item) => {
      return encodeURIComponent(item);
    });
    await axiosGetService(
      `api/sdmphase/getphasemergedactivities/${solutionMethodId}/${phaseId}/${complexity.id
      }/${false}/${getDynamicFilterData(phaseDynamicFilterTag, role)}/${searchInput.trim().length > 0
        ? encodeURIComponent(searchInput.trim())
        : 0
      }/${phaseHashtags.length > 0 ? encodedHashtags.toString() : 0}/false/${filterColourLegend.length > 0
        ? encodeURIComponent(filterColourLegend.join())
        : 0
      }`
    ).then((response) => {
      setPhasesData((prevState) => ({
        ...prevState,
        [phaseId]: cloneGroup(response.data.activityGroupsData),
      }));
    });
  };

  const handleSolutionMethodsExpand = (
    type,
    solutionMethod,
    parentPhaseName
  ) => {
    const id = `${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}_${parentPhaseName}`;
    if (!solutionMethodsExpand.includes(id)) {
      setSolutionMethodsExpand((prevState) => [...prevState, id]);
    } else if (type !== "filterUpdate") {
      const newt = solutionMethodsExpand.filter((item) => item !== id);
      setSolutionMethodsExpand(newt);
    }
    if (!solutionMethodsData[solutionMethod.solutionMethodId]) {
      getSolutionMethodData(solutionMethod);
    }
  };

  const handleSolutionMethodPhaseExpand = (
    phaseId,
    solutionMethodId,
    parentPhaseName
  ) => {
    const id = `${phaseId}_${solutionMethodId}_${parentPhaseName}`;
    if (!solutionMethodsExpand.includes(id)) {
      setSolutionMethodsExpand((prevState) => [...prevState, id]);
    } else {
      const newt = solutionMethodsExpand.filter((item) => item !== id);
      setSolutionMethodsExpand(newt);
    }
    if (!phasesData[phaseId]) {
      getPhaseData(phaseId, solutionMethodId);
    }
  };

  const handleGroupExpandOnRoleSelect = () => {
    if (
      selectedRoleId !== 0 ||
      getDynamicFilterData(phaseDynamicFilterTag, role) !== 0 ||
      selectedComplexityId !== 3
    ) {
      const idStore = [];
      for (const phase of phases) {
        for (const group of phase.groups) {
          idStore.push(`${phase.phaseName}_${group.groupId}`);
          for (const activity of group.activities) {
            if (activity.isSubgroup) {
              idStore.push(
                `${phase.phaseName}_${activity.subGroupId}_${activity.subGroupName}`
              );
            }
          }
        }
      }
      setExpandedActivityGroups(idStore);
    }
  };

  const cloneActivity = (activities, activityCount) => {
    const activityClone = [];
    for (const activity in activities) {
      if (activity.includes("activity")) {
        activityClone.push({
          ...activities[activity],
          dragNDropId: uniqueIdGenerator(),
          isSubgroup: false,
        });
      } else if (activity.includes("subGroups")) {
        const subgroupActivities = [];
        for (const subgroupActivity in activities[activity]) {
          if (subgroupActivity.includes("activity")) {
            subgroupActivities.push({
              ...activities[activity][subgroupActivity],
              dragNDropId: uniqueIdGenerator(),
            });
          }
        }
        activityClone.push({
          ...activities[activity],
          dragNDropId: uniqueIdGenerator(),
          isSubgroup: true,
          activities: subgroupActivities,
        });
        activityCount += subgroupActivities.length;
      }
    }
    return activityClone;
  };

  const cloneGroup = (data) => {
    let activityCount = 0;
    const activityGroupClone = [];
    for (const group in data) {
      if (group.includes("activityGroups") && group.includes("activity")) {
        const groupClone = {
          dragNDropId: uniqueIdGenerator(),
          groupId: data[group].groupId,
          groupName: data[group].groupName,
          isParentActivity: data[group].isParentActivity,
          activities: cloneActivity(data[group], activityCount),
          approverMembersList: data[group].approverMembersList,
          reviewerMembersList: data[group].reviewerMembersList,
          isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
          colourCode: data[group].colourCode,
          isSolutionMethod: false,
          isGroupRefered: data[group].isGroupRefered,
          isGroupReferredWithActivity: data[group].isGroupReferredWithActivity,
          referedFromSolutionMethodName:
            data[group].referedFromSolutionMethodName,
          referedFromPhaseName: data[group].referedFromPhaseName,
        };
        activityCount += groupClone.activities.length;
        activityGroupClone.push(groupClone);
      } else if (group.includes("activity")) {
        const orphanActivityClone = {
          dragNDropId: uniqueIdGenerator(),
          groupId: 0,
          groupName: "",
          isParentActivity: data[group].isParentActivity,
          activities: [
            {
              ...data[group],
              dragNDropId: uniqueIdGenerator(),
              isSubgroup: false,
            },
          ],
          approverMembersList: [],
          reviewerMembersList: [],
          isMethodOwnerOrCoOwner: [],
          isSolutionMethod: false,
        };
        activityCount += orphanActivityClone.activities.length;
        activityGroupClone.push(orphanActivityClone);
      } else if (group.includes("solutionMethod")) {
        const groupClone = {
          dragNDropId: uniqueIdGenerator(),
          solutionMethodId: data[group].solutionMethodId,
          solutionMethodName: data[group].solutionMethodName,
          activities: [],
          isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
          colourCode: data[group].colourCode,
          isSolutionMethod: true,
          projectTypeId: data[group].projectTypeId,
        };
        activityCount += groupClone.activities.length;
        activityGroupClone.push(groupClone);
      }
    }
    return activityGroupClone;
  };

  const getMatrixViewDataForUserSettings = async () => {
    let phaseIds;
    const encodedHashtags = phaseHashtags.map((item) => {
      return encodeURIComponent(item);
    });
    setTemplateDownload(null);
    setShowSpinner(true);
    await axiosGetService(
      `api/sdmsolutionmethod/getmatrixview/${solutionMethodId}/${selectedComplexityId}/${userActivityFilter[0]?.filters}/${searchInput.trim().length > 0
        ? encodeURIComponent(searchInput.trim())
        : 0
      }/${phaseHashtags.length > 0 ? encodedHashtags.toString() : 0}/${filterColourLegend.length > 0
        ? encodeURIComponent(filterColourLegend.join())
        : 0
      }`
    ).then((response) => {
      const data = [];
      for (const phase of response.data.phasesList) {
        data.push({
          ...phase,
          groups: cloneGroup(phase.activityGroupsData),
        });
      }
      phaseIds = response.data.phasesList.map(({ phaseId }) => {
        return phaseId;
      });

      setPhases(data);
      setShowSpinner(false);
      track(2, 1600, solutionMethodId, solutionMethod.solutionMethodName);
    });
    return phaseIds;
  };

  const compareFilterStrings = (userSettingsString, dynamicMethodString) => {
    const processArtifactGroup = (group) => {
      if (!group) return ''; 
      const [artifactId, tags] = group.split('-');
      if (!tags) return '';
      const sortedTags = tags.split(',').sort((a, b) => a - b).join(',');
      return `${artifactId}-${sortedTags}`;
    };
  
    if (!userSettingsString || !dynamicMethodString || userSettingsString === "0" || dynamicMethodString === "0") {
      return false;
    }
  
    const userSettingsGroups = userSettingsString.split('|').map(processArtifactGroup);
    const dynamicMethodGroups = dynamicMethodString.split('|').map(processArtifactGroup);
  
    if (userSettingsGroups.includes("0") || dynamicMethodGroups.includes("0")) {
      return false;
    }
  
    return userSettingsGroups.sort().join('|') === dynamicMethodGroups.sort().join('|');
  };

  const getMatrixViewData = async () => {
    const isEqual = compareFilterStrings(userActivityFilter[0]?.filters, getDynamicFilterData(
      phaseDynamicFilterTag,
      role
    ));
    if(!isEqual){
    setUserDataAvailable(false);

    setUserActivityFilter([]);
    let phaseIds;
    const encodedHashtags = phaseHashtags.map((item) => {
      return encodeURIComponent(item);
    });
    setTemplateDownload(null);
    setShowSpinner(true);
    await axiosGetService(
      `api/sdmsolutionmethod/getmatrixview/${solutionMethodId}/${selectedComplexityId}/${getDynamicFilterData(
        phaseDynamicFilterTag,
        role
      )}/${searchInput.trim().length > 0
        ? encodeURIComponent(searchInput.trim())
        : 0
      }/${phaseHashtags.length > 0 ? encodedHashtags.toString() : 0}/${filterColourLegend.length > 0
        ? encodeURIComponent(filterColourLegend.join())
        : 0
      }`
    ).then((response) => {
      const data = [];
      for (const phase of response.data.phasesList) {
        data.push({
          ...phase,
          groups: cloneGroup(phase.activityGroupsData),
        });
      }
      phaseIds = response.data.phasesList.map(({ phaseId }) => {
        return phaseId;
      });

      setPhases(data);
      setShowSpinner(false);
      track(2, 1600, solutionMethodId, solutionMethod.solutionMethodName);

    });
    return phaseIds;}
  };

  const getCollapseAll = async () => {
    let collapse;
    await axiosGetService(
      `api/sdmsolutionmethod/getiscollapse/${solutionMethodId}`
    ).then((response) => {
      collapse = response.data;
      setIsCollapse(response.data);
    });
    return collapse;
  };

  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${solutionMethodId}&itemTypeId=15&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0&isNotifications=false
			&solutionMethodId=${solutionMethodId}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());

      const projectBreadcrumb = {
        title: breadcrumbTitle[1],
        type: "projectType",
        path: `/project/${projectTypeId}`,
        parent: ["home"],
      };
      const solutionMethodBreadcrumb = {
        title: breadcrumbTitle[2],
        type: "solutionMethod",
        path: `/project/${projectTypeId}/method/${solutionMethodId}`,
        parent: "projectType",
      };
      const newBreadcrumbs = breadcrumbs.slice(
        0,
        breadcrumbs.length > 1 && breadcrumbs[1].type === "search" ? 2 : 1
      );
      newBreadcrumbs.push(projectBreadcrumb);
      newBreadcrumbs.push(solutionMethodBreadcrumb);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[2]);
    });
  };

  const getPhaseDataWithFilter = async (solutionMethodId, phaseId) => {
    const encodedHashtags = phaseHashtags.map((item) => {
      return encodeURIComponent(item);
    });

    await axiosGetService(
      `api/sdmphase/getphasemergedactivities/${solutionMethodId}/${phaseId}/${complexity.id
      }/${false}/${getDynamicFilterData(phaseDynamicFilterTag, role)}/${searchInput.trim().length > 0
        ? encodeURIComponent(searchInput.trim())
        : 0
      }/${phaseHashtags.length > 0 ? encodedHashtags.toString() : 0}/false/${filterColourLegend.length > 0
        ? encodeURIComponent(filterColourLegend.join())
        : 0
      }`
    ).then((response) => {
      setPhasesData((prevState) => ({
        ...prevState,
        [phaseId]: cloneGroup(response.data.activityGroupsData),
      }));
    });
  };

  const checkPhaseData = () => {
    for (const solutionMethodId in solutionMethodsData) {
      if (solutionMethodsData[solutionMethodId])
        for (const phase of solutionMethodsData[solutionMethodId]) {
          getPhaseDataWithFilter(solutionMethodId, phase.phaseId);
        }
    }
  };

  const getGroupColorLegend = () => {
    const phasesGroupColorLegend = {};
    for (const phase of phases) {
      for (const group of phase.groups) {
        if (group.groupId) {
          const id = `${group.groupName}_${group.colourCode}`;
          if (!phasesGroupColorLegend.hasOwnProperty(id)) {
            phasesGroupColorLegend[id] = {
              colourCode: group.colourCode,
              groupName: group.groupName,
              groupId: group.groupId,
            };
          }
        }
      }
    }
    for (const solutionMethodId in solutionMethodsData) {
      if (solutionMethodsData[solutionMethodId])
        for (const phase of solutionMethodsData[solutionMethodId]) {
          if (phasesData[phase.phaseId]) {
            for (const group of phasesData[phase.phaseId]) {
              if (group.groupId) {
                const id = `${group.groupName}_${group.colourCode}`;
                if (!phasesGroupColorLegend.hasOwnProperty(id)) {
                  phasesGroupColorLegend[id] = {
                    colourCode: group.colourCode,
                    groupName: group.groupName,
                    groupId: group.groupId,
                  };
                }
              }
            }
          }
        }
    }
    let updatedGroupLegend = [];
    for (const colorLegend in phasesGroupColorLegend) {
      if (
        !groupColorLegend.some(
          ({ groupId }) =>
            groupId === phasesGroupColorLegend[colorLegend].groupId
        )
      ) {
        updatedGroupLegend.push(phasesGroupColorLegend[colorLegend]);
      }
    }
    updatedGroupLegend = updatedGroupLegend.map((group) => {
      if (group.colourCode !== null) return group;
      else {
        group.colourCode = "-1";
        return group;
      }
    });
    setGroupColorLegend([...groupColorLegend, ...updatedGroupLegend]);
  };

  const renderOrphanActivity = (item, group) => {
    return (
      <>
        {group.activities.length !== 0 ? (
          group.activities.map((activity) => (
            <div
              className="activityListMainContainer  orphanActivityMatrix"
              key={activity.dragNDropId}
            >
              <div className="activityContainer">
                <span className="activitytypeColor" />
                {activity.isMethodOwnerOrCoOwner &&
                  activity.isDrafted &&
                  !endUserView ? (
                  <span
                    className={
                      activity.isDrafted ? "draftActivity" : "nonDraftActivity"
                    }
                  >
                    Draft
                  </span>
                ) : (
                  <></>
                )}
                <div className="activityTitle" title={activity.activityTitle}>
                  <span className="titleOfActivity" style={{display:'inline-flex'}}>
                  {/* <span style={{ whiteSpace: 'pre' }}>{activity.isProcessStep ? '⭐ ' : '      '}</span> */}
                  <span title='Mandatory' className={activity.isProcessStep ? 'mandatoryIcon' : 'blankSpaceForMatrixViewActivityIcon'}></span>
									<span  title='Business Rule' className={activity.hasBusinessRule ? 'businessIcon' : 'blankSpaceForMatrixViewActivityIcon'}></span>
                    {activity.activityIdentifier ? (
                      <>

                        <NavLink
                          activeClassName="activityDetail"
                          className='contentIcons'
                          to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${activity.phaseId}/parentPhase/null/activity/${activity.activityIdentifier}?complexity=${selectedComplexityId}`}
                          key={activity.dragNDropId}
                          tabIndex={0}
                          aria-label={activity.activityTitle}
                          onKeyPress={(e) => {
                            if (
                              e.which === 13 &&
                              !e.ctrlKey &&
                              !e.shiftKey &&
                              !e.altKey
                            ) {
                              track(
                                1,
                                1603,
                                "Activity",
                                activity.activityTitle
                              );
                            }
                          }}
                          onClick={(e) => {
                            if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
                              track(
                                1,
                                1603,
                                "Activity",
                                activity.activityTitle
                              );
                            }
                          }}
                        >
                          {activity.activityTitle}
                        </NavLink>
                      </>
                    ) : (
                      <>
                      {activity.activityTitle}
                      </>
                    )}
                  </span>
                </div>

                <Info
                  templateCount={activity.templateCount}
                  activityId={activity.activityIdentifier}
                  templateDownload={templateDownload}
                  setTemplateDownload={setTemplateDownload}
                  data={group}
                  complexityEnabled={
                    phase.enableComplexity ||
                    (phase.id === null && parentPhase.enableComplexity)
                  }
                  phaseData={item}
                  activity={activity}
                  complexityId={selectedComplexityId}
                  url={url}
                />
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderGroup = (item, group, isDelivery) => {
    return (
      <div className="activityListMainContainer activityGroupParent" role="tree">
        <div className="activityContainer activityGroup" role="none">
          <span className="activitytypeColor" />
          <div
            className="activityTitle"
            title={group.groupName}
            onKeyPress={(e) => {
              if (e.which === 13) {
                handleGroupExpand("userUpdate", item.phaseName, group.groupId);
              }
            }}
            onClick={() =>
              handleGroupExpand("userUpdate", item.phaseName, group.groupId)
            }
            tabIndex={0}
            role="treeitem" aria-setsize={group.activities.length + 1} aria-posinset="1" aria-level="1"
            aria-expanded={
              expandedActivityGroups.includes(
                `${item.phaseName}_${group.groupId}`
              )
                ? "true"
                : "false"
            }
            aria-label={group.groupName}
          >
            {expandedActivityGroups.includes(
              `${item.phaseName}_${group.groupId}`
            ) ? (
              <Icon iconName="CaretDownSolid8" />
            ) : (
              <Icon iconName="CaretRightSolid8" />
            )}
            <span className="titleOfActivity">
              {group.groupName}
            </span>
            {group.isGroupRefered ? (
              <>
                <span
                  className="activityReferredIcon"
                  title={`Linked Group from solution method ${group.referedFromSolutionMethodName} and phase ${group.referedFromPhaseName}`}
                ></span>
              </>
            ) : (
              <></>
            )}

            <span
              className="groupColor"
              style={{ background: group.colourCode }}
            ></span>
          </div>
        </div>
        {expandedActivityGroups.includes(
          `${item.phaseName}_${group.groupId}`
        ) ? (
          group.activities.length !== 0 ? (
            group.activities.map((activity) => (
              <div key={item.dragNDropId}>
                {activity.isSubgroup ? (
                  <div
                    className={
                      activity.isParentActivity || isDelivery
                        ? "delivery subGroupContainer"
                        : !activity.isParentActivity
                          ? "orangeDelivery subGroupContainer"
                          : "subGroupContainer"
                    }
                    role="treeitem"
                    aria-level="1"
                  >
                    <div className="activityChildContainer">
                      <div class="activityListMainContainer activityGroupParent" role="tree">
                        <div
                          class="activityContainer activityGroup"
                          tabIndex="0"
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              handleSubgroupExpand(
                                "userUpdate",
                                activity.subGroupId,
                                activity.subGroupName,
                                item.phaseName
                              );
                            }
                          }}
                          onClick={() => {
                            handleSubgroupExpand(
                              "userUpdate",
                              activity.subGroupId,
                              activity.subGroupName,
                              item.phaseName
                            );
                          }}
                          aria-label={activity.subGroupName}
                          role="treeitem"
                          aria-expanded={
                            expandedActivityGroups.includes(
                              `${item.phaseName}_${group.groupId}_${activity.subGroupName}`
                            )
                              ? "true"
                              : "false"
                          }
                        >
                          <span class="activitytypeColor"></span>
                          <div
                            class="activityTitle"
                            title={activity.subGroupName}
                          >
                            {expandedActivityGroups.includes(
                              `${item.phaseName}_${activity.subGroupId}_${activity.subGroupName}`
                            ) ? (
                              <Icon iconName="CaretDownSolid8" />
                            ) : (
                              <Icon iconName="CaretRightSolid8" />
                            )}
                            <span class="titleOfActivity">
                              {activity.subGroupName}
                            </span>
                          </div>
                        </div>
                        {expandedActivityGroups.includes(
                          `${item.phaseName}_${activity.subGroupId}_${activity.subGroupName}`
                        ) &&
                          activity.activities.map((subgroupActivity) => (
                            <div
                              key={subgroupActivity.dragNDropId}
                              className={
                                subgroupActivity.isParentActivity || isDelivery
                                  ? "delivery"
                                  : !subgroupActivity.isParentActivity
                                    ? "orangeDelivery"
                                    : ""
                              }
                            >
                              <div className="activityChildContainer" role="none">
                                <div role="none">
                                  <div role="none"
                                    className="activityChildTitle"
                                    title={subgroupActivity.activityTitle}
                                  >
                                    {subgroupActivity.isMethodOwnerOrCoOwner &&
                                      subgroupActivity.isDrafted &&
                                      !endUserView ? (
                                      <span
                                        className={
                                          subgroupActivity.isDrafted
                                            ? "draftActivity"
                                            : "nonDraftActivity"
                                        }
                                      >
                                        Draft
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    <span className="titleOfActivity">
                                      {subgroupActivity.activityIdentifier ? (
                                        <>
                                          <NavLink
                                            activeClassName="activityDetail"
                                            to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${subgroupActivity.phaseId}/parentPhase/null/activity/${subgroupActivity.activityIdentifier}?complexity=${selectedComplexityId}`}
                                            key={subgroupActivity.dragNDropId}
                                            tabIndex={0}
                                            aria-level="2"
                                            aria-label={`${subgroupActivity.activityTitle} activity`}
                                            onKeyPress={(e) => {
                                              if (
                                                e.which === 13 &&
                                                !e.ctrlKey &&
                                                !e.shiftKey &&
                                                !e.altKey
                                              ) {
                                                track(
                                                  1,
                                                  1603,
                                                  "Activity",
                                                  subgroupActivity.activityTitle
                                                );
                                              }
                                            }}
                                            onClick={(e) => {
                                              if (
                                                !e.ctrlKey &&
                                                !e.shiftKey &&
                                                !e.altKey
                                              ) {
                                                track(
                                                  1,
                                                  1603,
                                                  "Activity",
                                                  subgroupActivity.activityTitle
                                                );
                                              }
                                            }}
                                            role="treeitem"
                                          >
                                            {subgroupActivity.activityTitle}
                                          </NavLink>
                                        </>
                                      ) : (
                                        <>{subgroupActivity.activityTitle}</>
                                      )}
                                    </span>
                                  </div>

                                  <Info
                                    templateCount={
                                      subgroupActivity.templateCount
                                    }
                                    activityId={
                                      subgroupActivity.activityIdentifier
                                    }
                                    templateDownload={templateDownload}
                                    setTemplateDownload={setTemplateDownload}
                                    data={subgroupActivity}
                                    complexityEnabled={
                                      phase.enableComplexity ||
                                      (phase.id === null &&
                                        parentPhase.enableComplexity)
                                    }
                                    phaseData={item}
                                    activity={subgroupActivity}
                                    complexityId={selectedComplexityId}
                                    url={url}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={
                        activity.isParentActivity || isDelivery
                          ? "delivery"
                          : !activity.isParentActivity
                            ? "orangeDelivery"
                            : ""
                      }
                    >
                      <div className="activityChildContainer" role="none">
                        <div role="none">
                          <div role="none"
                            className="activityChildTitle"
                            title={activity.activityTitle}
                          >
                            {activity.isMethodOwnerOrCoOwner &&
                              activity.isDrafted &&
                              !endUserView ? (
                              <span
                                className={
                                  activity.isDrafted
                                    ? "draftActivity"
                                    : "nonDraftActivity"
                                }
                              >
                                Draft
                              </span>
                            ) : (
                              <></>
                            )}
                            <span className="titleOfActivity" style={{display:'inline-flex'}}>
                            {/* <span style={{ whiteSpace: 'pre' }}>{activity.isProcessStep ? '⭐ ' : '      '}</span> */}
                            <span title='Mandatory' className={activity.isProcessStep ? 'mandatoryIcon' : 'blankSpaceForMatrixViewActivityIcon'}></span>
										     	<span title='Business Rule' className={activity.hasBusinessRule ? 'businessIcon' : 'blankSpaceForMatrixViewActivityIcon'}></span>
                              {activity.activityIdentifier ? (
                                <>
                                  <NavLink
                                    activeClassName="activityDetail"
                                    className='contentIcons'
                                    to={`/project/${projectTypeId}/method/${solutionMethodId}/phase/${activity.phaseId}/parentPhase/null/activity/${activity.activityIdentifier}?complexity=${selectedComplexityId}`}
                                    key={activity.dragNDropId}
                                    tabIndex={0}
                                    aria-label={`${activity.activityTitle} activity`}
                                    onKeyPress={(e) => {
                                      if (
                                        e.which === 13 &&
                                        !e.ctrlKey &&
                                        !e.shiftKey &&
                                        !e.altKey
                                      ) {
                                        track(
                                          1,
                                          1603,
                                          "Activity",
                                          activity.activityTitle
                                        );
                                      }
                                    }}
                                    onClick={(e) => {
                                      if (
                                        !e.ctrlKey &&
                                        !e.shiftKey &&
                                        !e.altKey
                                      ) {
                                        track(
                                          1,
                                          1603,
                                          "Activity",
                                          activity.activityTitle
                                        );
                                      }
                                    }}
                                    role="treeitem" aria-setsize={group.activities.length}  aria-level="2"
                                  >
                                   {activity.activityTitle}
                                  </NavLink>
                                </>
                              ) : (
                                <>
                               {activity.activityTitle}
                                </>
                              )}
                            </span>
                          </div>

                          <Info
                            templateCount={activity.templateCount}
                            activityId={activity.activityIdentifier}
                            templateDownload={templateDownload}
                            setTemplateDownload={setTemplateDownload}
                            data={group}
                            complexityEnabled={
                              phase.enableComplexity ||
                              (phase.id === null &&
                                parentPhase.enableComplexity)
                            }
                            phaseData={item}
                            activity={activity}
                            complexityId={selectedComplexityId}
                            url={url}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))
          ) : (
            <div className="activityChildContainer">
              <div className="activityChildTitle noActivities">
                <span className="titleOfActivity">No Activities Found</span>
              </div>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderSolutionMethod = (
    solutionMethod,
    parentPhaseName,
    isDelivery
  ) => {
    return (
      <div className="activityListMainContainer activityGroupParent renderSolutionMethod">
        <div className="activityContainer activityGroup">
          <span className="activitytypeColor" />
          <div
            className="activityTitle"
            title={solutionMethod.solutionMethodName}
          >
            {solutionMethodsExpand.includes(
              `${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}_${parentPhaseName}`
            ) ? (
              <Icon
                iconName="CaretDownSolid8"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleSolutionMethodsExpand(
                      "userUpdate",
                      solutionMethod,
                      parentPhaseName
                    );
                  }
                }}
                onClick={() =>
                  handleSolutionMethodsExpand(
                    "userUpdate",
                    solutionMethod,
                    parentPhaseName
                  )
                }
                aria-label={`Click here to Collapse ${solutionMethod.solutionMethodName} Method `}
                role="button"
                aria-expanded={"true"}
              />
            ) : (
              <Icon
                iconName="CaretRightSolid8"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleSolutionMethodsExpand(
                      "userUpdate",
                      solutionMethod,
                      parentPhaseName
                    );
                  }
                }}
                onClick={() =>
                  handleSolutionMethodsExpand(
                    "userUpdate",
                    solutionMethod,
                    parentPhaseName
                  )
                }
                title="Expand Group Icon"
                aria-label={`Click here to Expand ${solutionMethod.solutionMethodName} Method `}
                role="button"
                aria-expanded={"false"}
              />
            )}
            <span
              aria-label={solutionMethod.solutionMethodName}
              className="titleOfActivity"
            >
              <Link
                to={`/project/${solutionMethod.projectTypeId}/method/${solutionMethod.solutionMethodId}?showMatrixView=true`}
              >
                {solutionMethod.solutionMethodName}
              </Link>
            </span>
            <span
              class="activityReferredIcon"
              title="Linked solution method"
            ></span>
          </div>
        </div>
        {solutionMethodsExpand.includes(
          `${solutionMethod.solutionMethodName}_${solutionMethod.solutionMethodId}_${parentPhaseName}`
        ) ? (
          solutionMethodsData[solutionMethod.solutionMethodId] ? (
            solutionMethodsData[solutionMethod.solutionMethodId].map(
              (phase) => (
                <div key={phase.phaseId}>
                  <div
                    className={
                      phase.isParentActivity || isDelivery
                        ? "delivery subGroupContainer"
                        : !phase.isParentActivity
                          ? "orangeDelivery subGroupContainer"
                          : "subGroupContainer"
                    }
                  >
                    <div className="activityChildContainer">
                      <div class="activityListMainContainer activityGroupParent" role="tree">
                        <div class="activityContainer activityGroup" role="none">
                          <span class="activitytypeColor"></span>
                          <div class="activityTitle" title={phase.phaseName}>
                            {solutionMethodsExpand.includes(
                              `${phase.phaseId}_${solutionMethod.solutionMethodId}_${parentPhaseName}`
                            ) ? (
                              <Icon
                                iconName="CaretDownSolid8"
                                tabIndex="0"
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    handleSolutionMethodPhaseExpand(
                                      phase.phaseId,
                                      solutionMethod.solutionMethodId,
                                      parentPhaseName
                                    );
                                  }
                                }}
                                onClick={() => {
                                  handleSolutionMethodPhaseExpand(
                                    phase.phaseId,
                                    solutionMethod.solutionMethodId,
                                    parentPhaseName
                                  );
                                }}
                                role="treeitem"
                                aria-expanded={"true"}
                              />
                            ) : (
                              <Icon
                                iconName="CaretRightSolid8"
                                tabIndex="0"
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    handleSolutionMethodPhaseExpand(
                                      phase.phaseId,
                                      solutionMethod.solutionMethodId,
                                      parentPhaseName
                                    );
                                  }
                                }}
                                onClick={() => {
                                  handleSolutionMethodPhaseExpand(
                                    phase.phaseId,
                                    solutionMethod.solutionMethodId,
                                    parentPhaseName
                                  );
                                }}
                                role="treeitem"
                                aria-expanded={"false"}
                              />
                            )}
                            <span
                              aria-label={phase.phaseName}
                              class="titleOfActivity"
                            >
                              <Link
                                to={`/project/${solutionMethod.projectTypeId}/method/${solutionMethod.solutionMethodId}/phase/${phase.phaseId}/parentPhase/null`}
                              >
                                {phase.phaseName}
                              </Link>
                            </span>
                          </div>
                        </div>
                        {solutionMethodsExpand.includes(
                          `${phase.phaseId}_${solutionMethod.solutionMethodId}_${parentPhaseName}`
                        ) ? (
                          <>
                            {phasesData[phase.phaseId] ? (
                              phasesData[phase.phaseId].map((group) => (
                                <div
                                  className={
                                    group.isParentActivity ||
                                      group.isSolutionMethod ||
                                      isDelivery
                                      ? "delivery"
                                      : ""
                                  }
                                  key={group.dragNDropId}
                                >
                                  <div className="activityChildContainer">
                                    {group.isSolutionMethod ? (
                                      <>
                                        {renderSolutionMethod(
                                          group,
                                          phase.phaseName,
                                          true
                                        )}
                                      </>
                                    ) : group.groupId ? (
                                      <>
                                        {renderGroup(
                                          phasesData[phase.phaseId],
                                          group,
                                          true
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {renderOrphanActivity(
                                          phasesData[phase.phaseId],
                                          group
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="activityChildContainer">
                                <Spinner />
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <div className="activityChildContainer">
              <Spinner />
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    );
  };
  useEffect(() => {
    if (isFirstAPICallInProgress) {
      return;
    }
    const timeOutId = setTimeout(() => {
      if (!isCollapse) {
        Promise.all([getMatrixViewData(), getCollapseAll()])
          .then((data) => {
            if (data[1] === 0) {
              setCollapsePhases([...data[0]]);
            }
          })
          .catch((error) => {
            setShowSpinner(false);
          });
      } else if (
        phaseDynamicFilterTag &&
        Object.keys(phaseDynamicFilterTag).length > 0 &&
        phaseDynamicFilterTag !== prevPhaseDynamicFilterTag.current // Check for updates
      ) {
        prevPhaseDynamicFilterTag.current = phaseDynamicFilterTag; // Update the ref
        setIsUpdatingFilters(true);
  
          getMatrixViewData()
            .catch((error) => {
              setShowSpinner(false);
            });
      }
    }, 1000);
  
    return () => {
      clearTimeout(timeOutId);
    };
  }, [
    isEmpty,
    phaseHashtags,
    applyfilterTrigger,
    searchInput,
    filterColourLegend,
    isFirstAPICallInProgress,
  ]);


useEffect(() => {
  if (userActivityFilter?.length > 0 && userActivityFilter[0]?.filters !== "0" && !isFirstAPICallInProgress) { // Only call if first API is not in progress

    setIsFirstAPICallInProgress(true); 
    if (!isCollapse) {
    Promise.all([getMatrixViewDataForUserSettings(),getCollapseAll()])
      .then((data) => {
        if (data[1] === 0) {
          setCollapsePhases([...data[0]]);
          
        }
      })
      .catch((error) => {
        setShowSpinner(false);
      })
      .finally(() => {
        setIsFirstAPICallInProgress(false); 
      });
  }}
}, [userActivityFilter]);



  useEffect(() => {
    loadBreadcrumb();
  }, []);

  useEffect(() => {
    handleGroupExpandOnRoleSelect();
  }, [ phases, applyfilterTrigger]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      checkPhaseData();
    }, 1000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [
    phaseHashtags,
    searchInput,
    applyfilterTrigger,
    filterColourLegend,
  ]);
  useEffect(() => {
    window.addEventListener("click", collapseDefaultStateModal);
    return () => {
      window.removeEventListener("click", collapseDefaultStateModal);
    };
  }, []);

  useEffect(() => {
    getGroupColorLegend();
  }, [phases, solutionMethodsData, phasesData]);

  return (
    <>
      {showSpinner ? (
        <Spinner />
      ) : (
        <div className={`${realizeValueToggler ===1 ? "deliveryPlayBookProgramMetrixView" : "deliveryPlayBookMetrixView"}  newDeliveryPlayBookMetrixView`}>
          {/* <div className="deliveryPlayBookLegendsContainer">
            {groupColorLegend.map((group) => (
              <div
                className={`dpLegends ${
                  filterColourLegendGroupId.includes(group.groupId)
                    ? "selectedcolourfilter"
                    : ""
                }`}
              >
                <span
                  className="dpLegendsColorIndicator"
                  style={{ background: group.colourCode }}
                ></span>
                <span
                  onClick={() => {
                    handleColourFilterChange(group);
                  }}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      handleColourFilterChange(group);
                    }
                  }}
                  tabIndex={0}
                  role="button"
                  aria-label="Colour Filter"
                  name={group.groupName}
                  title={group.groupName}
                >
                  {group.groupName}
                </span>
              </div>
            ))}
          </div>  */}
          {isPhaseSelected ?
            <>
              <div className="PhasesCollapseExpandContainer">
                {filterColourLegend.length > 0 && (
                  <div
                    onClick={() => {
                      setFilterColourLegend([]);
                      setFilterColourLegendGroupId([]);
                    }}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setFilterColourLegend([]);
                        setFilterColourLegendGroupId([]);
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    aria-label="Clear Colour Filter"
                  >
                    <span>Clear Color Filter</span>
                    <span className="filterColseIcon"></span>
                  </div>
                )}
                <div
                  onClick={() => {
                    ExpandAll();
                  }}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      ExpandAll();
                    }
                  }}
                  tabIndex={0}
                  role="button"
                  aria-label={`${collapsePhases.length ? 'Selected ' : ''}Expand All`}
                >
                  <span>Expand All</span>
                  <span className="expandForwardIcon"></span>
                </div>
                <div
                  onClick={() => {
                    setCollapsePhases([]);
                  }}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      setCollapsePhases([]);
                    }
                  }}
                  tabIndex={0}
                  role="button"
                  aria-label={`${collapsePhases.length ? '' : 'Selected '}Collapse All`}
                >
                  {/* <Icon iconName="DoubleChevronLeftMed" /> */}
                  <span>Collapse All</span>
                  <span className="backwardIcon"></span>
                </div>
                <div class="viewChangeDefaultSelect" id="defaultViewStateContainer">
                  <span
                    id="moreVerticalId"
                    title="Set Matrix View Default State"
                    onClick={() =>
                      setShowDefaultStateModal((prevState) => !prevState)
                    }
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setShowDefaultStateModal((prevState) => !prevState);
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    aria-label="Set Matrix View Default State"
                    aria-expanded={showDefaultStateModal ? "true" : "false"}
                  >
                    <Icon iconName="MoreVertical" />
                  </span>
                  {showDefaultStateModal && (
                    <div>
                      <span class="showMoreArrow"></span>
                      <span class="viewChangeSelectDropDown">
                        <span class="viewChangeSelectTitle">
                          Select default state
                        </span>
                        <span class="viewChangeSelectIconContainer">
                          <span class="viewChangeSelectIcons">
                            <label class="checkBoxContainer filterChkboxContainer">
                              <span class="filterLabel">Expand all</span>
                              <input
                                name="selection"
                                type="radio"
                                checked={isCollapse === 0}
                                onChange={() => handleIsCollapseChange(0)}
                                tabIndex={-1}
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    handleIsCollapseChange(0);
                                  }
                                }}
                              />

                              <span 
                                class="checkmark" 
                                tabIndex={0}
                                onChange={() => handleIsCollapseChange(0)}
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    handleIsCollapseChange(0);
                                    setTimeout(() => {
                                      document.getElementById('moreVerticalId')?.focus();
                                      setShowDefaultStateModal((prevState) => !prevState);
                                    }, 1000);
                                  }
                                }}
                              ></span>
                            </label>
                          </span>
                          <span class="viewChangeSelectIcons">
                            <label class="checkBoxContainer filterChkboxContainer">
                              <span class="filterLabel">Collapse all</span>
                              <input
                                name="selection"
                                type="radio"
                                checked={isCollapse === 1}
                                onChange={() => handleIsCollapseChange(1)}
                                tabIndex={-1}
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    handleIsCollapseChange(1);
                                  }
                                }}
                              />
                              <span 
                              class="checkmark" 
                              tabIndex={0}
                              onChange={() => handleIsCollapseChange(1)}
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    handleIsCollapseChange(1);
                                    setTimeout(() => {
                                      document.getElementById('moreVerticalId')?.focus();
                                      setShowDefaultStateModal((prevState) => !prevState);
                                    }, 1000);
                                  }
                                }}
                              ></span>
                            </label>
                          </span>
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${realizeValueToggler ===1 ? "dPlayBookMetrixViewContainer" : "dPlayBookMetrixProjectViewContainer"}`}
              // "dPlayBookMetrixViewContainer"
              >
                {phases.map((item) => (
                  <div
                    key={item.phaseId}
                    className={`MetrixViewSMContainer ${!collapsePhases.includes(item.phaseId)
                      ? "collapsePhaseView"
                      : ""
                      }`}
                  >
                    <div className="metrixViewContainerHeader">
                      <h3>{item.phaseName}</h3>
                      {item.isRefered ? (
                        <span
                          class="activityReferredIcon"
                          title={`Linked phase from solution method ${item.referedFromSolutionMethodName}`}
                        ></span>
                      ) : (
                        <></>
                      )}
                      <Icon
                      className="matrixToggleFocus"
                        iconName={
                          !collapsePhases.includes(item.phaseId)
                            ? "DoubleChevronLeftMedMirrored"
                            : "DoubleChevronLeftMed"
                        }
                        onClick={() => {
                          handlePhaseExpand(item.phaseId);
                        }}
                        tabIndex={0}
                        role="button"
                        aria-label={
                          !collapsePhases.includes(item.phaseId)
                            ? "expand phase"
                            : "collapse phase"
                        }
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            handlePhaseExpand(item.phaseId);
                          }
                        }}
                      />
                      <span class="matrixbeakArrow"></span>
                    </div>
                    <div className={`${realizeValueToggler ===1 ? "metrixViewGroupsContainer" : "metrixProjectViewGroupsContainer"}`}
                    // "metrixViewGroupsContainer"
                    >
                      <div class="activitiesList">
                        {item.groups.map((group) => (
                          <div
                            className={
                              group.isParentActivity || group.isSolutionMethod
                                ? "delivery"
                                : ""
                            }
                            key={group.dragNDropId}
                          >
                            {group.isSolutionMethod ? (
                              <>
                                {renderSolutionMethod(group, item.phaseName, true)}
                              </>
                            ) : group.groupId ? (
                              <>{renderGroup(item, group, false)}</>
                            ) : (
                              <>{renderOrphanActivity(item, group)}</>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
            :
            <></>
          }
        </div>
      )}
    </>
  );
};

const Info = ({
  activityId,
  templateDownload,
  setTemplateDownload,
  data, // activityGroups Data
  complexityEnabled,
  phaseData,
  templateCount,
  activity,
  complexityId,
  url,
}) => {
  const [showTemplates, setShowTemplates] = useState(false);

  const [templates, setTemplates] = useState([]);

  const { appState, role } = useLeftMenuCollapse();
  const { axiosGetService } = useAxios();

  const { defaultRole } = appState;
  const getTemplates = async () => {
    axiosGetService(
      `api/sdmactivity/getactivitytemplates/${activityId}/${complexityId} `
    )
      .then((res) => {
        if (res.data.length !== 0) {
          setTemplates(res.data);
        } else {
          setTemplates(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activityShowMoreCollapse = (e) => {
    const icon = document.getElementById(`activityShowMore_${activityId}`);
    if (icon !== null && !icon.contains(e.target)) {
      setShowTemplates(false);
    }
  };

  useEffect(() => {
    if (activityId !== templateDownload || templateDownload === null) {
      setShowTemplates(false);
    }
  }, [templateDownload]);

  useEffect(() => {
    setShowTemplates(false);
    window.addEventListener("click", activityShowMoreCollapse);
    return () => {
      window.removeEventListener("click", activityShowMoreCollapse);
    };
  }, []);

  return (
    <>
      <div className="complexityOfActivity">
        <div
          title="Responsible"
          className={
            role.id === defaultRole.id
              ? "raciWrapper hide"
              : activity.responsible
                ? "raciWrapper responsible"
                : "raciWrapper faded"
          }
        >
          <span>R</span>
        </div>

        <div
          title="Accountable"
          className={
            role.id === defaultRole.id
              ? "raciWrapper hide"
              : activity.accountable
                ? "raciWrapper accountable"
                : "raciWrapper faded"
          }
        >
          <span>A</span>
        </div>

        <div
          title="Consulted"
          className={
            role.id === defaultRole.id
              ? "raciWrapper hide"
              : activity.consulted
                ? "raciWrapper consulted"
                : "raciWrapper faded"
          }
        >
          <span>C</span>
        </div>

        <div
          title="Informed"
          className={
            role.id === defaultRole.id
              ? "raciWrapper hide"
              : activity.informed
                ? "raciWrapper informed"
                : "raciWrapper faded"
          }
        >
          <span>I</span>
        </div>
        <div className="raciWrapper"></div>
      </div>
      {activity.isRefered ? (
        <span
          className="activityReferredIcon"
          title={`Linked activity from solution method ${activity.referedFromSolutionMethodName} and phase ${activity.referedFromPhaseName}`}
        ></span>
      ) : (
        <></>
      )}
      <div className="moreEllipsis" id={`activityShowMore_${activityId}`}>
        {templateCount > 0 ? (
          <div
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.which === 13) {
                setShowTemplates(!showTemplates);
                getTemplates();
                setTemplateDownload(activityId);
              }
            }}
            onClick={() => {
              setShowTemplates(!showTemplates);
              getTemplates();
              setTemplateDownload(activityId);
            }}
            a
            aria-label={` Click here to download templates for activity`}
            aria-expanded={showTemplates ? "true" : "false"}
            role="button"
          >
            <Icon
              iconName="MoreVertical"
              title="Download Templates"
              className="pointer"
            />
          </div>
        ) : (
          <div>
            <Icon
              iconName="MoreVertical"
              title="No templates available"
              className="disabledContent"
            />
          </div>
        )}

        {showTemplates ? (
          <TemplateDownloadShowMore
            templates={templates}
            phaseData={phaseData}
            activity={activity}
          />
        ) : null}
      </div>
    </>
  );
};

export const TemplateDownloadShowMore = ({ templates, phaseData }) => {
  const { track } = useAppInsights();
  return (
    <div className="showMoreMenu">
      <span className="showMoreArrow" />
      <ul className="menuItemUL">
        {templates !== null ? (
          templates.map((item) => (
            <li
              className={
                item.inProgress
                  ? " moreItemLi templateInProgress"
                  : "moreItemLi"
              }
              title={item.title}
              key={item.id}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={item.fileLocation}
                className="templateLink"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    track(1, 112, item.id, item.title, {
                      phaseName: item.phaseName,
                      complexityId: item.complexityId,
                    });
                  }
                }}
                onClick={() =>
                  track(1, 112, item.id, item.title, {
                    phaseName: item.phaseName,
                    complexityId: item.complexityId,
                  })
                }
              >
                <img
                  src={
                    item.inProgress
                      ? fileIcons["inProgress"]
                      : fileIcons[item.fileType] || fileIcons["defaultTemplate"]
                  }
                  alt="inProgressImg"
                  className="ellipseMenuItemImg"
                />
                <div className="ellipseMenuItem">
                  <span>{item.title}</span>
                </div>
              </a>
            </li>
          ))
        ) : (
          <li className="noTemplatesShowMore">
            <span>No Templates available</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default MatrixView;
