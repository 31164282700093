import React, { useState, useEffect, useCallback } from 'react';
// import { useParams } from 'react-router-dom';
import dompurify from 'dompurify';
import { v4 as uniqueIdGenerator } from 'uuid';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { Container, Row, Col, Card } from "react-bootstrap";
import Breadcrumbs from "../Breadcrumbs";
import Activity from '../Activity';
import $ from 'jquery';
import Activities from '../Activities';
import ActivityDeleteModal from './ActivityDeleteModal';
import PhaseDeleteModal from './PhaseDeleteModal';
import ActivityBuilder from '../PhaseLeftMenu/ActivityAddition/CreateActivity';
import PhasePublishModal from './PhasePublishModal';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import DeletedEntityModel from '../DeletedEntity';
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import GroupDerefer from './GroupDerefer';
import DereferSolutionMethod from './DereferSolutionMethod';
import PhasePublishCancelModal from './PhasePublishCancelModal';
import { TabTitle } from "../../utils/GeneralFunctions";

import './Phase.css';
import GroupDeleteModal from './GroupDeleteModal';
import SubGroupDeleteModal from './SubGroupDeleteModal';
import GroupActualDeleteModal from './GroupActualDeleteModal';
import SubGroupActualDeleteModal from './SubGroupActualDeleteModal';
import NewActivities from './NewActivities'
import './NewPhase.css'

const getDynamicFilterData = (phaseDynamicFilterTag, role) => {
	const selectedFiltersIds = [];
	const filterIds = Object.keys(phaseDynamicFilterTag);
	for (const id of filterIds) {
		if (phaseDynamicFilterTag[Number(id)].selectedTagId.length > 0) {
			selectedFiltersIds.push(
				`${Number(id)}` +
				'-' +
				phaseDynamicFilterTag[Number(id)].selectedTagId.toString()
			);
		}
	}
	if (role.id !== 0) {
		selectedFiltersIds.push(`16-${role.id}`);
	}
	return selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : 0;
};
const NewPhase = ({
	urlParams,
	setShowMoreDescription,
	setShowMoreVisible,
	projectTypeId,
	solutionMethodId,
	showGroup,
	showFilters,
	selectedRoles,
	isMerged,
	solutionMethodName,
	parentMethodName,
	isRetired,
	phaseCreated,
	showPublishButton,
	setShowPublishButton,
	showPublishModal,
	setShowPublishModal,
	setPhaseCreated,
	coOwners,
	searchInput,
	deletePhase,
	setDeletePhase,
	setDeletePhaseId,
	showDeletePhaseModal,
	setShowDeletePhaseModal,
	isActivityCountZero,
	setIsActivityCountZero,
	getSolutionMethodData,
	setDeletePhaseName,
	selectedComplexity,
	setShowPhaseDeletionMessage,
	setPhasePublishTitle,
	setShowPhaseUpdateModal,
	setUpdatePhaseId,
	setSelectedTab,
	updatePhaseId,
	selectedPhase,
	setSelectedPhase,
	updateComplexityFilter,
	setUpdateComplexityFilter,
	setSelectedComplexity,
	setSelectedComplexityId,
	showMatrixView,
	complexities,
	setComplexities,
	isEnableComplexity,
}) => {
	// const urlParams = useParams();
	const { track } = useAppInsights();
	const {
		parentPhase,
		appState,
		phase,
		setPhase,
		breadcrumbs,
		setBreadcrumbs,
		setCollapsedLeftMenu,
		setRole,
		complexity,
		setComplexity,
		complexityValidActivities,
		phaseHashtags,
		phaseDynamicFilterTag,
		role,
		endUserView,
	} = useLeftMenuCollapse();

	const { defaultRole } = appState;
	const { axiosGetService, userAlias } = useAxios();
	const { phaseId } = urlParams;
	const [toggleActivityDetails, setToggleActivityDetails] = useState(false);
	const [templateDownload, setTemplateDownload] = useState();
	const [phaseData, setPhaseData] = useState({});
	const [activityGroups, setActivityGroups] = useState([]);
	const [htmlThreshold, setHtmlThreshold] = useState(0);
	const [descriptionTextLength, setDescriptionTextLength] = useState(0);
	const [showActivityBuilder, setShowActivityBuilder] = useState(false);
	const [previousActivityGroups, setPreviousActivityGroups] = useState([]);
	const [showActivityDeleteModal, setShowActivityDeleteModal] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);
	//const [complexities, setComplexities] = useState([]);
	const [activityBuilderTabId, setActivityBuilderTabId] = useState(1);
	const [apiCalled, setApiCalled] = useState(false);
	const [noActivityMessage, setNoActivityMessage] = useState(false);
	const [isMethodOwnerOrCoOwner, setIsMethodOwnerOrCoOwner] = useState(false);
	const [showSubgroupForm, setShowSubgroupForm] = useState(false);
	const [selectedGroupDetails, setSelectedGroupDetails] = useState(null);
	const [showSubgroupEditForm, setShowSubgroupEditForm] = useState(false);
	const [selectedSubgroupDetails, setSelectedSubgroupDetails] = useState('');
	const [showDereferGroupModal, setShowDereferGroupModal] = useState(false);
	const [showDereferSolutionMethodModal, setShowDereferSolutionMethodModal] = useState(false);
	const [selectedSolutionMethod, setSelectedSolutionMethod] = useState(null);
	const [referrefdSolutionMethodsData, setreferrefdSolutionMethodsData] = useState({});
	const [referrefdPhasesData, setReferrefdPhasesData] = useState({});
	const [showPhasePublishCancelModal, setShowPhasePublishCancelModal] = useState(false);
	const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
	const [showDeleteSubGroupModal, setShowDeleteSubGroupModal] = useState(false);
	const [showActualDeleteGrpModal, setShowActualDeleteGrpModal] = useState(false);
	const [selectedDeleteGroupDetails, setSelectedDeleteGroupDetails] = useState(null);
	const [showActualDeleteSGrpModal, setShowActualDeleteSGrpModal] = useState(false);
	const [selectedDeleteSubGroupDetails, setSelectedDeleteSubGroupDetails] = useState(null);

	const htmlSanitizer = (data) => {
		const config = {
			ADD_ATTR: ['target'],
		};
		return dompurify.sanitize(data, config);
	};

	const loadBreadcrumb = async () => {
		await axiosGetService(
			`api/sdmuser/getbreadcrumb?itemId=${phaseId}&itemTypeId=25&
			isEdit=false&isAutoComplete=false&isSearchResultsHome=false&
			isSearchResults=false&isMyContributions=false&documentStatus=0&
			isNotifications=false&solutionMethodId=${solutionMethodId}&phaseId=${phaseId}`
		).then((response) => {
			if (response.data) {
				const breadcrumbTitle = response.data
					.split('>')
					.map((item) => item.trim());
				if (isRetired) {
					const solutionMethodBreadcrumb = {
						title: breadcrumbTitle[2],
						type: 'solutionMethod',
						path: `/project/${projectTypeId}/method/${solutionMethodId}`,
						parent: ['home'],
					};
					const phaseBreadcrumb = {
						title: breadcrumbTitle[3],
						type: 'Phase',
						path: window.location.url,
						parent: 'solutionMethod',
					};
					const newBreadcrumbs = AddBreadCrumb(breadcrumbs.slice(0, 1), [
						solutionMethodBreadcrumb,
						phaseBreadcrumb,
					]);
					setBreadcrumbs(newBreadcrumbs);
				} else {
					const projectBreadcrumb = {
						title: breadcrumbTitle[1],
						type: 'projectType',
						path: `/project/${projectTypeId}`,
						parent: ['home', 'search'],
					};
					const solutionMethodBreadcrumb = {
						title: breadcrumbTitle[2],
						type: 'solutionMethod',
						path: `/project/${projectTypeId}/method/${solutionMethodId}`,
						parent: 'projectType',
					};
					const phaseBreadcrumb = {
						title: breadcrumbTitle[3],
						type: 'Phase',
						path: window.location.url,
						parent: 'solutionMethod',
					};
					const newBreadcrumbs = AddBreadCrumb(
						breadcrumbs.slice(
							0,
							breadcrumbs.length > 1 && breadcrumbs[1].type === 'search' ? 2 : 1
						),
						[projectBreadcrumb, solutionMethodBreadcrumb, phaseBreadcrumb]
					);
					setBreadcrumbs(newBreadcrumbs);
					TabTitle(breadcrumbTitle[3]);
				}
			}
		});
	};

	const groupDeleteOption = (data) => {
		if (data.activities.length === 0) {
			setSelectedDeleteGroupDetails(data);
			setShowActualDeleteGrpModal(true);
		} else {
			setSelectedDeleteGroupDetails(data);
			setShowDeleteGroupModal(true)
		}
	}
	const subGroupDeleteOption = (data) => {
		if (data.activities.length === 0) {
			setSelectedDeleteSubGroupDetails(data);
			setShowActualDeleteSGrpModal(true);
		} else {
			setSelectedDeleteSubGroupDetails(data);
			setShowDeleteSubGroupModal(true)
		}
	}
	const cloneActivity = (activities, activityCount) => {
		const activityClone = [];
		for (const activity in activities) {
			if (activity.includes('activity')) {
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: false,
				});
			} else if (activity.includes('subGroups')) {
				const subgroupActivities = [];
				for (const subgroupActivity in activities[activity]) {
					if (subgroupActivity.includes('activity')) {
						subgroupActivities.push({
							...activities[activity][subgroupActivity],
							dragNDropId: uniqueIdGenerator(),
						});
					}
				}
				activityClone.push({
					...activities[activity],
					dragNDropId: uniqueIdGenerator(),
					isSubgroup: true,
					activities: subgroupActivities,
				});
				activityCount += subgroupActivities.length;
			}
		}
		return activityClone;
	};

	const cloneGroup = (data, setValue, isRefered) => {
		let activityCount = 0;
		const activityGroupClone = [];
		for (const group in data) {
			if (group.includes('activityGroups') && group.includes('activity')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: data[group].groupId,
					groupName: data[group].groupName,
					isParentActivity: data[group].isParentActivity,
					activities: cloneActivity(data[group], activityCount),
					approverMembersList: data[group].approverMembersList,
					reviewerMembersList: data[group].reviewerMembersList,
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isGroupRefered: data[group].isGroupRefered,
					isSolutionMethod: false,
					referedFromSolutionMethodName:
						data[group].referedFromSolutionMethodName,
					referedFromPhaseName: data[group].referedFromPhaseName,
					isGroupReferredWithActivity: data[group].isGroupReferredWithActivity,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			} else if (group.includes('activity')) {
				const orphanActivityClone = {
					dragNDropId: uniqueIdGenerator(),
					groupId: 0,
					groupName: '',
					isParentActivity: data[group].isParentActivity,
					activities: [
						{
							...data[group],
							dragNDropId: uniqueIdGenerator(),
							isSubgroup: false,
						},
					],
					approverMembersList: [],
					reviewerMembersList: [],
					isMethodOwnerOrCoOwner: [],
					isSolutionMethod: false,
				};
				activityCount += orphanActivityClone.activities.length;
				activityGroupClone.push(orphanActivityClone);
			} else if (group.includes('solutionMethod')) {
				const groupClone = {
					dragNDropId: uniqueIdGenerator(),
					solutionMethodId: data[group].solutionMethodId,
					solutionMethodName: data[group].solutionMethodName,
					activities: [],
					isMethodOwnerOrCoOwner: data[group].isMethodOwnerOrCoOwner,
					colourCode: data[group].colourCode,
					isSolutionMethod: true,
					projectTypeId: data[group].projectTypeId,
				};
				activityCount += groupClone.activities.length;
				activityGroupClone.push(groupClone);
			}
		}
		if (activityCount === 0 || isRefered) {
			setIsActivityCountZero(true);
		} else {
			setIsActivityCountZero(false);
		}
		setValue(activityGroupClone);
	};

	const getDefaultdynamiccomplexity = async () => {
		await axiosGetService(`api/sdmsolutionmethod/defaultdynamiccomplexity?solutionMethodId=${solutionMethodId}`).then(
			(response) => {
				const data = JSON.parse(response.data)
				setComplexity(data)
			}
		);
	};

	const getPhaseData = async () => {
		if (complexity.id) {
			setPhaseData({});
			setApiCalled(true);
			setActivityGroups([]);
			await axiosGetService(
				`api/sdmphase/getphasemergedactivities/${solutionMethodId}/${urlParams.phaseId
				}/${complexity.id}/${coOwners.includes(userAlias) && !endUserView
				}/0/0/0/false/0`
			)
				.then((res) => {
					if (res.data && !res.data.isDeleted) {
						setShowPublishButton(res.data.isDrafted);
						setIsMethodOwnerOrCoOwner(res.data.isMethodOwnerOrCoOwner);
						if (res.data.isDrafted && !res.data.publishScheduledDateTime) {
							setPhasePublishTitle(res.data.phaseName);
						} else {
							setPhasePublishTitle('');
						}
						TabTitle(res.data.phaseName);
						setDeletePhaseName(res.data.phaseName);
						const description = res.data.phaseDescription
							? res.data.phaseDescription
							: '';
						const firstParagraph = description.split('<br />')[0];
						const span = document.createElement('span');
						let thresholdCharCount = 0;
						let countThreshold = 0;
						const threshold = isMerged ? 310 : 120;
						cloneGroup(
							res.data.activityGroupsData,
							setActivityGroups,
							res.data.isRefered
						);
						cloneGroup(
							res.data.activityGroupsData,
							setPreviousActivityGroups,
							res.data.isRefered
						);
						for (let i = 0; i < description.length; i++) {
							if (countThreshold === threshold || i === description.length - 1) {
								setHtmlThreshold(thresholdCharCount);
								setDescriptionTextLength(countThreshold);
								break;
							}
							if (description[i] === '<') {
								while (description[i] !== '>') {
									thresholdCharCount++;
									i++;
								}
								i--;
								thresholdCharCount++;
							} else if (description[i] === '&' && i + 5 < description.length) {
								const space = description.slice(i, i + 6);
								if (
									space === '&nbsp;' ||
									space === '&quot;' ||
									space === '&apos;'
								) {
									thresholdCharCount += 5;
									i += 4;
								}
							} else {
								countThreshold++;
								thresholdCharCount++;
							}
						}

						span.innerHTML = firstParagraph;
						const filteredFirstParagraph = span.textContent || span.innerText;

						span.innerHTML = description;
						const filteredDescription = span.textContent || span.innerText;

						setPhaseData({
							...res.data,
							shortDescription: description,
							filteredDescription,
							filteredFirstParagraph,
						});

						setPhase({
							...phase,
							id: Number(urlParams.phaseId),
							title: res.data.phaseName,
							enableComplexity: res.data.enableComplexity,
							svgFileName: res.data.svgFileName,
						});
						cloneGroup(
							res.data.activityGroupsData,
							setActivityGroups,
							res.data.isRefered
						);
						cloneGroup(
							res.data.activityGroupsData,
							setPreviousActivityGroups,
							res.data.isRefered
						);
						if (
							Object.keys(res.data.activityGroupsData).length === 0 &&
							parentPhase.id === null
						) {
							setRole(defaultRole);
						}
					} else if (res.data) {
						setPhaseData({
							...res.data,
						});
					}
					setApiCalled(false);
				})
				.catch((err) => console.log(err));
		}

	};

	const getComplexities = async () => {
		axiosGetService('api/activities/projectcomplexity').then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
					type: 'option',
				};
			});
			setComplexities(data);
		});
	};

	const formatPublishDate = (publishDate) => {
		const date = new Date(publishDate);
		return date.toLocaleString();
	};
	useEffect(() => {
		getPhaseData();
	}, [urlParams.phaseId, isMerged, phaseCreated, complexity.id]);

	useEffect(() => {
		if (phaseData.phaseName !== undefined && phaseData.phaseName) {
			track(2, 25, urlParams.phaseId, phaseData.phaseName);
		}
	}, [phaseData]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (phaseId && solutionMethodId && typeof isRetired !== 'undefined') {
				loadBreadcrumb();
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [phaseId, solutionMethodId, isRetired, phaseCreated]);

	useEffect(() => {
		setCollapsedLeftMenu(true);
		getComplexities();
	}, []);

	useEffect(() => {
		let groupCount = 0;
		for (const group of activityGroups) {
			if (group.groupId) {
				groupCount += 1;
			}
		}

		if (
			(groupCount == 0 &&
				phaseHashtags.length > 0 &&
				complexityValidActivities.length === 0) ||
			(groupCount == 0 &&
				searchInput.trim().length > 0 &&
				complexityValidActivities.length === 0) ||
			(groupCount == 0 &&
				getDynamicFilterData(phaseDynamicFilterTag, role) !== 0 &&
				complexityValidActivities.length === 0)
		) {
			setNoActivityMessage(true);
		} else {
			setNoActivityMessage(false);
		}
	}, [activityGroups]);

	useEffect(() => {
		getDefaultdynamiccomplexity();
	}, [])

	useEffect(() => {
		if (!selectedActivity) {
		  setToggleActivityDetails(false);
		}
	}, [selectedActivity]); 
	useEffect(() => {
		if (toggleActivityDetails) {
			$('.descrpOfPhases').css('background', '#F5F5F5');
			$('._complexityContainer').css('padding-bottom', '2px');
			$('._complexityContainer').css('padding-left', '2px');
		}
		if (!toggleActivityDetails) {
		  $('.solutionMethodDescription.noDelivery').css('padding-top', '10px');
		  $('.solutionMethodDescription.noDelivery').css('margin-top', '0px');
		  $('._breadcrumbForNonRealized').css('padding-bottom', '0px');
		  $('._complexityContainer').css('padding-bottom', '');
		}
	  }, [toggleActivityDetails]);

	$('[id^=breadcrumbId_], [id^=phaseId_]').on('click', () => {
		setSelectedActivity(null);
	});
	$('._phaseTile').on('click', () => {
		setSelectedActivity(null)
	});
	if (phaseData.isDeleted) {
		return <DeletedEntityModel errorMsg={phaseData.errorMessage} />;
	}
	$('body').css('overflow-y', 'hidden');
	$('.newHomePageContainer').attr('style', function (i, style) {
		return (style || '') + '; min-height:auto !important ; height: auto !important;';
	  });
	return (
		<>

<Container fluid className={`m-0 p-0  px-1`}>
	{apiCalled? <Spinner />:(
	<>
		{showPublishModal && (
			<PhasePublishModal
				phaseData={phaseData}
				showPublishModal={showPublishModal}
				setShowPublishModal={setShowPublishModal}
				solutionMethodId={solutionMethodId}
				setPhaseCreated={setPhaseCreated}
				isActivityCountZero={isActivityCountZero}
				complexity={complexity}
				coOwners={coOwners}
				setPhaseData={setPhaseData}
				setShowActivityBuilder={setShowActivityBuilder}
				setShowPhaseUpdateModal={setShowPhaseUpdateModal}
				setUpdatePhaseId={setUpdatePhaseId}
				setSelectedTab={setSelectedTab}
				getSolutionMethodData={getSolutionMethodData}
				phaseCreated={phaseCreated}
				activityGroups={activityGroups}
				setShowSubgroupForm={setShowSubgroupForm}
				setActivityBuilderTabId={setActivityBuilderTabId}
				setSelectedGroupDetails={setSelectedGroupDetails}
				setSelectedSubgroupDetails={setSelectedSubgroupDetails}
				setShowSubgroupEditForm={setShowSubgroupEditForm}
			/>
		)}
		{phaseData.publishScheduledDateTime && (
			<MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
				<p>Changes made to this phase has been scheduled to be published at{' '} {formatPublishDate(phaseData.publishScheduledDateTimeString)}{' '} (Local). you can cancel here if you want to</p>
				<button onClick={() => { setShowPhasePublishCancelModal(true);}}>Cancel</button>
			</MessageBar>
		)}
	
		<Row style={selectedActivity?{background:'#F5F5F5', paddingTop:'1px'}:{background:'#F5F5F5', paddingTop:'1px',paddingRight:'0'}}>
			{!toggleActivityDetails && (
			<Col xs={12} sm={4} className="m-0 pr-2 _leftSideForNonRealized">
				{noActivityMessage ? <p>No activities found</p> : <></>}
				{activityGroups.map((item) => (
					<div className={ item.isParentActivity || item.isSolutionMethod ? 'delivery' : '' } key={item.dragNDropId} >
						<NewActivities
							data={item}
							key={item.dragNDropId}
							templateDownload={templateDownload}
							setTemplateDownload={setTemplateDownload}
							phaseType="phase"
							showGroup={showGroup}
							showFilters={showFilters}
							selectedRoles={selectedRoles}
							isMerged={isMerged}
							isParentActivity={item.isParentActivity}
							activityGroups={activityGroups}
							solutionMethodId={solutionMethodId}
							setShowActivityDeleteModal={setShowActivityDeleteModal}
							selectedActivity={selectedActivity}
							setSelectedActivity={setSelectedActivity}
							coOwners={coOwners}
							userAlias={userAlias}
							searchInput={searchInput}
							selectedComplexity={selectedComplexity}
							isMethodOwnerOrCoOwner={isMethodOwnerOrCoOwner}
							setShowSubgroupForm={setShowSubgroupForm}
							setActivityBuilderTabId={setActivityBuilderTabId}
							setShowActivityBuilder={setShowActivityBuilder}
							setSelectedGroupDetails={setSelectedGroupDetails}
							setSelectedSubgroupDetails={setSelectedSubgroupDetails}
							setShowSubgroupEditForm={setShowSubgroupEditForm}
							setShowDereferGroupModal={setShowDereferGroupModal}
							projectTypeId={projectTypeId}
							setShowDereferSolutionMethodModal={setShowDereferSolutionMethodModal}
							setSelectedSolutionMethod={setSelectedSolutionMethod}
							solutionMethodsData={referrefdSolutionMethodsData}
							setSolutionMethodsData={setreferrefdSolutionMethodsData}
							phasesData={referrefdPhasesData}
							setPhasesData={setReferrefdPhasesData}
							isPhaseRefered={phaseData.isRefered}
							activityPhaseId={phaseData.phaseId}
							groupDeleteOption={groupDeleteOption}
							subGroupDeleteOption={subGroupDeleteOption}
							pageType={'nonRealizedItem'}
						/>
					</div>
				))}
			</Col>
			)}
			<Col xs={12} sm={toggleActivityDetails?12:8} className="m-0 mt-0 pl-0">
			{selectedActivity ? (
				<Activity
					projectTypeId={projectTypeId}
					solutionMethodId={solutionMethodId}
					isEnableComplexity={isEnableComplexity}
					selectedActivity={selectedActivity}
					pageType={'nonRealizedItem'}
					selectedPhaseId={phaseId}
					setToggleActivityDetails={setToggleActivityDetails}
					toggleActivityDetails={toggleActivityDetails}
				/>
				):(
					<div className='phaseDescription' >
					<span
						dangerouslySetInnerHTML={{
							__html: htmlSanitizer(phaseData.phaseDescription)

						}}
					></span>
					</div>
				)}
			</Col>
		</Row>
		</>
	)}
</Container>
{/* 
			{apiCalled ? (
				<Spinner />
			) : (
				<>
					{showPublishModal ? (
						<PhasePublishModal
							phaseData={phaseData}
							showPublishModal={showPublishModal}
							setShowPublishModal={setShowPublishModal}
							solutionMethodId={solutionMethodId}
							setPhaseCreated={setPhaseCreated}
							isActivityCountZero={isActivityCountZero}
							complexity={complexity}
							coOwners={coOwners}
							setPhaseData={setPhaseData}
							setShowActivityBuilder={setShowActivityBuilder}
							setShowPhaseUpdateModal={setShowPhaseUpdateModal}
							setUpdatePhaseId={setUpdatePhaseId}
							setSelectedTab={setSelectedTab}
							getSolutionMethodData={getSolutionMethodData}
							phaseCreated={phaseCreated}
							activityGroups={activityGroups}
							setShowSubgroupForm={setShowSubgroupForm}
							setActivityBuilderTabId={setActivityBuilderTabId}
							setSelectedGroupDetails={setSelectedGroupDetails}
							setSelectedSubgroupDetails={setSelectedSubgroupDetails}
							setShowSubgroupEditForm={setShowSubgroupEditForm}
						/>
					) : (
						<></>
					)}
					{phaseData.publishScheduledDateTime && (
						<MessageBar
							messageBarType={MessageBarType.warning}
							isMultiline={true}
						>
							<p>
								Changes made to this phase has been scheduled to be published at{' '}
								{formatPublishDate(phaseData.publishScheduledDateTimeString)}{' '}
								(Local). you can cancel here if you want to
							</p>
							<button
								onClick={() => {
									setShowPhasePublishCancelModal(true);
								}}
							>
								Cancel
							</button>
						</MessageBar>
					)}
					<div className='newPhaseContainer'>
						<div className='newPhaseLeftContainer'>
							<h1 className='phaseName' >{phaseData.phaseName}</h1>
							{noActivityMessage ? <p>No activities found</p> : <></>}
							<div className="activitiesList">
								{activityGroups.map((item) => (
									<div
										className={
											item.isParentActivity || item.isSolutionMethod
												? 'delivery'
												: ''
										}
										key={item.dragNDropId}
									>
										<NewActivities
											data={item}
											key={item.dragNDropId}
											templateDownload={templateDownload}
											setTemplateDownload={setTemplateDownload}
											phaseType="phase"
											showGroup={showGroup}
											showFilters={showFilters}
											selectedRoles={selectedRoles}
											isMerged={isMerged}
											isParentActivity={item.isParentActivity}
											activityGroups={activityGroups}
											solutionMethodId={solutionMethodId}
											setShowActivityDeleteModal={setShowActivityDeleteModal}
											selectedActivity={selectedActivity}
											setSelectedActivity={setSelectedActivity}
											coOwners={coOwners}
											userAlias={userAlias}
											searchInput={searchInput}
											selectedComplexity={selectedComplexity}
											isMethodOwnerOrCoOwner={isMethodOwnerOrCoOwner}
											setShowSubgroupForm={setShowSubgroupForm}
											setActivityBuilderTabId={setActivityBuilderTabId}
											setShowActivityBuilder={setShowActivityBuilder}
											setSelectedGroupDetails={setSelectedGroupDetails}
											setSelectedSubgroupDetails={setSelectedSubgroupDetails}
											setShowSubgroupEditForm={setShowSubgroupEditForm}
											setShowDereferGroupModal={setShowDereferGroupModal}
											projectTypeId={projectTypeId}
											setShowDereferSolutionMethodModal={setShowDereferSolutionMethodModal}
											setSelectedSolutionMethod={setSelectedSolutionMethod}
											solutionMethodsData={referrefdSolutionMethodsData}
											setSolutionMethodsData={setreferrefdSolutionMethodsData}
											phasesData={referrefdPhasesData}
											setPhasesData={setReferrefdPhasesData}
											isPhaseRefered={phaseData.isRefered}
											activityPhaseId={phaseData.phaseId}
											groupDeleteOption={groupDeleteOption}
											subGroupDeleteOption={subGroupDeleteOption}
										/>
									</div>
								))}
							</div>
						</div>

						{/* <h4>{phaseData.phaseName}</h4> */}
						{/* <div style={{ height:'100%', border: '1px solid #1B2C46',opacity:'0.15' }}></div> */}
						{/* 
						<div className='newPhaseRightContainer'>
							<div className='phaseDescription' >
								<span
									dangerouslySetInnerHTML={{
										__html: htmlSanitizer(phaseData.phaseDescription)

									}}
								></span>
							</div>
						</div>  */}
						{showActivityDeleteModal ? (
							<ActivityDeleteModal
								setShowActivityDeleteModal={setShowActivityDeleteModal}
								showActivityDeleteModal={showActivityDeleteModal}
								selectedActivity={selectedActivity}
								setSelectedActivity={setSelectedActivity}
								complexities={complexities}
								activityGroups={activityGroups}
								setActivityGroups={setActivityGroups}
								setIsActivityCountZero={setIsActivityCountZero}
								setPhaseCreated={setPhaseCreated}
								phaseCreated={phaseCreated}
								phaseId={phaseId}
								solutionMethodId={solutionMethodId}
							/>
						) : (
							<></>
						)}
						{showActualDeleteGrpModal ? (
							<GroupActualDeleteModal
								setShowActualDeleteGrpModal={setShowActualDeleteGrpModal}
								showActualDeleteGrpModal={showActualDeleteGrpModal}
								setPhaseCreated={setPhaseCreated}
								phaseCreated={phaseCreated}
								selectedDeleteGroupDetails={selectedDeleteGroupDetails}
							/>
						) : <></>}

						{showActualDeleteSGrpModal ? (
							<SubGroupActualDeleteModal
								setShowActualDeleteSGrpModal={setShowActualDeleteSGrpModal}
								showActualDeleteSGrpModal={showActualDeleteSGrpModal}
								setPhaseCreated={setPhaseCreated}
								phaseCreated={phaseCreated}
								selectedDeleteSubGroupDetails={selectedDeleteSubGroupDetails}
							/>
						) : <></>}

						{showDeleteGroupModal ? (
							<GroupDeleteModal
								showDeleteGroupModal={showDeleteGroupModal}
								setShowDeleteGroupModal={setShowDeleteGroupModal}
								selectedDeleteGroupDetails={selectedDeleteGroupDetails}
							/>
						) : (<></>)}

						{showDeleteSubGroupModal ? (
							<SubGroupDeleteModal
								showDeleteSubGroupModal={showDeleteSubGroupModal}
								setShowDeleteSubGroupModal={setShowDeleteSubGroupModal}
								selectedDeleteSubGroupDetails={selectedDeleteSubGroupDetails}
							/>
						) : (<></>)}

						{showActivityBuilder ? (
							<ActivityBuilder
								showActivityBuilder={showActivityBuilder}
								setShowActivityBuilder={setShowActivityBuilder}
								activityGroups={activityGroups}
								setActivityGroups={setActivityGroups}
								solutionMethodId={solutionMethodId}
								phaseId={phaseId}
								previousActivityGroups={previousActivityGroups}
								setPreviousActivityGroups={setPreviousActivityGroups}
								projectTypeId={projectTypeId}
								activityBuilderTabId={activityBuilderTabId}
								setActivityBuilderTabId={setActivityBuilderTabId}
								coOwners={coOwners}
								userAlias={userAlias}
								getSolutionMethodData={getSolutionMethodData}
								getPhaseData={getPhaseData}
								setPhaseCreated={setPhaseCreated}
								phaseCreated={phaseCreated}
								showSubgroupForm={showSubgroupForm}
								setShowSubgroupForm={setShowSubgroupForm}
								selectedGroupDetails={selectedGroupDetails}
								setSelectedGroupDetails={setSelectedGroupDetails}
								selectedSubgroupDetails={selectedSubgroupDetails}
								setSelectedSubgroupDetails={setSelectedSubgroupDetails}
								showSubgroupEditForm={showSubgroupEditForm}
								setShowSubgroupEditForm={setShowSubgroupEditForm}
								searchInput={searchInput}
								updateComplexityFilter={updateComplexityFilter}
								setUpdateComplexityFilter={setUpdateComplexityFilter}
							/>
						) : (<></>)}

						{showDereferGroupModal ? (
							<GroupDerefer
								showDereferGroupModal={showDereferGroupModal}
								setShowDereferGroupModal={setShowDereferGroupModal}
								phaseId={phaseId}
								solutionMethodId={solutionMethodId}
								getPhaseData={getPhaseData}
								coOwners={coOwners}
								apiCalled={apiCalled}
								setApiCalled={setApiCalled}
								selectedGroup={selectedGroupDetails}
								setSelectedGroup={setSelectedGroupDetails}
							/>
						) : (<></>)}

						{showDereferSolutionMethodModal ? (
							<DereferSolutionMethod
								showDereferSolutionMethodModal={showDereferSolutionMethodModal}
								setShowDereferSolutionMethodModal={
									setShowDereferSolutionMethodModal
								}
								phaseId={phaseId}
								selectedSolutionMethod={selectedSolutionMethod}
								setSelectedSolutionMethod={setSelectedSolutionMethod}
								getPhaseData={getPhaseData}
							/>
						) : (<></>)}

						{showPhasePublishCancelModal && (
							<PhasePublishCancelModal
								phaseData={phaseData}
								solutionMethodId={solutionMethodId}
								type="phase"
								showPublishCancelModal={showPhasePublishCancelModal}
								setShowPublishCancelModal={setShowPhasePublishCancelModal}
								setPhaseCreated={setPhaseCreated}
								phaseCreated={phaseCreated}
								formatPublishDate={formatPublishDate}
							/>
						)}
					{/* </div>
				</>
			)} */}

		</>
	);
}

export default NewPhase;
