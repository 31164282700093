import React from 'react';
import { useRaciGrid } from './RaciContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';



const ComplexityFilter = ({ complexities }) => {
	const { appState } = useLeftMenuCollapse();
	const { track } = useAppInsights();
	const { 
		selectedComplexites,
		setSelectedComplexites,
	} = useRaciGrid();
	const handleCheckbox = (item) => {
		if (selectedComplexites.includes(item.id.toString())) {
			if (selectedComplexites.length === 1) {
				item.id === 3 ? setSelectedComplexites([...selectedComplexites]) : setSelectedComplexites([appState.defaultComplexity.id.toString()]);

			}
			else {
				setSelectedComplexites(
					selectedComplexites.filter((comp) => comp != item.id)
				);
			}
		} else {
			setSelectedComplexites([...selectedComplexites, item.id.toString()]);
		}

		
	};

	const handleKeyPress = (item, e) => {
		if (e.key === 'Enter' || e.key === ' ') {
		  e.preventDefault();
		  handleCheckbox(item);
		}
	  };

	return (
		<>

			<ul title='Complexity'>
				{complexities.map((item) => (
					<li	key={item.id} >
						<label 
							className="NotificationChkboxContainer"
							onKeyPress={(e) => handleKeyPress(item, e)}
						>
							<input
								name={item.name}
								type="checkbox"
								checked={selectedComplexites.includes(item.id.toString())}
								onChange={() => {
									handleCheckbox(item);
									track(
										1,
										2108,
										item.id,
										item.name
									);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										handleCheckbox(item);
										track(
											1,
											2108,
											item.id,
											item.name
										);
									}
								}}
								
							/>
							<span className="CustomCheckmark" 
							aria-checked={selectedComplexites.includes(item.id)} 
							tabIndex={0}
							role="checkbox" 
							title={item.name}
							  ></span>
							<span className="filterLabel" title={item.name}>
								{item.name}
							</span>
						</label>
					</li>
				))}
			</ul>
		</>
	);
};

export default ComplexityFilter;
