import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { getUsersList, getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import UserSelectionDropdown from '../PhaseLeftMenu/PhaseAddition/UserSelectionDropdown';
import OwnerSelectionDropdown from './OwnerSelectionDropdown';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import HashtagSuggestions from '../HashtagSuggestions';
import ProcessGroupDropdown from './ProcessGroupDropdown';
import AddTools from './AddTools';
import CKEditor from './CkEditor';
import AddRoles from './AddRoles';
import PreviewModal from './PreviewModal';
import DiscardModal from './DiscardModal';
import ResponseModal from './ResponseModal';
import Spinner from '../../helpers/Spinner';
import Modal from 'react-bootstrap/Modal';
import SaveSpinner from '../../helpers/CrowdSourcingSpinner';
import {
	handleTernaryCondition,
	titleValidator,
	urlValidator,
} from '../../helpers/Validation';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import Breadcrumbs from '../Breadcrumbs';
import AdminModal from './AdminMessage';
import Dropdown from './Dropdown';
import TopicsSequence from './TopicsSequence';

import './TopicBuilder.css';
import TopicPublishModal from './TopicPublishModal';
import { TabTitle } from "../../utils/GeneralFunctions";
import { Accordion, Card } from 'react-bootstrap';

const TopicBuilder = ({ selectedSection }) => {
	const tabs = [
		{ id: 1, name: 'Topic Details' },
		{ id: 2, name: 'Sequence' },
	];
	const source = window.location.search
		? queryString.parse(window.location.search).source === 'proc'
		: null;
	const { track } = useAppInsights();
	const { categoryId } = useParams();
	const { breadcrumbs, setBreadcrumbs, isAdmin } = useLeftMenuCollapse();
	const { userAlias, axiosGetService, axiosPostService, axiosDeleteService } = useAxios();
	const [topicTitle, setTopicTitle] = useState('');
	const [description, setDescription] = useState('');
	const [content, setContent] = useState('');
	const [relatedContent, setRelatedContent] = useState([{ title: "", url: "" }]);
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [approvers, setApprovers] = useState([]);
	const [reviewers, setReviewers] = useState([]);
	const [coOwners, setCoOwners] = useState([]);
	const [reviewerEmail, setReviewerEmail] = useState('');
	const [approverEmail, setApproverEmail] = useState('');
	const [coOwnwerEmail, setCoOwnwerEmail] = useState('');
	const [topicType, setTopicType] = useState(3);
	const [emailFound, setEmailFound] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		topicOwner: false,
	});
	const [error, setError] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		topicOwner: false,
	});
	const [usersList, setUsersList] = useState({
		coOwner: [],
		reviewer: [],
		approver: [],
		topicOwner: [],
	});
	const [processGroups, setProcessGroups] = useState([]);
	const [selectedProcessGroup, setSelectedProcessgroup] = useState([]);
	const [toolsData, setToolsData] = useState([]);
	const [selectedTools, setSelectedTools] = useState([]);
	const [toolSearchValue, setToolSearchValue] = useState('');
	const [toolsMapper, setToolsMapper] = useState({});
	const [roles, setRoles] = useState([]);
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [filteredRoles, setFilteredRoles] = useState([]);
	const [roleSearchValue, setRoleSearchValue] = useState('');
	const [relatedContentTitle, setRelatedContentTitle] = useState('');
	const [relatedContentUrl, setRelatedContentUrl] = useState('');
	const [isVisible, setIsVisible] = useState(true);
	const [showPreview, setShowPreview] = useState(false);
	const [showDiscardModal, setShowDiscardModal] = useState(false);
	const [showResponseModal, setShowResponseModal] = useState(false);
	const [message, setMessage] = useState('');
	const [showSaveSpinner, setShowSaveSpinner] = useState(false);
	const [relatedContentEditMode, setRelatedContentEditMode] = useState(false);
	const [relatedContentUpdateInfo, setRelatedContentUpdateInfo] = useState({});
	const [topicOwnerInfo, setTopicOwnerInfo] = useState({});
	const [topicOwnerEmail, setTopicOwnerEmail] = useState('');
	const [isOwnerOrCoOwner, setIsOwnerOrCoOwner] = useState(true);
	const [topics, setTopics] = useState([]);
	const [showPublishModal, setShowPublishModal] = useState(false);
	const topicTypes = [
		{ id: 1, name: 'Visio' },
		{ id: 2, name: 'Url' },
		{ id: 3, name: 'Plain Text' },
	];
	const [selectedTab, setSelectedTab] = useState(1);
	const [topicCategories, setTopicCategories] = useState([]);
	const [updatedCategoryId, setUpdatedCategoryId] = useState(categoryId);
	const [isCrowdSourceable, setIsCrowdSourcable] = useState(false);
	const [topicId, setTopicId] = useState(0)
	const [businessRelatedContentTitle, setBusinessRelatedContentTitle] = useState('')
	const [businessRelatedContentUrl, setBusinessRelatedContentUrl] = useState('')
	const [businessRule, setBusinessRule] = useState([])
	const [businessRelatedContentEditMode, setBusinessRelatedContentEditMode] = useState(false)
	const [BusinessRelatedContentUpdateInfo, setBusinessRelatedContentUpdateInfo] = useState({})
	const [activeKey, setActiveKey] = useState('');
	const [activeKeyRelativeContent, setactiveKeyRelativeContent] = useState('');
	const [activeKeyBusinessRule, setactiveKeyBusinessRule] = useState('');

	const [showRelatedContentModal, setShowRelatedContentModal] = useState(false);
	const [showBusinessRuleModal, setShowBusinessRuleModal] = useState(false);
	const [showRelatedContentUrlError, setShowRelatedContentUrlError] = useState(false);
	const [showRelatedContentTitleError, setShowRelatedContentTitleError] = useState(false);
	const [showRelatedContentUrlErrorMsg, setShowRelatedContentUrlErrorMsg] = useState("");
	const [showRelatedContentTitleErrorMsg, setShowRelatedContentTitleErrorMsg] = useState("");
	const [showRelatedContentIndexError, setShowRelatedContentIndexError] = useState();
	const [showBusinessRuleUrlError, setShowBusinessRuleUrlError] = useState(false);
	const [showBusinessRuleTitleError, setShowBusinessRuleTitleError] = useState(false);
	const [showBusinessRuleUrlErrorMsg, setShowBusinessRuleUrlErrorMsg] = useState("");
	const [showBusinessRuleTitleErrorMsg, setShowBusinessRuleTitleErrorMsg] = useState("");
	const [showBusinessRuleIndexError, setShowBusinessRuleIndexError] = useState();

	const [businessRuleUpdatedArray, setBusinessRuleUpdatedArray] = useState([]);
	const [businessRuleOrgContent, setBusinessRuleOrgContent] = useState([]);
	const [businessRelatedContent, setBusinessRelatedContent] = useState([{ name: "", url: "" }]);
	const [updateApiCall, setUpdateApiCall] = useState(false);
	const [sdmContentErrorMessage, setSDMContentErrorMessage] = useState('');

	const [categoryOwner, setCategoryOwner] = useState({});
	const getOwnerInfo = async (userEmail, setValue) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue(response);
		});
	};
	useEffect(() => {
		getSDMContentErrorMessage()
	}, [])
	const getSDMContentErrorMessage = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=Topic_EmptyContentMessage`).then(
			(response) => {
				setSDMContentErrorMessage(response?.data[0]?.value);
			}
		);
	};

	const getCategoryDetails = async () => {
		await axiosGetService(
			`api/sdmtopiccategories/getbyid?topicCategpryId=${categoryId}`
		).then((response) => {
			const data = response.data;
			if (data) {
				if (data.owner) {
					getOwnerInfo(data.owner, setCategoryOwner);
					// setCategoryOwner(data.owner);
				}	
			}
		});
	};
	const getTopicsCategoryDataForAdmin = async () => {
		const response = await axiosGetService(
			`api/admin/topics?pageSize=10&pageNumber=1&id=${0}&searchValue=""`
		);
		setTopicCategories(
			response.data.adminCategory.map((topicCategory) => {
				return {
					id: topicCategory.id.toString(),
					name: topicCategory.displayName,
					categoryType: topicCategory.categoryTypeId,
				};
			})
		);
	};
	const removeUser = (email, value, setValue) => {
		const updatedList = value.filter(({ mail }) => mail !== email);
		setValue(updatedList);
	};

	const getUser = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const submitHashtags = () => {
		// Don't do anything
	};

	const getProcessGroup = async () => {
		await axiosGetService(`api/sdmprocessgroups/getallforactivity`).then(
			(response) => {
				const data = response.data.map((item) => {
					return {
						name: item.name,
						id: item.id,
					};
				});

				setProcessGroups(data);
			}
		);
	};

	const getTools = async () => {
		await axiosGetService(
			`api/sdmtools/getAllWithDrafts?searchValue=${toolSearchValue ? toolSearchValue : 0
			}`
		).then((response) => {
			const data = response.data;
			const tools = data.map((item) => {
				return {
					id: item.id,
					name: item.name,
					url: item.url,
					isSelected: item.isSelected,
				};
			});
			if (Object.keys(toolsMapper).length === 0) {
				const toolsIdMap = {};
				for (const tool of data) {
					toolsIdMap[tool.id] = {
						name: tool.name,
						url: tool.url,
					};
				}
				setToolsMapper(toolsIdMap);
			}
			setToolsData(tools);
		});
	};

	const getRoles = async () => {
		await axiosGetService(`api/sdmphase/getallroles`).then((response) => {
			const roleIds = response.data.map((item) => {
				return {
					id: item.id,
					name: item.name,
				};
			});
			setRoles(roleIds);
		});
	};

	const validateUser = async () => {
		await axiosGetService(
			`api/sdmtopics/istopiccategoryownerorcoowner/${categoryId}`
		).then((response) => {
			setIsOwnerOrCoOwner(response.data);
		});
		window.scroll(0, 0);
	};

	// load and set the breadcrumb
	const loadBreadcrumb = async () => {
		await axiosGetService(
			`api/sdmuser/getbreadcrumb?itemId=${0}&itemTypeId=10
			&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false
			&documentStatus=0&isNotifications=false&solutionMethodId=${categoryId}&phaseId=${0}`
		).then((response) => {
			const breadcrumbTitle = response?.data?.split('>')
				.map((item) => item.trim());
			const topicsBreadcrumb = {
				title: breadcrumbTitle[1],
				path:
					breadcrumbTitle[1] === "Job Aides"
						? "/topics?topicType=job&selectedJobAides=2"
						: breadcrumbTitle[1] === "Readiness"
							? "/topics?topicType=read&selectedReadiness=2"
							: '/topics',
				parent: ['home', 'myContributions'],
				type: 'topics',
			};
			// const topicsCategoryBreadcrumb = {
			// 	title: breadcrumbTitle[2],
			// 	path:
			// 		breadcrumbTitle[1] === "Job Aides"
			// 			? `/topics?topicType=job&selectedJobAides=2`
			// 			: breadcrumbTitle[1] === "Readiness"
			// 				? `/topics?topicType=read&selectedReadiness=2`
			// 				: `/topics`,
			// 	parent: 'topics',
			// 	type: 'topicType',
			// };
			const currentTopicsContentBreadcrumb = {
				title: breadcrumbTitle[2],
				parent: ['pageTitle'],
			};
			const newBreadcrumbs = breadcrumbs.slice(0, 1);
			newBreadcrumbs.push(topicsBreadcrumb);
			// newBreadcrumbs.push(topicsCategoryBreadcrumb);
			newBreadcrumbs.push(currentTopicsContentBreadcrumb);
			setBreadcrumbs(newBreadcrumbs);
			TabTitle(breadcrumbTitle[2]);
		});
	};

	const searchRoles = () => {
		if (roleSearchValue.trim().length !== 0) {
			const filterRoles = roles.filter((role) =>
				role.name.toLowerCase().includes(roleSearchValue.toLowerCase())
			);
			setFilteredRoles(filterRoles);
		}
	};
	const getPostData = () => {
		const processGroupIds = selectedProcessGroup.map(({ id }) => {
			return id;
		});
		const rolesIds = selectedRoles.map(({ id }) => {
			return id;
		});
		const reviewersList = reviewers.map(({ mail }) => {
			return mail;
		});
		const approversList = approvers.map(({ mail }) => {
			return mail;
		});
		const coOwnerList = coOwners.map(({ mail }) => {
			return mail;
		});
		const toolIds = selectedTools.map(({ id }) => {
			return id;
		});
		const imagesUrl = sessionStorage.getItem('topicBuilderImage')
			? JSON.parse(sessionStorage.getItem('topicBuilderImage'))
			: [];
		const postData = {
			imagePaths: imagesUrl,
			topicName: topicTitle,
			diagramName: content,
			description: description,
			categoryId: updatedCategoryId,
			isVisible: isVisible,
			isCrowdSourceable: topicType === 3 ? true : isCrowdSourceable,
			reviewerMembersList: reviewersList,
			approverMembersList: approversList,
			processGroupIds: processGroupIds.toString(),
			HashTags: userAddedHashtags.toString(),
			topicRelatedContentList: relatedContent,
			toolIds: toolIds.toString(),
			roleIds: rolesIds.toString(),
			owner: topicOwnerInfo.mail!==null?topicOwnerInfo.mail:categoryOwner,
			coOwnerMembersList: coOwnerList,
			topicType: topicType,
		};
		return postData;
	};

	const updateRelatedContent = (duplicateTitle, duplicateUrl) => {
		const isError =
			(relatedContentTitle && !titleValidator.test(relatedContentTitle)) ||
			(relatedContentUrl && !urlValidator.test(relatedContentUrl));
		if (duplicateTitle.length) {
			toast.error('Duplicate title', {
				position: 'top-right',
			});
		} else if (duplicateUrl.length) {
			toast.error('Duplicate url', {
				position: 'top-right',
			});
		} else if (!isError) {
			const updatedContent = relatedContent.map((item) => {
				if (item.title === relatedContentUpdateInfo.title) {
					return {
						title: relatedContentTitle,
						url: relatedContentUrl,
					};
				} else {
					return item;
				}
			});
			setRelatedContent([...updatedContent]);
			setRelatedContentUrl('');
			setRelatedContentTitle('');
			setRelatedContentEditMode(false);
			setRelatedContentUpdateInfo({});
		}
	};

	const addRelatedContent = () => {
		if (relatedContentEditMode) {
			const duplicateTitle = relatedContent.filter(
				({ title }) =>
					title === relatedContentTitle &&
					title !== relatedContentUpdateInfo.title
			);
			const duplicateUrl = relatedContent.filter(
				({ url }) =>
					url === relatedContentUrl && url !== relatedContentUpdateInfo.url
			);
			updateRelatedContent(duplicateTitle, duplicateUrl);
		} else {
			const duplicateTitle = relatedContent.filter(
				({ title }) => title === relatedContentTitle
			);
			const duplicateUrl = relatedContent.filter(
				({ url }) => url === relatedContentUrl
			);
			const isError =
				(relatedContentTitle && !titleValidator.test(relatedContentTitle)) ||
				(relatedContentUrl && !urlValidator.test(relatedContentUrl));
			if (duplicateTitle.length) {
				toast.error('Duplicate title', {
					position: 'top-right',
				});
			} else if (duplicateUrl.length) {
				toast.error('Duplicate url', {
					position: 'top-right',
				});
			} else if (!isError && relatedContentTitle && relatedContentUrl) {
				setRelatedContent([
					...relatedContent,
					{ title: relatedContentTitle, url: relatedContentUrl },
				]);
				setRelatedContentUrl('');
				setRelatedContentTitle('');
			}
		}
	};
	const toggleAccordion = (key) => {
		setActiveKey(activeKey === key ? '' : key);
		setactiveKeyRelativeContent('');
		setactiveKeyBusinessRule('');

	};
	const toggleAccordionRelativeContent = (key) => {
		setactiveKeyRelativeContent(activeKeyRelativeContent === key ? '' : key);
		setActiveKey('');
		setactiveKeyBusinessRule('');
	};
	const toggleAccordionBusineesRule = (key) => {
		setactiveKeyBusinessRule(activeKeyBusinessRule === key ? '' : key);
		setActiveKey('');
		setactiveKeyRelativeContent('');
	};
	// const removeRelatedContent = (item) => {
	// 	const updatedRelatedContent = relatedContent.filter(
	// 		({ title }) => title !== item
	// 	);
	// 	setRelatedContent(updatedRelatedContent);
	// };

	const removeRelatedContent = (item, index) => {
		const updatedContent = [...relatedContent];
		updatedContent.splice(index, 1);
		setRelatedContent(updatedContent);
	};

	const updateRelatedContentPolygon = () => {
		const updatedRelatedContent = relatedContent.filter(
			({ title, url }) => title !== "" || url !== ""
		);
		setRelatedContent(updatedRelatedContent);
		setShowRelatedContentModal(false);
	};

	const handleAddRelatedContentFields = () => {
		const updatedRelatedContent = relatedContent.filter(
			({ title, url }) => title === "" && url === ""
		);
		if (!updatedRelatedContent.length) {
			setRelatedContent([...relatedContent, { title: "", url: "" }]);
		}
	};

	const handleToChangeBusinessContent = (e, item, index) => {
		if (e.target.name === 'name') {
			const duplicateTitle = businessRelatedContent.filter(({ name }) => name.toLowerCase() === e.target.value.toLowerCase());
			const isError = e.target.value && !titleValidator.test(e.target.value);
			if (duplicateTitle.length) {
				setShowBusinessRuleTitleError(true);
				setShowBusinessRuleTitleErrorMsg("Duplicate title");
				setShowBusinessRuleIndexError(index);
			} else if (isError) {
				setShowBusinessRuleTitleError(true);
				setShowBusinessRuleTitleErrorMsg("Invalid title");
				setShowBusinessRuleIndexError(index);
			} else if (!isError) {
				setShowBusinessRuleTitleError(false);
				setShowBusinessRuleTitleErrorMsg('');
			}
		} else {
			const duplicateUrl = businessRelatedContent.filter(({ url }) => url === e.target.value);
			const isError = e.target.value && !urlValidator.test(e.target.value);
			if (duplicateUrl.length) {
				setShowBusinessRuleUrlError(true);
				setShowBusinessRuleUrlErrorMsg("Duplicate url");
				setShowBusinessRuleIndexError(index);
			} else if (isError) {
				setShowBusinessRuleUrlError(true);
				setShowBusinessRuleUrlErrorMsg("Invalid Url");
				setShowBusinessRuleIndexError(index);
			} else if (!isError) {
				setShowBusinessRuleUrlError(false);
				setShowBusinessRuleUrlErrorMsg('');
			}
		}
		if (item.id !== undefined) {
			setBusinessRuleUpdatedArray([...businessRuleUpdatedArray, item.id]);
		}
		const field = e.target.name;
		const updatedBusinessRuleContent = [...businessRelatedContent];
		updatedBusinessRuleContent[index][field] = e.target.value;
		setBusinessRelatedContent(updatedBusinessRuleContent);
	}

	const removeBusinessRelatedContent = (index) => {
		const updatedContent = [...businessRelatedContent];
		updatedContent.splice(index, 1);
		setBusinessRelatedContent(updatedContent);
	}

	const deleteBusinessRelatedContent = async (id) => {
		await axiosDeleteService(
			`api/sdmbusinessrules/deletebusinessrule/${id}/${topicId}/10/0`
		).then(response => {
			if (response.status) {
				toast.info('Content has been deleted successfully', {
					position: toast.POSITION.TOP_RIGHT,
				});
				// getBusinessRelatedContent();
			}
		});
	}

	// const getBusinessRelatedContent = async () => {
	//     const filteredTopicId = topicsData.filter(e => e.polygonSequence === selectedPath)[0].topicId
	//     let api = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${filteredTopicId}/10/0/${false}`
	//     await axiosGetService(api).then(
	//         (response) => {
	//             const businessRuleContentData = response.data;
	//             setBusinessRelatedContent([...businessRuleContentData, { name: "", url: "" }]);
	//             setBusinessRuleOrgContent(response.data)
	//         })
	// }

	const handleAddBusinessContentFields = () => {
		const updatedBusinessContent = businessRelatedContent.filter(
			({ name, url }) => name === "" && url === ""
		);
		if (!updatedBusinessContent.length) {
			setBusinessRelatedContent([...businessRelatedContent, { name: "", url: "" }]);
		}
	};

	const saveTopic = async () => {
		setShowSaveSpinner(true);
		const postData = getPostData();
		postData.sequenceTopicIds = '';
		await axiosPostService(`api/sdmtopics/adddraft`, postData).then(
			(response) => {
				if (response.data.status) {
					setMessage('You have successfully saved the topic');
					setShowResponseModal(true);
					sessionStorage.setItem('topicBuilderImage', JSON.stringify([]));
					track(1, 1801, 'Topics Builder', topicTitle);
					let id = response.data.topicId.toString();
					if (businessRelatedContent.length) {
						businessRelatedContent.forEach(e => {
							publishBusinessRule(e, id, true)
						})
					}
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
			}
		);
		setShowSaveSpinner(false);
	};

	const updateBusinessRelatedContent = (duplicateTitle, duplicateUrl) => {
		const isError =
			(businessRelatedContentTitle && !titleValidator.test(businessRelatedContentTitle)) ||
			(businessRelatedContentUrl && !urlValidator.test(businessRelatedContentUrl));
		if (duplicateTitle.length) {
			toast.error('Duplicate title', {
				position: 'top-right',
			});
		} else if (duplicateUrl.length) {
			toast.error('Duplicate url', {
				position: 'top-right',
			});
		} else if (!isError) {
			const updatedContent = businessRule.map((item) => {
				if (item.name === BusinessRelatedContentUpdateInfo.name) {
					return {
						name: businessRelatedContentTitle,
						url: businessRelatedContentUrl,
					};

				} else {
					return item;
				}
			});
			setBusinessRule([...updatedContent]);
			toast.info('Content has been updated successfully', {
				position: toast.POSITION.TOP_RIGHT,
			})
			setBusinessRelatedContentUrl('');
			setBusinessRelatedContentTitle('');
			setBusinessRelatedContentEditMode(false);
			setBusinessRelatedContentUpdateInfo({});
		}
	};

	const addBusinessRelatedContent = () => {
		if (businessRelatedContentEditMode) {
			const duplicateTitle = businessRule.filter(
				({ name }) =>
					name === businessRelatedContentTitle &&
					name !== BusinessRelatedContentUpdateInfo.title
			);
			const duplicateUrl = businessRule.filter(
				({ url }) =>
					url === businessRelatedContentUrl && url !== BusinessRelatedContentUpdateInfo.url
			);
			updateBusinessRelatedContent(duplicateTitle, duplicateUrl);
		} else {
			const duplicateTitle = businessRule.filter(
				({ name }) => name === businessRelatedContentTitle
			);
			const duplicateUrl = businessRule.filter(
				({ url }) => url === businessRelatedContentUrl
			);
			const isError =
				(businessRelatedContentTitle && !titleValidator.test(businessRelatedContentTitle)) ||
				(businessRelatedContentUrl && !urlValidator.test(businessRelatedContentUrl));
			if (duplicateTitle.length) {
				toast.error('Duplicate title', {
					position: 'top-right',
				});
			} else if (duplicateUrl.length) {
				toast.error('Duplicate url', {
					position: 'top-right',
				});
			} else if (!isError && businessRelatedContentTitle && businessRelatedContentUrl) {
				setBusinessRule([
					...businessRule,
					{ name: businessRelatedContentTitle, url: businessRelatedContentUrl },
				]);
				toast.info('Content has been add successfully', {
					position: toast.POSITION.TOP_RIGHT,
				})
				setBusinessRelatedContentUrl('');
				setBusinessRelatedContentTitle('');
			}
		}
	};

	// const removeBusinessRelatedContent = (item) => {
	// 	const updatedRelatedContent = businessRule.filter(
	// 		({ name }) => name !== item
	// 	);
	// 	setBusinessRule(updatedRelatedContent);
	// 	toast.info('Content has been deleted successfully', {
	// 		position: toast.POSITION.TOP_RIGHT,
	// 	});
	// };

	const publishBusinessRule = async (e, id, isDrafted) => {
		let postData = {
			name: e.name,
			url: e.url,
			isDrafted: isDrafted,
			ArtifactId: id,
			ArtifactTypeId: 10,
			ComplexityId: 0
		}
		await axiosPostService(`api/sdmbusinessrules/addBusinessRule`, postData)
	}
	// useEffect(() => {
	//     getBusinessRelatedContent();
	// }, [selectedPath, showPreview])

	const publishTopic = async () => {
		let id;
		setShowSaveSpinner(true);
		const postData = getPostData();
		let response = await axiosPostService(
			`api/sdmtopics/publishtopic`,
			postData
		);
		if (isAdmin && response.data.status) {
			const updatedSequencesIds = topics.map(({ id }) => {
				if (id === 0) return response.data.topicId;
				return Number(id);
			});
			id = response.data.topicId.toString()
			const postSequenceData = {
				sequenceTopicIds: updatedSequencesIds.toString(),
				categoryId: updatedCategoryId,
			};
			response = await axiosPostService(
				`api/sdmtopics/updatetopicsequence`,
				postSequenceData
			);
		}
		if (response.data.status) {
			setMessage('You have successfully published the topic');
			setShowResponseModal(true);
			sessionStorage.setItem('topicBuilderImage', JSON.stringify([]));
			track(1, 1805, 'Topics Builder', topicTitle);
			if (businessRelatedContent.length) {
				businessRelatedContent.forEach(e => {
					publishBusinessRule(e, id, false)
				})
			}
		} else {
			toast.error(response.data.errorMessage, {
				position: 'top-right',
			});
		}
		setShowSaveSpinner(false);
	};

	const schedulePublishTopic = async (publishScheduledDateTime) => {
		setShowSaveSpinner(true);
		const postData = getPostData();
		postData.publishScheduledDateTime = publishScheduledDateTime;
		let response = await axiosPostService(
			`api/sdmtopics/publishscheduletopic`,
			postData
		);
		if (isAdmin && response.data.status) {
			const updatedSequencesIds = topics.map(({ id }) => {
				if (id === 0) return response.data.topicId;
				return Number(id);
			});
			const postSequenceData = {
				sequenceTopicIds: updatedSequencesIds.toString(),
				categoryId: updatedCategoryId,
			};
			response = await axiosPostService(
				`api/sdmtopics/updatetopicsequence`,
				postSequenceData
			);
		}
		if (response.data.status) {
			setMessage('You have successfully scheduled to publish the topic');
			setShowResponseModal(true);
			sessionStorage.setItem('topicBuilderImage', JSON.stringify([]));
			track(1, 1805, 'Topics Builder', topicTitle);
		} else {
			toast.error(response.data.errorMessage, {
				position: 'top-right',
			});
		}
		setShowSaveSpinner(false);
	};

	const removeProcessGroup = (item) => {
		const updatedValue = selectedProcessGroup.filter(
			({ id }) => id !== item.id
		);
		setSelectedProcessgroup(updatedValue);
	};

	const handleToChangeContent = (e, index) => {
		if (e.target.name === 'title') {
			const duplicateTitle = relatedContent.filter(({ title }) => title.toLowerCase() === e.target.value.toLowerCase());
			const isError = e.target.value && !titleValidator.test(e.target.value);
			if (duplicateTitle.length) {
				setShowRelatedContentTitleError(true);
				setShowRelatedContentTitleErrorMsg("Duplicate title");
				setShowRelatedContentIndexError(index);
			} else if (isError) {
				setShowRelatedContentTitleError(true);
				setShowRelatedContentTitleErrorMsg("Invalid title");
				setShowRelatedContentIndexError(index);
			} else if (!isError) {
				setShowRelatedContentTitleError(false);
				setShowRelatedContentTitleErrorMsg('');
			}
		} else {
			const duplicateUrl = relatedContent.filter(({ url }) => url === e.target.value);
			const isError = e.target.value && !urlValidator.test(e.target.value);
			if (duplicateUrl.length) {
				setShowRelatedContentUrlError(true);
				setShowRelatedContentUrlErrorMsg("Duplicate url");
				setShowRelatedContentIndexError(index);
			} else if (isError) {
				setShowRelatedContentUrlError(true);
				setShowRelatedContentUrlErrorMsg("Invalid url");
				setShowRelatedContentIndexError(index);
			} else if (!isError) {
				setShowRelatedContentUrlError(false);
				setShowRelatedContentUrlErrorMsg('');
			}
		}
		const field = e.target.name;
		const updatedContent = [...relatedContent];
		updatedContent[index][field] = e.target.value;
		setRelatedContent(updatedContent);
	}

	const addBusinessRuleContent = async () => {
		// // const filteredTopicId = topicsData.filter(e => e.polygonSequence === selectedPath)[0].topicId;
		// businessRelatedContent.map(async (item) => {
		//     if (item.id === undefined) {
		//         const relatedContent =
		//         {
		//             name: item.name,
		//             url: item.url,
		//             isDrafted: true,
		//             ArtifactId: 0,
		//             ArtifactTypeId: 10,
		//             ComplexityId: 0
		//         }
		//         const postData = JSON.stringify(relatedContent)
		//         await axiosPostService(`api/sdmbusinessrules/addBusinessRule`, postData).then(
		//             (response) => {
		//                 if (response.data.status && response.data.errorMsg === null) {
		//                     toast.info('Content has been added successfully', {
		//                         position: toast.POSITION.TOP_RIGHT,
		//                     })
		//                     // getBusinessRelatedContent();
		//                 } else {
		//                     toast.error(response.data.errorMsg, {
		//                         position: toast.POSITION.TOP_RIGHT,
		//                     })
		//                 }
		//             }
		//         )
		//     } else {
		//         if (businessRuleUpdatedArray.includes(item.id)) {
		//             const updatedRelatedContent =
		//             {
		//                 id: item.id,
		//                 name: item.name,
		//                 url: item.url,
		//                 isDrafted: true,
		//                 ArtifactId: 0,
		//                 ArtifactTypeId: 10,
		//                 ComplexityId: 0
		//             }
		//             const postData = JSON.stringify(updatedRelatedContent)
		//             await axiosPostService(`api/sdmbusinessrules/updateBusinessRule`, postData).then(
		//                 (response) => {
		//                     if (response.data.status && response.data.errorMsg === null) {
		//                         toast.info('Content has been updated successfully', {
		//                             position: toast.POSITION.TOP_RIGHT,
		//                         })
		//                         // getBusinessRelatedContent();
		//                     } else {
		//                         toast.error(response.data.errorMsg, {
		//                             position: toast.POSITION.TOP_RIGHT,
		//                         })
		//                     }
		//                 })
		//         }
		//     }
		// });

		const updatedRelatedContent = businessRelatedContent.filter(
			({ name, url }) => name !== "" || url !== ""
		);
		setBusinessRelatedContent(updatedRelatedContent);
		setShowBusinessRuleModal(false);
	}

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getRoles();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, []);

	useEffect(() => {
		searchRoles();
	}, [roleSearchValue]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerEmail) {
				getUser(reviewerEmail, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverEmail) {
				getUser(approverEmail, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (coOwnwerEmail) {
				getUser(coOwnwerEmail, 'coOwner');
			} else {
				setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
				setError({ ...error, ['coOwner']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [coOwnwerEmail]);

	useEffect(() => {
		getProcessGroup();
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getTools();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [toolSearchValue]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (topicOwnerEmail) {
				getUser(topicOwnerEmail, 'topicOwner');
			} else {
				setUsersList((prevState) => ({ ...prevState, topicOwner: [] }));
				setError({ ...error, ['topicOwner']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [topicOwnerEmail]);

	useEffect(() => {
		validateUser();
	}, []);

	useEffect(() => {
		loadBreadcrumb();
	}, []);

	useEffect(() => {
		if (isAdmin) {
			getTopicsCategoryDataForAdmin();
		}
	}, [isAdmin]);
useEffect(() => {
	getCategoryDetails();
}, [showPreview])

	useEffect(() => {
		if (isAdmin) {
			axiosGetService(
				`api/sdmtopics/getbycategoryid/${updatedCategoryId}`
			).then((response) => {
				const data = response.data.map((item) => {
					return {
						id: item.id.toString(),
						name: item.name,
						type: item.isDrafted ? 'Draft' : '',
					};
				});
				data.push({ id: '0', name: topicTitle, type: 'New' });
				setTopics(data);
			});
		}
	}, [updatedCategoryId, isAdmin]);

	useEffect(() => {
		setTopics(
			topics.map((topic) => {
				if (topic.id === 0) {
					topic.name = topicTitle;
				}
				return topic;
			})
		);
	}, [selectedTab]);

	if (!isOwnerOrCoOwner) {
		return <AdminModal />;
	}

	return (

		// Old Code for Reference

		// <div className="topicBulderMainContainer">
		// 	<Breadcrumbs />
		// 	<div className="topicBulderContainer">
		// 		<div className="topicBulderHeaderContainer">
		// 			<h4>Topic Builder</h4>
		// 	<div className="topicBulderHeaderButtons">
		// 		<button
		// 			onClick={() => {
		// 				setShowDiscardModal(true);
		// 			}}
		// 			onKeyPress={(e) => {
		// 				if (e.which === 13) {
		// 					setShowDiscardModal(true);
		// 				}
		// 			}}
		// 			title="Discard"
		// 		>
		// 			Discard
		// 		</button>
		// 		<button
		// 			onClick={() => {
		// 				saveTopic();
		// 			}}
		// 			onKeyPress={(e) => {
		// 				if (e.which === 13) {
		// 					saveTopic();
		// 				}
		// 			}}
		// 			title="Save"
		// 		>
		// 			Save
		// 		</button>
		// 		<button
		// 			onClick={() => {
		// 				setShowPreview(true);
		// 			}}
		// 			onKeyPress={(e) => {
		// 				if (e.which === 13) {
		// 					setShowPreview(true);
		// 				}
		// 			}}
		// 			title="Preview & Publish"
		// 		>
		// 			Preview & Publish
		// 		</button>
		// 	</div>
		// </div>
		// <div className="topicsMenuContainer">
		// 	<ul className="topicsMenu" role="tablist">
		// 		{tabs.map(
		// 			(item) =>
		// 				(item.id !== 2 || isAdmin) && (
		// 					<li
		// 						name={item}
		// 						role="tab"
		// 						tabIndex="0"
		// 						className={handleTernaryCondition(
		// 							selectedTab === item.id,
		// 							'selectedTopicMenu',
		// 							'notSelectedTopicMenu'
		// 						)}
		// 						onClick={() => {
		// 							setSelectedTab(item.id);
		// 						}}
		// 						onKeyPress={(e) => {
		// 							if (e.which === 13) {
		// 								setSelectedTab(item.id);
		// 							}
		// 						}}
		// 					>
		// 						<span className="consultingMenu">{item.name}</span>
		// 					</li>
		// 				)
		// 		)}
		// 	</ul>
		// </div>
		// {selectedTab == 1 && (
		// 	<div className="topicBulderFieldsContainer">
		// 		<div className="topicAdditionCont">
		// 			<div className="topicAdditionContainer">
		// 				<div
		// 					className={
		// 						!selectedSection.includes('addNewTopics')
		// 							? 'addTopicButtonAction'
		// 							: 'addTopicButtonAction expanded'
		// 					}
		// 				>
		// 					<div
		// 						className="selectAddTopicButton"
		// 						onClick={() => {
		// 							// do nothing
		// 						}}
		// 					>
		// 						Add a New Topic
		// 					</div>
		// 					{selectedSection.includes('addNewTopics') ? (
		// 						<>
		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv addTopicTitlePart">
		// 									<div>
		// 										<span className="AddActivityFieldLabel">
		// 											Topic Title *
		// 										</span>
		// 										<input
		// 											placeholder="Topic title"
		// 											value={topicTitle}
		// 											onChange={(e) => {
		// 												setTopicTitle(e.target.value);
		// 											}}
		// 										/>
		// 									</div>
		//           {!(topicType === 1 || topicType === 2) ? (
		//             <div className="isVisibleDiv">
		//               <input
		//                 type="checkbox"
		//                 title="Is crowd source checkbox"
		//                 checked={isCrowdSourceable}
		//                 onChange={() => {
		//                   setIsCrowdSourcable(!isCrowdSourceable);
		//                 }}
		//               />
		//               <span>Iscrowdsourceable</span>
		//             </div>
		//           ) : (
		//             <></>
		//           )}
		// 									<div className="isVisibleDiv">
		// 										<input
		// 											type="checkbox"
		// 											title="Is visible checkbox"
		// 											checked={isVisible}
		// 											onChange={() => {
		// 												setIsVisible(!isVisible);
		// 											}}
		// 										/>
		// 										<span>Isvisible</span>
		// 									</div>
		// 								</div>
		// 							</div>
		// 							{isAdmin && (
		// 								<div className="addTopicFields">
		// 									<div className="addTopicFieldsDiv">
		// 										<span className="AddActivityFieldLabel">
		// 											Topic Type
		// 										</span>
		// 										<Dropdown
		// 											className="topicType"
		// 											data={topicTypes}
		// 											value={topicType}
		// 											setValue={setTopicType}
		// 											enableDropdown={true}
		// 										/>
		// 									</div>
		// 								</div>
		// 							)}
		// 							{isAdmin && (
		// 								<div className="addTopicFields">
		// 									<div className="addTopicFieldsDiv">
		// 										<span className="AddActivityFieldLabel">
		// 											Topic Category
		// 										</span>
		// 										<Dropdown
		// 											className="topicCategory"
		// 											data={topicCategories}
		// 											value={updatedCategoryId}
		// 											setValue={setUpdatedCategoryId}
		// 											enableDropdown={true}
		// 										/>
		// 									</div>
		// 								</div>
		// 							)}
		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv">
		// 									<span className="AddActivityFieldLabel">
		// 										Add Hashtags
		// 									</span>
		// 									<HashtagSuggestions
		// 										className="topicsHashtagsInput"
		// 										enableDropdown={true}
		// 										data={hashtagSuggestions}
		// 										setHashtagSuggestions={setHashtagSuggestions}
		// 										searchValue={hashtagsInput}
		// 										setSearchValue={setHashtagsInput}
		// 										existingHashtags={userAddedHashtags}
		// 										userAddedHashtags={userAddedHashtags}
		// 										setUserAddedHashtags={setUserAddedHashtags}
		// 										hashtagSuggestionInput={hashtagSuggestionInput}
		// 										setHashtagSuggestionInput={
		// 											setHashtagSuggestionInput
		// 										}
		// 										closeHashtagForm={() => {
		// 											setHashtagsInput('');
		// 											setUserAddedHashtags([]);
		// 										}}
		// 										submitHashtags={submitHashtags}
		// 										autoFocus={false}
		// 										showSubmitButton={false}
		// 									/>
		// 								</div>
		// 							</div>
		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv">
		// 									<span className="AddActivityFieldLabel">
		// 										Description
		// 									</span>
		// 									<CKEditor
		// 										value={description}
		// 										setValue={setDescription}
		// 									/>
		// 								</div>
		// 							</div>
		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv">
		// 									<span className="AddActivityFieldLabel">
		// 										Add Topic Content *
		// 									</span>
		// 									<CKEditor value={content} setValue={setContent} />
		// 								</div>
		// 							</div>
		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv relatedContentBuilder">
		// 									<span className="AddActivityFieldLabel">
		// 										Related Content
		// 									</span>
		// 									<div class="Detailswrapper templateWrapActive">
		// 										<div class="DetailswrapperTitle">
		// 											<div class="newActivityfieldsWrapper">
		// 												<div class="cantFindToolsFields">
		// 													<div className="AddTopicFieldContentButtons">
		// 														<button
		// 															onClick={() => {
		// 																setRelatedContentUrl('');
		// 																setRelatedContentTitle('');
		// 																setRelatedContentEditMode(false);
		// 																setRelatedContentUpdateInfo({});
		// 															}}
		// 															onKeyPress={(e) => {
		// 																if (e.which === 13) {
		// 																	setRelatedContentUrl('');
		// 																	setRelatedContentTitle('');
		// 																	setRelatedContentEditMode(false);
		// 																	setRelatedContentUpdateInfo({});
		// 																}
		// 															}}
		// 														>
		// 															Cancel
		// 														</button>
		// 														<button
		// 															onClick={() => {
		// 																addRelatedContent();
		// 															}}
		// 															onKeyPress={(e) => {
		// 																if (e.which === 13) {
		// 																	addRelatedContent();
		// 																}
		// 															}}
		// 														>
		// 															Add
		// 														</button>
		// 													</div>
		// 													<div className="AddTopicFieldContenUrlContainer">
		// 														<div className="AddTopicFieldContenUrl">
		// 															<span className="AddActivityFieldLabel">
		// 																Content Title
		// 															</span>
		// 															<input
		// 																placeholder="Enter Content Title"
		// 																value={relatedContentTitle}
		// 																onChange={(e) => {
		// 																	setRelatedContentTitle(
		// 																		e.target.value
		// 																	);
		// 																}}
		// 															/>
		// 															{relatedContentTitle &&
		// 																!titleValidator.test(
		// 																	relatedContentTitle
		// 																) ? (
		// 																<p className="errorMsg">
		// 																	Invalid title
		// 																</p>
		// 															) : (
		// 																<></>
		// 															)}
		// 														</div>
		// 														<div className="AddTopicFieldContenUrl">
		// 															<span className="AddActivityFieldLabel">
		// 																Content URL
		// 															</span>
		// 															<input
		// 																placeholder="Enter url"
		// 																value={relatedContentUrl}
		// 																onChange={(e) => {
		// 																	setRelatedContentUrl(e.target.value);
		// 																}}
		// 															/>
		// 															{relatedContentUrl &&
		// 																!urlValidator.test(relatedContentUrl) ? (
		// 																<p className="errorMsg">Invalid url</p>
		// 															) : (
		// 																<></>
		// 															)}
		// 														</div>
		// 													</div>
		// 												</div>
		// 												<ul>
		// 													{relatedContent.map((item) => (
		// 														<li key={item.title}>
		// 															<div className="topicBuilderRelatedContent">
		// 																<div>Title:&nbsp;{item.title}</div>
		// 																<div>URL:&nbsp;{item.url}</div>
		// 															</div>
		// 															<Icon
		// 																tabIndex="0"
		// 																iconName="EditSolid12"
		// 																onClick={() => {
		// 																	setRelatedContentTitle(item.title);
		// 																	setRelatedContentUrl(item.url);
		// 																	setRelatedContentUpdateInfo({
		// 																		title: item.title,
		// 																		url: item.url,
		// 																	});
		// 																	setRelatedContentEditMode(true);
		// 																}}
		// 																onKeyPress={(e) => {
		// 																	if (e.which === 13) {
		// 																		setRelatedContentTitle(item.title);
		// 																		setRelatedContentUrl(item.url);
		// 																		setRelatedContentUpdateInfo({
		// 																			title: item.title,
		// 																			url: item.url,
		// 																		});
		// 																		setRelatedContentEditMode(true);
		// 																	}
		// 																}}
		// 															/>
		// 															<Icon
		// 																tabIndex="0"
		// 																onClick={() =>
		// 																	removeRelatedContent(item.title)
		// 																}
		// 																onKeyPress={(e) => {
		// 																	if (e.which === 13) {
		// 																		removeRelatedContent(item.title);
		// 																	}
		// 																}}
		// 																iconName="Cancel"
		// 															/>
		// 														</li>
		// 													))}
		// 												</ul>
		// 											</div>
		// 										</div>
		// 									</div>
		// 								</div>
		// 							</div>
		// 							<div className="addTopicFields">
		//                               <div className="addTopicFieldsDiv relatedContentBuilder">
		//           <span className="AddActivityFieldLabel">
		//             Business Rule
		//           </span>
		//           <div class="Detailswrapper templateWrapActive">
		//             <div class="DetailswrapperTitle">
		//               <div class="newActivityfieldsWrapper">
		//                 <div class="cantFindToolsFields">
		//                   <div className="AddTopicFieldContentButtons">
		//                     <button
		//                       onClick={() => {
		//                         setBusinessRelatedContentUrl("");
		//                         setBusinessRelatedContentTitle("");
		//                         setBusinessRelatedContentEditMode(false)
		// 						setBusinessRelatedContentUpdateInfo({})
		//                       }}
		//                       onKeyPress={(e) => {
		//                         if (e.which === 13) {
		//                           setBusinessRelatedContentUrl("");
		//                           setBusinessRelatedContentTitle("");
		//                           setBusinessRelatedContentEditMode(false)
		// 						  setBusinessRelatedContentUpdateInfo({})
		//                         }
		//                       }}
		//                     >
		//                       Cancel
		//                     </button>
		//                     <button
		//                       onClick={() => {
		//                         addBusinessRelatedContent();
		//                       }}
		//                       onKeyPress={(e) => {
		//                         if (e.which === 13) {
		// 						addBusinessRelatedContent();
		//                         }
		//                       }}
		//                     >
		//                       Add
		//                     </button>
		//                   </div>
		//                   <div className="AddTopicFieldContenUrlContainer">
		//                     <div className="AddTopicFieldContenUrl">
		//                       <span className="AddActivityFieldLabel">
		//                        Title
		//                       </span>
		//                       <input
		//                         placeholder="Enter Title"
		//                         value={businessRelatedContentTitle}
		//                         onChange={(e) => {
		//                           setBusinessRelatedContentTitle(
		//                             e.target.value
		//                           );
		//                         }}
		//                       />
		//                       {businessRelatedContentTitle &&
		//                         !titleValidator.test(
		//                           businessRelatedContentTitle
		//                         ) ? (
		//                         <p className="errorMsg">
		//                           Invalid title
		//                         </p>
		//                       ) : (
		//                         <></>
		//                       )}
		//                     </div>
		//                     <div className="AddTopicFieldContenUrl">
		//                       <span className="AddActivityFieldLabel">
		//                         URL
		//                       </span>
		//                       <input
		//                         placeholder="Enter url"
		//                         value={businessRelatedContentUrl}
		//                         onChange={(e) => {
		//                           setBusinessRelatedContentUrl(e.target.value);
		//                         }}
		//                       />
		//                       {businessRelatedContentUrl &&
		//                         !urlValidator.test(businessRelatedContentUrl) ? (
		//                         <p className="errorMsg">Invalid url</p>
		//                       ) : (
		//                         <></>
		//                       )}
		//                     </div>
		//                   </div>
		//                 </div>
		//                 <ul>
		//                   {businessRule.map((item) => (
		//                     <li key={item.name}>
		//                       <div className="topicBuilderRelatedContent">
		//                         <div>Title:&nbsp;{item.name}</div>
		//                         <div>URL:&nbsp;{item.url}</div>
		//                       </div>
		//                       <Icon
		//                         iconName="EditSolid12"
		//                         onClick={() => {
		//                           setBusinessRelatedContentTitle(item.name);
		//                           setBusinessRelatedContentUrl(item.url);
		// 						  setBusinessRelatedContentUpdateInfo({
		// 							name: item.name,
		// 							url: item.url,
		// 						  });
		// 						  setBusinessRelatedContentEditMode(true);
		//                         }}
		//                       />
		//                       <Icon
		//                         onClick={() =>
		//                           removeBusinessRelatedContent(item.name)
		//                         }
		//                         iconName="Cancel"
		//                       />
		//                     </li>
		//                   ))}
		//                 </ul>
		//               </div>
		//             </div>
		//           </div>
		//                               </div>
		//                             </div>

		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv">
		// 									<span className="AddActivityFieldLabel">
		// 										Add Owner
		// 									</span>
		// 									<OwnerSelectionDropdown
		// 										className="topicOwner"
		// 										enableDropdown={true}
		// 										data={usersList}
		// 										value={topicOwnerInfo}
		// 										setValue={setTopicOwnerInfo}
		// 										type="topicOwner"
		// 										searchValue={topicOwnerEmail}
		// 										setSearchValue={setTopicOwnerEmail}
		// 										error={error}
		// 										emailFound={emailFound['topicOwner']}
		// 									/>
		// 									{topicOwnerInfo.mail ? (
		// 										<ul
		// 											className={
		// 												'afterSaveListTopic collaboratorsApprovers'
		// 											}
		// 										>
		// 											<li key={topicOwnerInfo.mail}>
		// 												<span className="collabaratorPersonaImage">
		// 													<img src={topicOwnerInfo.photo} alt="img" />
		// 												</span>
		// 												<span className="collabaratorPersonaTitle">
		// 													{topicOwnerInfo.firstName +
		// 														' ' +
		// 														topicOwnerInfo.surname}
		// 												</span>
		// 												<span>
		// 													<Icon
		// 														iconName="Cancel"
		// 														onClick={() => {
		// 															setTopicOwnerInfo({});
		// 														}}
		// 														onKeyPress={(e) => {
		// 															if (e.which === 13) {
		// 																setTopicOwnerInfo({});
		// 															}
		// 														}}
		// 														role="button"
		// 														tabIndex={0}
		// 														aria-label={`Remove ${topicOwnerInfo.firstName} ${topicOwnerInfo.surname}`}
		// 														title="Remove Owner"
		// 													/>
		// 												</span>
		// 											</li>
		// 										</ul>
		// 									) : (
		// 										<></>
		// 									)}
		// 								</div>
		// 							</div>

		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv">
		// 									<span className="AddActivityFieldLabel">
		// 										Add Co-owners
		// 									</span>
		// 									<UserSelectionDropdown
		// 										className="coOwner"
		// 										enableDropdown={true}
		// 										data={usersList}
		// 										value={coOwners}
		// 										setValue={setCoOwners}
		// 										type="coOwner"
		// 										searchValue={coOwnwerEmail}
		// 										setSearchValue={setCoOwnwerEmail}
		// 										error={error}
		// 										emailFound={emailFound['coOwner']}
		// 									/>
		// 									{coOwners.length > 0 ? (
		// 										<ul
		// 											className={
		// 												'afterSaveListTopic collaboratorsApprovers'
		// 											}
		// 										>
		// 											{coOwners.map((user) => (
		// 												<li key={user.mail}>
		// 													<span className="collabaratorPersonaImage">
		// 														<img src={user.photo} alt="img" />
		// 													</span>
		// 													<span className="collabaratorPersonaTitle">
		// 														{user.firstName + ' ' + user.surname}
		// 													</span>
		// 													<span>
		// 														<Icon
		// 															iconName="Cancel"
		// 															onClick={() => {
		// 																removeUser(
		// 																	user.mail,
		// 																	coOwners,
		// 																	setCoOwners
		// 																);
		// 															}}
		// 															onKeyPress={(e) => {
		// 																if (e.which === 13) {
		// 																	removeUser(
		// 																		user.mail,
		// 																		coOwners,
		// 																		setCoOwners
		// 																	);
		// 																}
		// 															}}
		// 															role="button"
		// 															tabIndex={0}
		// 															aria-label={`Remove ${user.firstName} ${user.surname}`}
		// 															title="Remove CoOwner"
		// 														/>
		// 													</span>
		// 												</li>
		// 											))}
		// 										</ul>
		// 									) : (
		// 										<></>
		// 									)}
		// 								</div>
		// 							</div>

		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv">
		// 									<span className="AddActivityFieldLabel">
		// 										Add Approvers
		// 									</span>
		// 									<UserSelectionDropdown
		// 										className="approver"
		// 										enableDropdown={true}
		// 										data={usersList}
		// 										value={approvers}
		// 										setValue={setApprovers}
		// 										type="approver"
		// 										searchValue={approverEmail}
		// 										setSearchValue={setApproverEmail}
		// 										error={error}
		// 										emailFound={emailFound['approver']}
		// 									/>
		// 									{approvers.length > 0 ? (
		// 										<ul
		// 											className={
		// 												'afterSaveListTopic collaboratorsApprovers'
		// 											}
		// 										>
		// 											{approvers.map((user) => (
		// 												<li key={user.mail}>
		// 													<span className="collabaratorPersonaImage">
		// 														<img src={user.photo} alt="img" />
		// 													</span>
		// 													<span className="collabaratorPersonaTitle">
		// 														{user.firstName + ' ' + user.surname}
		// 													</span>
		// 													<span>
		// 														<Icon
		// 															iconName="Cancel"
		// 															onClick={() => {
		// 																removeUser(
		// 																	user.mail,
		// 																	approvers,
		// 																	setApprovers
		// 																);
		// 															}}
		// 															onKeyPress={(e) => {
		// 																if (e.which === 13) {
		// 																	removeUser(
		// 																		user.mail,
		// 																		approvers,
		// 																		setApprovers
		// 																	);
		// 																}
		// 															}}
		// 															role="button"
		// 															tabIndex={0}
		// 															aria-label={`Remove ${user.firstName} ${user.surname}`}
		// 															title="Remove approver"
		// 														/>
		// 													</span>
		// 												</li>
		// 											))}
		// 										</ul>
		// 									) : (
		// 										<></>
		// 									)}
		// 								</div>
		// 							</div>
		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv">
		// 									<span className="AddActivityFieldLabel">
		// 										Add Reviewers
		// 									</span>
		// 									<UserSelectionDropdown
		// 										className="reviewer"
		// 										enableDropdown={true}
		// 										data={usersList}
		// 										value={reviewers}
		// 										setValue={setReviewers}
		// 										type="reviewer"
		// 										searchValue={reviewerEmail}
		// 										setSearchValue={setReviewerEmail}
		// 										error={error}
		// 										emailFound={emailFound['reviewer']}
		// 									/>
		// 									{reviewers.length > 0 ? (
		// 										<ul
		// 											className={
		// 												'afterSaveListTopic collaboratorsApprovers'
		// 											}
		// 										>
		// 											{reviewers.map((user) => (
		// 												<li key={user.mail}>
		// 													<span className="collabaratorPersonaImage">
		// 														<img src={user.photo} alt="img" />
		// 													</span>
		// 													<span className="collabaratorPersonaTitle">
		// 														{user.firstName + ' ' + user.surname}
		// 													</span>
		// 													<span>
		// 														<Icon
		// 															iconName="Cancel"
		// 															onClick={() => {
		// 																removeUser(
		// 																	user.mail,
		// 																	reviewers,
		// 																	setReviewers
		// 																);
		// 															}}
		// 															onKeyPress={(e) => {
		// 																if (e.which === 13) {
		// 																	removeUser(
		// 																		user.mail,
		// 																		reviewers,
		// 																		setReviewers
		// 																	);
		// 																}
		// 															}}
		// 															role="button"
		// 															tabIndex={0}
		// 															aria-label={`Remove ${user.firstName} ${user.surname}`}
		// 															title="Remove reviewer"
		// 														/>
		// 													</span>
		// 												</li>
		// 											))}
		// 										</ul>
		// 									) : (
		// 										<></>
		// 									)}
		// 								</div>
		// 							</div>

		// 							<div className="addTopicFields">
		// 								<div className="addTopicFieldsDiv">
		// 									<span className="AddActivityFieldLabel">
		// 										Process Group
		// 									</span>
		// 									<ProcessGroupDropdown
		// 										className="topicBuilderProcessGroup"
		// 										data={processGroups}
		// 										setValue={setSelectedProcessgroup}
		// 										value={selectedProcessGroup}
		// 										enableDropdown={true}
		// 									/>
		// 									{selectedProcessGroup.length > 0 ? (
		// 										<ul className="afterSaveList beforEditSavedList">
		// 											{selectedProcessGroup.map((item) => (
		// 												<li key={item.id}>
		// 													{item.name}{' '}
		// 													<Icon
		// 														iconName="Cancel"
		// 														onClick={() => removeProcessGroup(item)}
		// 														onKeyPress={(e) => {
		// 															if (e.which === 13) {
		// 																removeProcessGroup(item);
		// 															}
		// 														}}
		// 														aria-label={`Click to cancel added ${item.name} prcedure group `}
		// 														role="button"
		// 													/>
		// 												</li>
		// 											))}
		// 										</ul>
		// 									) : (
		// 										<></>
		// 									)}
		// 								</div>
		// 							</div>
		// 							{source == 'procedures' ? (
		// 								<>
		// 									<div className="addTopicFields">
		// 										<div className="addTopicFieldsDiv topicBuilderAddToolsContainer">
		// 											<span className="AddActivityFieldLabel">
		// 												Tools
		// 											</span>
		// 											<AddTools
		// 												data={toolsData}
		// 												value={selectedTools}
		// 												setValue={setSelectedTools}
		// 												enableDropdown={true}
		// 												className="topicsBuilderAddTools"
		// 												searchValue={toolSearchValue}
		// 												setSearchValue={setToolSearchValue}
		// 												toolsMapper={toolsMapper}
		// 												setToolsMapper={setToolsMapper}
		// 												getTools={getTools}
		// 											/>
		// 										</div>
		// 									</div>
		// 									<div className="addTopicFields">
		// 										<div className="addTopicFieldsDiv">
		// 											<span className="AddActivityFieldLabel">
		// 												Roles
		// 											</span>
		// 											<AddRoles
		// 												data={roles}
		// 												value={selectedRoles}
		// 												setValue={setSelectedRoles}
		// 												enableDropdown={true}
		// 												className="topicsBuilderRoles"
		// 												searchValue={roleSearchValue}
		// 												setSearchValue={setRoleSearchValue}
		// 												filteredRoles={filteredRoles}
		// 											/>
		// 										</div>
		// 									</div>
		// 								</>
		// 							) : (
		// 								<></>
		// 							)}
		// 						</>
		// 					) : (
		// 						<></>
		// 					)}
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// )}
		// {selectedTab === 2 && (
		// 	<TopicsSequence topics={topics} setTopics={setTopics} />
		// )}
		// 	</div>
		// {showPreview ? (
		// 	<PreviewModal
		// 		showPreviewModal={showPreview}
		// 		setShowPreviewModal={setShowPreview}
		// 		hashTags={userAddedHashtags}
		// 		topicVersion="1.0"
		// 		title={topicTitle}
		// 		content={content}
		// 		setShowPublishModal={setShowPublishModal}
		// 		relatedContent={relatedContent}
		// 		topicOwnerInfo={topicOwnerInfo}
		// 		businessRelatedContent={businessRule}
		// 	/>
		// ) : (
		// 	<></>
		// )}
		// {showDiscardModal ? (
		// 	<DiscardModal
		// 		showDiscardModal={showDiscardModal}
		// 		setShowDiscardModal={setShowDiscardModal}
		// 		url={`/topics`}
		// 		type={'topicBuilder'}
		// 	/>
		// ) : (
		// 	<></>
		// )}
		// {showResponseModal ? (
		// 	<ResponseModal
		// 		showResponseModal={showResponseModal}
		// 		setShowResponseModal={setShowResponseModal}
		// 		message={message}
		// 		url={`/topics`}
		// 	/>
		// ) : (
		// 	<></>
		// )}
		// {showPublishModal ? (
		// 	<TopicPublishModal
		// 		showPublishModal={showPublishModal}
		// 		setShowPublishModal={setShowPublishModal}
		// 		title={topicTitle}
		// 		publishTopic={publishTopic}
		// 		schedulePublishTopic={schedulePublishTopic}
		// 	/>
		// ) : (
		// 	<></>
		// )}
		// {showSaveSpinner ? <SaveSpinner /> : <></>}
		// </div>

		// End of Old Code 
		<>
			<div className="topicBulderMainContainer hexagonBulderMainContainer">
				<div className="topicBulderContainer hexagonBulderContainer">
					<div className="topicBulderHeaderContainer hexagonBulderHeaderContainer mt-0">
						<h4>Topic Builder</h4>
						<div className="topicBulderHeaderContainer hexagonHeaderContainer">
							<div className="topicBulderHeaderButtons">
								<button
									onClick={() => {
										setShowDiscardModal(true);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setShowDiscardModal(true);
										}
									}}
									title="Discard"
								>
									Discard
								</button>
								<button
									onClick={() => {
										saveTopic();
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											saveTopic();
										}
									}}
									title="Save"
								>
									Save
								</button>
								<button
									onClick={() => {
										setShowPreview(true);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setShowPreview(true);
										}
									}}
									title="Preview & Publish"
								>
									Preview & Publish
								</button>
							</div>
						</div>
					</div>
					<div className="topicsMenuContainer">
						<ul className="topicsMenu" role="tablist">
							{tabs.map(
								(item) =>
									(item.id !== 2 || isAdmin) && (
										<li
											name={item}
											role="tab"
											tabIndex="0"
											className={handleTernaryCondition(
												selectedTab === item.id,
												'selectedTopicMenu pt-0',
												'notSelectedTopicMenu pt-0'
											)}
											onClick={() => {
												setSelectedTab(item.id);
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setSelectedTab(item.id);
												}
											}}
										>
											<span className="consultingMenu">{item.name}</span>
										</li>
									)
							)}
						</ul>
					</div>
					{selectedTab == 1 && (
						<div className="topicBulderMainContainer hexagonBuilderMainContainer">
							<div className="polygonMainCountainer">

								<div className="topicBulderFieldsContainer topicBulderPolygonFieldsContainer">
									<div className="topicAdditionCont">
										<div className="topicAdditionContainer">
											<div
												className={
													!selectedSection.includes('addNewTopics')
														? 'addTopicButtonAction'
														: 'addTopicButtonAction expanded mb-0 pt-0'
												}
											>
												{/* <div
										className="selectedCategoryLabel"
										onClick={() => {
											// do nothing
										}}
									>
										Add a New Topic
									</div> */}
												{selectedSection.includes('addNewTopics') ? (
													<>
														{/* Topic Title */}

														<div className="addPolygonTopicFields">
															<div className="addPolygonTopicFieldsDiv">
																<span className="addTopicFieldLabel">
																	Topic Title *
																</span>
																<input
																	placeholder="Topic title"
																	value={topicTitle}
																	onChange={(e) => {
																		setTopicTitle(e.target.value);
																	}}
																/>

															</div>
														</div>
														{/* Topic type */}

														{/* {isAdmin && (
												<div className="addPolygonTopicFields">
													<div className="addPolygonTopicFieldsDiv">
														<span className="addTopicFieldLabel">
															Topic Type
														</span>
														<Dropdown
															className="topicType"
															data={topicTypes}
															value={topicType}
															setValue={setTopicType}
															enableDropdown={true}
														/>
													</div>
												</div>
											)} */}

														{/* topic category */}
														{/* {isAdmin && (
												<div className="addPolygonTopicFields">
													<div className="addPolygonTopicFieldsDiv">
														<span className="addTopicFieldLabel">
															Topic Category
														</span>
														<Dropdown
															className="topicCategory"
															data={topicCategories}
															value={updatedCategoryId}
															setValue={setUpdatedCategoryId}
															enableDropdown={true}
														/>
													</div>
												</div>
											)} */}

														{/* Topic HashTags */}
														<div className="addPolygonTopicFields">
															<div className="addPolygonTopicFieldsDiv">
																<span className="addTopicFieldLabel">
																	Topic Hashtags
																</span>
																<HashtagSuggestions
																	className="topicsHashtagsInput"
																	enableDropdown={true}
																	data={hashtagSuggestions}
																	setUpdateApiCall={setUpdateApiCall}
																	setHashtagSuggestions={setHashtagSuggestions}
																	searchValue={hashtagsInput}
																	setSearchValue={setHashtagsInput}
																	existingHashtags={userAddedHashtags}
																	userAddedHashtags={userAddedHashtags}
																	setUserAddedHashtags={setUserAddedHashtags}
																	hashtagSuggestionInput={hashtagSuggestionInput}
																	setHashtagSuggestionInput={
																		setHashtagSuggestionInput
																	}
																	closeHashtagForm={() => {
																		setHashtagsInput('');
																		setUserAddedHashtags([]);
																	}}
																	submitHashtags={submitHashtags}
																	autoFocus={false}
																	showSubmitButton={false}
																	pageType="topics"
																/>
															</div>
														</div>

														{/* Topic Description */}
														{/* <div className="addPolygonTopicFields">
												<div className="addPolygonTopicFieldsDiv">
													<span className="addTopicFieldLabel">
														Description
													</span>
													<CKEditor
														value={description}
														setValue={setDescription}
													/>
												</div>
											</div> */}

														{/* Topic Content */}
														<div className="addPolygonTopicFields">
															<div className="addPolygonTopicFieldsDiv">
																<span className="addTopicFieldLabel">
																	Topic Content *
																</span>
																<CKEditor value={content} setValue={setContent} />
															</div>
														</div>

														{/* Source==procedure for Tools render */}
														{/* {source == 'procedures' ? (
												<>
													<div className="addTopicFields">
														<div className="addTopicFieldsDiv topicBuilderAddToolsContainer">
															<span className="AddActivityFieldLabel">
																Tools
															</span>
															<AddTools
																data={toolsData}
																value={selectedTools}
																setValue={setSelectedTools}
																enableDropdown={true}
																className="topicsBuilderAddTools"
																searchValue={toolSearchValue}
																setSearchValue={setToolSearchValue}
																toolsMapper={toolsMapper}
																setToolsMapper={setToolsMapper}
																getTools={getTools}
															/>
														</div>
													</div>
													<div className="addTopicFields">
														<div className="addTopicFieldsDiv">
															<span className="AddActivityFieldLabel">
																Roles
															</span>
															<AddRoles
																data={roles}
																value={selectedRoles}
																setValue={setSelectedRoles}
																enableDropdown={true}
																className="topicsBuilderRoles"
																searchValue={roleSearchValue}
																setSearchValue={setRoleSearchValue}
																filteredRoles={filteredRoles}
															/>
														</div>
													</div>
												</>
											) : (
												<></>
											)} */}
													</>
												) : (
													<></>
												)}
											</div>
										</div></div></div>


								{/* RightSideContent */}
								<div className="polygonTopicRightContainer">
									<div className="polygonbasicTopicRightContent basicTopicContainer">
										<Accordion defaultActiveKey="0" className="mb-0">
											{/* Owners/co-owners/approvers/reviewers */}
											<Card className='mr-0' style={{ overflow: activeKey === '0' ? 'visible' : 'hidden' }}>
												<Card.Header className="polygonTopicRelatedContentLabel row">
													<span className="col-lg-7 col-xl-7">Owner / Collaborators</span>
													<span className='col-lg-3 col-xl-3 d-flex justify-content-end chevronContentSpan hideArrowForEmpty' >
													</span>
													<span className="col-lg-2 col-xl-2 clickableIcon" >
														<Icon onClick={(e) => { e.stopPropagation(); toggleAccordion('0'); }} iconName={activeKey === '0' ? "ChevronUp" : "ChevronDown"} className="ChevronStyleForTopic" />
													</span>
												</Card.Header>
												<Accordion.Collapse eventKey="0" in={activeKey === '0'}>
													<Card.Body>
													{/* {(isAdmin || isOwnerOrCoOwner || topicOwnerInfo?.mail) && ( */}
														<div className='addPolygonTopicRightFields'>
															<div className='addPolygonTopicRightFieldsDiv'>
																<span className='addPolygonTopicRightLabel'>
																	{/* Add Owner */}
																	{isAdmin || isOwnerOrCoOwner ? 'Add Owner' : 'Owner'}
																	{/* {isAdmin || isOwnerOrCoOwner ? 'Add Owner' : 'Owner'} */}
																</span>
																{(isAdmin || isOwnerOrCoOwner) && 
																<OwnerSelectionDropdown
																	className="topicOwner"
																	enableDropdown={true}
																	data={usersList}
																	value={topicOwnerInfo}
																	setValue={setTopicOwnerInfo}
																	type="topicOwner"
																	searchValue={topicOwnerEmail}
																	setSearchValue={setTopicOwnerEmail}
																	error={error}
																	emailFound={emailFound['topicOwner']}
																	pageType="topics"
																/>}
																{topicOwnerInfo.mail ? (
																	<ul
																		className={
																			'afterSaveListTopic polygonCollaboratorsApprovers'
																		}
																	>
																		<li key={topicOwnerInfo.mail}>
																			<span className="collabaratorPersonaImage">
																				<img src={topicOwnerInfo.photo} alt="img" />
																			</span>
																			<span className="collabaratorPersonaTitle">
																				{topicOwnerInfo.firstName +
																					' ' +
																					topicOwnerInfo.surname}
																			</span>
																			{(isAdmin || isOwnerOrCoOwner) ? (
																			<span>
																				<Icon
																					iconName="Cancel"
																					onClick={() => {
																						setTopicOwnerInfo({});
																					}}
																					onKeyPress={(e) => {
																						if (e.which === 13) {
																							setTopicOwnerInfo({});
																						}
																					}}
																					role="button"
																					tabIndex={0}
																					aria-label={`Remove ${topicOwnerInfo.firstName} ${topicOwnerInfo.surname}`}
																					title="Remove Owner"
																				/>
																			</span>) : (<></>)}
																		</li>
																	</ul>
																) : (
																	<></>
																)}
															</div>
														</div>
														{/* )} */}
														<div className='addPolygonTopicRightFields'>
															<div className='addPolygonTopicRightFieldsDiv'>
															{/* Add Co-owners */}
															<span className='addPolygonTopicRightLabel'>{!(coOwners?.some((owner) => owner?.mail === userAlias)) || isAdmin || isOwnerOrCoOwner || userAlias === topicOwnerInfo.mail ? 'Add Co-owner' : 'Co-owner'}</span>
															{!(coOwners?.some((owner) => owner?.mail === userAlias)) || isAdmin || isOwnerOrCoOwner || userAlias === topicOwnerInfo.mail ? <UserSelectionDropdown
																	className="coOwner"
																	enableDropdown={true}
																	data={usersList}
																	value={coOwners}
																	setValue={setCoOwners}
																	type="coOwner"
																	searchValue={coOwnwerEmail}
																	setSearchValue={setCoOwnwerEmail}
																	error={error}
																	emailFound={emailFound['coOwner']}
																	pageType="topics"
																/>: <></>}
																{coOwners.length > 0 ? (
																	<ul
																		className={
																			'afterSaveListTopic polygonCollaboratorsApprovers'
																		}
																	>
																		{coOwners.map((user) => (
																			<li key={user.mail}>
																				<span className="collabaratorPersonaImage">
																					<img src={user.photo} alt="img" />
																				</span>
																				<span className="collabaratorPersonaTitle">
																					{user.firstName + ' ' + user.surname}
																				</span>
																				{!(coOwners?.some((owner) => owner?.mail === userAlias)) || isAdmin || isOwnerOrCoOwner || userAlias === topicOwnerInfo.mail ? (
																				<span>
																					<Icon
																						iconName="Cancel"
																						onClick={() => {
																							removeUser(
																								user.mail,
																								coOwners,
																								setCoOwners
																							);
																						}}
																						onKeyPress={(e) => {
																							if (e.which === 13) {
																								removeUser(
																									user.mail,
																									coOwners,
																									setCoOwners
																								);
																							}
																						}}
																						role="button"
																						tabIndex={0}
																						aria-label={`Remove ${user.firstName} ${user.surname}`}
																						title="Remove CoOwner"
																					/>
																				</span>) : (<></>)}
																			</li>
																		))}
																	</ul>
																) : (
																	<></>
																)}
															</div></div>
														<div className='addPolygonTopicRightFields'>
															<div className='addPolygonTopicRightFieldsDiv'>
																<span className='addPolygonTopicRightLabel'>Add Approvers</span>
																<UserSelectionDropdown
																	className="approver"
																	enableDropdown={true}
																	data={usersList}
																	value={approvers}
																	setValue={setApprovers}
																	type="approver"
																	searchValue={approverEmail}
																	setSearchValue={setApproverEmail}
																	error={error}
																	emailFound={emailFound['approver']}
																	pageType="topics"
																/>
																{approvers.length > 0 ? (
																	<ul
																		className={
																			'afterSaveListTopic polygonCollaboratorsApprovers'
																		}
																	>
																		{approvers.map((user) => (
																			<li key={user.mail}>
																				<span className="collabaratorPersonaImage">
																					<img src={user.photo} alt="img" />
																				</span>
																				<span className="collabaratorPersonaTitle">
																					{user.firstName + ' ' + user.surname}
																				</span>
																				<span>
																					<Icon
																						iconName="Cancel"
																						onClick={() => {
																							removeUser(
																								user.mail,
																								approvers,
																								setApprovers
																							);
																						}}
																						onKeyPress={(e) => {
																							if (e.which === 13) {
																								removeUser(
																									user.mail,
																									approvers,
																									setApprovers
																								);
																							}
																						}}
																						role="button"
																						tabIndex={0}
																						aria-label={`Remove ${user.firstName} ${user.surname}`}
																						title="Remove approver"
																					/>
																				</span>
																			</li>
																		))}
																	</ul>
																) : (
																	<></>
																)}
															</div></div>
														<div className='addPolygonTopicRightFields'>
															<div className='addPolygonTopicRightFieldsDiv'>
																<span className='addPolygonTopicRightLabel'>Add Reviewers</span>

																<UserSelectionDropdown
																	className="reviewer"
																	enableDropdown={true}
																	data={usersList}
																	value={reviewers}
																	setValue={setReviewers}
																	type="reviewer"
																	searchValue={reviewerEmail}
																	setSearchValue={setReviewerEmail}
																	error={error}
																	emailFound={emailFound['reviewer']}
																	pageType="topics"
																/>
																{reviewers.length > 0 ? (
																	<ul
																		className={
																			'afterSaveListTopic polygonCollaboratorsApprovers'
																		}
																	>
																		{reviewers.map((user) => (
																			<li key={user.mail}>
																				<span className="collabaratorPersonaImage">
																					<img src={user.photo} alt="img" />
																				</span>
																				<span className="collabaratorPersonaTitle">
																					{user.firstName + ' ' + user.surname}
																				</span>
																				<span>
																					<Icon
																						iconName="Cancel"
																						onClick={() => {
																							removeUser(
																								user.mail,
																								reviewers,
																								setReviewers
																							);
																						}}
																						onKeyPress={(e) => {
																							if (e.which === 13) {
																								removeUser(
																									user.mail,
																									reviewers,
																									setReviewers
																								);
																							}
																						}}
																						role="button"
																						tabIndex={0}
																						aria-label={`Remove ${user.firstName} ${user.surname}`}
																						title="Remove reviewer"
																					/>
																				</span>
																			</li>
																		))}
																	</ul>
																) : (
																	<></>
																)}
															</div></div>

													</Card.Body>
												</Accordion.Collapse>
											</Card>
											{/* RealtedContent */}
											<Card className="mr-0">
												<Card.Header className="polygonTopicRelatedContentLabel row">
													<span className="col-lg-7 col-xl-7">Related Content</span>
													<span className='col-lg-3 col-xl-3 d-flex justify-content-end chevronContentSpan' >
														<Icon
															iconName="EditSolid12"
															onClick={() => {
																setShowRelatedContentModal(true);
															}}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setShowRelatedContentModal(true);
																}
															}}
															role="button"
															aria-label={`Add Related Content`}
															title='Add Related Content'
														/>
													</span>
													<span className="col-lg-2 col-xl-2 clickableIcon" >
														<Icon onClick={(e) => { e.stopPropagation(); toggleAccordionRelativeContent('0'); }} iconName={activeKeyRelativeContent === '0' ? "ChevronUp" : "ChevronDown"} className="ChevronStyleForTopic" />
													</span>
												</Card.Header>
												<Accordion.Collapse eventKey="0" in={activeKeyRelativeContent === '0'}>
													<Card.Body>
														{showRelatedContentModal ?
															<Modal
																size="lg"
																centered
																show={true}
																onHide={() => {
																	/* Not calling any function to close modal*/
																}}
																dialogClassName="modal-90w"
															>
																<Modal.Header>
																	<Modal.Title>
																		<div className="polygonTopicModalRelatedContenTitle">
																			<span>Related Content</span>
																		</div>
																	</Modal.Title>
																	<Icon
																		className="relatedContentClose"
																		iconName="ChromeClose"
																		title="Close"
																		onClick={() => setShowRelatedContentModal(false)}
																	/>
																</Modal.Header>
																<Modal.Body>
																	<div className="addPolygonTopicContenUrlContainer">
																		<div className="addPolygonTopicRelatedContentLabel">
																			<div className="addPolygonTopicFieldContenUrlLabel">
																				<span className="addPolygonTopicFieldLabel">
																					Content Title
																				</span>
																			</div>
																			<div className="addPolygonTopicFieldContenUrlLabel">
																				<span className="addPolygonTopicFieldLabel">
																					Content URL
																				</span>
																			</div>
																		</div>
																		<div className="addPolygonTopicRelatedInputContainer">
																			<div className="polygonInputContainer">
																				{relatedContent.map((item, index) => (
																					<>
																						<div className="addPolygonTopicRelatedInput">
																							<div className="addPolygonTopicFieldContenUrl">
																								<input
																									placeholder="Enter Content Title"
																									name="title"
																									value={item.title}
																									onChange={(e) => handleToChangeContent(e, index)}
																									disabled={topicId === '1001' ||
																										topicId === '1002' ||
																										topicId === '1003' ||
																										topicId === '1004' ||
																										topicId === '1005' ||
																										topicId === '1006' ||
																										topicId === '1007' ||
																										topicId === '272'}
																								/>
																							</div>
																							<div className="addPolygonTopicFieldContenUrl">
																								<input
																									placeholder="Enter url"
																									name="url"
																									value={item.url}
																									onChange={(e) => handleToChangeContent(e, index)}
																									disabled={topicId === '1001' ||
																										topicId === '1002' ||
																										topicId === '1003' ||
																										topicId === '1004' ||
																										topicId === '1005' ||
																										topicId === '1006' ||
																										topicId === '1007' ||
																										topicId === '272'}
																								/>
																							</div>
																							<div className="addPolygonTopicFieldSave">
																								<button className="relatedContentDelete"
																									onClick={() => removeRelatedContent(item.title, index)} >
																									<Icon
																										className="relatedContentDeleteIcon"
																										iconName="ChromeClose"
																										title="Close"
																									/>
																								</button>
																							</div>
																							{index === relatedContent.length - 1 && (
																								<div className="addPolygonTopicFieldSave">
																									<button
																										className="relatedContentAdd"
																										onClick={() => { handleAddRelatedContentFields(); }}
																										onKeyPress={(e) => {
																											if (e.which === 13) {
																												handleAddRelatedContentFields();
																											}
																										}}
																									>
																										{/* <Icon
																											className="relatedContentAddIcon"
																											iconName="Add"
																											title="Add"
																										/> */}
																									</button>
																								</div>)}
																						</div>
																						{(showRelatedContentTitleError || showRelatedContentUrlError) &&
																							(showRelatedContentIndexError === index) && (
																								<div className="errorBusinessRule">
																									<div className="addPolygonTopicFieldContenUrl">
																										{showRelatedContentTitleError && (
																											<p className="errorMsg">
																												{showRelatedContentTitleErrorMsg}
																											</p>
																										)}</div><div className="addPolygonTopicFieldContenUrl">
																										{showRelatedContentUrlError && (
																											<p className="errorMsg urlRelativeContent">
																												{showRelatedContentUrlErrorMsg}
																											</p>
																										)}</div>
																								</div>
																							)}
																					</>
																				))}
																			</div>

																		</div>
																	</div>
																</Modal.Body>
																<Modal.Footer>
																	<div className="AddTopicFieldContentButtons addMarginBusinnessRelative">
																		<button
																			className="cancelRelatedContent  mr-2"
																			onClick={() => {
																				setShowRelatedContentModal(false);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					setShowRelatedContentModal(false);
																				}
																			}}
																		>
																			Cancel
																		</button>
																		<button
																			className="addBtnRelatedContent"
																			onClick={() => {
																				updateRelatedContentPolygon();
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					updateRelatedContentPolygon();
																				}
																			}}
																		>
																			Add
																		</button>
																	</div>
																</Modal.Footer>
															</Modal>
															:
															<></>
														}

														{relatedContent.map((item) => (
															<>
																{item.title !== '' ?
																	<a className="relatedContentAnchor" href={item.url} target="_blank" title={item.url}>
																		<span>{item.title}</span>
																	</a>
																	:
																	<></>
																}
															</>
														))}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
											{/* Business Rule */}
											<Card className="mr-0">
												<Card.Header className="polygonTopicRelatedContentLabel row">
													<span className="col-lg-7 col-xl-7">Business Rule</span>
													<span className='col-lg-3 col-xl-3 d-flex justify-content-end chevronContentSpan'>
														<Icon
															iconName="EditSolid12"
															onClick={() => {
																setShowBusinessRuleModal(true);
															}}
															tabIndex={0}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	setShowBusinessRuleModal(true);
																}
															}}
															role="button"
															aria-label={`Add Business Rule`}
															title='Add Business Rule'
														/>
													</span>
													<span className="col-lg-2 col-xl-2 clickableIcon">
														<Icon onClick={() => toggleAccordionBusineesRule('0')} iconName={activeKeyBusinessRule === '0' ? "ChevronUp" : "ChevronDown"} className="ChevronStyleForTopic" />
													</span>
												</Card.Header>
												<Accordion.Collapse eventKey="0" in={activeKeyBusinessRule === "0"}>
													<Card.Body>
														{showBusinessRuleModal ?
															<Modal
																size="lg"
																centered
																show={true}
																onHide={() => {
																	/* Not calling any function to close modal*/
																}}
																dialogClassName="modal-90w"
															>
																<Modal.Header>
																	<Modal.Title>
																		<div className="polygonTopicModalRelatedContenTitle">
																			<span>Business Rule</span>
																		</div>
																	</Modal.Title>
																	<Icon
																		className="relatedContentClose"
																		iconName="ChromeClose"
																		title="Close"
																		onClick={() => setShowBusinessRuleModal(false)}
																	/>
																</Modal.Header>
																<Modal.Body>
																	<div className="addPolygonTopicContenUrlContainer">
																		<div className="addPolygonTopicRelatedContentLabel">
																			<div className="addPolygonTopicFieldContenUrlLabel">
																				<span className="addPolygonTopicFieldLabel">
																					Content Title
																				</span>
																			</div>
																			<div className="addPolygonTopicFieldContenUrlLabel">
																				<span className="addPolygonTopicFieldLabel">
																					Content URL
																				</span>
																			</div>
																		</div>
																		<div className="addPolygonTopicRelatedInputContainer">
																			<div className="polygonInputContainer">
																				{businessRelatedContent.map((item, index) => (
																					<>
																						<div className="addPolygonTopicRelatedInput">
																							<div className="addPolygonTopicFieldContenUrl">
																								<input
																									placeholder="Enter Title"
																									name="name"
																									value={item.name}
																									onChange={(e) => {
																										handleToChangeBusinessContent(e, item, index);
																									}}
																								/>
																							</div>
																							<div className="addPolygonTopicFieldContenUrl">
																								<input
																									placeholder="Enter url"
																									name="url"
																									value={item.url}
																									onChange={(e) => {
																										handleToChangeBusinessContent(e, item, index);
																									}}
																								/>
																							</div>
																							<div className="addPolygonTopicFieldSave">
																								<button className="relatedContentDelete"
																									onClick={() => {
																										removeBusinessRelatedContent(index);
																										// if (item.id === undefined) {

																										// } else {
																										// 	deleteBusinessRelatedContent(item.id)
																										// }
																									}}>
																									<Icon
																										className="relatedContentDeleteIcon"
																										iconName="ChromeClose"
																										title="Close"
																									/>
																								</button>
																							</div>
																							{index === businessRelatedContent.length - 1 && (
																								<div className="addPolygonTopicFieldSave">
																									<button
																										className="relatedContentAdd"
																										onClick={() => {
																											handleAddBusinessContentFields();
																										}}
																										onKeyPress={(e) => {
																											if (e.which === 13) {
																												handleAddBusinessContentFields();
																											}
																										}}
																									>
																										{/* <Icon
																											className="relatedContentAddIcon"
																											iconName="Add"
																											title="Add"
																										/> */}
																									</button>
																								</div>)}
																						</div>

																						{(showBusinessRuleTitleError || showBusinessRuleUrlError) &&
																							(showBusinessRuleIndexError === index) && (
																								<div className="errorBusinessRule">
																									<div className="addPolygonTopicFieldContenUrl">
																										{showBusinessRuleTitleError &&
																											<p className="errorMsg">
																												{showBusinessRuleTitleErrorMsg}
																											</p>
																										}</div><div className="addPolygonTopicFieldContenUrl">
																										{showBusinessRuleUrlError &&
																											<p className="errorMsg">
																												{showBusinessRuleUrlErrorMsg}
																											</p>
																										}</div>
																								</div>
																							)}
																					</>
																				))}

																			</div>
																		</div>
																	</div>
																</Modal.Body>
																<Modal.Footer>
																	<div className="AddTopicFieldContentButtons addMarginBusinnessRelative">
																		<button
																			className="cancelBusinessContent  mr-2"
																			onClick={() => {
																				setShowBusinessRuleModal(false);
																				setBusinessRelatedContent([{ name: '', url: '' }]);
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					setShowBusinessRuleModal(false);
																					setBusinessRelatedContent([{ name: '', url: '' }]);
																				}
																			}}
																		>
																			Cancel
																		</button>
																		<button
																			className="addBtnBusinessContent"
																			onClick={() => {
																				addBusinessRuleContent();
																			}}
																			onKeyPress={(e) => {
																				if (e.which === 13) {
																					addBusinessRuleContent();
																				}
																			}}
																		>
																			Add
																		</button>
																	</div>
																</Modal.Footer>
															</Modal>
															:
															<></>
														}
														{businessRelatedContent.map((item) => (
															<>
																{item.name !== '' ?
																	<a className="relatedContentAnchor" href={item.url} target="_blank" title={item.url}>
																		<span>{item.name}</span>
																	</a>
																	:
																	<></>
																}
															</>
														))}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</Accordion>
										{/* Isvisible and Iscrowdsourceable */}
										<div className="polygonTopicCheckbox">
											<div className="polygonTopicIsVisible">
												{!(topicType === 1 || topicType === 2) ? (
													<div className="isVisibleDivPolygon mr-0">
														<input
															type="checkbox"
															title="Is crowd source checkbox"
															checked={isCrowdSourceable}
															onChange={() => {
																setIsCrowdSourcable(!isCrowdSourceable);
															}}
														/>
														<span className="ml-1">Iscrowdsourceable</span>
													</div>
												) : (
													<></>
												)}
												<div className="isVisibleDivPolygon">
													<input
														type="checkbox"
														title="Is visible checkbox"
														checked={isVisible}
														onChange={() => {
															setIsVisible(!isVisible);
														}}
													/>
													<span className="ml-1">Isvisible</span>



												</div>
											</div></div>
										{/* ProcessGroup */}
										<div className="addTopicFields">
											<div className="addTopicFieldsDiv">
												<span className="AddActivityFieldLabel">
													Process Group
												</span>
												<ProcessGroupDropdown
													className="topicBuilderProcessGroup"
													data={processGroups}
													setValue={setSelectedProcessgroup}
													value={selectedProcessGroup}
													enableDropdown={true}
												/>
												{selectedProcessGroup.length > 0 ? (
													<ul className="afterSaveList beforEditSavedList">
														{selectedProcessGroup.map((item) => (
															<li key={item.id}>
																{item.name}{' '}
																<Icon
																	iconName="Cancel"
																	onClick={() => removeProcessGroup(item)}
																	onKeyPress={(e) => {
																		if (e.which === 13) {
																			removeProcessGroup(item);
																		}
																	}}
																	aria-label={`Click to cancel added ${item.name} prcedure group `}
																	role="button"
																/>
															</li>
														))}
													</ul>
												) : (
													<></>
												)}
											</div>
										</div>

										{isAdmin && (
											<div className="addTopicFields">
												<div className="addTopicFieldsDiv">
													<span className="AddActivityFieldLabel">
														Topic Type
													</span>
													<Dropdown
														className="topicType"
														data={topicTypes}
														value={topicType}
														setValue={setTopicType}
														enableDropdown={true}
													/>
												</div>
											</div>
										)}
										{isAdmin && (
											<div className="addTopicFields">
												<div className="addTopicFieldsDiv">
													<span className="AddActivityFieldLabel">
														Topic Category
													</span>
													<Dropdown
														className="topicCategory"
														data={topicCategories}
														value={updatedCategoryId}
														setValue={setUpdatedCategoryId}
														enableDropdown={true}
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					{selectedTab === 2 && (
						<TopicsSequence topics={topics} setTopics={setTopics} />
					)}



				</div>
				{showPreview ? (
					<PreviewModal
						showPreviewModal={showPreview}
						setShowPreviewModal={setShowPreview}
						hashTags={userAddedHashtags}
						topicVersion="1.0"
						title={topicTitle}
						content={content}
						setShowPublishModal={setShowPublishModal}
						relatedContent={relatedContent}
						topicOwnerInfo={Object.keys(topicOwnerInfo).length>0?topicOwnerInfo:categoryOwner}
						businessRelatedContent={businessRelatedContent}
						sdmContentErrorMessage={sdmContentErrorMessage}
					/>
				) : (
					<></>
				)}
				{showDiscardModal ? (
					<DiscardModal
						showDiscardModal={showDiscardModal}
						setShowDiscardModal={setShowDiscardModal}
						url={`/topics`}
						type={'topicBuilder'}
					/>
				) : (
					<></>
				)}
				{showResponseModal ? (
					<ResponseModal
						showResponseModal={showResponseModal}
						setShowResponseModal={setShowResponseModal}
						message={message}
						url={`/topics`}
					/>
				) : (
					<></>
				)}
				{showPublishModal ? (
					<TopicPublishModal
						showPublishModal={showPublishModal}
						setShowPublishModal={setShowPublishModal}
						title={topicTitle}
						publishTopic={publishTopic}
						schedulePublishTopic={schedulePublishTopic}
					/>
				) : (
					<></>
				)}
				{showSaveSpinner ? <SaveSpinner /> : <></>}

				<div className=" hexagonSaveContainer">
					<div className="polygonStepsButtons">

					</div>
					<div className="topicBulderHeaderContainer">
						<div className="topicBulderHeaderButtons">
							<button
								onClick={() => {
									setShowDiscardModal(true);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowDiscardModal(true);
									}
								}}
								title="Discard"
							>
								Discard
							</button>
							<button
								onClick={() => {
									saveTopic();
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										saveTopic();
									}
								}}
								title="Save"
							>
								Save
							</button>
							<button
								onClick={() => {
									setShowPreview(true);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowPreview(true);
									}
								}}
								title="Preview & Publish"
							>
								Preview & Publish
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TopicBuilder;
