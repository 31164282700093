import React, { useState, useEffect, useRef } from 'react';
import { useAxios } from '../../context/AxiosContext';
import { Icon } from '@fluentui/react/lib/Icon';

import ComplexityFilter from './ComplexityFilter';
import TemplateDynamicFilter from './TemplateDynamicFilter';
import { toast } from 'react-toastify';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';


const TemplateLandingFilter = ({
	showFilters,
	setShowFilters,
	dynamicFilterTag,
	setDynamicFilterTag,
	setDynamicFilterTagMapping,
	complexityTag,
	setComplexityTag,
	applyFilters,
	userFilterTag,
	setUserFilterTag,
	dynamicFilterTagMapping,
	complexitiesmain,
	setComplexitiesmain,
	filtersClearAll,
	filtersCleared
}) => {
	const {appState } =
	useLeftMenuCollapse();
		const { defaultComplexity } = appState;
	const { axiosGetService,axiosPostService } = useAxios();
	const searchInputRef = useRef(null);
	const [complexities, setComplexities] = useState([]);
	const [dynamicFilterData, setDynamicFilterData] = useState([]);
	const [dynamicSearchText, setDynamicSearchText] = useState({});
	const [dynamicFilterSearchResult, setDynamicFilterSearchResult] = useState(
		{}
	);
	const [dynamicFilterSearchEnable, setDynamicFilterSearchEnable] = useState(
		{}
	);

	const getComplexities = async () => {
		await axiosGetService('api/activities/projectcomplexity').then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
					type: 'option',
				};
			});
			setComplexities(data);
			setComplexitiesmain(data);
		});
	};

useEffect(() => {

	getFilterData();
}, [userFilterTag]);

useEffect(() => {
	const checkAndTriggerApi = async () => {
		if (filtersCleared) {
			try {
				// Trigger your API call here
				getFilterData([]);
				
			} catch (error) {
				console.error("Error fetching child data:", error);
			}
		}
	};

	checkAndTriggerApi(); // Call the function

	// Optional: Cleanup function to avoid any potential memory leaks

}, [filtersCleared]);

	const getFilterData = async (userFilter) => {
		const selectedFiltersIds = [];
		const filterIds = Object.keys(dynamicFilterTag);
	
		for (const id of filterIds) {
			if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
				selectedFiltersIds.push(
					`${Number(id)}-${dynamicFilterTag[Number(id)].selectedTagId.join(',')}`
				);
			}
		}
	
		await axiosGetService(
			`api/sdmartifactmaster/getdynamicfiltersbypagetype?pageTypeId=${8}&isFilter=true&artifactId=0&filterIds=${
				selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : (userFilter.length > 0 && userFilter[0].filters) ?? 0
			}&complexityIds=${complexityTag.id}&searchValue=0`
		).then((response) => {
			const nameMapping = {};
			const filterTag = {};
			const filterTagMapping = {};
			const serachFilterData = {};
			let searchText = {};
	
			// Map the response data and convert IDs to numbers
			const data = response.data.map((item) => ({
				artifactTypeId: Number(item.artifactTypeId), // Convert to number
				artifactTypeName: item.artifactTypeName,
				artifactData: item.artifactData.map(artifact => ({
					...artifact,
					id: Number(artifact.id) // Convert artifact ID to number
				})),
			}));
	
			for (const id in data) {
				const artifactTypeId = data[id].artifactTypeId;
				nameMapping[artifactTypeId] = dynamicFilterSearchEnable[artifactTypeId];
				filterTag[artifactTypeId] = {
					artifactTypeId,
					artifactTypeName: data[id].artifactTypeName,
					selectedTagId: [],
				};
				const tag = data[id].artifactData;
				let artifactTag = {};
				for (const tagId in tag) {
					artifactTag = {
						...artifactTag,
						[tag[tagId].id]: tag[tagId].name,
					};
				}
				filterTagMapping[artifactTypeId] = artifactTag;
				searchText[artifactTypeId] = dynamicSearchText[artifactTypeId] ?? '';
				serachFilterData[artifactTypeId] = dynamicSearchText[artifactTypeId]
					? tag.filter((item) =>
							item.name
								.toLowerCase()
								.includes(dynamicSearchText[artifactTypeId].toLowerCase())
					  )
					: [];
			}
	
			setDynamicFilterSearchResult({ ...serachFilterData });
			setDynamicSearchText({ ...searchText });
			setDynamicFilterTagMapping({ ...filterTagMapping });
			setDynamicFilterSearchEnable({ ...nameMapping });
			setDynamicFilterData(data); // Use the data with converted IDs
	
			// Step 4: Handle userFilter mapping
			const newDynamicFilterTag = { ...dynamicFilterTag };
	
			if (userFilter.length > 0 && userFilter[0].filters) {
				// Parse filters string, e.g., "2-1400,1400,1338|15-7|25-8|75-2"
				const userFilterMappings = userFilter[0].filters.split('|').map((filter) => {
					const [artifactTypeId, selectedTagIds] = filter.split('-');
					return {
						artifactTypeId: Number(artifactTypeId), // Convert to number
						selectedTagIds: selectedTagIds ? selectedTagIds.split(',').map(Number) : [],
					};
				});
	
	
				// Update newDynamicFilterTag based on userFilterMappings
				userFilterMappings.forEach(({ artifactTypeId, selectedTagIds }) => {
					const artifactTypeData = data.find((d) => d.artifactTypeId === artifactTypeId);
	
					// Ensure artifactTypeId exists in newDynamicFilterTag
					if (artifactTypeData) {
						newDynamicFilterTag[artifactTypeId] = {
							artifactTypeId,
							artifactTypeName: artifactTypeData.artifactTypeName,
							selectedTagId: selectedTagIds,
						};
					}
				});
			}
	
			if (JSON.stringify(dynamicFilterTag) !== JSON.stringify(newDynamicFilterTag)) {
				setDynamicFilterTag(newDynamicFilterTag);
			}
		});
	};
	useEffect(() => {
		if (dynamicFilterTagMapping) {
		  setDynamicFilterTagMapping(dynamicFilterTagMapping);
		}
	  }, [dynamicFilterTagMapping]);
	  
	
	const searchForArtifact = (searchText, artifactTypeId, artifactData) => {
		if (searchText.trim().length > 0) {
			const filteredRoles = artifactData.filter((item) =>
				item.name.toLowerCase().includes(searchText.toLowerCase())
			);
			setDynamicFilterSearchResult((prevState) => ({
				...prevState,
				[artifactTypeId]: filteredRoles,
			}));
		}
		setDynamicSearchText((prevState) => ({
			...prevState,
			[artifactTypeId]: searchText.trim().length > 0 ? searchText : '',
		}));
	};

	useEffect(() => {
			const fetchData = async () => {
				try {
					const userSettingsResponse = await axiosGetService(`api/sdmusersettings/getusersettings/8/false/0`);
					setUserFilterTag(userSettingsResponse?.data || []);
					const complexityId = parseInt(userSettingsResponse?.data[0]?.complexities, 10) || 3;
        			setComplexityTag({ id: complexityId ,name: complexities?.find((item) => item.id === complexityId)?.name });
					await getFilterData(userSettingsResponse?.data || []);
				} catch (error) {
					console.error("Error fetching user settings or filter data:", error);
				}
			};
			fetchData();
	}, []);

	const handleApplyFilters = async () => {
		await getFilterData(dynamicFilterTag); // Apply filters on button click
		await applyFilters();
	  };

	  const setDefaultFilter = async (e) => {
		e.preventDefault();
		await getFilterData(dynamicFilterTag); 
		await applyFilters();
		try {
			const selectedFiltersIds = [];
		const filterIds = Object.keys(dynamicFilterTag);
		for (const id of filterIds) {
			if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
				selectedFiltersIds.push(
					`${Number(id)}` +
						'-' +
						dynamicFilterTag[Number(id)].selectedTagId.toString()
				);
			}
		}
			const postData = {
				"pageTypeId":"8",
				"filters": selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : 0,
				"complexities": complexityTag.id,
				"userSettingType": "1"
			};
			await axiosPostService(`/api/sdmusersettings/insertusersettings`, postData).then((response) => {
			if (response.data) {
			  setShowFilters(!showFilters);
			//   setDynamicFilterTag(response.data);
			  toast.info('You have successfully saved filters.', {
				position: 'top-right',
			  });
			} else {
			  toast.error(response.data.errorMessage, {
				position: 'top-right',
			  });
			}
		  })
		} catch (e) {
		  console.log(e);
		};
	  }

	useEffect(() => {
		getComplexities();
	}, [filtersCleared]);

	useEffect(() => {
		const dynamicRefValue = Object.values(dynamicFilterSearchEnable);
		if (dynamicRefValue.includes(true) && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [dynamicFilterSearchEnable]);
	return (
		<div
			id="filtersRightPanel"
			className={!showFilters ? 'topicLandingFilters' : ''}
		>
			<div className="filterPanelHeader">
				<h5>Filters</h5>
				<Icon
					iconName="cancel"
					onClick={() => {
						setShowFilters(false);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setShowFilters(false);
						}
					}}
					tabIndex={0}
					role="button"
					aria-label="Close Filters"
				/>
			</div>
			<div className="filtersPanelBodyLatest">
				<div className="filtersCategoryPanel">
					<h6>Complexity</h6>
					<ComplexityFilter
						data={complexities}
						setValue={setComplexityTag}
						value={complexityTag}
						telemetryId="601"
					/>
				</div>
				{dynamicFilterData.map((item) => (
					<div className="filtersCategoryPanel" key={item.artifactTypeId}>
						{item.artifactData.length > 5 ? (
							<>
								{dynamicFilterSearchEnable[item.artifactTypeId] ? (
									<div className="rolesSearch">
										<input
											type="text"
											placeholder={`Search for  ${item.artifactTypeName}`}
											value={dynamicSearchText[item.artifactTypeId]}
											onChange={(e) => {
												e.persist();
												searchForArtifact(
													e.target.value,
													item.artifactTypeId,
													item.artifactData
												);
											}}
											ref={searchInputRef}
											title={`${item.artifactTypeName} search box enabled`}
											role={'textbox'}
											aria-label={`${item.artifactTypeName} search box enabled`}
										/>
										<Icon
											className="searchIcon"
											iconName="cancel"
											onClick={() => {
												setDynamicFilterSearchEnable({
													...dynamicFilterSearchEnable,
													[item.artifactTypeId]: false,
												});
												setDynamicSearchText({
													...dynamicSearchText,
													[item.artifactTypeId]: '',
												});
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: false,
													});
													setDynamicSearchText({
														...dynamicSearchText,
														[item.artifactTypeId]: '',
													});
												}
											}}
											tabIndex={0}
											role="button"
											aria-label="Cancel Search Filter"
										/>
									</div>
								) : (
									<div className="filtersearch">
										<h6>{item.artifactTypeName}</h6>
										<span
											className="searchIcon"
											onClick={() =>
												setDynamicFilterSearchEnable({
													...dynamicFilterSearchEnable,
													[item.artifactTypeId]: true,
												})
											}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: true,
													});
												}
											}}
											tabIndex={0}
											role="button"
											aria-label={`click to search ${item.artifactTypeName}`}
											title={`click to search ${item.artifactTypeName}`}
										>
											<Icon iconName="search" className="searchIcon" role={'search'} />
										</span>
									</div>
								)}
							</>
						) : (
							<h6>{item.artifactTypeName}</h6>
						)}
						<TemplateDynamicFilter
							data={
								dynamicSearchText[item.artifactTypeId]
									? dynamicFilterSearchResult[item.artifactTypeId]
									: item.artifactData
							}
							value={dynamicFilterTag}
							artifactTypeName={item.artifactTypeName}
							setValue={setDynamicFilterTag}
							artifactTypeId={item.artifactTypeId}
						/>
					</div>
				))}
			</div>
			<div className="filterPanelFooter">
          <button
            type="button"
            className="btn btn-primary"
            tabIndex={0}
            onClick={handleApplyFilters}
          >
            Apply
          </button>
          <button
            type="button"
            className="btn btn-default"
            tabIndex={0}
            onClick={ setDefaultFilter}
          >
            Set as Default
          </button>
        </div>
		</div>
	);
};

export default TemplateLandingFilter;
