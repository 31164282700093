import React, { useRef, useState, useEffect } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useAxios } from '../../context/AxiosContext';
import dompurify from "dompurify";
import { Icon } from "@fluentui/react";
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import ExtractMethodlogy from '../LeftMenu/ExtractMethodology';
import AdminEditSolutionMethod from '../SolutionMethod/SolutionMethod';
import MethodDeleteModal from '../SolutionMethod/Delete';
import MethodDelete from "../SolutionMethod/Delete";

function ProjectDeliveryPhases({
  projectDeliveryPhases,
  setIsPhaseSelected,
  setIsActiveSolution,
  isActiveSolution,
  deliveryManagementId,
  setRealizeValueToggler,
  realizeValueToggler,
  setIsEditSolutionMethod,
  isEditSolutionMethod,
  setIsDeleteSolutionMethod,
  isDeleteSolutionMethod,
  clearFilters,
  isSolutionCollaboratorsEdited,
  setIsSolutionCollaboratorsEdited
}) {
  const {
    appState,
    endUserView,
    consultingSolutionMethods,
    supportSolutionMethods,
  } = useLeftMenuCollapse();
  const [selectedPhase, setSelectedPhase] = useState('')
  const htmlSanitizer = dompurify.sanitize
  const history = useHistory()
  const { axiosGetService } = useAxios();
  const [projectId, setProjectId] = useState(0);

  const [isAdmin, setIsAdmin] = useState(false);
  const [showMethodAdminMenu, setShowMethodAdminMenu] = useState(false);
  const [showExtractMethodlogy, setShowExtractMethodlogy] = useState(false);
  const [extractMethodlogyId, setExtractMethodologyId] = useState(0);
  const [complexities, setComplexities] = useState([]);
  const [solutionMethodName, setSolutionMethodName] = useState('');
  const [selectedMethodName, setSelectedMethodName] = useState("");
  const { defaultComplexity, projectTypeId } = appState;
  const [selectedComplexity, setSelectedComplexity] = useState(defaultComplexity);
  const [showMethodAdminMenuId, setShowMethodAdminMenuId] = useState("");
  const [showEditSolutionMethodlogy, setShowEditSolutionMethodlogy] = useState(false);
  const [selectedSolutionMethod, setSelectedSolutionMethod] = useState(0);
  const [showDeleteMethodModal, setShowDeleteMethodModal] = useState(false);
  const [isProgramDelivery, setIsProgramDelivery] = useState('');

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });
  const [popupContent, setPopupContent] = useState(null);
  const [ismethodownerorcoowner, setIsmethodownerorcoowner] = useState(false);
  const inputRef = useRef();

  const handleDivClick = (index) => {
    setSelectedPhase(index)
  }

  const getIsAdmin = async () => {
    await axiosGetService('api/user/profile').then((res) => {
      setIsAdmin(res.data.isAdmin);
    });
  }

  const getCoOwnerGroupId = async (methodId) => {
    await axiosGetService(
      `api/sdmsolutionmethod/ismethodownerorcoowner/${methodId}`
    ).then((response) => {
      setIsmethodownerorcoowner(response.data);
    });
  };

  const getProgramDeliveryId = async () => {
    const response = await axiosGetService(`api/sdmsettings?sdmKey=SDMProgramDeliveryId`).then(
      (response) => {
        setIsProgramDelivery(response.data[0].value)
      }
    );
  };

  const togglePopup = (index, event) => {
    const menuItemRect = event.currentTarget.getBoundingClientRect();
    const left = menuItemRect.left;
    const top = menuItemRect.bottom;
    setPopupVisible(!popupVisible);
    setPopupContent(index);
    setPopupPosition({ left, top });

    // Show ExtractMethodlogy popup
    if (event.target.classList.contains('ellipseProjectMenuItem')) {
      setExtractMethodologyId(index.solutionMethodId);
      setSolutionMethodName(index.solutionMethodName);
      setShowExtractMethodlogy(true);
    } else {
      setShowExtractMethodlogy(false);
    }
  };

  const getComplexities = async () => {
    await axiosGetService("api/activities/projectcomplexity").then((res) => {
      const data = res.data.map((item) => {
        return {
          name: item.name,
          id: item.id,
          type: "option",
        };
      });
      setComplexities(data);
    });
  };

  useEffect(() => {
    getIsAdmin();
    getProgramDeliveryId();
    getComplexities();
  }, []);

  useEffect(() => {

    const isDeliveryId = Number(window.location.href.split("/").at(6)) === deliveryManagementId ? true : false
    if (isDeliveryId) {
      setIsActiveSolution(deliveryManagementId);
      setIsPhaseSelected(true);
    } else {
      const isProgramId = Number(window.location.href.split("/").at(6)) === Number(isProgramDelivery) ? true : false
      if (isProgramId) {
        setRealizeValueToggler(1);
        setIsActiveSolution('')
        setIsPhaseSelected(true)
      } else {
        setRealizeValueToggler(2);
        setIsActiveSolution(Number(window.location.href.split("/").at(6)));
        setIsPhaseSelected(true);
      }
    }
  }, [deliveryManagementId, isProgramDelivery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setPopupVisible(false);
        setShowExtractMethodlogy(false);
      }
    };

    const handleScroll = () => {
      setPopupVisible(false);
      setShowExtractMethodlogy(false);
    };

    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [inputRef]);

  useEffect(() => {
    if (isActiveSolution) {
      clearFilters(); // Trigger clearFilters whenever isActiveSolution changes
    }
  }, [isActiveSolution]); // Dependency array to watch for phase changes

  return (
    <div className='projectDeliveryPhaseMainContainer'>
      {projectDeliveryPhases.map((e, index) => (

        <div className={isActiveSolution === e.solutionMethodId ? "projectDeliveryPhaseActive" : "projectDeliveryPhaseInActive"}>
          <NavLink
            exact
            className="projectDeliveryPhaseLink"
            onClick={() => {
              setIsActiveSolution(e.solutionMethodId);
              setIsPhaseSelected(true);
            }}
            to={`/project/${1}/method/${e.solutionMethodId}`}
          >
            <div
              className="solutionMethodIcon"
              dangerouslySetInnerHTML={{
                __html: htmlSanitizer(e.svgFile),
              }}
            />

            <span>{e.solutionMethodName}</span>
          </NavLink>
          <div className="editSolutionMethod">
            <Icon
              className="extractMoreIcon"
              iconName="MoreVertical"
              title="Extract Methodology"
              aria-label="Click to view extract Methodology"
              role="none"
              tabIndex={0}
              onClick={async (event) => {
                togglePopup(e, event);
                await getCoOwnerGroupId(e.solutionMethodId);
                if (e.solutionMethodId === showMethodAdminMenuId) {
                  setShowMethodAdminMenu(false);
                  setShowMethodAdminMenuId(0);
                } else {
                  setShowMethodAdminMenu(true);
                  setShowMethodAdminMenuId(e.solutionMethodId);
                }
              }}
              onKeyPress={(event) => {
                if (event.which === 13) {
                  togglePopup(e, event);
                  if (e.solutionMethodId === showMethodAdminMenuId) {
                    setShowMethodAdminMenu(false);
                    setShowMethodAdminMenuId(0);
                  } else {
                    setShowMethodAdminMenu(true);
                    setShowMethodAdminMenuId(e.solutionMethodId);
                  }
                }
              }}
            />
          </div>
        </div>
      ))}
      {popupVisible && (
        <div
          ref={inputRef}
          className={`showMoreProjectMenu ${showMethodAdminMenu &&
            popupContent.solutionMethodId == showMethodAdminMenuId
            ? ""
            : "hideMethodEditMenu"
            }`}
          style={{ left: popupPosition.left, top: popupPosition.top, position: 'absolute', zIndex: 1000, marginLeft: '-9rem' }}
        >
          <span className="showMoreArrow"></span>
          <ul className="menuProjectItemUL">
            {(isAdmin || ismethodownerorcoowner) && !endUserView && (
              <li className="moreProjectItemLi" tabIndex="0">
                <div
                  className="ellipseProjectMenuItem"
                  tabIndex={0}
                  onClick={(event) => {
                    event.preventDefault();
                    setShowEditSolutionMethodlogy(true);
                    setSelectedSolutionMethod(popupContent.solutionMethodId);
                    setPopupVisible(false);
                  }}
                  onKeyPress={(event) => {
                    if (event.which === 13) {
                      event.preventDefault();
                      setShowEditSolutionMethodlogy(true);
                      setSelectedSolutionMethod(popupContent.solutionMethodId);
                      setPopupVisible(false);
                    }
                  }}
                  aria-label="Click to edit Solution Method"
                  role="option"
                >
                  <span>Edit Solution Method</span>
                </div>
              </li>
            )}
            {isAdmin && !endUserView && (
              <li className="moreProjectItemLi" tabIndex="0">
                <div
                  className="ellipseProjectMenuItem"
                  tabIndex={0}
                  onClick={() => {
                    setShowDeleteMethodModal(true);
                    setSelectedSolutionMethod(popupContent.solutionMethodId);
                    setSelectedMethodName(popupContent.solutionMethodName);
                    setPopupVisible(false);
                  }}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      setShowDeleteMethodModal(true);
                      setSelectedSolutionMethod(popupContent.solutionMethodId);
                      setSelectedMethodName(popupContent.solutionMethodName);
                      setPopupVisible(false);
                    }
                  }}
                  aria-label="Click to delete Solution Method"
                  role="option"
                >
                  <span>Delete Solution Method</span>
                </div>
              </li>
            )}
            <li className="moreProjectItemLi" tabIndex="0">
              <div
                className="ellipseProjectMenuItem"
                tabIndex={0}
                onClick={(event) => {
                  event.stopPropagation();
                  setExtractMethodologyId(popupContent.solutionMethodId);
                  setShowExtractMethodlogy(true);
                  setPopupVisible(false);
                  setSolutionMethodName(popupContent.solutionMethodName);
                }}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    setExtractMethodologyId(popupContent.solutionMethodId);
                    setSolutionMethodName(popupContent.solutionMethodName);
                    setShowExtractMethodlogy(true);
                    setPopupVisible(false);
                  }
                }}
                aria-label="Click to extract Solution Method"
                role="option"
              >
                <span>Extract Solution Method</span>
              </div>
            </li>
          </ul>
        </div>
      )}
      {showExtractMethodlogy ? (
        <ExtractMethodlogy
          showExtractMethodlogy={showExtractMethodlogy}
          extractMethodlogyId={extractMethodlogyId}
          solutionMethodName={solutionMethodName}
          selectedComplexity={selectedComplexity}
          setSelectedComplexity={setSelectedComplexity}
          setExtractMethodologyId={setExtractMethodologyId}
          setShowExtractMethodlogy={setShowExtractMethodlogy}
          complexities={complexities}
          popupPosition={popupPosition}
        />
      ) : null}
      {showEditSolutionMethodlogy && (
        <AdminEditSolutionMethod
        isSolutionCollaboratorsEdited={isSolutionCollaboratorsEdited}
				setIsSolutionCollaboratorsEdited={setIsSolutionCollaboratorsEdited}
          showEditSolutionMethodlogy={showEditSolutionMethodlogy}
          setShowEditSolutionMethodlogy={setShowEditSolutionMethodlogy}
          selectedSolutionMethod={selectedSolutionMethod}
          setSelectedSolutionMethod={setSelectedSolutionMethod}
          setIsEditSolutionMethod={setIsEditSolutionMethod}
          isEditSolutionMethod={isEditSolutionMethod}
          methods={
            projectId === 1
              ? selectedSolutionMethod
                ? consultingSolutionMethods
                : [
                  ...consultingSolutionMethods,
                  {
                    solutionMethodId: 0,
                    solutionMethodName: "",
                    type: "new",
                  },
                ]
              : selectedSolutionMethod
                ? supportSolutionMethods
                : [
                  ...supportSolutionMethods,
                  { solutionMethodId: 0, solutionMethodName: "", type: "new" },
                ]
          }
        />
      )}
      {showDeleteMethodModal && (
        <MethodDelete
          showDeleteMethodModal={showDeleteMethodModal}
          setShowDeleteMethodModal={setShowDeleteMethodModal}
          selectedSolutionMethod={selectedSolutionMethod}
          setSelectedSolutionMethod={setSelectedSolutionMethod}
          selectedMethodName={selectedMethodName}
          setSelectedMethodName={setSelectedMethodName}
          projectTypeId={projectTypeId}
          setIsDeleteSolutionMethod={setIsDeleteSolutionMethod}
          isDeleteSolutionMethod={isDeleteSolutionMethod}
        />
      )}
    </div>
  )
}

export default ProjectDeliveryPhases
