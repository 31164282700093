import React from 'react';
import { IrisWebChat, IIrisBotProps } from '@iris/react-webchat';
import '@iris/react-webchat/dist/index.css';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAxios } from '../../context/AxiosContext';
import { useConfig } from '../../context/ConfigContext';

const IrisCopilot = ({
    setShowIrisCopilot,
    showIrisCopilot,
    irisSupportTicket,
    setIrisSupportTicket,
}) => {
    const { appState, setAppState } = useLeftMenuCollapse();
    const { axiosGetService } = useAxios();
    const { irisCopilotApiUrl } = useConfig();

    // const getIrisBotTokenCallback = () => {
    //     return new Promise<string>((resolve) => {
    //       resolve('<<Implement the method to generate the token>>')
    //     });
    // }

    const getIrisBotTokenCallback = () => {
        return new Promise((resolve, reject) => {
            axiosGetService(`api/sdmsolutionmethod/getiriscopilotaccesstoken`).then(
                (response) => {
                    return resolve(response.data);
                }
            ).catch((error) => {
                reject(error);
            });
        });
	};

    const closeIrisBot = () => {
        setIrisSupportTicket(irisSupportTicket);
        setShowIrisCopilot(!showIrisCopilot);
    }

    const IrisCopilotConfig = {
        acquireToken: getIrisBotTokenCallback,
        closeBot: closeIrisBot,
        appName: 'SDM Plus',
        platform: 'SDM Plus',
        enableAutoSuggest: true, // true to enable auto suggestion
        hideCloseButton: false, // true to hide the close button
        enableWindowResizing: false, // true to enable window resizing
        selfHelpContext: irisSupportTicket ? {
            selfHelpEventName: "DEEPLINK",
            messages: ['Create a Support Ticket'],
            activityContext: {
              'Name': 'SDM Plus'
            }
          } :  null,
        irisApiBaseUrl: irisCopilotApiUrl,
        style: { 
            avatarIconUrl: 'https://iris-cdn.azureedge.net/iris-ux/assets/images/iris-copilot-icon.svg'
        }    
    }
    return(
        <>
            {showIrisCopilot && 
                <IrisWebChat 
                    {...IrisCopilotConfig}
                />
            }
        </>
    )
}

export default IrisCopilot;