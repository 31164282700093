import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import queryString from "query-string";
import { initializeIcons } from "@uifabric/icons";
import { useAxios } from "../../context/AxiosContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import Spinner from "../../helpers/Spinner";
import { useAppInsights } from "../../context/TelemetryContext";
import { Icon } from "@fluentui/react/lib/Icon";
import TopicLeftMenu from "./TopicsLeftMenu";
import Breadcrumbs from "../Breadcrumbs";
import TopicLandingFilters from "./TopicsLandingFilter";
import HashtagAutoSuggestions from "./HashtagSuggestions";
import TopicsOwner from "../TopicsOwner/TopicsOwner";
import DeleteTopicModal from "./DeleteTopicModal";
import TopiCategory from "../TopicCategory";
import CategoryDeleteModal from "../TopicCategory/Delete";

import { TabTitle } from "../../utils/GeneralFunctions";

import "./TopicsLandingPage.css";
initializeIcons();

const Topics = ({
  dynamicFilterTag,
  setDynamicFilterTag,
  dynamicFilterTagMapping,
  setDynamicFilterTagMapping,
  resetSolutionMethodFilterTag,
  resetPhaseFilterTag,
  resetActivitiesFilterTag,
  expandTopicsLeftMenu,
  setExpandTopicsLeftMenu,
  isUserAction,
  setIsUserAction,
  leftMenuSelectedId,
  setLeftMenuSelectedId,
  topicSearchInput,
  setTopicSearchInput,
  topicHashtag,
  setTopicHashtag,
  showTutorials,
}) => {
  const location = window.location.search;
  const { track } = useAppInsights();
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const selectedTopicType = searchParams.get('topicType');
  const { axiosGetService } = useAxios();
  const { projectType } = 'Consulting';
  const {
    appState,
    breadcrumbs,
    setBreadcrumbs,
    setSkipNavigationId,
    isAdmin,
    endUserView,
  } = useLeftMenuCollapse();
  const { userDefaultProjectTypeId } = appState;
  const [showLoader, setShowLoader] = useState(false);
  const [consultingTopics, setConsultingTopics] = useState([]);
  const [supportTopics, setSupportTopics] = useState([]);
  const [updatedConsultingTopics, setUpdatedConsultingTopics] = useState([]);
  const [updatedSupportTopics, setUpdatedSupportTopics] = useState([]);
  const topicsTab = ["Consulting"];
  const [selectedTab, setSelectedTab] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [userFilter, setUserFilterTag] = useState([]);
  const [filterCleared,setFilterCleared] = useState(false);
  const [selectedProcedures, setSelectedProcedures] = useState(
    window.location.search &&
      queryString.parse(window.location.search).topicType === "job" &&
      (queryString.parse(window.location.search).selectedJobAides === "2" ||
        queryString.parse(window.location.search).selectedJobAides === "3")
      ? Number(queryString.parse(window.location.search).selectedJobAides)
      : 0
  );
  const [selectedReadiness, setSelectedReadiness] = useState(
    window.location.search &&
      queryString.parse(window.location.search).topicType === "read" &&
      (queryString.parse(window.location.search).selectedReadiness === "2" ||
        queryString.parse(window.location.search).selectedReadiness === "3")
      ? Number(queryString.parse(window.location.search).selectedReadiness)
      : 0
  );
  const [expandedState, setExpandedState] = useState({});
  const handleAccordion = (e) => {
    const selectHeading = e.target.id;
    const selectSubheading = 'data_id_' + e.target.id.split('_')[1];  
    const elements = document.querySelectorAll(`#${selectSubheading}`);
    
    elements.forEach(element => {
      if (element.classList.contains('hidden')) {
        element.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
      }
    });
    setExpandedState(prevState => ({
      ...prevState,
      [selectHeading]: !prevState[selectHeading]
    }));
  };
  const [enableFilterTagContainer, setEnableFilterTagContainer] =
    useState(false);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
  const [hashtagSearchInput, setHashtagSearchInput] = useState("");
  const [showCollaboratorsPanel, setShowCollaboratorsPanel] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [userAction, setUserAction] = useState(false);
  const [selectedCategoryTopics, setSelectedCategoryTopics] = useState([]);
  const [showDeleteTopicModal, setShowDeleteTopicModal] = useState(false);
  const [deleteTopicInfo, setDeleteTopicInfo] = useState({});
  const [showTopicCategoryModal, setShowTopicCategoryModal] = useState(false);
  const [selectedCategoryInfo, setSelectedCategoryInfo] = useState({
    categoryId: 0,
  });
  const [filtersAllCleared, setFiltersAllCleared] = useState(false); 
  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);
  const [topicTypeModal, setTopicTypeModal] = useState('')
  const modalRef = useRef(null);
  const filtersCleared = useRef(false); 
  const filterClearedDynamic = useRef(false);

  const hashtagsAutoComplete = async () => {
    await axiosGetService(
      `api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
        hashtagSearchInput
      )}`
    ).then((response) => {
      setHashtagSuggestions(response.data);
    });
  };

  const getConsultingOrSupportTopics = async (topicId) => {
    setShowLoader(true);
    const encodedHashtags = topicHashtag.map((item) => {
      return encodeURIComponent(item);
    });
    const selectedFilterIds = [];
    const filterIds = Object.keys(dynamicFilterTag);
    let selectedFilter = false;
    for (const id in filterIds) {
      if (dynamicFilterTag[Number(filterIds[id])].selectedTagId.length > 0) {
        selectedFilter = true;
        selectedFilterIds.push(
          `${Number(filterIds[id])}` +
          "-" +
          dynamicFilterTag[Number(filterIds[id])].selectedTagId.toString()
        );
      }
    }

    if (Number(selectedProcedures) === 0 && Number(selectedReadiness) === 0) {
      const response = await axiosGetService(
        `api/sdmtopics/getbycategorytypewithfilters?filterIds=${selectedFilterIds?.length > 0 ? selectedFilterIds.join("|") : (userFilter.length > 0 ? userFilter[0].filters : 0)
        }&searchTitle=${topicSearchInput.trim().length > 0
          ? encodeURIComponent(topicSearchInput)
          : 0
        }&hashTagsText=${topicHashtag.length > 0 ? encodedHashtags.toString() : 0
        }&endUserView=${endUserView}`
      );
      if (topicHashtag.length > 0 || topicSearchInput.trim().length > 0) {
        track(1, 1206, "Topics Landing Page", "Topics", {
          hashtag: topicHashtag.length > 0 ? topicHashtag.toString() : null,
          title: topicSearchInput.trim().length > 0 ? topicSearchInput : null,
        });
      }
      setConsultingTopics(response.data);   
    } else if (selectedProcedures !== 0) {
      await axiosGetService(
        `api/sdmtopics/getbyprocedurefilters?artifactTypeIds=${82}&procedureTypeId=${0}&procedureFilterIds=${ selectedFilter  ? selectedFilterIds.join("|") : (userFilter.length > 0 ? userFilter[0].filters : 0)
        }&searchTitle=${topicSearchInput.trim().length > 0
          ? encodeURIComponent(topicSearchInput)
          : 0
        }&hashTagsText=${topicHashtag.length > 0 ? encodedHashtags.toString() : 0
        }&pageTypeId=${9}&endUserView=${endUserView}`
      ).then((response) => {
        if (topicHashtag.length > 0 || topicSearchInput.trim().length > 0) {
          track(1, 1207, "Job Aides Landing Page", "Job Aides", {
            hashtag: topicHashtag.length > 0 ? topicHashtag.toString() : null,
            title: topicSearchInput.trim().length > 0 ? topicSearchInput : null,
          });
        }

          setConsultingTopics(response.data);
  
      });
    } else if (selectedReadiness !== 0) {
      await axiosGetService(
        `api/sdmtopics/getbyprocedurefilters?artifactTypeIds=${84}&procedureTypeId=${4}&procedureFilterIds=${selectedFilter ? selectedFilterIds.join("|") : (userFilter.length > 0 ? userFilter[0].filters : 0)
        }&searchTitle=${topicSearchInput.trim().length > 0
          ? encodeURIComponent(topicSearchInput)
          : 0
        }&hashTagsText=${topicHashtag.length > 0 ? encodedHashtags.toString() : 0
        }&pageTypeId=${18}&endUserView=${endUserView}`
      ).then((response) => {
        if (topicHashtag.length > 0 || topicSearchInput.trim().length > 0) {
          track(1, 1210, "Readiness Landing Page", "Readiness", {
            hashtag: topicHashtag.length > 0 ? topicHashtag.toString() : null,
            title: topicSearchInput.trim().length > 0 ? topicSearchInput : null,
          });
        }
          setConsultingTopics(response.data);
        
      });
    } else {
      const response = await axiosGetService(
        `api/sdmtopics/getbycategorytype`
      );
    
        setConsultingTopics(response.data);
    }
    setShowLoader(false);
    window.scroll(0, 0, { behaviour: "smooth" });
  };

  // load breadcrumb
  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=0&itemTypeId=${selectedProcedures !== 0 ? 82 : selectedReadiness !== 0 ? 84 : 10
      }&isEdit=false
			&isAutoComplete=false&isSearchResultsHome=false&isSearchResults=false
			&isMyContributions=false&documentStatus=0&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());
      const pageBreadcrumb = {
        title: breadcrumbTitle[1],
        parent: "Home",
        path: window.location.pathname,
      };
      const newBreadcrumbs = breadcrumbs.slice(0, 1);
      newBreadcrumbs.push(pageBreadcrumb);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[1]);
    });
  };

  const getUserDefaultTopicId = async () => {
    if (
      window.location.search &&
      queryString.parse(window.location.search).topicType === "job" &&
      (queryString.parse(window.location.search).selectedJobAides === "2" ||
        queryString.parse(window.location.search).selectedJobAides === "3")
    ) {
      const tabId = Number(
        queryString.parse(window.location.search).selectedJobAides
      );
      if (tabId === 2) {
        setSelectedTab("Consulting");
      }
    } else if (
      window.location.search &&
      queryString.parse(window.location.search).topicType === "read" &&
      (queryString.parse(window.location.search).selectedReadiness === "2" ||
        queryString.parse(window.location.search).selectedReadiness === "3")
    ) {
      const tabId = Number(
        queryString.parse(window.location.search).selectedReadiness
      );
      if (tabId === 2) {
        setSelectedTab("Consulting");
      }
    } else {
      const response = await axiosGetService("/api/sdmuser/getbyemail");
      if (response !== null && response.data !== null) {
        if (projectType) {
          setSelectedTab(
            projectType.toUpperCase().charAt(0) +
            projectType.slice(1).toLocaleLowerCase()
          );
          track(2, 10, `topics_landingPage_${projectType}`, "Topics");
        } else if (response.data.projectTypeId === 1 || response.data.projectTypeId === 2) {
          track(2, 10, "topics_landingPage_consulting", "Topics");
          setSelectedTab("Consulting");
        }
      }
    }
  };

  // To convert all consulting or supporting data in an array of length 3 to adjust data in three column
  const getUpdatedSupportOrConsultingTopics = (
    consultingOrSupportData,
    topicType
  ) => {
    const topicsLength = consultingOrSupportData.length;
    const updatedConsultingOrSupportData = [];
    if (topicsLength >= 3) {
      let initialIndex = 0;
      let increment_size = 0;
      let divider = 3;
      if (topicsLength % divider === 0) {
        increment_size = Math.floor(topicsLength / divider);
        while (initialIndex < topicsLength) {
          updatedConsultingOrSupportData.push(
            consultingOrSupportData.slice(
              initialIndex,
              (initialIndex += increment_size)
            )
          );
        }
      } else {
        while (initialIndex < topicsLength) {
          increment_size = Math.ceil((topicsLength - initialIndex) / divider--);
          updatedConsultingOrSupportData.push(
            consultingOrSupportData.slice(
              initialIndex,
              (initialIndex += increment_size)
            )
          );
        }
      }
    } else {
      for (const data in consultingOrSupportData) {
        updatedConsultingOrSupportData.push(
          consultingOrSupportData.slice(data, data + 1)
        );
      }
    }
    if (topicType === "Consulting") {
      setUpdatedConsultingTopics(updatedConsultingOrSupportData);
    }
  };

  const handleProcessGroupFilterTag = (
    filterTagId,
    filterTag,
    setFilterTag
  ) => {
    const updatedProcessGroupFilterTag = filterTag.filter(
      (id) => id !== filterTagId
    );
    setFilterTag([...updatedProcessGroupFilterTag]);
  };

  const handleHastagsFilterTag = (item) => {
    const updateHastags = topicHashtag.filter((hashtag) => hashtag !== item);
    setTopicHashtag(updateHastags);
  };

  const handleDynamicFilterTag = (artifactTypeId, tagId) => {
    const updatedProcessGroupFilterTag = dynamicFilterTag[
      artifactTypeId
    ].selectedTagId.filter((id) => id !== tagId);
    const updatedValue = {
      artifactTypeId: artifactTypeId,
      artifactTypeName: dynamicFilterTag[artifactTypeId].artifactTypeName,
      selectedTagId: [...updatedProcessGroupFilterTag],
    };
    setDynamicFilterTag({
      ...dynamicFilterTag,
      [artifactTypeId]: updatedValue,
    });
  };
  const clearFilters = () => {
    setFiltersAllCleared(true);
    setUserFilterTag([]);

    const newDynamicFilterTag = { ...dynamicFilterTag };
    const filterIds = Object.keys(newDynamicFilterTag);
    for (const id of filterIds) {
      newDynamicFilterTag[Number(id)].selectedTagId = [];
    }
    setEnableFilterTagContainer(false);
    setDynamicFilterTag(newDynamicFilterTag);
    setFilterCleared(true);
    setHashtagSearchInput("");
    setTopicHashtag([]);
  };

  const checkForFilterTag = () => {
    setEnableFilterTagContainer(false);
    const artifactTypeIds = Object.keys(dynamicFilterTag);
    for (const id in artifactTypeIds) {
      if (
        dynamicFilterTag[Number(artifactTypeIds[id])].selectedTagId.length > 0
      ) {
        setEnableFilterTagContainer(true);
        break;
      }
    }
    if (topicHashtag.length > 0) {
      setEnableFilterTagContainer(true);
    }
  };

  useEffect(() => {checkForFilterTag();}, [dynamicFilterTag, topicHashtag]);
  const collapseFilter = (e) => {
    const filterArea = document.getElementById("filtersRightPanel");
    if (
      filterArea &&
      !filterArea.contains(e.target) &&
      e.target.className !== "filtersButton" &&
      !(
        typeof e.target.className !== "object" &&
        e.target.className &&
        e.target.className.includes("searchIcon")
      ) &&
      e.target.className !== "root-50" &&
      e.target.className !== "root-56" &&
      e.target.className !== "showMoreFilter"
    ) {
      setShowFilters(false);
    }
  };
  
  const renderTopicsList = (topics, topicClickTelemetryId) => {
    return topics.map((item) => (
      <div key={item.categoryId}>
        <div className="topicsCatogoryHeader">
          <h5>{item.categoryName}</h5>
          {(item.isOwnerOrCoOwner || isAdmin) && !endUserView ? (
            // item.categoryName !== 'Portfolio Management' ?
            //   (
            <>
              {isAdmin && (
                <div className="topicEditCatogoryIcon">
                  <span
                    tabIndex="0"
                    onClick={() => {
                      setShowTopicCategoryModal(true);
                      setSelectedCategoryInfo(item);
                    }}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setShowTopicCategoryModal(true);
                        setSelectedCategoryInfo(item);
                      }
                    }}
                    className="topicEditCollaborators"
                    aria-label={`Click to Edit ${item.categoryName} Category`}
                    role="button"
                  ></span>
                  <div class="tabsTitleToolTip">
                    <span class="tabsToolTipArrow"></span>
                    <span>Edit Category</span>
                  </div>
                </div>
              )}

              {item.categoryName !== 'Portfolio Management' ?
                (
                  <div className="topicEditCatogoryIcon">
                    <span
                      tabIndex="0"
                      onClick={() => {
                        setSelectedCategoryName(item.categoryName);
                        setSelectedCategoryTopics(item.sdmTopics);
                        setSelectedCategoryId(item.categoryId);
                        setShowCollaboratorsPanel(true);
                      }}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          setSelectedCategoryName(item.categoryName);
                          setSelectedCategoryTopics(item.sdmTopics);
                          setSelectedCategoryId(item.categoryId);
                          setShowCollaboratorsPanel(true);
                        }
                      }}
                      className="phaseCollaboratorAddIcon"
                      aria-label={`Click to Edit ${item.categoryName} collabarators`}
                      role="button"
                    ></span>
                    <div class="tabsTitleToolTip">
                      <span class="tabsToolTipArrow"></span>
                      <span>Edit Collaborators</span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

              {isAdmin && (
                item.categoryName !== 'Portfolio Management' ?
                  (
                    <div className="topicEditCatogoryIcon">
                      <span
                        tabIndex="0"
                        onClick={() => {
                          setShowCategoryDeleteModal(true);
                          setSelectedCategoryInfo(item);
                        }}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setShowCategoryDeleteModal(true);
                            setSelectedCategoryInfo(item);
                          }
                        }}
                        className="ActivityDeleteIcon"
                        aria-label={`Click to Edit ${item.categoryName} Delete Category"`}
                        role="button"
                      ></span>
                      <div class="tabsTitleToolTip">
                        <span class="tabsToolTipArrow"></span>
                        <span>Delete Category</span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
              )}

              {item.categoryName !== 'Portfolio Management' ?
                (
                  <div className="topicEditCatogoryIcon">
                    {/* <Link
                      to={`/addtopics/${item.categoryId}?source=${selectedProcedures !== 0 ? "jobAides" : selectedReadiness !== 0 ? "readiness" : "topics"
                        }`}
                      aria-label="Click to add new topic"
                      role="button"
                    > */}
                    <span className="addTopicByOwnerIcon" onClick={() => setTopicTypeModal(item.categoryId)}></span>
                    {topicTypeModal === item.categoryId &&
                      <div className={selectedTopicType !== 'job' ? "topicSelectionContainer" : "topicSelectedContainer topicSelectionContainer"} ref={modalRef}>
                        <Link
                          to={`/addtopics/${item.categoryId}?source=${selectedProcedures !== 0 ? "jobAides" : selectedReadiness !== 0 ? "readiness" : "topics"
                            }`}
                          aria-label="Click to add new topic"
                          role="button"
                        >
                          New Topic
                        </Link>
                        {selectedTopicType !== 'job' && 
                        <Link
                          to={`/polygon/addTopics/${item.categoryId}?source="topics"`}
                          aria-label="Click to add new topic"
                          role="button"
                        >
                          Polygon Topic
                        </Link>
                        } 
                      </div>
                    }
                    <div class="tabsTitleToolTip">
                      <span class="tabsToolTipArrow"></span>
                      <span>
                        Add New {selectedProcedures !== 0 ? "Job Aids" : selectedReadiness !== 0 ? "Readiness" : "Topic"}
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

            </>
            // ) : (
            //   <></>
            // )
          ) : (
            <></>
          )}
        </div>
        <ul>
  {(() => {
    let currentIndex = '';
    return item.sdmTopics.map((sdmTopics, idx) => {
      const selectHeading = sdmTopics.polygonSides > 0 && sdmTopics.polygonSequence === 0;
      const selectSubHeading = sdmTopics.polygonSides > 0 && sdmTopics.polygonSequence > 0;
      if (selectHeading) {
        currentIndex = `id_${sdmTopics.id}`;
      } else if (selectSubHeading) {
          currentIndex = `${currentIndex}`;
      }
      return (
        <React.Fragment key={sdmTopics.id}>
          <li className={selectSubHeading ? 'hexagonTopicChilds' : 'singleTopic'}>
            {selectHeading?(
              <Icon
              className="polygonTopicIcons"
							iconName={expandedState[currentIndex] ?'CaretRightSolid8':'CaretDownSolid8'
							}
              id={currentIndex} 
              onClick={(e)=>handleAccordion(e)}
						/>
              ):<span className="addingWhiteSpacesForExpandIcon"></span>}
              
              {sdmTopics.isDrafted && sdmTopics.isOwnerOrCoOwner &&
                  !endUserView && sdmTopics.polygonSequence === 0 ? (
                  <span className="draftActivity">Draft</span>
                ) : (
                  <></>
                )}
               <span id={selectHeading?currentIndex:selectSubHeading?`data_${currentIndex}`:''} title='Business Rule' className={`topicIcon ${sdmTopics.hasBusinessRule ? 'businessIcon businessIconTopics' : ''} `}></span>
               <Link
                    to={
                      window.location.search
                        ? `/topics/${sdmTopics.id + window.location.search
                        }`
                        : `/topics/${sdmTopics.id}`
                    }
                    title={sdmTopics.name.replace(/_.*/, '')}
                    onClick={() => {
                      track(
                        1,
                        topicClickTelemetryId,
                        sdmTopics.id,
                        sdmTopics.name.replace(/_.*/, '')
                      );

                    }
                    }
                    aria-label={`Click to view ${sdmTopics.name.replace(/_.*/, '') + selectedProcedures !== 0
                      ? "Job Aide"
                      :
                      selectedReadiness !== 0
                        ? "Readiness"
                        : " Topic"
                      }`}
                      id={selectHeading?currentIndex:selectSubHeading?`data_${currentIndex}`:''}
                  >
                  {sdmTopics.name.replace(/_.*/, '')} 
                </Link>
              <div>
                  {(sdmTopics.isOwnerOrCoOwner &&
                    !endUserView &&
                    sdmTopics.topicType === 3) ||
                    isAdmin ? (
                    <>
                      {sdmTopics.polygonSequence === 0 ?
                        <>
                          {parseInt(sdmTopics.polygonSides) !== 0 ?
                            <>
                              <Link
                                to={`/polygon/addtopics/${item.categoryId}/${sdmTopics.id}?source="topics"`}
                                title={"Edit Topic"}
                                aria-label={`Click to Edit Topic}`}
                              >
                                <span className="topicOwnerEditIcon"></span>
                              </Link>
                              <span
                                className="topicOwnerDeletIcon"
                                title={" Delete Topic"}
                                tabIndex="0"
                                onKeyPress={(e) => {
                                  if (e.which === 13) {
                                    setDeleteTopicInfo({
                                      id: sdmTopics.id,
                                      title: sdmTopics.name.replace(/_.*/, ''),
                                    });
                                    setShowDeleteTopicModal(true);
                                  }
                                }}
                                onClick={() => {
                                  setDeleteTopicInfo({
                                    id: sdmTopics.id,
                                    title: sdmTopics.name.replace(/_.*/, ''),
                                  });
                                  setShowDeleteTopicModal(true);
                                }}
                              ></span>
                              {/* <Link
                                to={`/polygon/addtopics/${item.categoryId}/${sdmTopics.id}?source="topics"`}
                                title={"Delete Topic"}
                                aria-label={`Click to Delete Topic}`}
                              >
                                <span className="topicOwnerDeletIcon"></span>
                              </Link> */}
                            </>
                            :
                            <>
                              <Link
                                to={`/addtopics/${item.categoryId}/${sdmTopics.id
                                  }?source=${selectedProcedures !== 0 ? "jobAides" : selectedReadiness !== 0 ? "readiness" : "topics"
                                  }`}
                                title={
                                  selectedProcedures !== 0
                                    ? "Edit Job Aids"
                                    : selectedReadiness !== 0
                                      ? "Edit Readiness"
                                      : " Edit Topic"
                                }
                                aria-label={`Click to ${selectedProcedures !== 0
                                  ? "Edit Job Aids"
                                  : selectedReadiness !== 0
                                    ? "Edit Readiness"
                                    : " Edit Topic"
                                  }`}
                              >
                                <span className="topicOwnerEditIcon"></span>
                              </Link>
                              {(item.categoryName !== 'Portfolio Management' ?
                                (
                                  <span
                                    className="topicOwnerDeletIcon"
                                    title={
                                      selectedProcedures !== 0
                                        ? "Delete Job Aids"
                                        : selectedReadiness !== 0
                                          ? "Delete Readiness"
                                          : " Delete Topic"
                                    }
                                    tabIndex="0"
                                    onKeyPress={(e) => {
                                      if (e.which === 13) {
                                        setDeleteTopicInfo({
                                          id: sdmTopics.id,
                                          title: sdmTopics.name.replace(/_.*/, ''),
                                        });
                                        setShowDeleteTopicModal(true);
                                      }
                                    }}
                                    onClick={() => {
                                      setDeleteTopicInfo({
                                        id: sdmTopics.id,
                                        title: sdmTopics.name.replace(/_.*/, ''),
                                      });
                                      setShowDeleteTopicModal(true);
                                    }}
                                  ></span>
                                ) : (
                                  <></>
                                ))}
                            </>
                          }
                        </>
                        :
                        <></>
                      }
                    </>
                  ) : (
                    <></>
                  )}
                </div>
          </li>
        </React.Fragment>
      );
    });
  })()}
</ul>
      </div>
    ));
  };

  useEffect(() => {
    if (consultingTopics) {
      getUpdatedSupportOrConsultingTopics(consultingTopics, "Consulting");
    }

  }, [consultingTopics]);

  useEffect(() => {
    setSkipNavigationId("selectedTopicMenu");
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      getUserDefaultTopicId();
    }, 1000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [userDefaultProjectTypeId, location]);

  useEffect(() => {
    loadBreadcrumb();
    setTopicSearchInput("");
    setHashtagSearchInput("");
  }, [selectedProcedures, selectedReadiness]);


  const applyFilters = () => {
    setFiltersApplied(true); // Mark that filters are applied
    setUserFilterTag([]); // Clear userFilterTag
    filtersCleared.current = true; // Mark that filters are cleared
  };
  
  
  useEffect(() => {
    let timeOutId;
    setShowLoader(true);
    if (selectedTab === "Consulting" || userFilter.length === 0 && filtersCleared.current) {
      timeOutId = setTimeout(() => {
        getConsultingOrSupportTopics(1);
        filtersCleared.current = false;
      }, 1000);
    }
    checkForFilterTag();
    return () => {
      clearTimeout(timeOutId);
    };
  }, [
    userFilter,
    selectedProcedures,
    selectedReadiness,
    selectedTab,
    topicSearchInput,
    topicHashtag,
    userAction,
    endUserView,
    
  ]);
  useEffect(() => {
    if (
      window.location.search &&
      queryString.parse(window.location.search).topicType === "job" &&
      (queryString.parse(window.location.search).selectedJobAides === "2" ||
        queryString.parse(window.location.search).selectedJobAides === "3")
    ) {
      setSelectedProcedures(
        Number(queryString.parse(window.location.search).selectedJobAides)
      );
      setLeftMenuSelectedId(2);
    } else {
      setSelectedProcedures(0);
    }
    if (
      window.location.search &&
      queryString.parse(window.location.search).topicType === "read" &&
      (queryString.parse(window.location.search).selectedReadiness === "2" ||
        queryString.parse(window.location.search).selectedReadiness === "3")
    ) {
      setSelectedReadiness(
        Number(queryString.parse(window.location.search).selectedReadiness)
      );
      setLeftMenuSelectedId(4);
    } else {
      setSelectedReadiness(0);
    }
  }, [location]);

  useEffect(() => {
    if (
      window.location.search &&
      typeof queryString.parse(window.location.search).topicType ===
      "undefined" &&
      (queryString.parse(window.location.search).selectedJobAides === "2" ||
        queryString.parse(window.location.search).selectedJobAides === "3")
    ) {
      const tabId = Number(
        queryString.parse(window.location.search).selectedJobAides
      );
      history.push({
        pathname: `/topics`,
        search: `?topicType=job&selectedJobAides=${queryString.parse(window.location.search).selectedJobAides
          }`,
      });
    }
    if (
      window.location.search &&
      typeof queryString.parse(window.location.search).topicType ===
      "undefined" &&
      (queryString.parse(window.location.search).selectedReadiness === "2" ||
        queryString.parse(window.location.search).selectedReadiness === "3")
    ) {
      const tabId = Number(
        queryString.parse(window.location.search).selectedReadiness
      );
      history.push({
        pathname: `/topics`,
        search: `?topicType=read&selectedReadiness=${queryString.parse(window.location.search).selectedReadiness
          }`,
      });
    }
  }, []);

  useEffect(() => {

    if (selectedProcedures === 2) {
      track(2, 11, "topics_landingPage_jobaides_consulting", "Job Aides");
    } else if (selectedProcedures === 3) {
      track(2, 11, "topics_landingPage_jobaides_support", "Job Aides");
    }
    if (selectedReadiness === 2) {
      track(2, 12, "topics_landingPage_readiness_consulting", "Readiness");
    } else if (selectedReadiness === 3) {
      track(2, 12, "topics_landingPage_readiness_support", "Readiness");
    }
  }, [selectedProcedures, selectedReadiness]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (hashtagSearchInput.trim() && hashtagSearchInput[0] === "#") {
        hashtagsAutoComplete();
      } else {
        setHashtagSuggestions([]);
        if (topicSearchInput) {
          setTopicSearchInput("");
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [hashtagSearchInput]);

  useEffect(() => {
    window.addEventListener("click", collapseFilter);
    return () => {
      window.removeEventListener("click", collapseFilter);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setTopicTypeModal(null);
    }
  };

  useEffect(() => {
    if (topicTypeModal !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [topicTypeModal]);

  return (
   <div className="topicLandingMainContainer topicParentWrapper">
      <div className="topicsLandingPage">
        <Breadcrumbs />
        <div className="topicsLeftAndMainContainer">
          <TopicLeftMenu
            resetSolutionMethodFilterTag={resetSolutionMethodFilterTag}
            resetPhaseFilterTag={resetPhaseFilterTag}
            resetActivitiesFilterTag={resetActivitiesFilterTag}
            selectedProcedures={selectedProcedures}
            expandTopicsLeftMenu={expandTopicsLeftMenu}
            setExpandTopicsLeftMenu={setExpandTopicsLeftMenu}
            selectedTab={selectedTab}
            type="landingPage"
            isUserAction={isUserAction}
            setIsUserAction={setIsUserAction}
            leftMenuSelectedId={leftMenuSelectedId}
            setLeftMenuSelectedId={setLeftMenuSelectedId}
            showTutorials={showTutorials}
            selectedReadiness={selectedReadiness}
          />
          <div
            role="main"
            className={
              !expandTopicsLeftMenu
                ? "topicsContainer"
                : "topicsContainer expanded"
            }
            id="topicsLanding"
          >
            <div className="topicsMenuWrapper">
            <div className="topicsMenuDescriptionLatest">
{
  selectedProcedures !== 0
    ? <>Find all the job aids on how to execute tasks in tools like ESXP and Virtuoso.</>
    : selectedReadiness !== 0
      ? <>Discover resources that will help you deliver customer projects successfully.</>
      : <>Learn more about different topic areas used in ISD customer project delivery here.</>
}
</div>
              {isAdmin && (
                <div class="addDeliveryPlaybookButton">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      setShowTopicCategoryModal(true);
                    }}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setShowTopicCategoryModal(true);
                      }
                    }}
                    aria-label="add a new topic Category"
                    role="button"
                  >
                    <span>Add a new Topic Category</span>
                    <span class="editDelevieryPlayBookIcon"></span>
                  </button>
                  {showTopicCategoryModal ? (
                    <>
                      <TopiCategory
                        showTopicCategoryModal={showTopicCategoryModal}
                        setShowTopicCategoryModal={setShowTopicCategoryModal}
                        consultingTopics={consultingTopics}
                        isTopic={Number(selectedProcedures) === 0 && Number(selectedReadiness) === 0}
                        procedureTypeId={
                          queryString.parse(window.location.search).topicType === "read" ? 4
                            : queryString.parse(window.location.search).topicType === "job" ? ('2,3')
                              : 1
                        }
                        projectTypeId={selectedTab === "Consulting" ? 1 : 2}
                        categoryId={selectedCategoryInfo.categoryId}
                        setSelectedCategoryInfo={setSelectedCategoryInfo}
                        projectType={selectedTab}
                        getConsultingOrSupportTopics={
                          getConsultingOrSupportTopics
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              <div class="globalHashtagsSearch">
                <Icon iconName="Search" />
                <HashtagAutoSuggestions
                  className="topicLandingHashtagAutoSuggestion"
                  enableDropdown={true}
                  data={hashtagSuggestions}
                  searchValue={hashtagSearchInput}
                  setSearchValue={setHashtagSearchInput}
                  existingHashtags={topicHashtag}
                  setExistingHashtags={setTopicHashtag}
                  setHashtagSuggestions={setHashtagSuggestions}
                  setDataSearchInput={setTopicSearchInput}
                />
              </div>
              <div className="raciFiltersPart">
                <span>Clear all filters</span>
                <span
                title="Cancel"
                  className="ClearFiltersButton"
                  tabIndex={0}
                  onClick={clearFilters}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      clearFilters();
                    }
                  }}
                  role="button"
                  aria-label="clear  all filters"
                ></span>
              </div>
              <button
                className="filtersButton"
                onClick={() => setShowFilters(!showFilters)}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    setShowFilters(!showFilters);
                  }
                }}
                aria-label="filters"
              >
                Filters
                <Icon
                  iconName="FilterSolid"
                  title="Filters"
                  className="searchIcon"
                />
              </button>
              <TopicLandingFilters
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                selectedTab={selectedTab}
                dynamicFilterTag={dynamicFilterTag}
                setDynamicFilterTag={setDynamicFilterTag}
                dynamicFilterTagMapping={dynamicFilterTagMapping}
                setDynamicFilterTagMapping={setDynamicFilterTagMapping}
                leftMenuSelectedId={leftMenuSelectedId}
                applyFilters={applyFilters}
                userFilterTag={userFilter}
                setUserFilterTag={setUserFilterTag}
                filterCleared={filterCleared}
                filterClearedDynamic={filterClearedDynamic}
                filtersAllCleared={filtersAllCleared}

              />
            </div>
            <div className="topicsListContainer">
              {enableFilterTagContainer ? (
                <div className="topicFilterTagsDiv">
                  <div className={enableFilterTagContainer ? "filterTagsContainer filterTagsContainerNew" : "filterTagsContainer"}>
                    <div className="tagsContainer">
                    {(topicHashtag?.length > 0 || 
    Object.keys(dynamicFilterTag).some(
      (key) => dynamicFilterTag[Number(key)]?.selectedTagId?.length > 0
    )) && (
    <span className="ActivityLabel">Filters:&nbsp;</span>
  )}
                      {topicHashtag.map((item) => (
                        <span key={item} className="filtertagsStyle">
                          {item}
                          <Icon
                            onClick={() => handleHastagsFilterTag(item)}
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                handleHastagsFilterTag(item);
                              }
                            }}
                            aria-label={`remove ${item} hashtag`}
                            role="button"
                            iconName="cancel"
                            className="activity-remove-icon"
                          />
                        </span>
                      ))}
                      {Object.keys(dynamicFilterTag).map((item) => (
                        <>
                          {dynamicFilterTag[Number(item)].selectedTagId.length >
                            0 ? (
                            <>
                              {dynamicFilterTag[Number(item)].selectedTagId.map(
                                (tagId) => (
                                  <>
                                    {dynamicFilterTagMapping[
                                      dynamicFilterTag[Number(item)]
                                        .artifactTypeId
                                    ][tagId] ? (
                                      <span className="filtertagsStyle" key={tagId}>
                                        {`${dynamicFilterTagMapping[
                                          dynamicFilterTag[Number(item)]
                                            .artifactTypeId
                                        ][tagId]
                                          }`}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {showLoader ? (
                <div className="spinner">
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  {(updatedConsultingTopics.length === 0 &&
                    selectedTab === "Consulting") ||
                    (updatedSupportTopics.length === 0 &&
                      selectedTab === "Support") ? (
                    <p className="noWorkInstructions">
                      No {selectedProcedures !== 0 ? 'Job Aids' : selectedReadiness !== 0 ? 'Readiness' : 'Topics'}{' '}
                      Found!
                    </p>
                  ) : selectedTab ? (
                    <div className="topicsList">
                      {selectedTab === "Consulting" ? (
                        <>
                          <div className={enableFilterTagContainer ? "topicsListWrapper topicsListWrapperNew" : "topicsListWrapper" }>
                            {consultingTopics &&
                              updatedConsultingTopics.length >= 1 ? (
                              <>
                                {renderTopicsList(
                                  updatedConsultingTopics[0],
                                  selectedProcedures !== 0 ? 11 : selectedReadiness !== 0 ? 12 : 10
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className={enableFilterTagContainer ? "topicsListWrapper topicsListWrapperNew" : "topicsListWrapper"}>
                            {consultingTopics &&
                              updatedConsultingTopics.length > 1 ? (
                              <>
                                {renderTopicsList(
                                  updatedConsultingTopics[1],
                                  selectedProcedures !== 0 ? 11 : selectedReadiness !== 0 ? 12 : 10
                                )}
                              </>
                            ) : null}
                          </div>
                          <div className={enableFilterTagContainer ? "topicsListWrapper topicsListWrapperNew" : "topicsListWrapper"}>
                            {consultingTopics &&
                              updatedConsultingTopics.length > 2 ? (
                              <>
                                {renderTopicsList(
                                  updatedConsultingTopics[2],
                                  selectedProcedures !== 0 ? 11 : selectedReadiness !== 0 ? 12 : 10
                                )}
                              </>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={enableFilterTagContainer ? "topicsListWrapper topicsListWrapperNew" : "topicsListWrapper"}>
                            {supportTopics &&
                              updatedSupportTopics.length >= 1 ? (
                              <>
                                {renderTopicsList(
                                  updatedSupportTopics[0],
                                  selectedProcedures !== 0 ? 11 : selectedReadiness !== 0 ? 12 : 10
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className={enableFilterTagContainer ? "topicsListWrapper topicsListWrapperNew" : "topicsListWrapper"}>
                            {supportTopics &&
                              updatedSupportTopics.length > 1 ? (
                              <>
                                {renderTopicsList(
                                  updatedSupportTopics[1],
                                  selectedProcedures !== 0 ? 11 : selectedReadiness !== 0 ? 12 : 10
                                )}
                              </>
                            ) : null}
                          </div>
                          <div className={enableFilterTagContainer ? "topicsListWrapper topicsListWrapperNew" : "topicsListWrapper"}>
                            {supportTopics &&
                              updatedSupportTopics.length > 2 ? (
                              <>
                                {renderTopicsList(
                                  updatedSupportTopics[2],
                                  selectedProcedures !== 0 ? 11 : selectedReadiness !== 0 ? 12 : 10
                                )}
                              </>
                            ) : null}
                          </div>
                          <div className="supportMessage">
                            <div className="errorIcon">
                              <Icon iconName="Error" />
                            </div>
                            <p>
                              Support Delivery related Topics and Job Aids
                              have been revised and rehomed to the CSAM Wiki
                              (site for CSAMs by CSAM/HQ collaboration):{" "}
                              <a
                                href="https://aka.ms/CSAM_WIKI"
                                target="_blank"
                              >
                                <i>https://aka.ms/CSAM_WIKI</i>
                              </a>
                            </p>
                            <p>
                              Please reach out to the{" "}
                              <a href="mailto:CsRdDesign@service.microsoft.com?subject=SDMPlus Support Content Retirement">
                                <i> CSAM Strategy-Design</i>
                              </a>{" "}
                              to raise any questions about the legacy SDMPlus
                              Support methods and content retirement
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showCollaboratorsPanel ? (
        <TopicsOwner
          categoryId={selectedCategoryId}
          setCategoryId={setSelectedCategoryId}
          showCollaboratorsPanel={showCollaboratorsPanel}
          setShowCollaboratorsPanel={setShowCollaboratorsPanel}
          userAction={userAction}
          setUserAction={setUserAction}
          topicsSequenceData={selectedCategoryTopics}
          categoryName={selectedCategoryName}
        />
      ) : (
        <></>
      )}
      {showDeleteTopicModal ? (
        <DeleteTopicModal
          deleteTopicInfo={deleteTopicInfo}
          setDeleteTopicInfo={setDeleteTopicInfo}
          setShowDeleteTopicModal={setShowDeleteTopicModal}
          userAction={userAction}
          setUserAction={setUserAction}
        />
      ) : (
        <></>
      )}
      {showCategoryDeleteModal && (
        <CategoryDeleteModal
          setShowCategoryDeleteModal={setShowCategoryDeleteModal}
          setSelectedCategoryInfo={setSelectedCategoryInfo}
          selectedCategoryInfo={selectedCategoryInfo}
          getConsultingOrSupportTopics={getConsultingOrSupportTopics}
          projectTypeId={selectedTab === "Consulting" ? 1 : 2}
        />
      )}
    </div>
  );
};

export default Topics;
