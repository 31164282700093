import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-bootstrap/Modal';

import Dropdown from '../Dropdown';
import { artifactMapData } from '../../static/ArtifactMapData';
import HashtagSuggestions from '../HashtagSuggestions';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAxios } from '../../context/AxiosContext';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '140px 20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#F0F2F8',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

function FileDropModal({ enableFileDropModal, closeModal, data }) {
	const { axiosGetService, axiosPostService, axiosPostServiceSwimlane } = useAxios();
	const [file, setFile] = useState(null);
	const [fileSize, setFileSize] = useState(0);
	const {
		getRootProps,
		getInputProps,
		open,
		acceptedFiles,
		isDragActive,
		isDragAccept,
		isDragReject,
		rejectedFiles,
	} = useDropzone({
		// Disable click and keydown behavior 7340032
		noClick: true,
		noKeyboard: true,
		multiple: false,
		maxSize: fileSize,
	});
	const [errorMessage, setErrorMessage] = useState(null);
	const [isTemplateUrlResponse, setIsTemplateUrlResponse] = useState(true);
	const defaultLanguage = { name: 'English', id: 15 };
	const [languages, setLanguages] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

	const defaultCountry = { name: 'Worldwide', id: 217 };
	const [countries, setCountries] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

	const [error, setError] = useState(false);
	const [fileUploaded, setFileUploaded] = useState(false);
	const [fileError, setFileError] = useState(false);
	const [sizeError, setSizeError] = useState(false);
	const [fileUploadStatus, setFileUploadStatus] = useState(false);
	const [existingHashtags, setExistingHashtags] = useState([]);
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [updateApiCall, setUpdateApiCall] = useState(false);

	const getFileSize = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=TemplateFileSize`
		);
		setFileSize(response.data[0].value * 1048576);
	};

	const files = acceptedFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
			<button
				tabIndex={0}
				onKeyPress={(e) => {
					if (e.which === 13) {
						setFile(null);
					}
				}}
				onClick={() => setFile(null)}
			>
				X
			</button>
		</li>
	));

	const handleSubmit = async () => {
		if (file !== null) {
			const fileName = file.name.split('.');
			fileName.pop();
			fileName.join('.');

			const formData = new FormData();
			formData.append('fileLocation', file);
			setFile('uploading');

			const templateUrlResponse = await axiosPostServiceSwimlane(
				`api/sdmtemplate/uploadSampleTemplate`,
				formData
			);

			if (templateUrlResponse.data) {
				if (templateUrlResponse.data.status === 'false') {
					setFileUploadStatus(true);
					setErrorMessage(templateUrlResponse.data.errorMessage);
				} else {
					const postData = {
						title: templateUrlResponse.data.fileName,
						fileLocation: templateUrlResponse.data.templateURL,
						fileType: templateUrlResponse.data.templateType,
						country: selectedCountry.name,
						language: selectedLanguage.name,
						parentTemplateId: data.id,
						complexityId: data.complexityId,
						hashtags: userAddedHashtags.toString(),
					};
					await axiosPostService(`api/sdmtemplate/addsampletemplate`, postData)
						.then((response) => {
							if (response.data.status === 'false') {
								setFileUploadStatus(true);
							} else {
								setFileUploadStatus(false);
								setFileUploaded(true);
								setUserAddedHashtags([]);
								setExistingHashtags([]);
							}
						})
						.catch((err) => console.log(err));
				}
			} else {
				setFileUploadStatus(true);
				setIsTemplateUrlResponse(false);
				setErrorMessage("Something went wrong!");
			}
		} else {
			setError(true);
			setFileError(false);
			setSizeError(false);
		}
	};

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject]
	);

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const getLanguages = async () => {
		await axiosGetService(`api/sdmtemplate/languages/${selectedCountry.id}`)
			.then((response) => {
				const data = response.data.map((item) => ({
					name: item.name,
					id: item.id,
				}));
				setLanguages(data);
				setSelectedLanguage({
					id: data[0].id,
					name: data[0].name,
					option: 'type',
				});
			})
			.catch((error) => console.log(error));
	};

	const getCountries = async () => {
		await axiosGetService('api/sdmtemplate/countries')
			.then((response) => {
				const data = response.data.map((item) => ({
					name: item.name,
					id: item.id,
				}));
				setCountries(data);
			})
			.catch((error) => console.log(error));
	};

	const filterFileType = (file) => {
		const fileTypes = [
			'xlsx',
			'pptx',
			'potx',
			'ppt',
			'pdf',
			'doc',
			'docx',
			'dotx',
			//'zip',
			'xlsm',
			//'aspx',
			'msg',
			'mpp',
			'mpt',
		];
		const selectedFileType = file.name.split('.').slice(-1).pop().toLowerCase();
		if (fileTypes.includes(selectedFileType)) {
			setFile(file);
			setFileError(false);
			setError(false);
			setSizeError(false);
		} else {
			setFileError(true);
			setError(false);
			setSizeError(false);
		}
	};

	useEffect(() => {
		if (rejectedFiles.length && rejectedFiles[0].size > fileSize) {
			setError(false);
			setFileError(false);
			setSizeError(true);
		} else if (rejectedFiles.length) {
			filterFileType(rejectedFiles[0]);
		}
	}, [rejectedFiles]);

	useEffect(() => {
		if (acceptedFiles.length == 0) {
			setFile(null);
		} else {
			filterFileType(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	useEffect(() => {
		if (error && file !== null) {
			setError(false);
		}
	}, [file]);

	useEffect(() => {
		getCountries();
	}, []);

	useEffect(() => {
		if (selectedCountry.id !== 0) {
			getLanguages();
		}
	}, [selectedCountry]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	useEffect(() => {
		getFileSize();
	}, []);

	return (
		<Modal
		dialogClassName={
			!fileUploaded ? isTemplateUrlResponse?'templateModal':'templateFeedbackPopUp' : 'templateFeedbackPopUp'
		}
			show={enableFileDropModal}
			onHide={closeModal}
			centered
			size="sm"
		>
			{enableFileDropModal ? (
				fileUploaded || fileUploadStatus ? (
					<>
						<Modal.Body>
							{fileUploadStatus ? (
								<p>{errorMessage}</p>
							) : (
								<p>Sample uploaded successfully</p>
							)}

							<button
								title="OK"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
								onClick={closeModal}
								className="feedbackSubmittedOk"
							>
								OK
							</button>
						</Modal.Body>
					</>
				) : (
					<>
						<Modal.Header className="sampleSubmitTemplatePopUp">
							Submit Sample
							<Icon
								iconName="Cancel"
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
								onClick={closeModal}
							/>
						</Modal.Header>
						<Modal.Body>
							<div
								className={
									file === 'uploading'
										? 'sampleSubmitTemplatePopUp noClickEvents'
										: 'sampleSubmitTemplatePopUp'
								}
							>
								<div className="detailsOfSampleTemplate">
									<p>
										<span className="bold">Project Type:&nbsp; </span>
										<span>{data.projectType}</span>
									</p>
									<p>
										<span className="bold">Phase:&nbsp; </span>
										<span>{data.phaseName}</span>
									</p>
								</div>
								<div className="submitSampleFilterDiv">
									<div className="CountryDiv">
										<span>Country:</span>
										<Dropdown
											className="countryFilter"
											data={countries}
											setValue={setSelectedCountry}
											value={selectedCountry}
											enableDropdown={true}
										/>
									</div>
									<div className="languageDiv">
										<span>Language:</span>
										<Dropdown
											className="languageFilter"
											data={languages}
											setValue={setSelectedLanguage}
											value={selectedLanguage}
											enableDropdown={selectedCountry.id !== 0 ? true : false}
										/>
									</div>
								</div>
								<div className="detOfContributionTemplateforHashtags">
									<h6>Add Hashtags</h6>
									<HashtagSuggestions
										className="templateHashtagsInput"
										enableDropdown={true}
										data={hashtagSuggestions}
										setUpdateApiCall={setUpdateApiCall}
										setHashtagSuggestions={setHashtagSuggestions}
										searchValue={hashtagsInput}
										setSearchValue={setHashtagsInput}
										existingHashtags={existingHashtags}
										userAddedHashtags={userAddedHashtags}
										setUserAddedHashtags={setUserAddedHashtags}
										hashtagSuggestionInput={hashtagSuggestionInput}
										setHashtagSuggestionInput={setHashtagSuggestionInput}
										closeHashtagForm={() => {
											setHashtagsInput('');
											setUserAddedHashtags([]);
										}}
										submitHashtags={() => {
											//do nothing
										}}
										autoFocus={false}
										showSubmitButton={false}
										pageType={'templateSampleSubmit'}
									/>
								</div>
								<div className="container">
									<div
										className="filedropContainer"
										{...getRootProps({ style })}
									>
										<input {...getInputProps()} />
										{file === null || file === 'uploaded' ? (
											<>
												<span>Drag and drop a file here</span>
												<span>or</span>
												<button
													title="Choose File"
													className="dragDropButton"
													type="button"
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															open();
														}
													}}
													onClick={open}
												>
													Choose File
												</button>
											</>
										) : (
											<ul>{files}</ul>
										)}
										{file === 'uploading' ? (
											<div>Uploading File...</div>
										) : (
											<></>
										)}
										{sizeError ? (
											<span className="errorMsg">
												Choose a file size less than {fileSize / 1048576} MB.
											</span>
										) : (
											<></>
										)}
										{error ? (
											<span className="errorMsg">
												Choose a file size less than {fileSize / 1048576} MB and
												select Country & Language to Upload
											</span>
										) : (
											<></>
										)}
										{fileError ? (
											<span className="errorMsg">
												Invalid file Format. Only "xlsx, pptx, potx, ppt, pdf,
												doc, docx, dotx, xlsm, msg, mpp, mpt" are
												allowed.
											</span>
										) : (
											<></>
										)}
									</div>
								</div>
								<button
									title="Submit"
									className="sampleSubmitButton"
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleSubmit();
										}
									}}
									onClick={handleSubmit}
								>
									Submit
								</button>
							</div>
						</Modal.Body>
					</>
				)
			) : (
				<></>
			)}
		</Modal>
	);
}
export default FileDropModal;
