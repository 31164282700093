import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { v4 as uniqueIdGenerator } from 'uuid';
import { HtmlSanitizer } from '../../helpers/HtmlSanitizer';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TasksCKEditorLink from './TasksCKEditorLink';


const defaultTask = {
	name: '',
	status: 'new',
	sequence: 1,
};
const Tasks = ({
	showFields,
	setshowFields,
	handelFormView,
	handelFormViewEdit,
	selectedComplexity,
	complexityLevelDetails,
	setComplexityLevelDetails,
	complexityLevelUpdatedDetails,
	handelFormViewEditPerventClose,
	setComplexityLevelUpdatedDetails,
}) => {

	const [taskName, setTaskName] = useState('');
	const [error, setError] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [updatedId, setUpdateId] = useState(0);

	// const [tasksList, setTasksList] = useState([
	// 	...complexityLevelDetails[selectedComplexity].taskList
	// ]);

	const [tasksList, setTasksList] = useState(() => {
		const initialTasks = complexityLevelDetails[selectedComplexity]?.taskList || [];
		return initialTasks.length > 0 ? [...initialTasks] : [defaultTask];
	});

	// useEffect(()=>{setTasksList([...complexityLevelDetails[selectedComplexity].taskList])},[selectedComplexity])

	useEffect(() => {
		setTasksList(() => {
			const initialTasks = complexityLevelDetails[selectedComplexity]?.taskList || [];
			return initialTasks.length > 0 ? [...initialTasks] : [defaultTask];
		});
	}, [selectedComplexity]);
	// useEffect(() => {
	// 	if (editMode && updatedId !== 0) {
	// 		handelFormViewEditPerventClose('task');
	// 	}
	// }, [taskName]);

	// const updateTasks = () => {
	// 	if (taskName) {
	// 		if (editMode) {
	// 			const updatedDtails = tasksList.map((item) => {
	// 				if (item.id === updatedId) {
	// 					return {
	// 						...item,
	// 						name: taskName,
	// 					};
	// 				} else {
	// 					return item;
	// 				}
	// 			});
	// 			setTasksList([...updatedDtails]);
	// 			setTaskName('')
	// 			setError(false);
	// 		} else {
	// 			setTasksList([
	// 				...tasksList,
	// 				{
	// 					id: uniqueIdGenerator(),
	// 					name: taskName,
	// 					status: 'new',
	// 					sequence: tasksList.length+1,
	// 				},
	// 			]);
	// 			setTaskName('');
	// 			setError(false);
	// 		}
	// 	} else {
	// 		setError(true);
	// 	}


	// };


	// const deleteOtherDetails = (deleteId, value, setValue) => {
	// 	const filteredValue = value.filter(({ id }) => id !== deleteId);
	// 	filteredValue.forEach((item,index)=>{
	// 		item.sequence = index+1
	// 	})
	// 	setValue(filteredValue);
	// };


	// const saveTasks = () => {
	// 	setComplexityLevelUpdatedDetails((prevState) => ({
	// 		...prevState,
	// 		[selectedComplexity]: {
	// 			...complexityLevelUpdatedDetails[selectedComplexity],
	// 			taskList: [...tasksList],
	// 		},
	// 	}));
	// 	setComplexityLevelDetails((prevState) => ({
	// 		...prevState,
	// 		[selectedComplexity]: {
	// 			...complexityLevelDetails[selectedComplexity],
	// 			taskList: [...tasksList],
	// 		},
	// 	}));
	// 	handelFormView('task');
	// 	setError(false);
	// };
	return (
		<>
			<div
				className={
					'Detailswrapper acitivityeditStyle  pt-0 pb-0'
				}
			>

				<div className="DetailswrapperTitle">
					<div className="otherDetailsContainer">
						<div className="entryExitCriteriadiv">

							<TasksComponent
								error={error}
								setTaskName={setTaskName}
								taskName={taskName}
								tasksList={tasksList}
								setEditMode={setEditMode}
								setUpdateId={setUpdateId}
								showFields={showFields}
								handelFormView={handelFormView}
								// deleteOtherDetails={deleteOtherDetails}
								// updateTasks={updateTasks}
								setError={setError}
								setTasksList={setTasksList}
								setComplexityLevelUpdatedDetails={setComplexityLevelUpdatedDetails}
								selectedComplexity={selectedComplexity}
								complexityLevelUpdatedDetails={complexityLevelUpdatedDetails}
								setComplexityLevelDetails={setComplexityLevelDetails}
								complexityLevelDetails={complexityLevelDetails}
							/>
						</div>
					</div>
				</div>

			</div>
		</>
	);
};

const TasksComponent = ({
	error,
	setTaskName,
	taskName,
	tasksList,
	setEditMode,
	setUpdateId,
	showFields,
	handelFormView,
	deleteOtherDetails,
	updateTasks,
	setError,
	setTasksList,
	setComplexityLevelUpdatedDetails,
	selectedComplexity,
	complexityLevelUpdatedDetails,
	setComplexityLevelDetails,
	complexityLevelDetails

}) => {
	const handleAddBusinessContentFields = () => {
		const lastItem = tasksList[tasksList.length - 1];

		// Check if the last item has all required fields filled
		if (lastItem.taskName !== "") {

			setTasksList([
				...tasksList,
				{
					name: "",
					sequence: tasksList.length + 1,
				}
			]);
		} else {
			// Optionally, show an alert or error message if the fields are not filled
			alert("Please fill in the current row before adding a new one.");
		}
	};

	const saveTasks = (updatedTasksList) => {
		setComplexityLevelUpdatedDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelUpdatedDetails[selectedComplexity],
				taskList: [...updatedTasksList],
			},
		}));
		setComplexityLevelDetails((prevState) => ({
			...prevState,
			[selectedComplexity]: {
				...complexityLevelDetails[selectedComplexity],
				taskList: [...updatedTasksList],
			},
		}));
		handelFormView('task');
		setError(false);
	};
	const removeEntryContent = (index) => {
		// Only allow removal if there is more than one item in the list
		if (tasksList.length > 0) {
			const updatedContent = [...tasksList];
			updatedContent.splice(index, 1);
			setTasksList(updatedContent);
			saveTasks(updatedContent);
		}
	};
	// const handleTaskNameChange = (index, value) => {
	// 	debugger;
	// 	const updatedTasksList = tasksList.map((task, i) =>
	// 	  i === index ? { ...task, name: value } : task
	// 	);
	// 	setTasksList(updatedTasksList);
	// 	saveTasks(updatedTasksList)
	//   };

	const handleTaskNameChange = (index, value) => {
		if (value) {
			const updatedTasksList = tasksList.map((task, i) =>
				i === index ? { ...task, name: value } : task
			);
			setTasksList(updatedTasksList);
			saveTasks(updatedTasksList);
		} else {
			console.error("Task name cannot be null or empty");
		}
	};

	return (
		<>
			{/* {showFields.includes('task') && error && (
				<p className="errorMsg">Task Name is required</p>
			)}
			{true && (
				<div className="entryCriteriaDivDesc">
					<div className="entryCriteriaFieldsDesc specificForTask">						
						<div className="entryCriteriaFieldsDesc ">
							
							<TasksCKEditorLink  value={taskName} setValue={setTaskName}/>
						</div>
						<div className="entryExitCriteriaButtonsTask" style={{paddingLeft: '372px'}}>
							<button
								className="addButton"
								onClick={() => {
									updateTasks();
								}}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										updateTasks();
									}
								}}
							>
								Add
							</button>
							<button
								className="cancelButton"
								onClick={() => {
									setTaskName('');
									setEditMode(false);
									setUpdateId(0);
									setError(false);
									if (showFields.includes('task')) {
										handelFormView('task');
									}
								}}
								onKeyPress={(e) => {
									setTaskName('');
									setEditMode(false);
									setUpdateId(0);
									setError(false);
									if (showFields.includes('task')) {
										handelFormView('task');
									}
								}}
							>
								Cancel
							</button>

						</div>
					</div>
				</div>
			)} */}
			<div className="addPolygonTopicRelatedInputContainer">
				<div className="polygonInputContainer">

					{/* entryCriteriaList */}
					{/* {tasksList.map((item, index) => (

						<div className="row " key={item.id || index}>
							<div className="col-xl-11 pl-0 pb-2 pr-0">
							<TasksCKEditorLink  value={item.name} 
							// setValue={setTaskName} 
							setValue={(value) => handleTaskNameChange(index, value)}
							pageType="editactivity"/>
							</div>
							<div className="addPolygonTopicFieldSave taskalignment ml-2">
								<button className="relatededitContentDelete"
									onClick={() => {
										removeEntryContent(index);
									}}>
									<Icon
										className="relatedContentDeleteIcon"
										iconName="ChromeClose"
										title="Close"
									/>
								</button>
							</div>
							{index === tasksList.length - 1 && (
								<div className="addPolygonTopicFieldSave taskalignment">
									<button
										className="relatedContentAdd AddEntryBtn"
										onClick={handleAddBusinessContentFields}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleAddBusinessContentFields();
											}
										}}
									>
										<Icon
											className="relatedContentAddIcon"
											iconName="Add"
											title="Add"
										/>
									</button>
								</div>)}
						</div>
					))} */}

{(tasksList.length === 0 ? [{ id: null, name: "" }] : tasksList).map((item, index) => (
						<div className="row taskmaincontainer" key={item.id || index} style={{ display: 'flex', alignItems: 'stretch' }}>
							<div className="col-xl-11 col-lg-11 pl-0  pr-2 ckdivtasks">
      						{item && (
								<TasksCKEditorLink
								value={item.name || ''}
								setValue={(value) => {
									if (tasksList.length === 0) {
									setTasksList([{ name:null }]);
									} else {
									handleTaskNameChange(index, value);
									}
								}}
								pageType="editactivity"
								/>
							)}
							</div>
							<div className="ckdivtasksicon align-bottom">
								<button className="relatededitContentDelete"
									onClick={() => removeEntryContent(index)}>
									{/* <Icon className="relatedContentDeleteIcon" iconName="ChromeClose" title="Close" /> */}
								</button>
							</div>
							{index === tasksList.length - 1 && (
								<div className="ckdivtasksicon align-bottom">
									<button
										className="relatedContentAdd AddEntryBtn"
										onClick={handleAddBusinessContentFields}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleAddBusinessContentFields();
											}
										}}
									>
										{/* <Icon
											className="relatedContentAddIcon"
											iconName="Add"
											title="Add"
										/> */}
									</button>
								</div>)}
						</div>

					))}


				</div></div>


			{/* {tasksList.length > 0 && (
				<>
				<DragDropContext onDragEnd={onDragEnd}>
					<div
						className={`entryCriteriaList ${showFields.includes('task')
							? 'editEntryExitCriteria'
							: 'none'
							}`}
					>
						<h6>Task</h6>
						<div className="criteriaHeader">
							<div className="criteriaIcon">No</div>

							<div className="criteriaDesc">Task Name</div>
							{showFields.includes('task') && (
								<div className="criteriaEditDeleteIcons">Edit/Delete</div>
							)}
						</div>
						
							<Droppable droppableId="tasksList">
								{(provided) => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										<ul>
										{tasksList.map((value, index) => (
											<Draggable key={value.name} draggableId={value.name} index={index} isDragDisabled={dragState}>
												{(provided) => (
													<div
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														ref={provided.innerRef}
													>
														<li key={value.id}>
															<div className="criteriaTable">
																<div className="criteriaIcon">{index + 1}</div>
																<div className="criteriaDesc">
																	<div
																		dangerouslySetInnerHTML={{
																			__html: HtmlSanitizer(value.name),
																		}}
																	/>
																</div>
																<div className="criteriaEditDeleteIcons">
																	<Icon
																		title="Edit"
																		iconName="EditSolid12"
																		onClick={() => {
																			handelFormView('task');
																			setTaskName(value.name);
																			setEditMode(true);
																			setUpdateId(value.id);
																			setError(false);
																			if (!showFields.includes('task')) {
																				handelFormView('task');
																			}
																		}}
																		tabIndex={0}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				setTaskName(value.name);
																				setEditMode(true);
																				setUpdateId(value.id);
																				setError(false);
																				if (!showFields.includes('task')) {
																					handelFormView('task');
																				}
																			}
																		}}
																	/>
																	<Icon
																		title="Delete"
																		iconName="Cancel"
																		onClick={() => {
																			deleteOtherDetails(
																				value.id,
																				tasksList,
																				setTasksList
																			);
																			setEditMode(false);
																			setTaskName('');
																		}}
																		tabIndex={0}
																		onKeyPress={(e) => {
																			if (e.which === 13 || e.which === 32) {
																				deleteOtherDetails(
																					value.id,
																					tasksList,
																					setTasksList
																				);
																				setEditMode(false);
																				setTaskName('');
																			}
																		}}
																	/>
																</div>
															</div>
															</li>
													</div>
												)}
											</Draggable>

										))}
										</ul>

										{provided.placeholder} 
									</div>
								)}
								
							</Droppable>
					

					</div>
					</DragDropContext>
				</>
			)} */}
		</>
	);
};


export default Tasks;
