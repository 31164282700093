import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAppInsights } from '../../context/TelemetryContext';

const GridDynamicFilter = ({
    data,
    artifactTypeName,
    value,
    setValue,
    artifactTypeId,
    dynamicFilterData,

}) => {
    const { track } = useAppInsights();
    const [showMore, setShowMore] = useState(false);
    const [showLessData, setShowLessData] = useState([]);
	
    useEffect(() => {
        if (data.length > 5 && !showMore) {
            setShowLessData(data.slice(0, 5));
        } else {
            setShowLessData(data);
        }
    }, [data]);

    const handleCheckbox = (item) => {
		const dynamicFilter = Object.keys(value);
		const newValue = { ...value };
		if (dynamicFilter.includes(artifactTypeId.toString())) {
			if (newValue[artifactTypeId].selectedTagId.includes(item.id)) {
				const updatedProcessGroupFilterTag = newValue[
					artifactTypeId
				].selectedTagId.filter((id) => id !== item.id);
				const updatedValue = {
					artifactTypeId: artifactTypeId,
					artifactTypeName: artifactTypeName,
					selectedTagId: [...updatedProcessGroupFilterTag],
				};
				setValue({ ...newValue, [artifactTypeId]: updatedValue });
			} else {
				const updatedValue = {
					artifactTypeId: artifactTypeId,
					artifactTypeName: artifactTypeName,
					selectedTagId: [...newValue[artifactTypeId].selectedTagId, item.id],
				};
				setValue({ ...newValue, [artifactTypeId]: updatedValue });
			}
		} else {
			const updatedValue = {
				artifactTypeId: artifactTypeId,
				artifactTypeName: artifactTypeName,
				selectedTagId: [item.id],
			};

			setValue({ ...newValue, [artifactTypeId]: updatedValue });
		}


	};
    const handleKeyPress = (item, e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleCheckbox(item);
        }
    };

	return (
		<>
			{data.length !== 0 && data.length > 5 ? (
				<ul title={artifactTypeName}>
					{showLessData.map((item) => (
						<li key={item.id}>
							<label
								className="NotificationChkboxContainer"
								onKeyPress={(e) => handleKeyPress(item, e)}
							>
								<input
									name={artifactTypeName}
									type="checkbox"
									checked={
										value[artifactTypeId]
											? value[artifactTypeId].selectedTagId.includes(item.id)
											: false
									}
									onChange={() => {
										handleCheckbox(item);
										track(
											1,
											2106,
											`${artifactTypeId}-${item.id}`,
											`${artifactTypeName}-${item.name}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleCheckbox(item);
											track(
												1,
												2106,
												`${artifactTypeId}-${item.id}`,
												`${artifactTypeName}-${item.name}`
											);
										}
									}}
								/>
								<span className="CustomCheckmark" tabIndex={0} role="checkbox" aria-checked={value[artifactTypeId]
											? value[artifactTypeId].selectedTagId.includes(item.id)
											: false}
											title={item.name}
											></span>
								<span className="filterLabel">{item.name}</span>
							</label>
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								{item.toolTip.split('\\n').map((val) => (
									<span>{val}</span>
								))}
							</div>
						</li>
					))}
					{showMore ? (
						<li>
							<p
								onClick={() => {
									setShowLessData(data.slice(0, 5));
									setShowMore(!showMore);
								}}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowLessData(data.slice(0, 5));
										setShowMore(!showMore);
									}
								}}
								role="button"
								aria-label="Show Less"
							>
								- Show Less
							</p>
						</li>
					) : (
						<li>
							<p
								onClick={() => {
									setShowLessData([...data]);
									setShowMore(!showMore);
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setShowLessData([...data]);
										setShowMore(!showMore);
									}
								}}
								tabIndex={0}
								role="button"
								aria-label={data.length > 5 ? `${data.length - 5} More` : ''}
							>
								+ {data.length > 5 ? `${data.length - 5} More` : <></>}
							</p>
						</li>
					)}
				</ul>
			) : data.length !== 0 && data.length <= 5 ? (
				<ul title={artifactTypeName}>
					{data.map((item) => (
						<li key={item.id}>
							<label
								className="NotificationChkboxContainer"
								onKeyPress={(e) => handleKeyPress(item, e)}
							>
								<input
									name={artifactTypeName}
									type="checkbox"
									checked={
										value[artifactTypeId]
											? value[artifactTypeId].selectedTagId.includes(item.id)
											: false
									}
									onChange={() => {
										handleCheckbox(item);
										track(
											1,
											2106,
											`${artifactTypeId}-${item.id}`,
											`${artifactTypeName}-${item.name}`
										);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											handleCheckbox(item);
											track(
												1,
												2106,
												`${artifactTypeId}-${item.id}`,
												`${artifactTypeName}-${item.name}`
											);
										}
									}}
								/>
								<span className="CustomCheckmark" tabIndex={0}
								role="checkbox" aria-checked={value[artifactTypeId]
											? value[artifactTypeId].selectedTagId.includes(item.id)
											: false} 
											title={item.name}
											></span>
								<span className="filterLabel">{item.name}</span>
							</label>
							<div className="tabsTitleToolTip">
								<span className="tabsToolTipArrow"></span>
								{item.toolTip.split('\\n').map((val) => (
									<span>{val}</span>
								))}
							</div>
						</li>
					))}
				</ul>
			) : (
				<p className="noFiltersFound">No {artifactTypeName} available</p>
			)}
		</>
	);
};
export default GridDynamicFilter;
