import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Icon } from '@fluentui/react/lib/Icon';
import HashtagSuggestions from '../HashtagSuggestions';
import { useAppInsights } from '../../context/TelemetryContext';

import { useAxios } from '../../context/AxiosContext';
import { artifactMapData } from '../../static/ArtifactMapData';

function HashTagsModalForTemplate({
	template,
	closeModal,
	complexityTag,
	existingHashtags,
	setExistingHashtags,
	enableHashtagsForTemplate,
	type,
	pageType
}) {
	const { track } = useAppInsights();
	const { axiosGetService, axiosPostService } = useAxios();
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [updateApiCall, setUpdateApiCall] = useState(false);

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const submitHashtags = async () => {
		if (userAddedHashtags.length > 0) {
			const postData = {
				artifactTypeId: artifactMapData[type],
				artifactId: template.id,
				hashtags: [...userAddedHashtags, ...existingHashtags].toString(),
			};
			await axiosPostService(`api/sdmhashtags/add`, postData).then(
				(response) => {
					if (response.data.status) {
						track(1, 1202, template.id, template.title, {
							hashtags: userAddedHashtags.toString(),
							type: type,
						});
						setExistingHashtags([...userAddedHashtags, ...existingHashtags]);
						setHashtagsInput('');
						setHashtagSuggestionInput('');

						setUserAddedHashtags([]);
					} else {
						toast.error(response.data.errorMessage, {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
				}
			);
		}
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	return (
		<Modal
			show={enableHashtagsForTemplate}
			onHide={closeModal}
			centered
			dialogClassName="hashTagstemplateModal"
		>
			{enableHashtagsForTemplate ? (
				<>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
							Add Hashtags
						</Modal.Title>
						<Icon
							iconName="Cancel"
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									closeModal();
								}
							}}
							onClick={() => {
								closeModal();
							}}
							role="button"
							aria-label="Close Add Hashtags"
						/>
					</Modal.Header>
					<Modal.Body>
						<div className="hashtagsOfTemplateContainer">
							<div className="detailsOfHashtagsTemplate">
								<p>
									<span className="bold">Template:&nbsp; </span>
									<span>{template.title}</span>
								</p>
								<p>
									<span className="bold">Phase Name:&nbsp; </span>
									<span>{template.phaseName}</span>
								</p>
								<p>
									<span className="bold">Complexity:&nbsp; </span>
									<span>{complexityTag.name}</span>
								</p>
							</div>
							<div className="templateDetailsHasttagsContainer">
								<div className="templatesHasttagsContainer">
									<span className="bold">Add #Hashtags</span>
									<div className="templatesDetailsHasttagInput">
										<HashtagSuggestions
											className="templateHashtagsInput"
											enableDropdown={true}
											data={hashtagSuggestions}
											setUpdateApiCall={setUpdateApiCall}
											setHashtagSuggestions={setHashtagSuggestions}
											searchValue={hashtagsInput}
											setSearchValue={setHashtagsInput}
											existingHashtags={existingHashtags}
											userAddedHashtags={userAddedHashtags}
											setUserAddedHashtags={setUserAddedHashtags}
											hashtagSuggestionInput={hashtagSuggestionInput}
											setHashtagSuggestionInput={setHashtagSuggestionInput}
											closeHashtagForm={() => {
												setHashtagsInput('');
												setUserAddedHashtags([]);
											}}
											submitHashtags={submitHashtags}
											autoFocus={true}
											showSubmitButton={true}
											pageType={pageType}
										/>
									</div>

									<div className="hashTagsContainer _existingHashTagsOfActivity">
										{existingHashtags.map((item) => (
											<span>{item}</span>
										))}
									</div>
								</div>
							</div>
							<div className="templatesDetailsHasttagButtons">
								<button
									onClick={() => {
										closeModal();
									}}
									aria-label="Close"
								>
									Close
								</button>
							</div>
						</div>
					</Modal.Body>
				</>
			) : (
				<></>
			)}
		</Modal>
	);
}

export default HashTagsModalForTemplate;
