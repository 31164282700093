import React, { useState, useEffect, useRef} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react/lib/Icon';
import { toast } from 'react-toastify';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';

import LinkTemplate from './LinkTemplate';
import UploadTemplate from './UploadTemplate';
import Spinner from '../../helpers/CrowdSourcingSpinner';
import $ from 'jquery';

import './ActivityTemplateBuilder.css';

import './ActivityTemplateBuilder.css';

const ActivityTemplateBuilder = ({
	showActivityTemplateBuilder,
	setShowActivityTemplateBuilder,
	activityId,
	complexityId,
	pageType,
	addTemplateSuggestion,
	existingTemplates,
	setSelectedTemplateBuilder,
	stateUpdate,
}) => {
	const tabs = [
		{ id: 1, name: 'Link existing Templates' },
		{ id: 2, name: 'Upload new Templates' },
	];

	const { axiosGetService, axiosPostService, userAlias, axiosPostServiceSwimlane } = useAxios();
	const { track } = useAppInsights();

	const [selectedTab, setSelectedTab] = useState(1);
	const [errorMessage, setErrorMessage] = useState(false);

	const [selectedTemplates, setSelectedTemplates] = useState([]);
	const [templateSearchValue, setTemplateSearchValue] = useState('');
	const [templateList, setTemplateList] = useState([]);
	const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);
	const titleInputRef = useRef(null);
	const [templateTitle, setTemplateTitle] = useState('');
	const [hashtags, setHashtags] = useState([]);
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [hashtagsInput, setHashtagsInput] = useState('');

	const [templateFile, setTemplateFile] = useState(null);
	const [templateFileSize, setTemplateFileSize] = useState(0);
	const [fileTypeError, setFileTypeError] = useState(false);
	const [sizeError, setSizeError] = useState(false);
	const [apiCalled, setApiCalled] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);

	const getTemplateList = async () => {
		setShowSpinner(true);
		await axiosGetService(
			`api/sdmtemplate/getTemplatesForUpdateActivity?searchValue=${templateSearchValue ? encodeURIComponent(templateSearchValue) : 0
			}&complexityId=${complexityId}`
		).then((response) => {
			const templates = response.data
				.map((item) => {
					return {
						id: item.id,
						name: item.name,
						fileLocation: item.fileLocation,
						documentId: item.documentId,
						previewUrl: item.previewUrl,
						fileType: item.fileType,
						inProgress: item.inProgress,
						templateId: item.templateId,
						sampleTemplates: item.sampleTemplates ? item.sampleTemplates : [],
					};
				})
				.filter(
					({ id }) => !existingTemplates.some((template) => id === template.id)
				);
			setTemplateList(templates);
			setShowSpinner(false);
		});
	};

	const submitLinkedTemplate = async () => {
		if (pageType === 'crowdSource') {
			if (selectedTemplates.length > 0) {
				addTemplateSuggestion(selectedTemplates, 'linkTemplate');
				setSelectedTemplates([]);
				toast.info('Your contribution is successfull', {
					position: 'top-right',
				});
			}
		} else if (selectedTemplates.length > 0) {
			setApiCalled(true);
			const postData = {
				id: activityId,
				complexityId: complexityId,
				templateSuggestions: selectedTemplates.map((item) =>
					JSON.stringify({
						...item,
						suggestionType: 'insertion',
						createdAt: new Date(),
						authorId: userAlias,
						title: item.name,
					})
				),
			};
			await axiosPostService(
				`api/sdmactivity/addActivity2TemplateContributor`,
				postData
			).then((response) => {
				if (response.data.status) {
					track(1, 1900, activityId, 'Activity Template Link', {
						templates: selectedTemplates,
					});
					setSelectedTemplates([]);
					toast.info('Your contribution is successfull', {
						position: 'top-right',
					});
				} else {
					toast.error(response.data.errorMsg, {
						position: 'top-right',
					});
				}
			});
		}
		if (selectedTab === 2) {
			setShowActivityTemplateBuilder(false);
		} else {
			setSelectedTab(2);
		}
		setApiCalled(false);
	};

	const submitTemplate = async (templateType, templateURL) => {
		const postData = {
			title: templateTitle,
			hashTags: userAddedHashtags.toString(),
			complexityId: complexityId,
			filePath: templateURL,
			fileType: templateType,
			activityId: activityId,
			contributorEmail: userAlias,
			submitSuggestion: stateUpdate,
		};

		await axiosPostService(
			`api/sdmtemplate/addactivitytemplatecontribution`,
			postData
		).then((response) => {
			if (response.data.status) {
				track(1, 1901, activityId, 'Activity Template Upload', {
					templates: [JSON.parse(response.data.templateSuggestionJson)],
				});
				resetStateForTemplateUpload();
				if (pageType === 'crowdSource') {
					addTemplateSuggestion(
						[JSON.parse(response.data.templateSuggestionJson)],
						'templateUpload'
					);
				}
				if (selectedTemplates.length > 0) {
					submitLinkedTemplate();
				} else {
					toast.info('Your contribution is successfull', {
						position: 'top-right',
					});
					setShowActivityTemplateBuilder(false);
				}
			} else {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			}
			setApiCalled(false);
		});
	};

	const uploadTemplate = async () => {
		if (selectedTab == 2) {
			let response = null;
			if (templateTitle.length > 0) {
				response = await axiosGetService(
					`api/sdmtemplate/checkTemplateNameExists?templateTitle=${templateTitle}`
				);
			}
			if (!templateFile && !templateTitle) {
				toast.error(`Please add title and select a file to submit!`, {
					position: 'top-right',
				});
			} else if (!templateTitle) {
				toast.error(`Please add title!`, {
					position: 'top-right',
				});
			} else if (!templateFile) {
				toast.error(`Select a file to submit!`, {
					position: 'top-right',
				});
			}
			if (templateFile && templateTitle) {
				if (response.data.status === true && response != null) {
					setApiCalled(true);
					const postData = new FormData();
					const fileName = templateFile.name;
					const fileExtension = fileName.split('.').pop();
					postData.append("fileLocation", templateFile, `${templateTitle}.${fileExtension}`);
					await axiosPostServiceSwimlane(`api/sdmtemplate/uploadtemplate`, postData).then(
						(response) => {
							if (response.data) {
								submitTemplate(
									response.data.templateType,
									response.data.templateURL
								);
							} else {
								toast.error('Template upload failed', {
									position: 'top-right',
								});
								setApiCalled(false);
							}
						}
					);
				} else {
					toast.error(response.data.errorMsg, {
						position: "top-right",
					});
				}
			} else {
				titleInputRef.current.focus();
			}

		}
		else {
			if (selectedTemplates.length > 0) {
				submitLinkedTemplate();
			} else {
				toast.error(`Please select templates to submit`, {
					position: 'top-right',
				});
			}
		};
	}

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const getFileSize = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=TemplateFileSize`
		);
		setTemplateFileSize(response.data[0].value * 1048576);
	};

	const removeHashtag = (tag) => {
		const updatedHashtag = hashtags.filter((item) => tag !== item);
		setHashtags(updatedHashtag);
	};

	const updateHashtags = () => {
		setHashtags([...userAddedHashtags, ...hashtags]);
		setHashtagSuggestionInput('');
		setUserAddedHashtags([]);
	};

	const resetStateForTemplateUpload = () => {
		setTemplateTitle('');
		setTemplateFile(null);
		setHashtags([]);
	};

	const tabComponent = {
		1: (
			<LinkTemplate
				selectedTemplates={selectedTemplates}
				setSelectedTemplates={setSelectedTemplates}
				templateSearchValue={templateSearchValue}
				setTemplateSearchValue={setTemplateSearchValue}
				templateList={templateList}
				enableTemplatePreview={enableTemplatePreview}
				setEnableTemplatePreview={setEnableTemplatePreview}
				showSpinner={showSpinner}
			/>
		),
		2: (
			<UploadTemplate
				hashtagSuggestions={hashtagSuggestions}
				setHashtagSuggestions={setHashtagSuggestions}
				searchValue={hashtagsInput}
				setSearchValue={setHashtagsInput}
				existingHashtags={hashtags}
				userAddedHashtags={userAddedHashtags}
				setUserAddedHashtags={setUserAddedHashtags}
				hashtagSuggestionInput={hashtagSuggestionInput}
				setHashtagSuggestionInput={setHashtagSuggestionInput}
				closeHashtagForm={() => {
					setHashtagsInput('');
					setUserAddedHashtags([]);
				}}
				submitHashtags={updateHashtags}
				removeHashtag={removeHashtag}
				templateTitle={templateTitle}
				setTemplateTitle={setTemplateTitle}
				templateFileSize={templateFileSize}
				templateFile={templateFile}
				setTemplateFile={setTemplateFile}
				errorMessage={errorMessage}
				setErrorMessage={setErrorMessage}
				fileTypeError={fileTypeError}
				setFileTypeError={setFileTypeError}
				sizeError={sizeError}
				setSizeError={setSizeError}
				titleInputRef={titleInputRef}
			/>
		),
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getTemplateList();
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [templateSearchValue]);

	useEffect(() => {
		getFileSize();
	}, []);

	useEffect(() => {
		$('#ChromeClose').focus();
	  }, [showActivityTemplateBuilder]);

	return (
		<>
			{apiCalled ? <Spinner /> : <></>}
			<Modal
				className={`phaseCreationRightPopup addTemplate ${enableTemplatePreview || apiCalled ? 'hideTemplateBuilder' : ''
					}`}
				show={showActivityTemplateBuilder}
				onHide={() => {
					// do nothing
				}}
				id="templateSuggetionModal"
			>
				<Modal.Header>
					<Modal.Title id='addtemp'  tabIndex={0} ><h1 className='activityBuilderPopupHeading'>Add Template</h1> </Modal.Title>
					<Icon
						id='ChromeClose'
						iconName="ChromeClose"
						title="Close"
						onClick={() => {
							setShowActivityTemplateBuilder(false);
							if (pageType === 'activityBladeView') {
								setSelectedTemplateBuilder(0);
							}
						}}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setShowActivityTemplateBuilder(false);
								if (pageType === 'activityBladeView') {
									setSelectedTemplateBuilder(0);
								}
							}
						}}
						tabIndex={0}
						role="button"
						aria-label="Close Add Template"
					/>
				</Modal.Header>
				<Modal.Body>
					<div className="methodOwnerMainContainer" aria-label='Add Template' role={'dialog'}>
						<div className="methodOwnerPage">
							<div className="methodOwnerContainer">
								<div className="methodLeftContainer">
									<ul id="addTemplateId" className="methodPhaseLeftMenu">
										{tabs.map((tab) => (
											<li
												key={tab.id}
												onClick={() => setSelectedTab(tab.id)}
												className={selectedTab === tab.id ? 'selectedTab' : ''}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setSelectedTab(tab.id);
													}
												}}
												tabIndex={0}
												aria-label={tab.name}
												title={tab.name}
											>
												<span>{tab.name}</span>
											</li>
										))}
									</ul>
								</div>

								<div id="addTemplateContainerId" className="middleDetailsContainer">
									<div className="methodOwnerMiddleContainer">
										{tabComponent[selectedTab]}
									</div>
								</div>

								<div id="addTemplateSaveContinueId" className="saveContinueDetailsContainer">
									<button
										className="forwardButton"
										title="Suggests submit add template"
										disabled={
											errorMessage || fileTypeError || sizeError || apiCalled
										}
										onClick={() => {
											uploadTemplate();
										}}
										onKeyPress={(e) => {
											if (e.which === 13) {
												uploadTemplate();
											}
										}}
										tabIndex="0"
										aria-label="Submit Add Template"
										role="button"
									>
										<span className="saveContinueButton">Submit</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ActivityTemplateBuilder;
