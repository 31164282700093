import React, { useState, useEffect, useRef } from 'react';
import Spinner from '../../helpers/Spinner';

import { useAxios } from '../../context/AxiosContext';
import TopicsDynamicFilter from './TopicsDynamicFilter';
import { Icon ,button} from '@fluentui/react/lib/Icon';
import { toast } from 'react-toastify';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';

const pagetypeIds = {
	1: { Consulting: 2, Support: 3 },
	2: { Consulting: 9, Support: 10 },
	3: { Consulting: 11, Support: 11 },
	4: { Consulting: 18, Support: 19 },
};
function TopicLandingFilters({
	showFilters,
	setShowFilters,
	selectedTab,
	dynamicFilterTag,
	setDynamicFilterTag,
	setDynamicFilterTagMapping,
	leftMenuSelectedId,
	applyFilters,
	userFilterTag,
	setUserFilterTag,
	filterCleared,
	filterClearedDynamic,
	filtersAllCleared
}) {
	const searchInputRef = useRef(null);
	const [dynamicFilterData, setDynamicFilterData] = useState([]);
	const [dynamicSearchText, setDynamicSearchText] = useState({});
	const [dynamicFilterSearchResult, setDynamicFilterSearchResult] = useState(
		{}
	);
	const {userFilter,setUserFilter} = useLeftMenuCollapse();
	const [dynamicFilterSearchEnable, setDynamicFilterSearchEnable] = useState(
		{}
	);
	const [selectedFilters, setSelectedFilters] = useState({}); 
	const { axiosGetService ,axiosPostService} = useAxios();


	
	const getFilterData = async (userFilter) => {
		const selectedFiltersIds = [];
		const filterIds = Object.keys(dynamicFilterTag);
	
		for (const id of filterIds) {
			if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
				selectedFiltersIds.push(
					`${Number(id)}-${dynamicFilterTag[Number(id)].selectedTagId.toString()}`
				);
			}
		}
	
		const pageTypeId = userFilter.length > 0 ? userFilter[0].pageTypeId : pagetypeIds[leftMenuSelectedId][selectedTab];
		const filterIdsParam = selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : (userFilter.length > 0 ? userFilter[0].filters : 0);
	
	
		await axiosGetService(
			`api/sdmartifactmaster/getdynamicfiltersbypagetype?pageTypeId=${pageTypeId}&isFilter=true&artifactId=0&filterIds=${filterIdsParam}&complexityIds=0&searchValue=0`
		).then((response) => {
	
			const nameMapping = {};
			const filterTag = {};
			const filterTagMapping = {};
			const searchFilterData = {};
			let searchText = {};
	
			const data = response.data.map((item) => ({
				artifactTypeId: Number(item.artifactTypeId), // Convert to number
				artifactTypeName: item.artifactTypeName,
				artifactData: item.artifactData.map(artifact => ({
					...artifact,
					id: Number(artifact.id) // Convert artifact ID to number
				})),
			}));
	
			for (const item of data) {
				const artifactTypeId = item.artifactTypeId;
	
				// Populate nameMapping, filterTag, and searchText
				nameMapping[artifactTypeId] = dynamicFilterSearchEnable[artifactTypeId];
				filterTag[artifactTypeId] = {
					artifactTypeId,
					artifactTypeName: item.artifactTypeName,
					selectedTagId: [],
				};
	
				// Populate filterTagMapping with artifact ID and name mappings
				filterTagMapping[artifactTypeId] = item.artifactData.reduce((acc, tag) => {
					acc[tag.id] = tag.name;
					return acc;
				}, {});
	
				// Set searchText and searchFilterData based on dynamicSearchText
				searchText[artifactTypeId] = dynamicSearchText[artifactTypeId] ?? '';
				searchFilterData[artifactTypeId] = dynamicSearchText[artifactTypeId]
					? item.artifactData.filter((artifact) =>
						artifact.name.toLowerCase().includes(dynamicSearchText[artifactTypeId].toLowerCase())
					)
					: [];
			}
	
			// Update state with processed mappings and data
			setDynamicFilterSearchResult(searchFilterData);
			setDynamicSearchText(searchText);
			setDynamicFilterTagMapping({ ...filterTagMapping }); // Ensure filterTagMapping is passed correctly
			setDynamicFilterSearchEnable(nameMapping);
			setDynamicFilterData(data);
	
			const newDynamicFilterTag = { ...dynamicFilterTag };
	
			// If userFilter has mappings, update newDynamicFilterTag based on it
			if (userFilter.length > 0 && userFilter[0].filters) {
				const userFilterMappings = userFilter[0].filters.split('|').map((filter) => {
					const [artifactTypeId, selectedTagIds] = filter.split('-');
					return {
						artifactTypeId: Number(artifactTypeId),
						selectedTagIds: selectedTagIds ? selectedTagIds.split(',').map(Number) : [],
					};
				});
	
				// Update newDynamicFilterTag based on userFilterMappings
				userFilterMappings.forEach(({ artifactTypeId, selectedTagIds }) => {
					const artifactTypeData = data.find((d) => d.artifactTypeId === artifactTypeId);
					
					if (artifactTypeData) {
						newDynamicFilterTag[artifactTypeId] = {
							artifactTypeId,
							artifactTypeName: artifactTypeData.artifactTypeName,
							selectedTagId: selectedTagIds,
						};
					}
				});
			}
	
			// Only update dynamicFilterTag if it has changed
			if (JSON.stringify(dynamicFilterTag) !== JSON.stringify(newDynamicFilterTag)) {
				setDynamicFilterTag(newDynamicFilterTag);
			}
		});
	};
	
	  
	const searchForArtifact = (searchText, artifactTypeId, artifactData) => {
		if (searchText.trim().length > 0) {
			const filteredRoles = artifactData.filter((item) =>
				item.name.toLowerCase().includes(searchText.toLowerCase())
			);
			setDynamicFilterSearchResult((prevState) => ({
				...prevState,
				[artifactTypeId]: filteredRoles,
			}));
		}
		setDynamicSearchText((prevState) => ({
			...prevState,
			[artifactTypeId]: searchText.trim().length > 0 ? searchText : '',
		}));
	};


	useEffect(() => {
		if (selectedTab && leftMenuSelectedId) {
			const fetchData = async () => {
				try {
					const userSettingsResponse = await axiosGetService(`api/sdmusersettings/getusersettings/${pagetypeIds[leftMenuSelectedId][selectedTab]}/false/0`);
					setUserFilterTag(userSettingsResponse?.data || []);
					await getFilterData(userSettingsResponse?.data || []);
				} catch (error) {
					console.error("Error fetching user settings or filter data:", error);
				}
			};
			fetchData();
		}
	}, [selectedTab, leftMenuSelectedId]);

	useEffect(() => {
		const checkAndTriggerApi = async () => {
			if (filtersAllCleared) {
				try {
					// Trigger your API call here
					getFilterData([]);
					
				} catch (error) {
					console.error("Error fetching child data:", error);
				}
			}
		};
	
		checkAndTriggerApi(); // Call the function
	
		// Optional: Cleanup function to avoid any potential memory leaks
	
	}, [filtersAllCleared]);
	

	const handleApplyFilters = async (e) => {
		e.preventDefault();
		await getFilterData(dynamicFilterTag); // Apply filters on button click
		setUserFilterTag([]);
		await applyFilters();
	  };
	//   useEffect(() => {
	// 	if (userFilterTag.length === 0) {
	// 		setUserFilterTag([]);
	// 	 getFilterData(); 
	// 	}
	// }, [userFilterTag]);

	  

	  const setDefaultFilter = async (e) => {
		e.preventDefault();
		await getFilterData(dynamicFilterTag); 
		await applyFilters();
		try {
			const selectedFiltersIds = [];
		const filterIds = Object.keys(dynamicFilterTag);
		for (const id of filterIds) {
			if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
				selectedFiltersIds.push(
					`${Number(id)}` +
						'-' +
						dynamicFilterTag[Number(id)].selectedTagId.toString()
				);
			}
		}
			const postData = {
				"pageTypeId":pagetypeIds[leftMenuSelectedId][selectedTab],
				"filters": selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : 0,
				"complexities": "0",
				"userSettingType": "1"
			};
			await axiosPostService(`/api/sdmusersettings/insertusersettings`, postData).then((response) => {
			if (response.data) {
		   setShowFilters(!showFilters);
			//   setDynamicFilterTag(response.data);
			  toast.info('You have successfully saved filters.', {
				position: 'top-right',
			  });
			} else {
			  toast.error(response.data.errorMessage, {
				position: 'top-right',
			  });
			}
		  })
		} catch (e) {
		  console.log(e);
		};
	  }
	useEffect(() => {
		const dynamicRefValue = Object.values(dynamicFilterSearchEnable);
		if (dynamicRefValue.includes(true) && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [dynamicFilterSearchEnable]);

	return (
		<div
			id="filtersRightPanel"
			className={!showFilters ? 'topicLandingFilters' : ''}
		>
			<div className="filterPanelHeader">
				<h5>Filters</h5>
				<Icon
					iconName="cancel"
					onClick={() => {
						setShowFilters(false);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setShowFilters(false);
						}
					}}
					title="Close"
					tabIndex={0}
					aria-label="Click here to close filters panel"
					role="button"
				/>
			</div>
			<div className="filtersPanelBodyLatest">
				<>
					{dynamicFilterData.map((item) => (
						<div className="filtersCategoryPanel" key={item.artifactTypeId}>
							{item.artifactData.length > 5 ? (
								<>
									{dynamicFilterSearchEnable[item.artifactTypeId] ? (
										<div className="rolesSearch">
											<input
												type="text"
												placeholder={`Search for  ${item.artifactTypeName}`}
												value={dynamicSearchText[item.artifactTypeId]}
												onChange={(e) => {
													e.persist();
													searchForArtifact(
														e.target.value,
														item.artifactTypeId,
														item.artifactData
													);
												}}
												role="input"
												ref={searchInputRef}
												aria-expanded={
													dynamicFilterSearchEnable[item.artifactTypeId]
														? 'true'
														: 'false'
												}
											/>
											<Icon
												className="searchIcon"
												iconName="cancel"
												onClick={() => {
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: false,
													});
													setDynamicSearchText({
														...dynamicSearchText,
														[item.artifactTypeId]: '',
													});
												}}
												onKeyPress={(e) => {
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: false,
													});
													setDynamicSearchText({
														...dynamicSearchText,
														[item.artifactTypeId]: '',
													});
												}}
												tabIndex={0}
												aria-label="cancel search"
												role="button"
											/>
										</div>
									) : (
										<div className="filtersearch">
											<h6>{item.artifactTypeName}</h6>
											<span
												className="searchIcon"
												onClick={() =>
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: true,
													})
												}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setDynamicFilterSearchEnable({
															...dynamicFilterSearchEnable,
															[item.artifactTypeId]: true,
														});
													}
												}}
												tabIndex={0}
												aria-expanded={
													dynamicFilterSearchEnable[item.artifactTypeId]
														? 'true'
														: 'false'
												}
												aria-label={`click to search ${item.artifactTypeName}`}
												role="button"
											>
												<Icon iconName="search" className="searchIcon" />
											</span>
										</div>
									)}
								</>
							) : (
								<h6>{item.artifactTypeName}</h6>
							)}
							<TopicsDynamicFilter
								data={
									dynamicSearchText[item.artifactTypeId]
										? dynamicFilterSearchResult[item.artifactTypeId]
										: item.artifactData
								}
								value={dynamicFilterTag}
								artifactTypeName={item.artifactTypeName}
								setValue={setDynamicFilterTag}
								artifactTypeId={item.artifactTypeId}
							/>
						</div>
					))}
				</>
			</div>
			<div className="filterPanelFooter">
          <button
            type="button"
            className="btn btn-primary"
            tabIndex={0}
			onClick={handleApplyFilters}
          >
            Apply
          </button>
          <button
            type="button"
            className="btn btn-default"
            tabIndex={0}
            onClick={setDefaultFilter}
          >
            Set as Default
          </button>
        </div>
		</div>
	);
}

export default TopicLandingFilters;
