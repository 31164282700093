import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import $ from 'jquery';

const RatingConfirmationModal = ({
	setEnableRating,
	showRatingConfirmationPopUp,
	setShowRatingConfirmationPopUp,
}) => {
	$(function () {
		function handleKeyboardFocus(event) {
			console.log("biiiii",event.key);
			if (event.key === 'Tab') {
				if ($('#noRatingtemplate').is(':focus') && !event.shiftKey) {
					event.preventDefault();
					$('#yesRatingtemplate').focus();
				}
		  	}
			if(event.key === 'Escape'){
				$('.multi-widget-selected').focus();
			}
		}
		$(document).off('keydown');
		$(document).on('keydown', handleKeyboardFocus);
	})
	return (
		<Modal
			className="templateRejectModalPopUp"
			id="tempalteRejectModal"
			show={showRatingConfirmationPopUp}
			onHide={() => {
				setShowRatingConfirmationPopUp(false);
			}}
			size="md"
			centered
			tabIndex={-1}
		>
			<Modal.Body tabIndex={-1}  role="dialog" aria-modal="true">
				<p tabIndex={-1}>
					You have already rated this tutorial. Do you want to rate it again?
				</p>
				<Button
					id="noRatingtemplate"
					tabIndex={0}
					title="No"
					role='presentation'
					aria-label="dialog button No"
					className="noRating"
					onClick={() => {
						setShowRatingConfirmationPopUp(false);
					}}
				>
					No
				</Button>

				<Button
					id="yesRatingtemplate"
					tabIndex={1}
					aria-hidden='true'
					className="reRating"
					onClick={() => {
						setEnableRating(true);
						setShowRatingConfirmationPopUp(false);
					}}
					onKeyDown={(e)=>{
						if(e.key === 'Enter' || e.key === ' '){
							setEnableRating(false);
							setShowRatingConfirmationPopUp(false);
						}
				
					}}				
				>
					Yes
				</Button>
			</Modal.Body>
		</Modal>
	);
};

export default RatingConfirmationModal;
