function positiveFeedback() {
    // Custom function required to give positive feedback on the response.
    // i.e like button.
    console.log("PositiveFeedback")
}
function negativeFeedback() {
    // Custom function required to give negative feedback on the response.
    // i.e dislike button.
    console.log("negativeFeedback")
}

const trackCustomEvents = () =>{
    console.log("Track Events");
}

const closeMCAPSBot = () => {
    console.log("Close Copilot")
}


function yourSupportTicketFunction() {
    // To create support Incident this function is required.
    // And to create a ticket IRIS Copilot is required.
    console.log("Support ticket");
}

const ocvFeedback = () => {
    console.log("OcvFeedback")
}
const getBearerTokenCallback = () => {
    console.log("token")
}

export const CopilotConfig = {
    applicationName: "Project Management Copilot",                             // application name refers to the copilot application name.
    userName: "User1",    // Logged user name
    // supportTicket:{yourSupportTicketFunction},                    //To create a support incident function callback
    positiveFeedback: positiveFeedback ,                             // To give the positive feedback callback function
    negativeFeedback: negativeFeedback ,                             // To give the negative feedback callback function
    closeBot: closeMCAPSBot,                                // To give the close the copilot callback function
    isVisible : true,                                        // To open/close copilot using the state
    isToggleVisible :  false,                                        // To show toggle for day/night mode.
    isTypewritingOn :  false,                                       // 
    image : "",                                          // Logo of the copilot
    trackCustomEvents :  trackCustomEvents,                            // To give the callback for track custom event in app insight
    apiUrls : { questionApi: "" },                                      // Copilot Api Url
    msalInstance : "",                                               //
    appInsightConfig : { instrumentationKey: "" },   // To give the instrumentation key for configuration
    botWelcomeMessage : "Ready to explore? Select one of the suggestions below to get started...",
    // To give the welcome message on start of copilot
    botSuggestionMessage :  ["How does SDMPlus help with project governance?","Where is the template for kickoff presentation?","How is any engagement tracked from initiation to closure?","What are the tools associated with handling risks in an engagement?","What are the phases in Sure Step 365?"],
    // To give the suggestion of questions to users in copilot.
    getBearerTokenCallback : getBearerTokenCallback,
    ocvData : ocvFeedback,
    pageInfo : window.location.href ,
    VirtuosoCopilotErrorMessage : "Sorry the Copilot service is down right now. Please try again later.",
    // To give erro message on the failure of copilot.
    textBoxLength : 4000,
    isCreateIncidentButtonVisible :  false,                           // To show button to create the incident
    isCopyButtonVisible :  true,                                      // To show the copy button
    isVersionVisible :  true,

}