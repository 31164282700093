import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { toast } from 'react-toastify';

import TutorialsDynamicFilter from './TutorialsDynamicFilter';
import { useAxios } from '../../context/AxiosContext';

const TutorialsFilter = ({
	showFilters,
	setShowFilters,
	dynamicFilterTag,
	setDynamicFilterTag,
	setDynamicFilterTagMapping,
	applyFilters,
	setUserFilterTag,
	userFilter

}) => {
	const { axiosGetService,axiosPostService } = useAxios();
	const searchInputRef = useRef(null);

	const [dynamicFilterData, setDynamicFilterData] = useState([]);
	const [dynamicSearchText, setDynamicSearchText] = useState({});
	const [dynamicFilterSearchResult, setDynamicFilterSearchResult] = useState(
		{}
	);
	const [dynamicFilterSearchEnable, setDynamicFilterSearchEnable] = useState(
		{}
	);

	const getDynamicsFilterData = async () => {
		await axiosGetService(
			`api/sdmartifactmaster/getdynamicfiltersbypagetype?pageTypeId=11&isFilter=true&artifactId=0&filterIds=0&complexityIds=0&searchValue=0`
		).then((response) => {
			const nameMapping = {};
			const filterTag = {};
			const filterTagMapping = {};
			const serachFilterData = {};
			let searchText = {};
			const data = response.data.map((item) => {
				return {
					artifactTypeId: item.artifactTypeId,
					artifactTypeName: item.artifactTypeName,
					artifactData: item.artifactData,
				};
			});
			for (const id in data) {
				nameMapping[data[id].artifactTypeName] = false;
				filterTag[data[id].artifactTypeId] = {
					artifactTypeId: data[id].artifactTypeId,
					artifactTypeName: data[id].artifactTypeName,
					selectedTagId: [],
				};
				const tag = data[id].artifactData;
				let artifactTag = {};
				for (const tagId in tag) {
					artifactTag = {
						...artifactTag,
						[tag[tagId].id]: tag[tagId].name,
					};
				}
				filterTagMapping[data[id].artifactTypeId] = artifactTag;
				searchText = {
					...searchText,
					[data[id].artifactTypeId]: '',
				};
				serachFilterData[data[id].artifactTypeId] = [];
			}
			setDynamicFilterSearchResult({ serachFilterData });
			setDynamicSearchText({ ...searchText });
			setDynamicFilterTagMapping({ ...filterTagMapping });
			setDynamicFilterSearchEnable({ ...nameMapping });
			setDynamicFilterData([...data]);

			 const newDynamicFilterTag = { ...dynamicFilterTag };
	  
			 if (userFilter.length > 0 && userFilter[0].filters) {
			   const userFilterMappings = userFilter[0].filters.split('|').map((filter) => {
				 const [artifactTypeId, selectedTagIds] = filter.split('-');
				 return {
				   artifactTypeId: Number(artifactTypeId),
				   selectedTagIds: selectedTagIds ? selectedTagIds.split(',').map(Number) : [],
				 };
			   });
		 
		 
			   userFilterMappings.forEach(({ artifactTypeId, selectedTagIds }) => {
				 const artifactTypeData = data.find((d) => d.artifactTypeId === artifactTypeId);
				 
				 if (artifactTypeData) {
				   newDynamicFilterTag[artifactTypeId] = {
					 artifactTypeId,
					 artifactTypeName: artifactTypeData.artifactTypeName,
					 selectedTagId: selectedTagIds,
				   };
				 }
			   });
			 }
		 
			 if (JSON.stringify(dynamicFilterTag) !== JSON.stringify(newDynamicFilterTag)) {
			   setDynamicFilterTag(newDynamicFilterTag);
			 }
		});
	};

	const searchForArtifact = (searchText, artifactTypeId, artifactData) => {
		if (searchText.trim().length > 0) {
			const filteredRoles = artifactData.filter((item) =>
				item.name.toLowerCase().includes(searchText.toLowerCase())
			);
			setDynamicFilterSearchResult((prevState) => ({
				...prevState,
				[artifactTypeId]: filteredRoles,
			}));
		}
		setDynamicSearchText((prevState) => ({
			...prevState,
			[artifactTypeId]: searchText.trim().length > 0 ? searchText : '',
		}));
	};
	
	const setDefaultFilter = async (e) => {
		e.preventDefault();
		await applyFilters();
		try {
			const selectedFiltersIds = [];
		const filterIds = Object.keys(dynamicFilterTag);
		for (const id of filterIds) {
			if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
				selectedFiltersIds.push(
					`${Number(id)}` +
						'-' +
						dynamicFilterTag[Number(id)].selectedTagId.toString()
				);
			}
		}
			const postData = {
				"pageTypeId":"11",
				"filters": selectedFiltersIds.length > 0 ? selectedFiltersIds.join('|') : 0,
				"complexities": "0",
				"userSettingType": "1"
			};
			await axiosPostService(`/api/sdmusersettings/insertusersettings`, postData).then((response) => {
			if (response.data) {
			  setShowFilters(!showFilters);
			  toast.info('You have successfully saved filters.', {
				position: 'top-right',
			  });
			} else {
			  toast.error(response.data.errorMessage, {
				position: 'top-right',
			  });
			}
		  })
		} catch (e) {
		  console.log(e);
		};
	  }
	useEffect(() => {
		getDynamicsFilterData();
	},[userFilter]);
	const handleApplyFilters = async () => {
		await applyFilters();
	  };

	useEffect(() => {
		const dynamicRefValue = Object.values(dynamicFilterSearchEnable);
		if (dynamicRefValue.includes(true) && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [dynamicFilterSearchEnable]);

	return (
		<div
			id="filtersRightPanel"
			className={!showFilters ? 'topicLandingFilters' : ''}
		>
			<div className="filterPanelHeader">
				<h5>Filters</h5>
				<Icon
					iconName="cancel"
					onClick={() => {
						setShowFilters(false);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setShowFilters(false);
						}
					}}
					tabIndex={0}
					role="button"
					aria-label="Close Filters"
				/>
			</div>
			<div className="filtersPanelBodyLatest">
				{dynamicFilterData.map((item) => (
					<div className="filtersCategoryPanel" key={item.artifactTypeId}>
						{item.artifactData.length > 5 ? (
							<>
								{dynamicFilterSearchEnable[item.artifactTypeId] ? (
									<div className="rolesSearch">
										<input
											type="text"
											placeholder={`Search for  ${item.artifactTypeName}`}
											value={dynamicSearchText[item.artifactTypeId]}
											onChange={(e) => {
												e.persist();
												searchForArtifact(
													e.target.value,
													item.artifactTypeId,
													item.artifactData
												);
											}}
											ref={searchInputRef}
										/>
										<Icon
											className="searchIcon"
											iconName="cancel"
											onClick={() => {
												setDynamicFilterSearchEnable({
													...dynamicFilterSearchEnable,
													[item.artifactTypeId]: false,
												});
												setDynamicSearchText({
													...dynamicSearchText,
													[item.artifactTypeId]: '',
												});
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: false,
													});
													setDynamicSearchText({
														...dynamicSearchText,
														[item.artifactTypeId]: '',
													});
												}
											}}
											tabIndex={0}
											role="button"
											aria-label="Cancel Search Filter"
										/>
									</div>
								) : (
									<div className="filtersearch">
										<h6>{item.artifactTypeName}</h6>
										<span
											className="searchIcon"
											onClick={() =>
												setDynamicFilterSearchEnable({
													...dynamicFilterSearchEnable,
													[item.artifactTypeId]: true,
												})
											}
											onKeyPress={(e) => {
												if (e.which === 13) {
													setDynamicFilterSearchEnable({
														...dynamicFilterSearchEnable,
														[item.artifactTypeId]: true,
													});
												}
											}}
											tabIndex={0}
											role="button"
											aria-label={`Click to search ${item.artifactTypeName} `}
										>
											<Icon iconName="search" className="searchIcon" />
										</span>
									</div>
								)}
							</>
						) : (
							<h6>{item.artifactTypeName}</h6>
						)}
						<TutorialsDynamicFilter
							data={
								dynamicSearchText[item.artifactTypeId]
									? dynamicFilterSearchResult[item.artifactTypeId]
									: item.artifactData
							}
							value={dynamicFilterTag}
							artifactTypeName={item.artifactTypeName}
							setValue={setDynamicFilterTag}
							artifactTypeId={item.artifactTypeId}
						/>
					</div>
				))}
			</div>
			<div className="filterPanelFooter">
          <button
            type="button"
            className="btn btn-primary"
            tabIndex={0}
			onClick={handleApplyFilters}
          >
            Apply
          </button>
          <button
            type="button"
            className="btn btn-default"
            tabIndex={0}
            onClick={setDefaultFilter}
          >
            Set as Default
          </button>
        </div>
		</div>
	);
};

export default TutorialsFilter;
