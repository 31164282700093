import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import CrowdSourcingSpinner from '../../helpers/CrowdSourcingSpinner';
import { templateFileTypes } from '../../static/TemplateIcons';
import { Icon } from '@fluentui/react/lib/Icon';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { artifactMapData } from '../../static/ArtifactMapData';
import HashtagSuggestions from '../HashtagSuggestions';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	padding: '18px 20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#F0F2F8',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

function TemplateContributionModal({
	enableTemplateContributionModal,
	closeModal,
	data,
	existingHashtags,
	setExistingHashtags,
}) {
	const { track } = useAppInsights();
	const [messagesType, setMessagesType] = useState({});
	const { getNotifications } = useLeftMenuCollapse();
	const { axiosGetService, axiosPostService, userAlias, axiosPostServiceSwimlane } = useAxios();
	const [fileSize, setFileSize] = useState(0);

	const {
		getRootProps,
		getInputProps,
		open,
		acceptedFiles,
		isDragActive,
		isDragAccept,
		isDragReject,
		rejectedFiles,
	} = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: false,
		maxSize: fileSize,
	});

	const [file, setFile] = useState(null);
	const [comments, setComments] = useState('');
	const [enableDiscardPopUp, setEnableDiscardPopup] = useState(false);
	const [inputError, setInputError] = useState(false);
	const [fileUploaded, setFileUploaded] = useState(false);
	const [fileUploadStatus, setFileUploadStatus] = useState(null);
	const [fileTypeError, setFileTypeError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [sizeError, setSizeError] = useState(false);
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [updateApiCall, setUpdateApiCall] = useState(false);

	const getFileSize = async () => {
		const response = await axiosGetService(
			`api/sdmsettings?sdmKey=TemplateFileSize`
		);
		setFileSize(response.data[0].value * 1048576);
	};

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const updateHashtags = async () => {
		if (userAddedHashtags.length > 0) {
			const postData = {
				artifactTypeId: artifactMapData['templates'],
				artifactId: data.id,
				hashtags: [...userAddedHashtags, ...existingHashtags].toString(),
			};
			await axiosPostService(`api/sdmhashtags/add`, postData).then(
				(response) => {
					if (response.data.status) {
						track(1, 1202, data.id, data.title, {
							hashtags: userAddedHashtags.toString(),
						});
						setExistingHashtags([...userAddedHashtags, ...existingHashtags]);
						setHashtagsInput('');
						setHashtagSuggestionInput('');
						setUserAddedHashtags([]);
					} else {
						toast.error(response.data.errorMessage, {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
				}
			);
		}
	};

	const handleSubmit = async () => {
		const regexp = /^[a-zA-Z0-9\s\.()\d\w,:;""''_\-\*]+$/;
		setFileTypeError(false);
		if (file === null || comments.length === 0) {
			setSizeError(false);
			setInputError(true);
		} else if (!regexp.test(comments)) {
			setFileUploadStatus('inputerror');
			setErrorMessage('Input string is not in correct Format');
		} else {
			setInputError(false);
			const formData = new FormData();
			formData.append('fileLocation', file);
			setFileUploadStatus('uploading');
			const templateUrlResponse = await axiosPostServiceSwimlane(
				`api/sdmtemplate/uploadtemplate`,
				formData
			);

			if (templateUrlResponse.data) {
				if (templateUrlResponse.data.status === 'false') {
					setFileUploadStatus('inputerror');
					setErrorMessage(templateUrlResponse.data.errorMessage);
				} else {
					const postBody = {
						templateId: data.id,
						complexityId: data.complexityId,
						stateId: 1,
						filePath: templateUrlResponse.data.templateURL,
						actionTypeId: 1,
						contributorComments: comments,
						isLocalized: false,
						languageId: 15,
						countryId: 102,
						title: templateUrlResponse.data.fileName,
					};
					await axiosPostService(
						'api/sdmtemplate/InsertContributorAsync',
						postBody
					)
						.then((response) => {
							if (response.data.status === 'false') {
								setFileUploadStatus('inputerror');
								setErrorMessage(response.data.errorMessage);
							} else {
								setFileUploaded(true);
								setFileUploadStatus(null);
								getNotifications();
							}
						})
						.catch((error) => {
							setFileUploadStatus('error');
						});
				}
			} else {
				setFileUploadStatus('error');
			}
		}
	};
	const getMessage = async () => {
		const message = {};
		await axiosGetService(
			`/api/sdmuser/getmessagevalue?messageName=contributionSubmissionMessage&title=${encodeURIComponent(
				data.title
			)}`
		).then((response) => {
			message['contributionSubmissionMessage'] = response.data;
		});
		setMessagesType(message);
	};

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject]
	);

	const filterFileType = (file) => {
		if (file.name.split(".").length > 2) {
			setFileTypeError(true);
		}
		else {
			const selectedFileType = file.name.split('.').slice(-1).pop().toLowerCase();
			if (templateFileTypes.includes(selectedFileType)) {
				setFile(file);
				setSizeError(false);
				setFileTypeError(false);
				setInputError(false);
				setErrorMessage(null);
			} else {
				setFile(null);
				setFileTypeError(true);
				setInputError(false);
				setSizeError(false);
				setErrorMessage(null);
			}
		}
	};

	useEffect(() => {
		if (data) {
			getMessage();
		}
	}, [data]);

	useEffect(() => {
		if (acceptedFiles.length == 0) {
			setFile(null);
		} else {
			filterFileType(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	useEffect(() => {
		if (rejectedFiles.length && rejectedFiles[0].size > fileSize) {
			setSizeError(true);
			setFileTypeError(false);
			setInputError(false);
			setErrorMessage(null);
		} else if (rejectedFiles.length) {
			filterFileType(rejectedFiles[0]);
		}
	}, [rejectedFiles]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	useEffect(() => {
		getFileSize();
	}, []);

	if (fileUploadStatus === 'uploading') {
		return <CrowdSourcingSpinner />;
	}

	return (
		<Modal
			dialogClassName={
				fileUploaded || enableDiscardPopUp
					? 'templateFeedbackPopUp'
					: 'templateModal'
			}
			show={enableTemplateContributionModal}
			onHide={() => {
				if (fileUploadStatus !== 'uploading') {
					closeModal();
				}
			}}
			centered
			size="sm"
		>
			{enableTemplateContributionModal ? (
				fileUploaded ? (
					<>
						<Modal.Body>
							<p>{messagesType['contributionSubmissionMessage']}</p>
							<button
								title="OK"
								className="feedbackSubmittedOk"
								tabIndex={0}
								onClick={closeModal}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
							>
								OK
							</button>
						</Modal.Body>
					</>
				) : enableDiscardPopUp ? (
					<Modal.Body className="templateDiscardPopup">
						<p>Your suggested changes will be lost</p>
						<p>Are you sure you want to discard this?</p>
						<div className="discardPopUpButtons">
							<button
								title="No"
								className="templateDiscardButton"
								tabIndex={0}
								onClick={() => setEnableDiscardPopup(false)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										setEnableDiscardPopup(false);
									}
								}}
							>
								No
							</button>
							<button
								title="Yes"
								className="templateSubmitButton"
								tabIndex={0}
								onClick={closeModal}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
							>
								Yes
							</button>
						</div>
					</Modal.Body>
				) : (
					<>
						<Modal.Header className="sampleSubmitTemplatePopUp">
							<div className="templateContributionSelection">
								<span className="settingsLabel">Contribute Template</span>
							</div>
							<Icon
								iconName="Cancel"
								onClick={closeModal}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										closeModal();
									}
								}}
							/>
						</Modal.Header>
						<Modal.Body className="TemplateContributionPopUp">
							<div
								className={
									fileUploadStatus === 'uploading'
										? 'sampleSubmitTemplatePopUp noClickEvents'
										: 'sampleSubmitTemplatePopUp'
								}
							>
								<div className="detailsOfSampleTemplate">
									<p>
										<span className="bold">Template:&nbsp;</span>
										<span>{data.title}</span>
									</p>
									<p>
										<span className="bold">Phase Name:&nbsp; </span>
										<span>{data.phaseName}</span>
									</p>

									<p>
										<span className="bold">
											Complexities Applicable:&nbsp;{' '}
										</span>
										<span>{data.complexityList}</span>

										<span className="note">
											&nbsp;&nbsp;(**Note - An approver might change the
											applicable complexities for a template if needed)
										</span>
									</p>
								</div>
								<div className="container">
									<div {...getRootProps({ style })}>
										<h6>Choose File*</h6>
										<input {...getInputProps()} />
										{file === null || file === 'uploaded' ? (
											<div>
												<span>Drag and drop a file here or</span>
												<button
													title="Choose File"
													className="dragDropButton"
													type="button"
													onClick={open}
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															open();
														}
													}}
												>
													Choose File
												</button>
											</div>
										) : (
											<div className="templateContributionFileNameContainer">
												<div
													key={file.path}
													className="templateContributionFileName"
												>
													{file.path}
													<button
														onClick={() => setFile(null)}
														tabIndex={0}
														onKeyPress={(e) => {
															if (e.which === 13) {
																setFile(null);
															}
														}}
														title="Remove Uploaded File"
														role="Button"
														aria-label="Remove"
													>
														X
													</button>
												</div>
												<button
													title="Choose File"
													className="dragDropButton"
													type="button"
													onClick={open}
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															open();
														}
													}}
												>
													Change
												</button>
											</div>
										)}
									</div>
									<div className="detOfContributionTemplate">
										<h6>Add comments about changes made*</h6>
										<textarea
											placeholder="Type here"
											onChange={(e) => setComments(e.target.value)}
											value={comments}
											maxLength={280}
											minLength={1}
											title="comments"
										/>
										<span className="contributionCommentInfo">
											{comments.length}/280 Characters left
										</span>
									</div>
								</div>
								<div className="detOfContributionTemplateforHashtags">
									<h6>Add Hashtags</h6>

									<HashtagSuggestions
										className="templateHashtagsInput"
										enableDropdown={true}
										data={hashtagSuggestions}
										setUpdateApiCall={setUpdateApiCall}
										setHashtagSuggestions={setHashtagSuggestions}
										searchValue={hashtagsInput}
										setSearchValue={setHashtagsInput}
										existingHashtags={existingHashtags}
										userAddedHashtags={userAddedHashtags}
										setUserAddedHashtags={setUserAddedHashtags}
										hashtagSuggestionInput={hashtagSuggestionInput}
										setHashtagSuggestionInput={setHashtagSuggestionInput}
										closeHashtagForm={() => {
											setHashtagsInput('');
											setUserAddedHashtags([]);
										}}
										submitHashtags={updateHashtags}
										autoFocus={false}
										showSubmitButton={true}
									/>
									<p className="bold">
										*Hashtags will be added directly to the base template
									</p>
									{existingHashtags.length > 0 ? (
										<div className="hashTagsContainer _existingHashTagsOfActivity">
											{existingHashtags.map((item) => (
												<span key={item}>{item}</span>
											))}
										</div>
									) : (
										<></>
									)}
								</div>
								<div className="templateContributionErrorMsgContainer">
									{fileTypeError ? (
										<span className="errorMsg">
											Invalid file Format. Only "xlsx, pptx, potx, ppt, pdf,
											doc, docx, dotx, xlsm, msg, mpp, mpt" are
											allowed.
										</span>
									) : (
										<></>
									)}
									{sizeError ? (
										<span className="errorMsg">
											Uploaded file size exceeds {fileSize / 1048576} MB
										</span>
									) : (
										<></>
									)}

									{inputError ? (
										<span className="errorMsg">
											Choose a template and add comments to submit
										</span>
									) : (
										<></>
									)}
									{fileUploadStatus === 'error' ? (
										<>
											<br />
											<span className="errorMsg">
												Error occurred when uploading the file
											</span>
										</>
									) : (
										<></>
									)}
									{fileUploadStatus === 'inputerror' ? (
										<>
											<br />
											<span className="errorMsg">{errorMessage}</span>
										</>
									) : (
										<></>
									)}
								</div>
								<div className="templateSubmitDiscard">
									<button
										title="Discard"
										className="templateDiscardButton"
										onClick={() => setEnableDiscardPopup(true)}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setEnableDiscardPopup(true);
											}
										}}
									>
										Discard
									</button>
									<button
										title="Submit"
										className="templateSubmitButton"
										onClick={handleSubmit}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												handleSubmit();
											}
										}}
									>
										Submit
									</button>
								</div>
							</div>
						</Modal.Body>
					</>
				)
			) : (
				<></>
			)}
		</Modal>
	);
}
export default TemplateContributionModal;
