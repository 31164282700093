import React, { useState, useEffect } from "react";
import { Icon } from "@fluentui/react";
import { useAxios } from "../../context/AxiosContext";

import MethodEditor from "./Editor";

import UserSelectionDropdown from "../PhaseLeftMenu/PhaseAddition/UserSelectionDropdown";
import OwnerSelectionDropdown from "../TopicsBuilder/OwnerSelectionDropdown";
import Dropdown from "../Dropdown";
import MultiSelectDropdown from "./MultiSelectDropdown";

const SolutionMethodForm = ({
  methodName,
  setMethodName,
  methodType,
  setMethodType,
  parentMethod,
  setParentMethod,
  acronym,
  setAcronym,
  diagram,
  setDiagram,
  shortName,
  setShortName,
  isProcessStep,
  setIsProcessStep,
  enableComplexity,
  setEnableComplexity,
  isRetired,
  setIsRetired,
  isMerged,
  setIsMerged,
  isVisible,
  setIsVisible,
  complexityCritical,
  setComplexityCritical,
  complexityHigh,
  setComplexityHigh,
  complexityMedium,
  setComplexityMedium,
  complexityLow,
  setComplexityLow,
  owner,
  setOwner,
  ownerValue,
  setOwnerValue,
  coOwners,
  setCoOwners,
  coOwnerValue,
  setCoOwnerValue,
  approvers,
  setApprovers,
  approverValue,
  setApproverValue,
  reviewers,
  setReviewers,
  reviewerValue,
  setReviewerValue,
  description,
  setDescription,
  methodData,
  emailFound,
  setEmailFound,
  usersList,
  setUsersList,
  error,
  setError,
  removeCoOwner,
  isShowInLeftMenu,
  setIsShowInLeftMenu,
  newMethod,
  projectComplexity,
  setProjectComplexity,
  dynamicComplexities,
  setDynamicComplexities,
  selectedComplexities,
  setSelectedComplexities,
  pc,
  setPc,
  complexities,
  selectedGovernanceId,
  setSelectedGovernanceId,
  defaultCompDD,
  setDefaultCompDD,
  defCompId,
  setDefCompId,
  defCompData,
  setDefCompData,
  defComp,
  setTempPc
  // complexityPDoc,
  // setComplexityPDoc,
}) => {
  const { axiosGetService } = useAxios();
  const defaultComplexity = { id: 3, isSelected: false, name: 'None' };
  const [complexityTag, setComplexityTag] = useState();
  const getComplexityTag = async () => {
		await axiosGetService(
			`api/sdmsettings?sdmKey=DefaultComplexityTag`
		).then((response) => {
			const obj = {
				id: response.data[0].value.split(",").at(0),
				name: response.data[0].value.split(",").at(1).toString()
			}
			setComplexityTag(obj);
		});
	};
	
	useEffect(() => {
		getComplexityTag();
	}, []);
  const handleSetComplexity = (value, complexityName) => {
    if (dynamicComplexities == '') {
      let c = pc.map((item) => item.complexityGovernanceList)
      let d = c[0].map((item) => {
        if (item.id === value.id) {
          item.isSelected = true
        } else {
          item.isSelected = false
        }
        return item
      })
      const copiedProjectComplexity = JSON.parse(JSON.stringify(pc));
      const criticalItem = copiedProjectComplexity.find(item => item.name === complexityName);
      criticalItem.complexityGovernanceList = d;
      setPc(copiedProjectComplexity)
      const selectedIds = copiedProjectComplexity.map(item => {
        const selectedGovernance = item.complexityGovernanceList.find(governance => governance.isSelected);
        return selectedGovernance ? selectedGovernance.id : null;
      });
      let len = projectComplexity.length
      let sgId = selectedGovernanceId
      sgId.fill(null);
      sgId.fill(selectedIds[0], len - 1, len + 1);
      setSelectedGovernanceId(sgId)
    } else if (complexityName === 'Default') {
      let cmp = projectComplexity.filter((complexity) => complexity.name === complexityTag)
      let c = cmp.map((item) => (item.complexityGovernanceList))
      let d = c[0].map((item) => {
        if (item.id === value.id) {
          item.isSelected = true
        } else {
          item.isSelected = false
        }
        return item
      })

      const copiedProjectComplexity = JSON.parse(JSON.stringify(projectComplexity));
      const criticalItem = copiedProjectComplexity.find(item => item.name === complexityTag);
      criticalItem.complexityGovernanceList = d;
      const updatedArr = copiedProjectComplexity.map(item => {
        if (item.name !== complexityTag) {
          // Update complexities other than 'Medium' to set 'None' isSelected to true
          return {
            ...item,
            complexityGovernanceList: item.complexityGovernanceList.map(gov => ({
              ...gov,
              isSelected: gov.name === "None"
            }))
          };
        } else {
          // Leave 'Medium' complexity as is
          return item;
        }
      });
      const selectedIds = updatedArr.map(item => {
        const selectedGovernance = item.complexityGovernanceList.find(governance => governance.isSelected);
        return selectedGovernance ? selectedGovernance.id : 3;
      });
      const updatedDefComp = defComp.map(item => ({
        ...item,
        complexityGovernanceList: item.complexityGovernanceList.map(governance => ({
          ...governance,
          isSelected: governance.id === value.id
        }))
      }));
      setPc(updatedDefComp)
      setSelectedGovernanceId(selectedIds)
    }
    else {
      // Simplified selection logic
      const updateComplexityGovernanceList = (complexityList, complexityName, selectedValueId) => {
        const copiedList = JSON.parse(JSON.stringify(complexityList));
        const targetComplexity = copiedList.find(item => item.name === complexityName);

        if (targetComplexity) {
          targetComplexity.complexityGovernanceList.forEach(item => {
            item.isSelected = item.id === selectedValueId;
          });
        }

        return copiedList;
      };

      // Updating the project complexity
      const updatedProjectComplexity = updateComplexityGovernanceList(projectComplexity, complexityName, value.id);
      setProjectComplexity(updatedProjectComplexity);

      // Filter complexities based on dynamicComplexities
      const filteredComplexities = updatedProjectComplexity.filter(item => dynamicComplexities.includes(item.id));
      setTempPc(filteredComplexities);
      setPc(filteredComplexities);
      

      // Extracting selected governance IDs
      const selectedGovernanceIds = updatedProjectComplexity.map(item => {
        const governance = item.complexityGovernanceList.find(governance => governance.isSelected);
        // Explicitly check for undefined instead of falsy values
        return governance !== undefined ? governance.id : 3;
    });
    setSelectedGovernanceId(selectedGovernanceIds);   

    }

  }
  
  const handleApproverState = (owner) => {
    // Check if user already exists in approvers list
    if (!approvers.some((item) => item.mail === owner.mail)) {
          setApprovers((prevApprovers) => [...prevApprovers, owner]);
    }
  };  

  const prePublishComplexityfunction = (data) => {
    const filterValue = data.map((item) => item.id)
    setDynamicComplexities(filterValue)
    setDefCompData({})
    setDefCompId('')
  }
  const defaultComplexitySelect = (data) => {
    setDefCompData(data)
    setDefCompId(data.id)
  }

  const filterSourceComplexity = () => {
    const filterValue = complexities.filter(({ id }) =>
      dynamicComplexities.includes(id)
    );
    return filterValue;
  };
  setMethodType(methodData.solutionMethodologyTypes[0]);
  return (
    <div className="middleDetailsContainer">
      <div className="methodOwnerMiddleContainer">
        <div className="methodPhaseDetailsTab">
          <div className="addRoleNameContainer">
            <span className="AddRoleFieldLabel">Solution Method Name *</span>
            <input
              placeholder="Enter Solution Method Name"
              value={methodName}
              onChange={(e) => {
                setMethodName(e.target.value);
              }}
            />
          </div>
            <div className="hidingConsultingSupport">
            <span className="AddRoleFieldLabel">Solution Method Type *</span>
            <Dropdown
              className="methodType"
              data={methodData.solutionMethodologyTypes}
              setValue={setMethodType}
              value={methodType}
              enableDropdown={true}
            />
          </div>
          <div className="addRoleNameContainer">
            <span className="AddRoleFieldLabel">Parent Solution Method</span>
            <Dropdown
              className="parentSolutionMethod"
              data={methodData.parentSolutionMethods}
              setValue={setParentMethod}
              value={parentMethod}
              enableDropdown={true}
            />
          </div>
          <div className="addSolMethodFieldsContainer">
            <div className="addRoleNameContainer">
              <span className="AddRoleFieldLabel">Acronym *</span>
              <input
                placeholder="Enter Acronym "
                value={acronym}
                onChange={(e) => {
                  setAcronym(e.target.value);
                }}
                disabled={!newMethod}
              />
            </div>

            <div className="addRoleNameContainer">
              <span className="AddRoleFieldLabel">Diagram Name *</span>
              <input
                placeholder="Enter Diagram Name "
                value={diagram}
                onChange={(e) => {
                  setDiagram(e.target.value);
                }}
              />
            </div>
            <div className="addRoleNameContainer">
              <span className="AddRoleFieldLabel">Short Name *</span>
              <input
                placeholder="Enter Short Name"
                value={shortName}
                onChange={(e) => {
                  setShortName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="addSolMethodFieldsCheckBoxContainer addSolMethodFieldsCheckBoxContainerLatest">
            <ul>
              <li>
                <label className="NotificationChkboxContainer ">
                  <input
                    type="checkbox"
                    checked={isRetired}
                    onChange={() => {
                      setIsRetired(!isRetired);
                    }}
                  />
                  <span className="CustomCheckmark checkboxForSolutionMethod"></span>
                  <span className="complexityLabel">Is Retired</span>
                </label>
              </li>


              <li>
                <label className="NotificationChkboxContainer">
                  <input
                    type="checkbox"
                    checked={isMerged}
                    onChange={() => {
                      setIsMerged(!isMerged);
                    }}
                  />
                  <span className="CustomCheckmark checkboxForSolutionMethod"></span>
                  <span className="complexityLabel">Is Merged</span>
                </label>
              </li>
              <li>
                <label className="NotificationChkboxContainer">
                  <input
                    type="checkbox"
                    checked={isVisible}
                    onChange={() => {
                      setIsVisible(!isVisible);
                    }}
                  />
                  <span className="CustomCheckmark checkboxForSolutionMethod"></span>
                  <span className="complexityLabel">Is Visible</span>
                </label>
              </li>
              {/* <li>
                <label className="NotificationChkboxContainer">
                  <input
                    type="checkbox"
                    checked={isShowInLeftMenu}
                    onChange={() => {
                      setIsShowInLeftMenu(!isShowInLeftMenu);
                    }}
                  />
                  <span className="CustomCheckmark"></span>
                  <span className="complexityLabel">Show in Left Menu</span>
                </label>
              </li> */}
            </ul>
          </div>
          <div style={{
            flexDirection: 'row', flexWrap: 'wrap', gap: '10px 120px', background: '#F6F6FB 0% 0% no-repeat padding-box',
            border: '1px solid #E3E3E3',
            borderRadius: '6px',
            opacity: '1',
            justifyContent: 'center',
          }}>
            <label className={enableComplexity ? "NotificationChkboxContainer NotificationChkboxContainerForSolution mb-0" : "NotificationChkboxContainer NotificationChkboxContainerForSolution " } style={{ marginLeft: '20px' }}>
              <input
                type="checkbox"
                checked={enableComplexity}
                onChange={() => {
                  setEnableComplexity(!enableComplexity);
                }}
              />
              <span className="CustomCheckmark"></span>
              <span className="complexityLabel" style={{ fontSize: '15px' }}>Enable Complexity</span>
            </label>
            {enableComplexity && <hr/>}

            {enableComplexity && (<div className="addSolMethodFieldsContainerMain" style={{ marginLeft: '20px', height: '80px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <span style={{ fontSize: '15px' }}>Select Complexities Applicable</span>
                  <MultiSelectDropdown
                    className={`methodComplexityEnableComplexity`}
                    data={
                      complexities
                    }
                    value={filterSourceComplexity()}
                    setValue={prePublishComplexityfunction}
                    enableDropdown={true}
                  /></div>
                <div>
                  <span style={{ fontSize: '15px' }}>Select Default Complexity</span>
                  <Dropdown
                    className={`methodComplexityDefaultComplexity`}
                    data={defaultCompDD}
                    setValue={(data) => defaultComplexitySelect(data)}
                    value={defCompData || {}}
                    enableDropdown={true}
                  />
                </div>
              </div>

            </div>)}
          </div>
          <div style={{
            background: '#F6F6FB 0% 0% no-repeat padding-box',
            border: '1px solid #E3E3E3',
            borderRadius: '6px',
            opacity: '1',
            marginTop: '12px'
          }}>
            <label className={isProcessStep ? "NotificationChkboxContainer NotificationChkboxContainerForSolution mb-0" :"NotificationChkboxContainer NotificationChkboxContainerForSolution"} style={{ marginLeft: '20px' }}>
              <input
                type="checkbox"
                checked={isProcessStep}
                onChange={() => {
                  setIsProcessStep(!isProcessStep);
                }}
              />
              <span className="CustomCheckmark"></span>
              <span className="complexityLabel" style={{ fontSize: '15px' }}>Is Process Step</span>

            </label>
            {isProcessStep && <hr style={{marginBottom:'0px'}} />}

            {isProcessStep && (<div className="addSolMethodFieldsContainerMain" style={{
              flexDirection: 'row', flexWrap: 'wrap', gap: '10px 120px',
              marginLeft: '20px',
              marginBottom: '20px'
            }}>{pc.map((complexity) => (
              <div className="addRoleNameContainer" style={{ width: '220px' }} key={complexity.name}>
                <span className="AddRoleFieldLabel">
                  Complexity {complexity.name} *
                </span>
                <Dropdown
                  className={`methodComplexity${complexity.name}`}
                  data={
                    complexity.complexityGovernanceList
                  }
                  setValue={(value) => handleSetComplexity(value, complexity.name)}
                  value={complexity
                    .complexityGovernanceList.find((item) => item.isSelected) ??
                    defaultComplexity}
                  enableDropdown={true}
                />
              </div>
            ))}
            </div>)}
          </div>
          <div className="SolMethodAppoverReviwereContainer">
            <div className="addRoleNameContainer"> 
              <span className="AddRoleFieldLabel">Add Owner <span className="red-asterisk">*</span> </span>
              <OwnerSelectionDropdown
                className="methodOwner"
                enableDropdown={true}
                data={usersList}
                value={owner}
                setValue={setOwner}
                updateApprovers={handleApproverState}
                type="methodOwner"
                searchValue={ownerValue}
                setSearchValue={setOwnerValue}
                error={error}
                emailFound={emailFound["methodOwner"]}
              />
              {owner.mail && (
                <ul className={"afterSaveListTopic collaboratorsApprovers"}>
                  <li key={owner.mail}>
                    <span className="collabaratorPersonaImage">
                      <img src={owner.photo} alt="img" />
                    </span>
                    <span className="collabaratorPersonaTitle">
                      {owner.firstName + " " + owner.surname}
                    </span>
                    <span>
                      <Icon
                        iconName="Cancel"
                        onClick={() => {
                          setOwner({});
                        }}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            setOwner({});
                          }
                        }}
                        role="button"
                        tabIndex={0}
                        aria-label={`Remove ${owner.firstName} ${owner.surname}`}
                        title="Remove Owner"
                      />
                    </span>
                  </li>
                </ul>
              )}
            </div>
            <div className="addRoleNameContainer">
              <span className="AddRoleFieldLabel">Add Co-Owners  <span className="red-asterisk">*</span></span>
              <UserSelectionDropdown
                className="coOwner"
                enableDropdown={true}
                data={usersList}
                value={coOwners}
                setValue={setCoOwners}
                type="coOwner"
                searchValue={coOwnerValue}
                setSearchValue={setCoOwnerValue}
                error={error}
                emailFound={emailFound["coOwner"]}
              />
              {coOwners.length > 0 && (
                <div className="collabaratorsChildContainers">
                  <ul>
                    {coOwners.map((user) => (
                      <li key={user.mail}>
                        <span className="collabaratorPersonaImage">
                          <img src={user.photo} alt="img" />
                        </span>
                        <span className="collabaratorPersonaTitle">
                          {user.firstName + " " + user.surname}
                        </span>
                        <span>
                          <Icon
                            iconName="Cancel"
                            onClick={() => {
                              removeCoOwner(user.mail, coOwners, setCoOwners);
                            }}
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                removeCoOwner(user.mail, coOwners, setCoOwners);
                              }
                            }}
                            role="button"
                            tabIndex={0}
                            aria-label={`Remove ${user.firstName} ${user.surname}`}
                            title="Remove CoOwner"
                          />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="addRoleNameContainer">
              <span className="AddRoleFieldLabel">Add Approvers <span className="red-asterisk">*</span> </span>
              <UserSelectionDropdown
                className="approver"
                enableDropdown={true}
                data={usersList}
                value={approvers}
                setValue={setApprovers}
                type="approver"
                searchValue={approverValue}
                setSearchValue={setApproverValue}
                error={error}
                emailFound={emailFound["approver"]}
              />
              {approvers.length > 0 && (
                <div className="collabaratorsChildContainers">
                  <ul>
                    {approvers.map((user) => (
                      <li key={user.mail}>
                        <span className="collabaratorPersonaImage">
                          <img src={user.photo} alt="img" />
                        </span>
                        <span className="collabaratorPersonaTitle">
                          {user.firstName + " " + user.surname}
                        </span>
                        <span>
                          <Icon
                          hidden={(owner.mail === user.mail)}
                            iconName="Cancel"
                            onClick={() => {
                              removeCoOwner(user.mail, approvers, setApprovers);
                            }}
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                removeCoOwner(
                                  user.mail,
                                  approvers,
                                  setApprovers
                                );
                              }
                            }}
                            role="button"
                            tabIndex={0}
                            aria-label={`Remove ${user.firstName} ${user.surname}`}
                            title="Remove approver"
                          />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="addRoleNameContainer">
              <span className="AddRoleFieldLabel">Add Reviewers <span className="red-asterisk">*</span> </span>
              <UserSelectionDropdown
                className="reviewer"
                enableDropdown={true}
                data={usersList}
                value={reviewers}
                setValue={setReviewers}
                type="reviewer"
                searchValue={reviewerValue}
                setSearchValue={setReviewerValue}
                error={error}
                emailFound={emailFound["reviewer"]}
              />
              {reviewers.length > 0 && (
                <div className="collabaratorsChildContainers">
                  <ul>
                    {reviewers.map((user) => (
                      <li key={user.mail}>
                        <span className="collabaratorPersonaImage">
                          <img src={user.photo} alt="img" />
                        </span>
                        <span className="collabaratorPersonaTitle">
                          {user.firstName + " " + user.surname}
                        </span>
                        <span>
                          <Icon
                            iconName="Cancel"
                            onClick={() => {
                              removeCoOwner(user.mail, reviewers, setReviewers);
                            }}
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                removeCoOwner(
                                  user.mail,
                                  reviewers,
                                  setReviewers
                                );
                              }
                            }}
                            role="button"
                            tabIndex={0}
                            aria-label={`Remove ${user.firstName} ${user.surname}`}
                            title="Remove reviewer"
                          />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="SolMethodAppoverReviwereContainer">
              <div className="addRoleNameContainer">
                <span className="AddRoleFieldLabel">
                  Solution Method Description *
                </span>
                <MethodEditor
                  description={description ?? ""}
                  setDescription={setDescription}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default SolutionMethodForm;
