import React, { memo, useState, useEffect } from 'react';
import { useRaciGrid } from './RaciContext';
import { Icon } from '@fluentui/react/lib/Icon';
import GridRolesData from './GridRolesData';
import Spinner from '../../helpers/Spinner';
import { useAppInsights } from '../../context/TelemetryContext';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
const solutionMethodArtifactId = 15;
const updateRaciGrid = (
	parentRefrences,
	setRaciGridData,
	valueIndex,
	propertyName,
	propertyValue
) =>
	setRaciGridData((raciGridData) => {
		const newRaciGrid = [...raciGridData];
		let parent = newRaciGrid;
		for (const parentRefrence of parentRefrences) {
			if (parentRefrence.propertyName === 'solutionMethodId') {
				parent = parent.find(
					(value) =>
						value[parentRefrence.propertyName] === parentRefrence.propertyValue
				)?.phases;
			} else {
				parent = parent.find(
					(value) =>
						value[parentRefrence.propertyName] === parentRefrence.propertyValue
				)?.activities;
			}
		}
		parent[valueIndex][propertyName] = propertyValue;
		return newRaciGrid;
	});


const getTotalActivityCount = (activityData) => {
	let totalActivityCount = 0;
	if (activityData.activityId) {
		return 1;
	}
	if (activityData.solutionMethodId) {
		for (const phase of activityData.phases) {
			totalActivityCount += getTotalActivityCount(phase);
		}
	} else {
		for (const activity of activityData.activities) {
			totalActivityCount += getTotalActivityCount(activity);
		}
	}
	return totalActivityCount;
};



const ActivityGrid = () => {
	const { raciGridData } = useRaciGrid();
	const getMethodCount = () => {
		const selectedMethods = raciGridData.filter((solutionMethod) => solutionMethod.isSelected && !solutionMethod.isReferred);
		const methodCount = selectedMethods.length;
		return methodCount;
	}

	return (
		<ul>
			<li>
			{raciGridData.map((solutionMethod, solutionMethodIndex) => {
				return (
					solutionMethod.isSelected && (
						<GridMethod
							solutionMethod={solutionMethod}
							solutionMethodIndex={solutionMethodIndex}
							parentRefrence={[]}
							key={solutionMethod.solutionMethodId}
							methodCount={getMethodCount()}
						/>
					)
				);
			})}
			{raciGridData.length === 0 && <Spinner />}
			</li>
		</ul>
	);
};

const GridMethod = ({
	solutionMethod,
	solutionMethodIndex,
	parentRefrence,
	methodCount
}) => {
	const { solutionMethodId, phases, name, isExpanded, isRefered } =
		solutionMethod;
	const [totalActivityCount, setTotalActivityCount] = useState(0);
	const { track } = useAppInsights();
	const allPhaseCollapsed = phases.every(
		(phase) => !phase.isExpanded || getTotalActivityCount(phase) < 2
	);
	const [currentRefrence, setCurrentRefrence] = useState([
		...parentRefrence,
		{
			propertyName: 'solutionMethodId',
			propertyValue: solutionMethodId,
			isRefered: isRefered
		},
	]);
	const { dynamicFilterData, setDynamicFilterTag, setRaciGridData } =
		useRaciGrid();
	const setSolutionMethodLevelProperty = (propertyName, propertyValue) => {
		updateRaciGrid(
			parentRefrence,
			setRaciGridData,
			solutionMethodIndex,
			propertyName,
			propertyValue
		);
	};
	const deSelectSolutionMethod = () => {
		if (
			dynamicFilterData.find(
				({ artifactTypeId }) => artifactTypeId === solutionMethodArtifactId
			)
		) {
			setDynamicFilterTag((prevValue) => {
				const newValue = { ...prevValue };
				if (newValue[solutionMethodArtifactId]) {
					newValue[solutionMethodArtifactId].selectedTagId = newValue[
						solutionMethodArtifactId
					].selectedTagId.filter((methodId) => methodId !== solutionMethodId);
				}
				return newValue;
			});
		} else {
			setSolutionMethodLevelProperty('isSelected', false);
		}
	};
	const getSolutionMethodId = () => {
		let uniqueSolutionMethodId = '';
		if (parentRefrence.length > 0) {
			for (const parent in parentRefrence) {
				uniqueSolutionMethodId += '_' + parentRefrence[parent].propertyValue;
			}
		}

		return uniqueSolutionMethodId;
	}
	useEffect(() => {
		setTotalActivityCount(getTotalActivityCount(solutionMethod));
	}, [solutionMethod]);

	const _arrowClickHandler = (e) => {
		e.stopPropagation();
		if (parentRefrence.length > 0) {
			$("#" + getSolutionMethodId() + '_' + solutionMethodId).toggleClass('rotateArrow')
		}
		else {
			$("#" + solutionMethodId).toggleClass('rotateArrow');
		}
		if (parentRefrence.length > 0) {
			if ($("#" + getSolutionMethodId() + '_' + solutionMethodId + '_toggle').attr('aria-expanded') === 'true') {
				$("#" + getSolutionMethodId() + '_' + solutionMethodId + '_toggle').attr('aria-expanded', 'false');
			}
			else if ($("#" + getSolutionMethodId() + '_' + solutionMethodId + '_toggle').attr('aria-expanded') === 'false') {
				$("#" + getSolutionMethodId() + '_' + solutionMethodId + '_toggle').attr('aria-expanded', 'true');
			}
		}
		else {
			if ($("#" + solutionMethodId + '_toggle').attr('aria-expanded') === 'true') {
				$("#" + solutionMethodId + '_toggle').attr('aria-expanded', 'false');
			}

			else if ($("#" + solutionMethodId + '_toggle').attr('aria-expanded') === 'false') {
				$("#" + solutionMethodId + '_toggle').attr('aria-expanded', 'true');
			}
		}
	}


	return (
		<>
			<Row>
				<Col lg="12">

					<div className='accordion-container'>
						<Accordion defaultActiveKey={solutionMethodId} className={`${isRefered ? ' referredMethodologyAccordion' : 'methodologyAccordion'}`}>

							<Card className='methodology-accordion'>

								<Card.Header>
									<div className={methodCount === 1 ? `genericTableCloseIconValue iconDisable` : `genericTableCloseIconValue`}>
										<Icon iconName="Clear" onClick={deSelectSolutionMethod} />
									</div>
									<Accordion.Toggle aria-expanded="true" id={parentRefrence.length > 0 ? getSolutionMethodId() + '_' + solutionMethodId + '_toggle' : solutionMethodId + '_toggle'} onClick={(e) => _arrowClickHandler(e)} as={Button} variant="link" eventKey={solutionMethodId}>

										<div className='accordion-heading'>
											<Icon id={parentRefrence.length > 0 ? getSolutionMethodId() + '_' + solutionMethodId : solutionMethodId} className='methodology-arrow-icon'
												iconName={'DoubleChevronUp12'
												}
											/* onClick={() => {
												track(1, 2102, solutionMethodId, name);
												setSolutionMethodLevelProperty('isExpanded', !isExpanded);
											}} */
											/>
											<span>{name}</span>
										</div>
									</Accordion.Toggle>
									{isRefered ? (
										<div className="ml-auto referredMethodologyText">
											<span>Referred Methodology</span>
											<span
												className="activityReferredIcon"
												title="Linked solution method"
											></span>
										</div>
									) : (
										<div className="ml-auto nonreferredMethodologyText">Methodology</div>
									)}

								</Card.Header>
								<Accordion.Collapse eventKey={solutionMethodId}>
									<Card.Body>
										{phases.map((phase, phaseIndex) => (
											<GridPhase
												phase={phase}
												phaseIndex={phaseIndex}
												parentRefrence={currentRefrence}
												key={`phase_${phase.phaseId}`}
												refredMethod={isRefered}
												methodName={name}

											/>
										))}
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</div>
				</Col>
			</Row>
		</>
	);
};

const GridPhase = ({
	phase,
	phaseIndex,
	parentRefrence,
	refredMethod,
	methodName,

}) => {
	const {
		isExpanded,
		name,
		activities,
		isRefered,
		phaseId,
		referedFromSolutionMethodName,
	} = phase;
	const [totalActivityCount, setTotalActivityCount] = useState(0);
	const { setRaciGridData } = useRaciGrid();
	const { track } = useAppInsights();
	const [currentRefrence, setCurrentRefrence] = useState([
		...parentRefrence,
		{
			propertyName: 'phaseId',
			propertyValue: phaseId,
			isRefered: isRefered || refredMethod
		},
	]);
	const setPhaseLevelProperty = (propertyName, propertyValue) => {
		updateRaciGrid(
			parentRefrence,
			setRaciGridData,
			phaseIndex,
			propertyName,
			propertyValue
		);
	};
	const getPhaseId = () => {
		let uniquePhaseId = '';
		if (parentRefrence.length > 0) {
			for (const parent in parentRefrence) {
				uniquePhaseId += '_' + parentRefrence[parent].propertyValue;
			}
		}

		return uniquePhaseId;
	}
	useEffect(() => {
		setTotalActivityCount(getTotalActivityCount(phase));
	}, [phase]);

	const _phaseArrowClickHandler = (e) => {
		e.stopPropagation();

		if (parentRefrence.length > 0) {
			$("#" + getPhaseId() + '_' + phaseId).toggleClass('rotateArrow');
		}
		else {
			$("#" + phaseId).toggleClass('rotateArrow');
		}

		if (parentRefrence.length > 0) {
			if ($("#" + getPhaseId() + '_' + phaseId + '_toggle').attr('aria-expanded') === 'true') {
				$("#" + getPhaseId() + '_' + phaseId + '_toggle').attr('aria-expanded', 'false');
			}
			else if ($("#" + getPhaseId() + '_' + phaseId + '_toggle').attr('aria-expanded') === 'false') {
				$("#" + getPhaseId() + '_' + phaseId + '_toggle').attr('aria-expanded', 'true');
			}
		}
		else {
			if ($("#" + phaseId + '_toggle').attr('aria-expanded') === 'true') {
				$("#" + phaseId + '_toggle').attr('aria-expanded', 'false');
			}

			else if ($("#" + phaseId + '_toggle').attr('aria-expanded') === 'false') {
				$("#" + phaseId + '_toggle').attr('aria-expanded', 'true');
			}
		}
	}
	return (
		<div>

			<Accordion defaultActiveKey={phaseId} className={`${isRefered || refredMethod ? ' referredPhaseAccordion' : 'phaseAccordion'}`}>
				<Card className='phase-accordion'>
					<Card.Header>
						<Accordion.Toggle aria-expanded="true" id={parentRefrence.length > 0 ? getPhaseId() + '_' + phaseId + '_toggle' : phaseId + '_toggle'} as={Button} onClick={(e) => _phaseArrowClickHandler(e)} variant="link" eventKey={phaseId} >
							<div className='accordion-heading'>
								<Icon id={parentRefrence.length > 0 ? getPhaseId() + '_' + phaseId : phaseId} className='phase-arrow-icon'
									iconName={
										'DoubleChevronUp12'
									}
								/>
								<span>{name}</span>
							</div>
						</Accordion.Toggle>
						{isRefered || refredMethod ? (
							<div className="ml-auto referredphaseText">
								<span>Referred Phase</span>
								<span
									className="activityReferredIcon"
									title={`Linked phase from solution method ${referedFromSolutionMethodName ?? methodName
										} `}
								></span>
							</div>
						) : (
							<div className="ml-auto nonreferredphaseText">Phase</div>
						)}
					</Card.Header>
					<Accordion.Collapse eventKey={phaseId}>
						<Card.Body>
							{totalActivityCount > 0 ? (
								activities.map((activity, activityIndex) => {
									if (activity.hasOwnProperty('activityId')) {
										return (
											<div key={`activity_${activity.activityId} `}>
												<div className="genericTableGroupName"></div>
												<div className="genericTableSubPhase"></div>
												<GridActivity
													activity={activity}
													parentRefrence={currentRefrence}
													refredMethod={refredMethod}
													methodName={methodName}
													phaseName={name}
													source='raciOrphanActivity'
												/>
											</div>
										);
									} else if (activity.hasOwnProperty('groupId')) {
										return (
											<GridGroup
												group={activity}
												parentRefrence={currentRefrence}
												refredMethod={refredMethod}
												key={`group_${activity.groupId} `}
												methodName={referedFromSolutionMethodName ?? methodName}
												phaseName={name}
											/>
										);
									} else if (activity.hasOwnProperty('solutionMethodId')) {
										return (
											<GridMethod
												solutionMethod={activity}
												parentRefrence={currentRefrence}
												solutionMethodIndex={activityIndex}
												key={`method_${activity.solutionMethodId} `
												}
											/>
										);
									}
								})
							) : <></>}
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		</div>
	);
};

const GridGroup = ({
	group,
	parentRefrence,
	refredMethod,
	methodName,
	phaseName,
}) => {
	const { isRefered, name, activities, groupId } = group;
	const [totalActivityCount, setTotalActivityCount] = useState(0);
	const [currentRefrence, setCurrentRefrence] = useState([
		...parentRefrence,
		{
			propertyName: 'groupId',
			propertyValue: groupId,
		},
	]);
	useEffect(() => {
		setTotalActivityCount(getTotalActivityCount(group));
	}, [group]);
	return (
		<>
			{/* NEW DESIGN */}
			
			<div className={`groupGrid ${isRefered || refredMethod ? 'raciReferredGroup' : ''
				} `}>

				<div className='groupGrid-heading'>{name}
					{(isRefered || refredMethod) && (

						<span className="activityReferredIcon"
							title={`Linked Group from solution method ${methodName} and phase ${phaseName} `}
						></span>

					)}</div>

				<div role='table'
					className={`  ${totalActivityCount === 0 ? 'NoActivitiesPresent' : ''
						} `}
				>
					{totalActivityCount === 0 && (
						<div className="genericTableNoActivitiesValue">
							No Activites found with current filters.Please try changing filter
							selections
						</div>
					)}

					{activities.map((activity, activityIndex) => {
						if (activity.hasOwnProperty('activityId')) {
							return (
								<div key={`activity_${activity.activityId} `}>

									<GridActivity
										activity={activity}
										parentRefrence={currentRefrence}
										refredMethod={refredMethod}
										methodName={methodName}
										phaseName={phaseName}
										source='groupActivity'
									/>
								</div>
							);
						} else if (activity.hasOwnProperty('subGroupId')) {
							return (
								<GridSubGroup
									subGroup={activity}
									parentRefrence={currentRefrence}
									refredMethod={refredMethod}
									methodName={methodName}
									phaseName={phaseName}
									key={`subgroup_${activity.subGroupId} `}
								/>
							);
						} else if (activity.hasOwnProperty('solutionMethodId')) {
							return (
								<GridMethod
									solutionMethod={activity}
									parentRefrence={currentRefrence}
									solutionMethodIndex={activityIndex}
									key={`method_${activity.solutionMethodId} `}
								/>
							);
						}
					})}

				</div>
			</div>
		</>
	);
};
const GridSubGroup = ({
	subGroup,
	parentRefrence,
	refredMethod,
	methodName,
	phaseName,
}) => {
	const { isRefered, name, activities, subGroupId } = subGroup;
	const [totalActivityCount, setTotalActivityCount] = useState(0);
	const [currentRefrence, setCurrentRefrence] = useState([
		...parentRefrence,
		{
			propertyName: 'subGroupId',
			propertyValue: subGroupId,
		},
	]);
	useEffect(() => {
		setTotalActivityCount(getTotalActivityCount(subGroup));
	}, [subGroup]);

	return (
		<>
			{/* NEW DESIGN */}
			<div className={`subgroupGrid ${isRefered || refredMethod ? 'refredSubGroup' : ''} ${totalActivityCount === 0 ? 'shortZeroSubGroup' : ''} `}>
				<div className='groupGrid-heading'>

					<div className="" title={name}>
						<span>{name}</span>

						{(isRefered || refredMethod) && (

							<span
								className="activityReferredIcon"
								title={`Linked Group from solution method ${methodName} and phase ${phaseName} `}
							></span>

						)}</div>
				</div>
				<div
					className={`  ${totalActivityCount === 0 ? 'NoActivitiesPresent' : ''
						} `}
				>
					{totalActivityCount === 0 && (
						<div className="genericTableNoActivitiesValue">
							No Activites found with current filters.Please try changing filter
							selections
						</div>
					)}

					{activities.map((activity, activityIndex) => {
						if (activity.hasOwnProperty('activityId')) {
							return (
								<div key={`activity_${activity.activityId} `}>
									<GridActivity
										activity={activity}
										parentRefrence={currentRefrence}
										refredMethod={refredMethod}
										methodName={methodName}
										phaseName={phaseName}
										source='subGroupActivity'
									/>
								</div>
							);
						} else if (activity.hasOwnProperty('solutionMethodId')) {
							return (
								<GridMethod
									solutionMethod={activity}
									parentRefrence={currentRefrence}
									solutionMethodIndex={activityIndex}
									key={`method_${activity.solutionMethodId} `}
								/>
							);
						}
					})}

				</div>


			</div>
			{/* OLD DESIGN */}
			{/* <div
				className={`genericTableSubGroupNameGroup subGroupExpanded
			${ isRefered || refredMethod ? 'refredSubGroup' : '' } 
			${ totalActivityCount === 4 ? 'shortFourSubGroup' : '' }
			${ totalActivityCount === 3 ? 'shortThreeSubGroup' : '' }
			${ totalActivityCount === 2 ? 'shortTwoSubGroup' : '' }
			${ totalActivityCount === 1 ? 'shortOneSubGroup' : '' } ${
			totalActivityCount === 0 ? 'shortZeroSubGroup' : ''
		} `}
			>
				<div className="genericTableSubPhase">
					{(isRefered || refredMethod) && (
						<div className="genericTableRefferedValue">
							<span> SubGroup</span>
							<span
								className="activityReferredIcon"
								title={`Linked Group from solution method ${ methodName } and phase ${ phaseName } `}
							></span>
						</div>
					)}
					<div className="genericTableValue" title={name}>
						<span>{name}</span>
					</div>
				</div>
				<div
					className={`separation subGroupExpanded ${
			totalActivityCount === 0 ? 'NoActivitiesPresent' : ''
		} `}
				>
					{totalActivityCount === 0 && (
						<div className="genericTableNoActivitiesValue">
							No Activites found with current filters.Please try changing filter
							selections
						</div>
					)}
					{activities.map((activity, activityIndex) => {
						if (activity.hasOwnProperty('activityId')) {
							return (
								<div key={`activity_${ activity.activityId } `}>
									<GridActivity
										activity={activity}
										refredMethod={refredMethod}
										methodName={methodName}
										phaseName={phaseName}
									/>
								</div>
							);
						} else if (activity.hasOwnProperty('solutionMethodId')) {
							return (
								<GridMethod
									solutionMethod={activity}
									parentRefrence={currentRefrence}
									solutionMethodIndex={activityIndex}
									key={`method_${ activity.solutionMethodId } `}
								/>
							);
						}
					})}
				</div>
			</div> */}
		</>
	);
};

const GridActivity = ({ activity, parentRefrence, refredMethod, methodName, phaseName, source }) => {
	const {
		name,
		referedFromPhaseName,
		isProcessStep,
		referedFromSolutionMethodName,
		isRefered,
		link,
		activityId,
		hasBusinessRule
	} = activity;
	const getActivityId = () => {
		let uniqueActivityId = '';
		if (parentRefrence.length > 0) {
			for (const parent in parentRefrence) {
				uniqueActivityId += '_' + parentRefrence[parent].propertyValue;
			}
		}

		return uniqueActivityId;
	}
	const getActivityLevel = () => {
		const activityLevel = {
			2: 'level2',
			3: 'level3',
			4: 'level4',
			5: 'level5',
			6: 'level6',
		}

		return activityLevel[parentRefrence.length];
	}
	// const getReferedLevel = () => {
	// 	let uniqueReferredLevel = 0;
	// 	if (parentRefrence.length > 0) {
	// 		for (const parent in parentRefrence) {
	// 			if (parentRefrence[parent].isRefered) { uniqueReferredLevel += 1; }


	// 		}
	// 	}

	// 	return uniqueReferredLevel;
	// }
	return (
		<>

			{/* NEW DESIGN */}

			<div className='activityGrid' role="row">
				<Row className='activityRow'>
					{(isRefered || refredMethod) ? (
						<Col role="cell" xs="3" md="3" lg="3" className={`activityValue referredActivity ${getActivityLevel()}`} title={name} id={parentRefrence.length > 0 ? getActivityId() + '_' + activityId : activityId}><div className='activityLink' title={name}>
							<a className='align-middle' href={link} target="_blank" rel="noopener noreferrer">						
								{name}
							</a>
						</div>
							<span
								className="activityReferredIcon"
								title={`Linked activity from solution method ${referedFromSolutionMethodName ?? methodName
									} and phase ${referedFromPhaseName ?? phaseName} `}
							></span>
						</Col>
					) : (
						<Col role="cell" xs="3" md="3" lg="3" className={`activityValue ${getActivityLevel()}`} title={name} id={parentRefrence.length > 0 ? getActivityId() + '_' + activityId : activityId}>
							<div className='activityLink' title={name}>
								<span title='Mandatory' className={isProcessStep ? 'mandatoryIcon raci' : 'raciIcont'}></span>
								<span title='Business Rule' className={hasBusinessRule ? 'businessIcon raci' : 'raciIcont'}></span>
							<a className='align-middle' href={link} target="_blank" rel="noopener noreferrer">						
								{name}
							</a>
						</div>
						</Col>
					)}
					<Col xs="9" md="9" lg="9" className='rightGrid'>
						<div className="raci-table-container">
							<div className="table-row raciTableRow">
								<GridRolesData activity={activity} />
								{/* <div className="row-item">Item 2</div>
								<div className="row-item">Item 3</div>
								<div className="row-item">Item 4</div> */}
							</div>
						</div>
					</Col>
				</Row>
			</div>

			{/* OLD DESIGN */}
			{/* <div
				className={`genericTableActivity ${
			isRefered || refredMethod ? 'refredActivity' : ''
		} `}
				title={name}
			>
				<Link to={link} className="genericTableValue">
					{name}
				</Link>
				{(isRefered || refredMethod) && (
					<span
						className="activityReferredIcon"
						title={`Linked activity from solution method ${
			referedFromSolutionMethodName ?? methodName
		} and phase ${ referedFromPhaseName ?? phaseName } `}
					></span>
				)}
			</div> */}

			{/* GRID FOR RACI Design */}
			{/* <GridRolesData activity={activity} /> */}
		</>
	);
};

export default ActivityGrid;
