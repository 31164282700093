import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { Icon } from '@fluentui/react/lib/Icon';

import noResults from '../../assets/noResults.svg';
import { useAxios } from '../../context/AxiosContext';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import TemplateCard from '../TemplateCard';
import SearchResultPageActivity from './ActivitySearch';
import TopicSearch from './TopicSearch';
import TutorialSearch from './TutorialSearch';
import ProcedureSearch from './ProcedureSearch';
import MethodSearch from './MethodSearch';
import PhaseSearch from './PhaseSearch';
import Breadcrumbs from '../Breadcrumbs';
import Pagination from './SearchPagination';
import SearchFilter from './DynamicFilter';
import SampleTemplateSearch from './SampleTemplateSearch';
import LocalizedTemplateSearch from './LocalizedTemplateSearch';
import { TabTitle } from '../../utils/GeneralFunctions';

import './SearchResultPage.css';

const pageLimit = 10;
const tabs = [
	{
		id: 0,
		title: 'All',
		searchType: 'All',
		pageTypeId: 4,
		enableComplexity: true,
	},
	{
		id: 2,
		title: 'Activities',
		searchType: 'Activity',
		pageTypeId: 5,
		enableComplexity: true,
	},
	{
		id: 1,
		title: 'Templates',
		searchType: 'Template',
		pageTypeId: 6,
		enableComplexity: true,
	},
	{
		id: 10,
		title: 'Topics',
		searchType: 'Topic',
		pageTypeId: 7,
		enableComplexity: false,
	},
	{
		id: 82,
		title: 'Job Aids',
		searchType: 'Topics',
		pageTypeId: 14,
		enableComplexity: false,
	},
	{
		id: 81,
		title: 'Video Tutorials',
		searchType: 'VideoTutorials',
		pageTypeId: 15,
		enableComplexity: false,
	},
];
function SearchResultPage() {
	const { track } = useAppInsights();

	const searchParam = new URLSearchParams(useLocation().search).get(
		'searchParam'
	);
	const { axiosGetService } = useAxios();
	const {
		breadcrumbs,
		setBreadcrumbs,
		selectedTab,
		setSelectedTab,
		endUserView,
		userIsOwner,
		appState
	} = useLeftMenuCollapse();
	const {defaultComplexity} = appState;
	const [complexityTag, setComplexityTag] = useState();
	const [showFilters, setShowFilters] = useState(false);
	const [disableClearFilter, setDisableClearFilter] = useState(false)
	const [dynamicFilterTag, setDynamicFilterTag] = useState({});
	const [dynamicFilterTagMapping, setDynamicFilterTagMapping] = useState({});
	const [data, setData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [changeLastFiveDownload, setChangeLastFiveDownload] = useState(0);

	const [searchCount, setSearchCount] = useState(0);
	const [searchSuggestionId, setSearchSuggestionId] = useState(null); // set id of the search suggestion, currently displayed
	const [firstSearch, setFirstSearch] = useState(1);

	const [selectedTemplatePageTab, setSelectedTemplatePageTab] = useState(1);

	const [searchType, setSearchType] = useState(
		tabs.filter(({ title }) => title === selectedTab)[0].searchType
	);
	const [pageTypeId, setPageTypeId] = useState(
		tabs.filter(({ title }) => title === selectedTab)[0].pageTypeId
	);
	const [enableComplexity, setEnableComplexity] = useState(
		tabs.filter(({ title }) => title === selectedTab)[0].enableComplexity
	);

	const [showTutorials, setShowTutorials] = useState(false);

	const templatePageTab = [
		{
			id: 1,
			name: 'Main Templates',
			searchType: 'Template',
		},
		{
			id: 2,
			name: 'Samples',
			searchType: 'SampleTemplate',
		},
		{
			id: 3,
			name: 'Localized Templates',
			searchType: 'LocalizedTemplate',
		},
	];

	const getSearchType = (text) => {
		return tabs.filter(({ title }) => title === text)[0].searchType;
	};
	const getPageTypeId = (text) => {
		return tabs.filter(({ title }) => title === text)[0].pageTypeId;
	};
	const getEnableComplexity = (text) => {
		return tabs.filter(({ title }) => title === text)[0].enableComplexity;
	};

	const loadBreadcrumb = async () => {
		const pageBreadcrumb = {
			title: 'Search',
			type: 'search',
			parent: 'home',
			path: window.location.pathname + window.location.search,
		};
		track(2, 120, 'Search_param', searchParam);
		const newBreadcrumbs = AddBreadCrumb(
			breadcrumbs.slice(0, 1),
			pageBreadcrumb
		);
		setBreadcrumbs(newBreadcrumbs);
		TabTitle('Search Results : ' + searchParam);
	};

	const getTutorialStatus = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=ShowVideoTutorials`).then(
			(response) => {
				if (response.data[0].value === 'true') {
					setShowTutorials(true);
				}
			}
		);
	};

	const getSearchResults = () => {
		const selectedFilterIds = [];
		const filterIds = Object.keys(dynamicFilterTag);
		let selectedFilter = false;
		for (const id in filterIds) {
			if (dynamicFilterTag[Number(filterIds[id])].selectedTagId.length > 0) {
				selectedFilter = true;
				selectedFilterIds.push(
					`${Number(filterIds[id])}` +
					'-' +
					dynamicFilterTag[Number(filterIds[id])].selectedTagId.toString()
				);
			}
		}

		const regexp = /^[a-zA-Z0-9\s\.()[\]\d\w,:;#&""''_\-\*]+$/;
		if (!regexp.test(searchParam)) {
			setData(null);
			setSearchCount(0);
		} else {
			if(complexityTag !== undefined && complexityTag !== null){
				axiosGetService(
					`api/searchresults?pageSize=${pageLimit}&
					pageNumber=${currentPage}&searchValue=${encodeURIComponent(searchParam)}&
					idrSearchArtifactType=${searchType}&
					complexityId=${complexityTag.id}&projectTypeId=0&
					filterIds=${selectedFilter ? selectedFilterIds.join('|') : 0}&endUser=${!userIsOwner || endUserView
					}`
				).then((res) => {
					if (res.data.count === 0) {
						setData(null);
						setSearchCount(0);
						track(2, 405, 'Search Page', searchParam);
					} else {
						setData(res.data.records);
						setSearchCount(res.data.count);
						if (currentPage === 1) {
							track(2, 406, 'Search Result Page', searchParam, {
								pageNumber: 1,
								selectedTab: selectedTab,
							});
						}
						if (searchParam !== null && searchParam.length > 0 && searchParam[0] === '#') {
							track(1, 1205, 'Search Result Page Hashtag', searchParam);
						}
					}
				});
			}
		}
	};
	const handleDynamicFilterTag = (artifactTypeId, tagId) => {
		const updatedProcessGroupFilterTag = dynamicFilterTag[
			artifactTypeId
		].selectedTagId.filter((id) => id !== tagId);
		const updatedValue = {
			artifactTypeId: artifactTypeId,
			artifactTypeName: dynamicFilterTag[artifactTypeId].artifactTypeName,
			selectedTagId: [...updatedProcessGroupFilterTag],
		};
		setDynamicFilterTag({
			...dynamicFilterTag,
			[artifactTypeId]: updatedValue,
		});
	};

	const collapseFilter = (e) => {
		const filterArea = document.getElementById('filtersRightPanel');
		if (
			filterArea &&
			!filterArea.contains(e.target) &&
			e.target.className !== 'filtersButton' &&
			!(
				typeof e.target.className !== 'object' &&
				e.target.className &&
				e.target.className.includes('searchIcon')
			) &&
			e.target.className !== 'root-50' &&
			e.target.className !== 'root-56' &&
			e.target.className !== 'showMoreFilter'
		) {
			setShowFilters(false);
		}
	};

	const resetTab = (text) => {
		if (selectedTab !== text) {
			//setComplexityTag(defaultComplexity);
			setShowFilters(false);
			setCurrentPage(1);
			setSelectedTab(text);
			setSearchType(getSearchType(text));
			setPageTypeId(getPageTypeId(text));
			setEnableComplexity(getEnableComplexity(text));
		}
		setSelectedTemplatePageTab(1);
	};

	const clearFilters = () => {
		//setComplexityTag(defaultComplexity);
		const newDynamicFilterTag = { ...dynamicFilterTag };
		const filterIds = Object.keys(newDynamicFilterTag);
		for (const id of filterIds) {
			newDynamicFilterTag[Number(id)].selectedTagId = [];
		}
		setDynamicFilterTag(newDynamicFilterTag);
	};

	const getComplexityTag = async () => {
		await axiosGetService(
			`api/sdmsettings?sdmKey=DefaultComplexityTag`
		).then((response) => {
			const obj = {
				id: response.data[0].value.split(",").at(0),
				name: response.data[0].value.split(",").at(1).toString()
			}
			setComplexityTag(obj);
		});
	};
	
	useEffect(() => {
		getComplexityTag();
	}, []);

	useEffect(() => {
		if (firstSearch > 1) {
			//setComplexityTag(defaultComplexity);
			setSelectedTab('All');
			setDynamicFilterTag([]);
			setSearchType('All');
		}
		setShowFilters(false);
		setCurrentPage(1);
		setData([]);
		setSearchCount(0);
		setSearchSuggestionId(null);
		setFirstSearch((prevState) => prevState + 1);
		setPageTypeId(
			tabs.filter(({ title }) => title === selectedTab)[0].pageTypeId
		);
		setEnableComplexity(
			tabs.filter(({ title }) => title === selectedTab)[0].enableComplexity
		);
		setDisableClearFilter(false)
		window.scrollTo(0, 0);
	}, [searchParam]);

	useEffect(() => {
		setData([]);
		setSearchCount(0);
		setSearchSuggestionId(null);
		setFirstSearch((prevState) => prevState + 1);
		setPageTypeId(
			tabs.filter(({ title }) => title === selectedTab)[0].pageTypeId
		);
		setEnableComplexity(
			tabs.filter(({ title }) => title === selectedTab)[0].enableComplexity
		);
		setDisableClearFilter(false)
	}, [
		selectedTab,
		currentPage,
		complexityTag,
		dynamicFilterTag,
		searchType,
		pageTypeId,
	]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getSearchResults();
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [firstSearch, endUserView]);

	useEffect(() => {
		loadBreadcrumb();
	}, [searchParam]);

	useEffect(() => {
		getTutorialStatus();
	}, []);

	useEffect(() => {
		window.addEventListener('click', collapseFilter);
		return () => {
			window.removeEventListener('click', collapseFilter);
		};
	}, []);

	return (
		<div
			className={
				data !== null && data.length === 0
					? 'searchResultsMainContainer noClickEvents'
					: 'searchResultsMainContainer'
			}
		>
			<div className="SearchResultsTopMenu">
				<div className="searchResultsBreadcrumb">
					<Breadcrumbs />
				</div>
				<div className="searchTabsMenuContainer">
					<ul className="searchMenu" role="tablist">
						{tabs.map((tab) => (
							<>
								{tab.id !== 81 || (tab.id === 81 && showTutorials) ? (
									<li
										key={tab.id}
										tabIndex={0}
										className={
											selectedTab === tab.title ? 'selectedSearchMenu' : ''
										}
										onClick={(event) => {
											resetTab(event.target.innerText);
											track(
												1,
												121,
												event.target.innerText,
												event.target.innerText
											);
										}}
										onKeyPress={(event) => {
											if (event.which === 13) {
												resetTab(event.target.innerText);
												track(
													1,
													121,
													event.target.innerText,
													event.target.innerText
												);
											}
										}}
										role="tab"
										aria-label={
											selectedTab === tab.title
												? `${tab.title}`
												: `${tab.title} not selected`
										}
										aria-selected={selectedTab === tab.title ? 'true' : 'false'}
									>
										<h6>{tab.title}</h6>
									</li>
								) : (
									<></>
								)}
							</>
						))}
					</ul>
					<div className="raciFiltersPart">
						<span className='clearFilterText'>Clear all filters</span>
						<button
							className={!disableClearFilter ? "ClearFiltersButton" : "ClearFiltersButton disableClearFilter"}
							disabled={disableClearFilter}
							onClick={clearFilters}
							tabIndex={0}
							onKeyPress={(e) => {
								if (e.which === 13) {
									clearFilters();
								}
							}}
						></button>
					</div>
					<button
						className="filtersButton"
						onClick={() => setShowFilters(!showFilters)}
					>
						Filters
						<Icon
							className="searchIcon"
							iconName="FilterSolid"
							title="Filters"
						/>
					</button>
				</div>
			</div>
			<div
				className={
					selectedTab === 'Tutorials'
						? 'searchResultsWrapper searchTutorialsWrapper'
						: 'searchResultsWrapper'
				}
			>
				<div className="searchFilterContainer">
					<div className="filterTagsContainer">
						<div className="tagsContainer">
							{tabs.find((tab) => tab.title === selectedTab)
								.enableComplexity ? (
								<span>Complexity:&nbsp;{complexityTag?.name}</span>
							) : (
								<></>
							)}
							{Object.keys(dynamicFilterTag)?.map((item) => (
								<>
									{dynamicFilterTag[Number(item)].selectedTagId?.length > 0 ? (
										<>
											{dynamicFilterTag[Number(item)].selectedTagId?.map(
												(tagId) => (
													<span key={tagId}>
														{`${dynamicFilterTagMapping[
															dynamicFilterTag[Number(item)]?.artifactTypeId
														]?.length > 0 ? dynamicFilterTagMapping[
														dynamicFilterTag[Number(item)]?.artifactTypeId
														][tagId] : ''
															}`}
														<Icon
															onClick={() =>
																handleDynamicFilterTag(Number(item), tagId)
															}
															iconName="cancel"
														/>
													</span>
												)
											)}
										</>
									) : (
										<></>
									)}
								</>
							))}
						</div>
					</div>
				</div>
				<SearchFilter
					showFilters={showFilters}
					setShowFilters={setShowFilters}
					complexityTag={complexityTag}
					setComplexityTag={setComplexityTag}
					dynamicFilterTag={dynamicFilterTag}
					setDynamicFilterTag={setDynamicFilterTag}
					setDynamicFilterTagMapping={setDynamicFilterTagMapping}
					pageTypeId={pageTypeId}
					enableComplexity={enableComplexity}
					searchParam={searchParam}
					disableClearFilter={disableClearFilter}
					setDisableClearFilter={setDisableClearFilter}
				/>
				<div className="searchedContentResults">
					<div className="searchedKeywordDiv">
						<span className="keywordIcon"></span>
						<span>
							Complexities that are highlighted contains the keyword which you
							have searched for
						</span>
					</div>
					{selectedTab === 'Templates' ? (
						<div class="searchLocalizedSubMenu">
							<ul class="LocalizedSubMenu" role="tablist">
								{templatePageTab.map((item) => (
									<li
										tabIndex="0"
										className={
											item.id === selectedTemplatePageTab
												? 'selectedSearchMenu'
												: ''
										}
										onClick={() => {
											setSelectedTemplatePageTab(item.id);
											setSearchType(item.searchType);
										}}
										onKeyPress={(e) => {

											if (e.which === 13) {
												setSelectedTemplatePageTab(item.id);
												setSearchType(item.searchType);
											}
										}}
										role="tab"
										aria-selected={
											item.id === selectedTemplatePageTab ? true : false
										}
										aria-label={item.name}
									>
										<h6>{item.name}</h6>
									</li>
								))}
							</ul>
						</div>
					) : (
						<></>
					)}

					{data === null ? (
						<div className="noSearch">
							<img
								src={noResults}
								className="noRecentlyDownloaded"
								alt="fileType"
							/>
							<p>No results found</p>
						</div>
					) : data.length === 0 ? (
						<div className="spinner">
							<Spinner animation="border" />
						</div>
					) : (
						data.map((item, index) => {
							if (item.itemTypeId === 1) {
								return (
									<>
										<TemplateCard
											key={item.id}
											data={item}
											index={index}
											enableSearchSuggestion={index === 0 ? true : false}
											searchSuggestionId={searchSuggestionId}
											setSearchSuggestionId={setSearchSuggestionId}
											searchTemplateCard={true}
											changeLastFiveDownload={changeLastFiveDownload}
											setChangeLastFiveDownload={setChangeLastFiveDownload}
											currentPage={currentPage}
											complexityTag={complexityTag}
										/>
									</>
								);
							}
							if (item.itemTypeId === 45) {
								return (
									<>
										<SampleTemplateSearch
											key={item.id}
											data={item}
											index={index}
											enableSearchSuggestion={index === 0 ? true : false}
											searchSuggestionId={searchSuggestionId}
											setSearchSuggestionId={setSearchSuggestionId}
											searchTemplateCard={true}
											changeLastFiveDownload={changeLastFiveDownload}
											setChangeLastFiveDownload={setChangeLastFiveDownload}
											currentPage={currentPage}
											complexityTag={complexityTag}
										/>
									</>
								);
							}
							if (item.itemTypeId === 44) {
								return (
									<>
										<LocalizedTemplateSearch
											key={item.id}
											data={item}
											index={index}
											enableSearchSuggestion={index === 0 ? true : false}
											searchSuggestionId={searchSuggestionId}
											setSearchSuggestionId={setSearchSuggestionId}
											searchTemplateCard={true}
											changeLastFiveDownload={changeLastFiveDownload}
											setChangeLastFiveDownload={setChangeLastFiveDownload}
											currentPage={currentPage}
											complexityTag={complexityTag}
										/>
									</>
								);
							}
							if (item.itemTypeId === 2) {
								return (
									<SearchResultPageActivity
										key={item.id}
										data={item}
										index={index}
										enableSearchSuggestion={index === 0 ? true : false}
										searchSuggestionId={searchSuggestionId}
										setSearchSuggestionId={setSearchSuggestionId}
										complexityValue={complexityTag}
										currentPage={currentPage}
									/>
								);
							}
							if (item.itemTypeId === 10) {
								return (
									<TopicSearch
										key={item.id}
										data={item}
										enableSearchSuggestion={index === 0 ? true : false}
										searchSuggestionId={searchSuggestionId}
										setSearchSuggestionId={setSearchSuggestionId}
										currentPage={currentPage}
									/>
								);
							}
							if (item.itemTypeId === 15) {
								return (
									<MethodSearch
										key={item.id}
										data={item}
										enableSearchSuggestion={index === 0 ? true : false}
										searchSuggestionId={searchSuggestionId}
										setSearchSuggestionId={setSearchSuggestionId}
										currentPage={currentPage}
									/>
								);
							}
							if (item.itemTypeId === 25) {
								return (
									<PhaseSearch
										key={item.id}
										data={item}
										enableSearchSuggestion={index === 0 ? true : false}
										searchSuggestionId={searchSuggestionId}
										setSearchSuggestionId={setSearchSuggestionId}
										currentPage={currentPage}
									/>
								);
							}
							if (item.itemTypeId === 82) {
								return (
									<ProcedureSearch
										key={item.id}
										data={item}
										enableSearchSuggestion={index === 0 ? true : false}
										searchSuggestionId={searchSuggestionId}
										setSearchSuggestionId={setSearchSuggestionId}
										currentPage={currentPage}
									/>
								);
							}
							if (item.itemTypeId === 81 && showTutorials) {
								return (
									<TutorialSearch
										key={item.id}
										data={item}
										enableSearchSuggestion={index === 0 ? true : false}
										searchSuggestionId={searchSuggestionId}
										setSearchSuggestionId={setSearchSuggestionId}
										currentPage={currentPage}
									/>
								);
							}
						})
					)}
				</div>
			</div>
			{searchCount > pageLimit ? (
				<div
					className={`SearchPagination ${searchCount <= pageLimit ? 'hidePagination' : ''
						}`}
				>
					<Pagination
						pageCount={Math.ceil(searchCount / pageLimit)}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						searchParam={searchParam}
						selectedTab={selectedTab}
					/>
				</div>
			) : null}
		</div>
	);
}

export default SearchResultPage;
