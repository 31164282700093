import React, { useState, useEffect } from "react";
import { useParams, Link, useRouteMatch, useHistory } from "react-router-dom";
import { initializeIcons } from "@uifabric/icons";
import { Icon } from "@fluentui/react/lib/Icon";
import { toast } from "react-toastify";
import $ from "jquery";
import { MessageBar, MessageBarType } from "@fluentui/react";
import queryString from "query-string";
import { useAuth } from "../../context/UserAuthContext";
import { useAxios } from "../../context/AxiosContext";
import { useCrowdSourcing } from "../../context/CrowdSourcingContext";
import { getOtherUserProfile } from "../../helpers/GraphService";
import { axiosTokenService } from "../CkEditor/api/axiosBaseUrl";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import TopicLeftMenu from "../TopicsLanding/TopicsLeftMenu";
import { useAppInsights } from "../../context/TelemetryContext";
import { artifactMapData } from "../../static/ArtifactMapData";
import TopicYammer from "../Yammer/TopicsYammer";
import Breadcrumbs from "../Breadcrumbs";
import Spinner from "../../helpers/Spinner";
import FavoriteAndFeedback from "../FavoriteAndFeedback";
import HistoryPreview from "../TopicsReviewerCrowdSourcing/HistoricalPreviewPopUp";
import DeletedEntityModal from "../DeletedEntity";
import HashtagSuggestions from "../HashtagSuggestions";
import TopicsVersionHistory from "../TopicsOwner/TopicsVersionHistory";
import TopicCrowdSourceHistory from "../TopicsOwner/TopicCrowdSourceHistoryModal";
import Collaborators from "../TopicsOwner/Collaborators";
import TopicCollabrators from "./TopicCollabtors.js";
import areaDeliveryLeader from "../../assets/areaDeliveryLeader.svg";
import deliveryportfolio from "../../assets/deliveryportfolio.svg";
import isdDelivery from "../../assets/isdDelivery.png";
import consumptionTarget from "../../assets/consumptionTarget.png";
import consumption360view from "../../assets/consumption360view.png";
import onGoinghygien from "../../assets/onGoinghygien.png";
import backlogHealth from "../../assets/backlogHealth.png";
import executionHealth from "../../assets/executionHealth.png";
import monthlyCadance from "../../assets/monthlyCadance.png";
import userFriends from "../../assets/userFriends.svg";
import userAlt from "../../assets/userAlt.svg";
import "./TopicsDetailPage.css";
import TopicPublishCancelModal from "../TopicsBuilder/TopicPublishCancelModal";
import { TabTitle } from "../../utils/GeneralFunctions";
import SupportConfirmationModal from "../../helpers/SupportConfirmationModal";
import Shapes from "../PolygonTopicBuilder/Shapes";
initializeIcons();

const TopicsDetailPage = ({
  resetSolutionMethodFilterTag,
  resetPhaseFilterTag,
  resetActivitiesFilterTag,
  expandTopicsLeftMenu,
  setExpandTopicsLeftMenu,
  isUserAction,
  setIsUserAction,
  leftMenuSelectedId,
  setLeftMenuSelectedId,
  showTutorials,
}) => {
  const tabs = [
    { id: 1, name: 'Associated Content' },
    { id: 2, name: 'Business Rule' }
  ];

  const history = useHistory();
  const { track } = useAppInsights();
  const { userAlias, axiosGetService, axiosPostService, axiosDeleteService } = useAxios();
  const { topicsId, topicType } = useParams();
  const { user } = useAuth();
  const { url } = useRouteMatch();
  const {
    breadcrumbs,
    setBreadcrumbs,
    setSkipNavigationId,
    endUserView,
    userIsOwner,
  } = useLeftMenuCollapse();
  const [sdmContentErrorMessage, setSDMContentErrorMessage] = useState('');
  const { crowdSourcingAppState, setCrowdSourcingAppState } =
    useCrowdSourcing();
  const { showHistory } = crowdSourcingAppState;
  // const [topicsType] = "consulting";
  const pathSegments = url.split("/");
  const selectedTopicsListId = pathSegments[pathSegments.length - 1];
  // const [topicsType, selectedTopicsListId] = url.split("/").slice(2, 4);
  const selectedProcedures =
    window.location.search &&
      queryString.parse(window.location.search).topicType === "job" &&
      (queryString.parse(window.location.search).selectedJobAides === "2" ||
        queryString.parse(window.location.search).selectedJobAides === "3")
      ? Number(queryString.parse(window.location.search).selectedJobAides)
      : 0;
  const selectedReadiness =
    window.location.search &&
      queryString.parse(window.location.search).topicType === "read" &&
      (queryString.parse(window.location.search).selectedReadiness === "2" ||
        queryString.parse(window.location.search).selectedReadiness === "3")
      ? Number(queryString.parse(window.location.search).selectedReadiness)
      : 0;
  const CkEditorCurrentUser = user.profile.mail;
  const [selectedTab, setSelectedTab] = useState(1);
  const [relatedContent, setRelatedContent] = useState([]);
  const [showRelatedContentUpdateModal, setShowRelatedContentUpdateModal] =
    useState(false);
  const [updateRelatedContentData, setUpdateContentrelatedData] =
    useState(null);
  const [ckEditorUsers, setCkEditorUsers] = useState([]);
  const [isReviewer, setIsReviewer] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [consultingTopics, setConsultingTopics] = useState(null);
  const [supportTopics, setSupportTopics] = useState(null);
  const [topicDescriptionData, setTopicDescriptionData] = useState(null);
  const [contributorData, setContributorData] = useState(null);
  const [consultingTopicsClick, setConsultingTopicsClick] = useState(false);
  const [supportTopicsClick, setSupportTopicsClick] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showMenuButton, setShowMenuButton] = useState(true);
  const suggestEditUrl = url.split("/").slice(0, 2).join("/");
  const contributorInfo = [];
  const graphUserData = [];
  const userDataMap = new Map();
  const [showHashtagsForm, setShowHashtagsForm] = useState(false);
  const [existingHashtags, setExistingHashtags] = useState([]);
  const [hashtagsInput, setHashtagsInput] = useState("");
  const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState("");
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
  const [userAddedHashtags, setUserAddedHashtags] = useState([]);
  const [topicOwner, setTopicOwner] = useState({});
  const [showTopicsVersionHistory, setShowTopicsVersionHistory] =
    useState(false);
  const [showTopicsOwnerCollaborators, setShowTopicsOwnerCollaborators] =
    useState(false);
  const [showTopicsContributionHistory, setShowTopicsContributionHisotry] =
    useState(false);
  const [isAdminOrOwner, setIsAdminOrOwner] = useState(false);
  const [topicLockedBy, setTopicLockedBy] = useState("");
  const [lockedByUsername, setLockedByUsername] = useState("");
  const [showOwnerForm, setShowOwnerForm] = useState(false);
  const [publishScheduledDateTime, setPublishScheduledDateTime] = useState("");
  const [showTopicPublishCancelModal, setShowTopicPublishCancelModal] =
    useState(false);
  const [showRatingConfirmationPopUp, setShowRatingConfirmationPopUp] =
    useState(false);
  const [trackConsumption, settrackConsumption] = useState("");
  const [assessCustomerPulse, setassessCustomerPulse] = useState("");
  const [portfolioManagement, setportfolioManagement] = useState("");
  const [monitorFinan, setmonitorFinan] = useState("");
  const [assessDel, setassessDel] = useState("");
  const [monitorDeal, setmonitorDeal] = useState("");
  const [portfolioRobs, setportfolioRobs] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [topicContentOwner, settopicContentOwner] = useState("");
  const [topicContentOwnerEmail, setTopicContentOwnerEmail] = useState(false);
  const [showCrowdSourcableBanner, setshowCrowdSourcableBanner] =
    useState(false);
  const [isTopicCrowdSourcing, setisTopicCrowdSourcing] = useState(false);
  const [businessRelatedContent, setBusinessRelatedContent] = useState([])
  const [parentTopic, setParentTopic] = useState();
  const [topicsData, setTopicsData] = useState([]);
  const [count, setCount] = useState(4);
  const [selectedPath, setSelectedPath] = useState(0);
  const [showLinking, setLinking] = useState(true);
  const [updateApiCall, setUpdateApiCall] = useState(false);
  const hashtagsAutoComplete = async () => {
    await axiosGetService(
      `api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
        hashtagSuggestionInput
      )}`
    ).then((response) => {
      setHashtagSuggestions(response.data);
    });
  };

  const getTopicOwnerDetails = async (email) => {
    const accessToken = await axiosTokenService();
    const userData = await getOtherUserProfile(accessToken, email);
    setTopicOwner(userData);
    settopicContentOwner(userData.firstName + " " + userData.surname);
  };

  const getTopicDescriptionData = async () => {
    const response = await axiosGetService(
      `api/sdmtopics/get/${selectedTopicsListId}`
    );
    if (response !== null) {
      if (response.data !== null) {
        const hashtags = response?.data?.hashTagsList.map(({ name }) => {
          return name;
        });
        setportfolioManagement(
          window.location.origin + "/topics/1001"
        );
        setassessDel(window.location.origin + "/topics/1002");
        setmonitorFinan(window.location.origin + "/topics/1003");
        setportfolioRobs(window.location.origin + "/topics/1004");
        settrackConsumption(window.location.origin + "/topics/1005");
        setassessCustomerPulse(
          window.location.origin + "/topics/1006"
        );
        setmonitorDeal(window.location.origin + "/topics/1007");
        setExistingHashtags(hashtags);
        setTopicDescriptionData(response.data);
        setIsApprover(response.data.isApprover);
        setIsReviewer(response.data.isReviewer);
        setshowCrowdSourcableBanner(response.data.isCrowdSourceable);
        if (response.data.owner || response.data.categoryOwner) {
          getTopicOwnerDetails(response.data.owner ? response.data.owner : response.data.categoryOwner);
          setTopicContentOwnerEmail(response.data.owner ? response.data.owner : response.data.categoryOwner);
        }
        setTopicLockedBy(response.data.lastDraftChangesMadeBy);
        setIsAdminOrOwner(response.data.isOwnerOrCoOwner && !endUserView);
        setParentTopic(response.data.polygonParent);
        setCount(response.data.polygonSides);
        setSelectedPath(response.data.polygonSequence);
        setPublishScheduledDateTime(
          response.data.publishScheduledDateTimeString
        );
        if (response.data.isDrafted) {
          const accessToken = await axiosTokenService();
          const userData = await getOtherUserProfile(
            accessToken,
            response.data.lastDraftChangesMadeBy
          );
          if (userData)
            setLockedByUsername(userData.firstName + " " + userData.surname);
          else setLockedByUsername(response.data.lastDraftChangesMadeBy);
        }
        setShowOwnerForm(response.data.owner === userAlias);
      } else {
        setShowRatingConfirmationPopUp(true);
      }
    }
  };

  const getPolygonTopic = async () => {
    try {
      if (parentTopic !== null && parentTopic !== undefined && parentTopic !== 0) {
        await axiosGetService(`api/sdmtopics/getpolygontopicbyidwithtitle/${parentTopic}`,).then((response) => {
          setTopicsData(response.data);
        })
      }
    } catch (error) {
      console.log("Error", error);
    }
  }
  useEffect(() => {
		getSDMContentErrorMessage()
	}, [])
	const getSDMContentErrorMessage = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=Topic_EmptyContentMessage`).then(
			(response) => {
				setSDMContentErrorMessage(response.data[0].value);
			}
		);
	};
  const getIsTopicCrowdSourcing = async () => {
    const response = await axiosGetService(
      `api/sdmtopics/getProcedureByIsCrowdSourcing/${selectedTopicsListId}`
    );
    if (response !== null) {
      if (response.data !== null) {
        setisTopicCrowdSourcing(response.data);
      }
    }
  }

  useEffect(() => {
    if (parentTopic !== undefined && parentTopic !== 0) {
      getPolygonTopic();
    }
  }, [parentTopic])

  // call to api to get consulting or support topics data
  const getConsultingOrSupportTopics = async (projectTypeId) => {
    const response = await axiosGetService(
      `api/sdmtopics/getbycategorytype`
    );
    setConsultingTopics(response.data);
  };

  // call to api to get job aides consulting or support topics data
  const getProceduresConsultingOrSupportTopics = async (
    projectTypeId,
    proceduresId,
    pageTypeId
  ) => {
    const response = await axiosGetService(
      `api/sdmtopics/getbyprocedurefilters?artifactTypeIds=${82}&procedureTypeId=${proceduresId}&procedureFilterIds=${0}&searchTitle=0&hashTagsText=0&pageTypeId=${pageTypeId}&endUserView=${endUserView}`
    );
    setConsultingTopics(response.data);
  };

  // call to api to get Readiness consulting or support topics data
  const getReadinessConsultingOrSupportTopics = async (
    projectTypeId,
    proceduresId,
    pageTypeId
  ) => {
    const response = await axiosGetService(
      `api/sdmtopics/getbyprocedurefilters?artifactTypeIds=${84}&procedureTypeId=${proceduresId}&procedureFilterIds=${0}&searchTitle=0&hashTagsText=0&pageTypeId=${pageTypeId}&endUserView=${endUserView}`
    );

    setConsultingTopics(response.data);
  };

  const getContributorData = async () => {
    const response = await axiosGetService(
      `api/sdmtopics/getTopic2/${selectedTopicsListId}`
    );
    setContributorData(response.data);
  };

  const getContributor = (suggestions) => {
    for (const id in suggestions) {
      const userEmail = JSON.parse(suggestions[id]).authorId;
      if (userEmail && !userDataMap.has(userEmail)) {
        graphUserData.push(userEmail);
        userDataMap.set(userEmail, 1);
      }
    }
  };

  const removeDuplicateHashtag = (data) => {
    return data.filter((value, index) => data?.indexOf(value) === index);
  };

  const submitHashtags = async () => {
    if (userAddedHashtags.length > 0) {
      const postData = {
        artifactTypeId:
          Number(selectedProcedures) === 0 && Number(selectedReadiness) === 0
            ? artifactMapData["topics"]
            : selectedProcedures !== 0
              ? artifactMapData["job Aides"]
              : artifactMapData["readiness"],
        artifactId: topicsId,
        hashtags: [...userAddedHashtags, ...existingHashtags].toString(),
      };
      await axiosPostService(`api/sdmhashtags/add`, postData).then(
        (response) => {
          if (response.data.status) {
            track(1, 1200, topicDescriptionData.id, topicDescriptionData.name, {
              hashtags: userAddedHashtags.toString(),
            });
            setExistingHashtags([...userAddedHashtags, ...existingHashtags]);
            setHashtagsInput("");
            setHashtagSuggestionInput("");
            setUserAddedHashtags([]);
          } else {
            toast.error(response.data.errorMessage, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const getUsersInfo = async () => {
    if (contributorData.resultCalloutJson) {
      getContributor(contributorData.resultCalloutJson);
    }
    if (!userDataMap.has(CkEditorCurrentUser)) {
      graphUserData.push(CkEditorCurrentUser);
      userDataMap.set(CkEditorCurrentUser, 1);
    }
    const accessToken = await axiosTokenService();
    for (const id in graphUserData) {
      const userEmail = graphUserData[id];
      const userData = await getOtherUserProfile(accessToken, userEmail);
      contributorInfo.push({
        id: userData.mail,
        name: userData.firstName,
        avatar: userData.photo,
      });
    }
    setCkEditorUsers(contributorInfo);
  };

  const loadUrlTypeTopics = () => {
    if (topicDescriptionData && topicDescriptionData.topicType === 2) {
      return window.open(
        topicDescriptionData.diagramName.replace(/(<([^>]+)>)/gi, ""),
        "",
        `height=${window.screen.height},width=${window.screen.width},resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes`
      );
    }
  };

  const saveNextTopic = (category) => {
    setSelectedPath(category);
    const parentData = topicsData.find((item) => item.polygonSequence === category);
  }

  const getUrlTopicsType = () => {
    if (topicDescriptionData.topicType === 1) {
      return (
        <div
          className="topicVisioImage  ck-content"
          id="topicvisio"
          dangerouslySetInnerHTML={{
            __html: topicDescriptionData.visioFilePath,
          }}
        />
      );
    }
    if (topicDescriptionData.topicType === 3) {
      return (
        <div className={parentTopic !== undefined && parentTopic !== 0 ? "ck-content polygonDesc" : "ck-content"}
          dangerouslySetInnerHTML={{
            __html: topicDescriptionData.diagramName
          }}
        />
      );
    }
    if (topicDescriptionData.topicType === 2) {
      return (
        <a
          href={topicDescriptionData.diagramName.replace(/(<([^>]+)>)/gi, "")}
          target="_blank"
        >
          {topicDescriptionData.name}
        </a>
      );
    }
  };

  // function to set default category id when user clicks on topic landing page
  const setDeafultCategoryId = () => {
    for (const category in consultingTopics) {
      const defaultCategoryId = consultingTopics[category].categoryId;
      for (const topic in consultingTopics[category].sdmTopics) {
        if (
          Number(consultingTopics[category].sdmTopics[topic].id) ===
          Number(selectedTopicsListId)
        ) {
          setSelectedCategoryId(defaultCategoryId);
          break;
        }
      }
    }
    if (!selectedCategoryId && supportTopics !== null) {
      for (const category in supportTopics) {
        const defaultCategoryId = supportTopics[category].categoryId;
        for (const topic in supportTopics[category].sdmTopics) {
          if (
            Number(supportTopics[category].sdmTopics[topic].id) ===
            Number(selectedTopicsListId)
          ) {
            setSelectedCategoryId(defaultCategoryId);
            break;
          }
        }
      }
    }
  };

  // load and set the breadcrumb
  const loadBreadcrumb = async () => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${topicsId}&itemTypeId=${selectedProcedures !== 0 ? 82 : selectedReadiness !== 0 ? 84 : 10
      }
			&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0
			&isNotifications=false&solutionMethodId=${0}&phaseId=${0}`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());
      const topicsBreadcrumb = {
        title: breadcrumbTitle[1],
        path: window.location.search
          ? `/topics${window.location.search}`
          : "/topics",
        parent: ["home", "search"],
        type: "topics",
      };

      const currentTopicsContentBreadcrumb = {
        title: breadcrumbTitle[3],
        parent: ["topicType"],
      };

      const newBreadcrumbs = breadcrumbs.slice(
        0,
        breadcrumbs.length > 1 && breadcrumbs[1].type === "search" ? 2 : 1
      );
      newBreadcrumbs.push(topicsBreadcrumb);
      newBreadcrumbs.push(currentTopicsContentBreadcrumb);
      setBreadcrumbs(newBreadcrumbs);
      TabTitle(breadcrumbTitle[3]);
    });
  };

  const toggleTopicMenuIcon = () => {
    setShowMenuButton(!showMenuButton);
  };

  const showHistoryPreview = () => {
    // event.preventDefault();
    setCrowdSourcingAppState({
      ...crowdSourcingAppState,
      showHistory: true,
      showPreviewPopUp: true,
    });
  };

  // function to set current category id when user clicks on topic details page
  const setCurrentCategoryId = (currentCategoryId) => {
    if (Number(currentCategoryId) === Number(selectedCategoryId)) {
      setSelectedCategoryId(-1);
    } else {
      setSelectedCategoryId(currentCategoryId);
    }
  };
  const formatPublishDate = (publishDate) => {
    const date = new Date(publishDate);
    return date.toLocaleString();
  };

  const getRelatedContent = async () => {
    await axiosGetService(`api/sdmtopics/getrelatedcontent/${topicsId}`).then(
      (response) => {
        setRelatedContent(response.data);
      }
    );
  };

  // const deleteRelatedContent = async (id) => {
  //   await axiosDeleteService(`api/sdmtopics/delete/${id}`).then((response) => {
  //     if (response.data.status) {
  //       track(
  //         1,
  //         commentType === 'topic' ? 1403 : commentType === 'job aide' ? 1406 : 1412,
  //         id,
  //         commentType === 'topic'
  //           ? 'topics_related_content'
  //           : commentType === 'job aide' ? 'jobaides_related_content' : 'readiness_related_content',
  //         {
  //           deleteId: id,
  //         }
  //       );
  //       toast.error('You have deleted the content successfully', {
  //         position: 'top-right',
  //       });
  //       getRelatedContent();
  //     } else {
  //       toast.error(response.data.errorMessage, {
  //         position: 'top-right',
  //       });
  //     }
  //   });
  // };

  const getBusinessRelatedContent = async () => {
    let api = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${topicsId}/10/0/${false}`
    await axiosGetService(api).then(
      (response) => {
        let filterByDraft = response.data.filter(e => e.isDrafted === false)
        setBusinessRelatedContent(filterByDraft)
      })
  }

  useEffect(() => {
    getBusinessRelatedContent();
  }, [])

  useEffect(() => {
    getRelatedContent();
  }, [topicsId]);

  useEffect(() => {
    if (
      consultingTopics !== null &&
      !selectedCategoryId
    ) {
      setDeafultCategoryId();
    }
  }, [consultingTopics, selectedCategoryId]);

  useEffect(() => {
    loadBreadcrumb();
    //	getAdminStatus();
  }, [topicsId]);

  $(".scroll-to").bind("click");

  $(".scroll-up").bind("click");

  $("body").on("click", ".scroll-to", (e) => {
    const scrollId = document.getElementById(e.currentTarget.dataset.attrId);
    scrollId.scrollIntoView();
  });

  $("body").on("click", ".scroll-up", (e) => {
    $(".topicDescriptionDiv")[0].scroll(0, 0);
  });

  $(document).ready(function () {
    $.each($("#topicTextDescription a"), function (e, v) {
      if (
        (v.href !== undefined || v.href !== null) &&
        v.getAttribute("xlink:href") == null &&
        (v.getAttribute("linkchanged") === null ||
          v.getAttribute("linkchanged") === undefined)
      ) {
        if (!v.href) {
          $(v).attr("href", "#");
          $(v).attr("linkChanged", 1);
        }
        else {
          if (parentTopic !== undefined && parentTopic !== 0) {
            var url = processLinkChange(v.href.baseVal, v);
          } else {
            var url = processLinkChange(v.href, v);
          }

          $(v).attr("href", url);
          $(v).attr("linkChanged", 1);
        }
      }
    });
    $(
      ".is-consumption ul.tabs li, .assessCustomerPulse ul.tabs li, .portfolioManagement ul.tabs li"
    ).click(function () {
      var tab_id = $(this).attr("data-tab");

      $("ul.tabs li").removeClass("current");
      $(".tab-content").removeClass("current");

      $(this).addClass("current");
      $("#" + tab_id).addClass("current");
    });
    $(".track-consumption ul.tabs li").click(function () {
      var tab_id = $(this).attr("data-tab");

      $("ul.tabs li").removeClass("active");
      $(".tab-content").removeClass("active");

      $(this).addClass("active");
      $("#" + tab_id).addClass("active");
    });

    $.each($(".ck-content img"), function(e, v){
      if(v.hasAttribute('alt') && v.getAttribute('alt') !== ''){
        $(v).attr("role", "Presentation");
      }else{
        $(v).attr("alt", "No Image Found");
        $(v).attr("role", "None");
      }
    });
  });

  var processLinkChange = function (url, e) {
    if (url !== null && url !== undefined) {
      if (!url?.includes(window.location.origin)) {
        return url;
      }
      var redirectUrl = window.location.origin;
      var params = getParams(url);
      var urlWindow = "";
      if (
        url?.includes("activityContent") &&
        params.id &&
        params.complexityType
      ) {
        var activityid = params["id"];
        var complexityId = params["complexityType"];
        urlWindow = `${redirectUrl}/project/1/method/0/phase/null/parentPhase/null/activity/${activityid}?complexity=${complexityId}`;
      } else if (url?.includes("/topics") && params.id) {
        var topicId = params["id"];
        urlWindow = `${redirectUrl}/topics/${topicId}`;
      } else {
        return url;
      }
      return urlWindow;
    }
  };

  var getParams = function (url) {
    if (url?.includes("#")) {
      url = url?.substring(url.indexOf("#") + 1, url.length);
    }
    var params = {};
    var parser = document.createElement("a");
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  };

  $(document).ready(function () {
    $("figure").each(function (index, element) {
      if (!element.getAttribute("contenteditable")) {
        const imgElement = element.children[0];
        $(imgElement).attr({ style: $(element).attr("style") });
      }
    });
  });

  $(function () {
    $(".accordion li").on("click keypress", function (e) {
      $(this).toggleClass(" active ");
      $(this).siblings().removeClass(" active ");
      $(".submenu").stop().slideUp();
      $(".active .submenu").stop().slideDown();
      return false;
    });
  });

  useEffect(() => {
    getTopicDescriptionData();
    getIsTopicCrowdSourcing();
    getContributorData();

    if (Number(selectedProcedures) === 0 && Number(selectedReadiness) === 0) {
      if (!consultingTopics) {
        getConsultingOrSupportTopics(1);
      }
      if (!supportTopics) {
        getConsultingOrSupportTopics(2);
      }
    } else if (Number(selectedProcedures !== 0)) {
      if (!consultingTopics) {
        getProceduresConsultingOrSupportTopics(1, 2, 10);
      }
      if (!supportTopics) {
        getProceduresConsultingOrSupportTopics(2, 3, 9);
      }
    } else if (Number(selectedReadiness !== 0)) {
      if (!consultingTopics) {
        getReadinessConsultingOrSupportTopics(1, 4, 19);
      }
      if (!supportTopics) {
        getReadinessConsultingOrSupportTopics(2, 4, 18);
      }
    }
    setConsultingTopicsClick(true);
  }, [
    selectedTopicsListId,
    selectedProcedures,
    selectedReadiness,
    endUserView,
  ]);

  useEffect(() => {
    if (contributorData) {
      getUsersInfo();
    }
  }, [contributorData]);

  useEffect(() => {
    loadUrlTypeTopics();
    if (topicDescriptionData && !topicDescriptionData.isDeleted) {
      track(
        2,
        selectedProcedures !== 0 ? 11 : selectedReadiness !== 0 ? 12 : 10,
        selectedTopicsListId,
        `${topicDescriptionData.name}`
      );
    }
  }, [topicDescriptionData]);

  useEffect(() => {
    setSkipNavigationId("topicsMenu");
    setExpandTopicsLeftMenu(false);
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (hashtagSuggestionInput.trim()) {
        hashtagsAutoComplete();
      }
    }, 1000);

    return () => {
      setHashtagSuggestions([]);
      clearTimeout(timeOutId);
    };
  }, [hashtagSuggestionInput]);

  useEffect(() => {
    $("svg a").on("click", (e) => {
      if (
        e.currentTarget.getAttribute("activityid") !== undefined &&
        e.currentTarget.getAttribute("activityid") != null
      ) {
        var redirectUrl = window.location.origin;
        var activityid = e.currentTarget.getAttribute("activityid");
        const urlWindow = `${redirectUrl}/project/1/method/0/phase/null/parentPhase/null/activity/${activityid}?complexity=3`;
        window.location.href = urlWindow;
      }
    });
  });

  // call to get corresponding consulting or support data
  const getConsultingOrSupportSdmTopicsList = (topics, topic) =>
    topics.map((item) => (
      <div className="topicsAccordianViewContainer" key={item.categoryId}>
        <div
          className="TopicsParentWrapper"
          tabIndex={0}
          role="listbox"
          onKeyPress={(e) => {
            if (e.which === 13) {
              setCurrentCategoryId(item.categoryId);
            }
          }}
          onClick={() => {
            setCurrentCategoryId(item.categoryId);
          }}
          title={item.categoryName}
          aria-roledescription="dropdown"
          aria-label={item.categoryName}
          aria-expanded={
            selectedCategoryId === item.categoryId ? "true" : "false"
          }
        >
          <Icon
            iconName="ChevronDown"
            className={
              selectedCategoryId === item.categoryId
                ? "ShowTopicsChildWrapper"
                : "DoNotShowTopicsChildWrapper"
            }
          />
          <Icon
            iconName="ChevronRightMed"
            className={
              selectedCategoryId !== item.categoryId
                ? "ShowTopicsChildWrapper"
                : "DoNotShowTopicsChildWrapper"
            }
          />
          <span className="TopicsTitleSpan" title={item.categoryName}>
            {item.categoryName}
          </span>
        </div>
        <div
          className={
            selectedCategoryId === item.categoryId
              ? "ShowTopicsChildWrapper"
              : "DoNotShowTopicsChildWrapper"
          }
        >
          <div className="TopicsChildWrapper">
            <ul>
              {item.sdmTopics.map((sdmTopics) => (
                <li
                  key={sdmTopics.id}
                  className={
                    Number(selectedTopicsListId) === Number(sdmTopics.id)
                      ? "ActiveLink"
                      : "NotActiveLink"
                  }
                >
                  <Link
                    className={
                      Number(selectedTopicsListId) === Number(sdmTopics.id)
                        ? "ActiveLink"
                        : "NotActiveLink"
                    }
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        track(1, 10, sdmTopics.id, sdmTopics.name);
                      }
                    }}
                    onClick={() => {
                      track(1, 10, sdmTopics.id, sdmTopics.name);
                    }}
                    title={sdmTopics.name}
                    to={
                      window.location.search
                        ? `/topics/${topic}/${sdmTopics.id + window.location.search
                        }`
                        : `/topics/${topic}/${sdmTopics.id}`
                    }
                  >
                    {sdmTopics.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ));

  // function to select consulting topic
  const selectConsultingTopics = () => {
    setConsultingTopicsClick(true);
    setSupportTopicsClick(false);
  };

  // function to select support topic
  const selectSupportTopics = () => {
    setConsultingTopicsClick(false);
    setSupportTopicsClick(true);
  };

  if (topicDescriptionData && topicDescriptionData.isDeleted) {
    return <DeletedEntityModal errorMsg={topicDescriptionData.errorMsg} />;
  }

  function checkAndShowTooltip() {
    // Define your condition here. For example:
    const condition =
      contributorData.stateId === 2 ||
      topicDescriptionData.topicType !== 3 ||
      !topicDescriptionData.isApproverGroupExist ||
      !topicDescriptionData.isReviewerGroupExist ||
      !topicDescriptionData.isEditable ||
      topicDescriptionData.isDrafted
    // showCrowdSourcableBanner;

    if (condition) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }

  return (
    <div className="topicLandingMainContainer">
      <div className="topicsLandingPage">
        <Breadcrumbs />
        {/* <FavoriteAndFeedback /> */}
        <div className="topicsCrowdSourcingContainer">
          <div className="topicsLeftAndMainContainer">
            <TopicLeftMenu
              resetSolutionMethodFilterTag={resetSolutionMethodFilterTag}
              resetPhaseFilterTag={resetPhaseFilterTag}
              resetActivitiesFilterTag={resetActivitiesFilterTag}
              selectedProcedures={Number(selectedProcedures)}
              selectedReadiness={Number(selectedReadiness)}
              expandTopicsLeftMenu={expandTopicsLeftMenu}
              setExpandTopicsLeftMenu={setExpandTopicsLeftMenu}
              type="detailsPage"
              selectedTab={
                // topicType.toUpperCase().charAt(0) + topicType.slice(1)
                "consulting"
              }
              isUserAction={isUserAction}
              setIsUserAction={setIsUserAction}
              leftMenuSelectedId={leftMenuSelectedId}
              setLeftMenuSelectedId={setLeftMenuSelectedId}
              showTutorials={showTutorials}
            />
            {!topicDescriptionData ||
              !contributorData ||
              ckEditorUsers.length < 1 ? (
              <Spinner />
            ) : (
              <div role="main"
                className={
                  !expandTopicsLeftMenu
                    ? "topicsRightMainContainer"
                    : "topicsRightMainContainer expanded"
                }
              >
                <div className="topicsAccordianList">
                  {/* <div className="topicsMenu">
                    {showMenuButton ? (
                      <>
                        <div
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              toggleTopicMenuIcon();
                            }
                          }}
                          onClick={toggleTopicMenuIcon}
                          className={
                            showMenuButton
                              ? "topicsMenuCollapsedHamberger"
                              : "showHamberger"
                          }
                          title="topics menu"
                          aria-label="Click to expand topics menu"
                          aria-expanded="false"
                          role="button"
                        >
                          <Icon iconName="GlobalNavButton" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              toggleTopicMenuIcon();
                            }
                          }}
                          onClick={toggleTopicMenuIcon}
                          className="topicsLeftMenuCollapseIcon"
                          title="topics menu"
                          aria-label="Click to collapse topic menu"
                          aria-expanded="true"
                          role="button"
                        >
                          <Icon iconName="ChromeClose" />
                        </div>
                      </>
                    )}
                  </div> */}
                  <div
                    className={
                      showMenuButton
                        ? "showHamberger"
                        : "showTopicsListLeftMenu"
                    }
                  >
                    <div className="topicsAccordianLeftMenu displayNone">
                      <ul role="tablist">
                        <li
                          name="Consulting"
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              selectConsultingTopics();
                            }
                          }}
                          onClick={selectConsultingTopics}
                          className={
                            consultingTopicsClick ? "selectedTopicMenu" : null
                          }
                          role="tab"
                          aria-selected={
                            consultingTopicsClick ? "true" : "false"
                          }
                          aria-label={
                            consultingTopicsClick
                              ? "consulting"
                              : "support not selected"
                          }
                        >
                          <span className="consultingMenu">Consulting</span>
                        </li>
                        <li
                          name="Support"
                          tabIndex={0}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              selectSupportTopics();
                            }
                          }}
                          onClick={selectSupportTopics}
                          className={
                            supportTopicsClick ? "selectedTopicMenu" : null
                          }
                          role="tab"
                          aria-selected={supportTopicsClick ? "true" : "false"}
                          aria-label={
                            supportTopicsClick
                              ? "support"
                              : "consulting not selected"
                          }
                        >
                          <span className="supportingMenu">Support</span>
                        </li>
                      </ul>
                    </div>
                    <div className="listOfTopicsLeftMenu">
                      <div className="listOfTopicsMainContainer">
                        {consultingTopicsClick ? (
                          <>
                            {consultingTopics
                              ? getConsultingOrSupportSdmTopicsList(
                                consultingTopics,
                                "consulting"
                              )
                              : null}
                          </>
                        ) : (
                          <>
                            {supportTopics
                              ? getConsultingOrSupportSdmTopicsList(
                                supportTopics,
                                "support"
                              )
                              : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="topicsDescriptionDetails"
                  id="topicTextDescription"
                >
                  <div className="topicDescTitlePart">
                    {topicDescriptionData ? (
                      <>
                        <span className="topicVersion">
                          Document Version: {topicDescriptionData.version}
                          {topicDescriptionData.versionModified ? (
                            <>
                              ,&nbsp;&nbsp;{" "}
                              {topicDescriptionData.versionModified}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                        {(!isReviewer && !isApprover) ||
                          (isReviewer && contributorData.stateId === 0) ||
                          (isReviewer &&
                            contributorData.stateId === 2 &&
                            !isApprover) ||
                          (isApprover && contributorData.stateId === 0) ||
                          (isApprover &&
                            contributorData.stateId === 1 &&
                            !isReviewer) ? (
                          <div
                            className={`topicEdit ${contributorData.stateId === 2 ||
                              topicDescriptionData.topicType !== 3 ||
                              !topicDescriptionData.isApproverGroupExist ||
                              !topicDescriptionData.isReviewerGroupExist ||
                              !topicDescriptionData.isEditable ||
                              topicDescriptionData.isDrafted ||
                              !showCrowdSourcableBanner
                              ? "disabledEditTopic"
                              : null
                              }`}
                            title={
                              showTooltip
                                ? "You can not edit this content"
                                : "Suggest Edit"
                            }
                            onMouseEnter={checkAndShowTooltip}
                          >
                            <>
                              {isTopicCrowdSourcing ?
                                <Link
                                  linkchanged={1}
                                  tabIndex={0}
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
                                      track(
                                        1,
                                        140,
                                        topicDescriptionData.id,
                                        topicDescriptionData.name
                                      );
                                    }
                                  }}
                                  onClick={() => {
                                    track(
                                      1,
                                      140,
                                      topicDescriptionData.id,
                                      topicDescriptionData.name
                                    );
                                  }}
                                  to={
                                    isReviewer && contributorData.stateId === 0
                                      ? `${suggestEditUrl}/reviewer/edit/${topicDescriptionData.id}`
                                      : `${suggestEditUrl}/edit/${topicDescriptionData.id}`
                                  }
                                  role="link"
                                  aria-label="click to suggest edit"
                                >
                                  Suggest Edit
                                  <span className="editIcon" />
                                </Link>
                                : <></>}
                            </>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  {showTooltip && (
                    <MessageBar
                      messageBarType={MessageBarType.warning}
                      isMultiline={false}
                    >
                      <p>
                        Editing this content isn't possible due to an topic
                        configuration issue. Please contact{" "}
                        <a
                          target="_blank"
                          href={`https://teams.microsoft.com/l/chat/0/0?users=${topicContentOwnerEmail}`}
                          className="anchorTag"
                        >
                          {" "}
                          {topicContentOwner}
                        </a>{" "}
                        for more help.
                      </p>
                    </MessageBar>
                  )}

                  {topicDescriptionData ? (
                    <>
                      {topicDescriptionData.DiagramName === null ? (
                        <>
                          <br />
                          <span>No data to show</span>
                        </>
                      ) : (
                        <div className="topicDescriptionDivContainer">
                          <div className={parentTopic !== undefined && parentTopic !== 0 ? "topicPolygonDescriptionDiv" : "topicDescriptionDiv"}>
                            {!showCrowdSourcableBanner && (
                              <div
                                className="bannerActivity"
                                tabIndex="0"
                                aria-label="Not CrowdSourceable. This page is disabled for editing. Kindly reach out to the owner directly to suggest any changes"
                              >
                                <div className="banner-text">
                                  <b>{"  "}Not CrowdSourceable:</b> This page is
                                  disabled for editing. Kindly reach out to the
                                  owner directly to suggest any changes.
                                </div>
                              </div>
                            )}
                            <div className="topicsOwner">
                              <h1 className={parentTopic !== undefined && parentTopic !== 0 ? "topicTitle topicTitleNewView polygonTopicTitle" : "topicTitle topicTitleNewView"}>
                                {topicDescriptionData.name.replace(/_.*/, '')}
                              </h1>
                              {parentTopic === undefined || parentTopic === 0 ?
                                <>
                                  {topicDescriptionData.id !== 1001 &&
                                    topicDescriptionData.id !== 1002 &&
                                    topicDescriptionData.id !== 1003 &&
                                    topicDescriptionData.id !== 1004 &&
                                    topicDescriptionData.id !== 1005 &&
                                    topicDescriptionData.id !== 1006 &&
                                    topicDescriptionData.id !== 1007 &&
                                    topicDescriptionData.id !== 272 ? (
                                    <>

                                      <div className="topicsOwnerEditDetails">
                                        {topicDescriptionData.topicType === 3 &&
                                          isAdminOrOwner ? (
                                          <span
                                            className="topicsOwnerHistoryIcon"
                                            title="View Edit History"
                                            onClick={() => {
                                              setShowTopicsVersionHistory(true);
                                            }}
                                            onKeyPress={(e) => {
                                              if (e.which === 13) {
                                                setShowTopicsVersionHistory(true);
                                              }
                                            }}
                                            tabIndex={0}
                                            aria-label={`click to view edit history for ${topicDescriptionData.name} topic`}
                                            role="button"
                                          ></span>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {topicOwner.mail ? (
                                    <div
                                      className={
                                        topicDescriptionData.id !== 1001 &&
                                          topicDescriptionData.id !== 1002 &&
                                          topicDescriptionData.id !== 1003 &&
                                          topicDescriptionData.id !== 1004 &&
                                          topicDescriptionData.id !== 1005 &&
                                          topicDescriptionData.id !== 1006 &&
                                          topicDescriptionData.id !== 1007 &&
                                          topicDescriptionData.id !== 272
                                          ? "topicsOwnerDetails topicsOwnerDetailsNewView"
                                          : "topicsOwnerDetailsPM topicsOwnerDetailsNewView"
                                      }
                                    >
                                      <img
                                        src={topicOwner.photo}
                                        alt="topicOwnerImage"
                                      />
                                      <div className="topicsOwnerPersonalDetails" tabIndex={0} >
                                        <p>Owner</p>
                                        <a href={`mailto:${topicOwner.mail}`} role="link" aria-label={`Opens in new tab ${topicOwner.firstName +' '+ topicOwner.surname}`}>
                                          <span className="alternativeOfh6">
                                            {topicOwner.firstName +
                                              " " +
                                              topicOwner.surname}
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="topicOwnerCollaborators topicOwnerCollaboratorsNewView">
                                    <span className="newCollaboratorsImg">
                                      <span
                                        className="collaboratorsImg"
                                        title="Edit Approvers/Reviewers"
                                        tabIndex={0}
                                        onClick={() => {
                                          setShowTopicsOwnerCollaborators(true);
                                        }}
                                        onKeyPress={(e) => {
                                          if (e.which === 13) {
                                            setShowTopicsOwnerCollaborators(true);
                                          }
                                        }}
                                        aria-label={`click to edit approvers/reviewers for ${topicDescriptionData.name} topic`}
                                        role="button"
                                      ></span>
                                    </span>
                                  </div>
                                </>
                                :
                                <></>
                              }
                            </div>
                            <div className="existingHashTagsOfTopic _existingHashTagsOfActivity">
                              {!showHashtagsForm &&
                                existingHashtags.map((name) => (
                                  <span
                                    className="mr-1 hashTagsColor"
                                    key={name}
                                    onClick={() => {
                                      history.push(
                                        `/search?searchParam=${encodeURIComponent(
                                          name
                                        )}`
                                      );
                                    }}
                                    onKeyPress={(e) => {
                                      if (e.which === 13) {
                                        history.push(
                                          `/search?searchParam=${encodeURIComponent(
                                            name
                                          )}`
                                        );
                                      }
                                    }}
                                    tabIndex={0}
                                  >
                                    {name}
                                  </span>
                                ))}
                              {!showHashtagsForm ? (
                                <div className="addHashtagsIconContainer">
                                  <span
                                    title="Add Hashtags"
                                    className="addHashtagsIcon"
                                    onClick={() => {
                                      setShowHashtagsForm(true);
                                    }}
                                    onKeyPress={(e) => {
                                      if (e.which === 13) {
                                        setShowHashtagsForm(true);
                                      }
                                    }}
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Click to add hashtags"
                                  ></span>
                                  {/* <div className="tabsTitleToolTip">
                                    <span className="tabsToolTipArrow"></span>
                                    <span>Add Hashtags</span>
                                  </div> */}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {showHashtagsForm ? (
                              <div className="topicDetailsHasttagsContainer">
                                <div className="topicDetailsHasttagInput">
                                  <HashtagSuggestions
                                    className="topicsHashtagsInput"
                                    enableDropdown={true}
                                    data={hashtagSuggestions}
                                    setUpdateApiCall={setUpdateApiCall}
                                    setHashtagSuggestions={setHashtagSuggestions}
                                    searchValue={hashtagsInput}
                                    setSearchValue={setHashtagsInput}
                                    existingHashtags={existingHashtags}
                                    userAddedHashtags={userAddedHashtags}
                                    setUserAddedHashtags={setUserAddedHashtags}
                                    hashtagSuggestionInput={
                                      hashtagSuggestionInput
                                    }
                                    setHashtagSuggestionInput={
                                      setHashtagSuggestionInput
                                    }
                                    closeHashtagForm={() => {
                                      setHashtagsInput("");
                                      setShowHashtagsForm(false);
                                      setUserAddedHashtags([]);
                                    }}
                                    submitHashtags={submitHashtags}
                                    autoFocus={true}
                                    showSubmitButton={true}
                                    pageType={'topicDetails'}
                                  />
                                </div>

                                {existingHashtags.length > 0 ? (
                                  <div className="hashTagsContainer _existingHashTagsOfActivity mr-1 hashTagsColor">
                                    {existingHashtags.map((item) => (
                                      <span key={item}>{item}</span>
                                    ))}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            {topicDescriptionData.topicType === 2 ? (
                              <div className="WarningMessageActivityPage">
                                <MessageBar
                                  messageBarType={MessageBarType.warning}
                                  isMultiline={true}
                                >
                                  <span className="popUpMessageTop">
                                    Looking for "{topicDescriptionData.name}"
                                    document? Please click the link available
                                    below this notification.
                                  </span>
                                  <br />
                                  <br />
                                  <span className="popUpMessage">
                                    If documents are not opening automatically,
                                    please allow pop-ups for SDMPlus in your
                                    browser.{" "}
                                    <a
                                      href="https://aka.ms/AllowSDMPopups"
                                      target="_blank"
                                    >
                                      Here's how.
                                    </a>
                                  </span>
                                  <br />
                                  Only HTML topics are enabled for crowd sourcing
                                  and since this topic has
                                  visios/powerpoint/word/pdf, it is not open for
                                  crowd sourcing. Please contact
                                  <a
                                    href="https://aka.ms/sdmsupport"
                                    target="_blank"
                                  >
                                    sdmsupport&nbsp;
                                  </a>
                                  to make changes to this topic.
                                </MessageBar>
                              </div>
                            ) : null}
                            {!topicDescriptionData.isEditable ? (
                              <div className="WarningMessageActivityPage">
                                <MessageBar
                                  messageBarType={MessageBarType.warning}
                                  isMultiline={true}
                                >
                                  This topic is not enabled for crowd sourcing
                                  based on the author's request. Please reach out
                                  to the author if you want to suggest changes to
                                  the topic.
                                </MessageBar>
                              </div>
                            ) : (
                              <></>
                            )}
                            {topicDescriptionData.isDrafted ? (
                              <div className="WarningMessageActivityPage">
                                <MessageBar
                                  messageBarType={MessageBarType.warning}
                                  isMultiline={true}
                                >
                                  You can not edit this content now. Content is
                                  being updated by
                                  <a
                                    target="_blank"
                                    href={`https://teams.microsoft.com/l/chat/0/0?users=${topicLockedBy}`}
                                  >
                                    {lockedByUsername}
                                  </a>
                                  {" ."}
                                  Please check later for editing.
                                </MessageBar>
                              </div>
                            ) : (
                              <></>
                            )}
                            {topicDescriptionData.topicType === 1 ? (
                              <div className="WarningMessageActivityPage">
                                <MessageBar
                                  messageBarType={MessageBarType.warning}
                                  isMultiline={true}
                                >
                                  Only HTML topics are enabled for crowd sourcing
                                  and since this topic has
                                  visios/powerpoint/word/pdf, it is not open for
                                  crowd sourcing. Please contact
                                  <a
                                    href="https://aka.ms/sdmsupport"
                                    target="_blank"
                                  >
                                    sdmsupport&nbsp;
                                  </a>
                                  to make changes to this topic.
                                </MessageBar>
                              </div>
                            ) : null}
                            {topicDescriptionData.topicType === 3 &&
                              isReviewer &&
                              contributorData.stateId === 1 ? (
                              <div className="approverMainWrapper">
                                <div className="aprroverStatusIcon"></div>
                                <p>You have content pending for review</p>
                                <Link
                                  tabIndex={0}
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
                                      track(
                                        1,
                                        141,
                                        topicDescriptionData.id,
                                        topicDescriptionData.name
                                      );
                                    }
                                  }}
                                  onClick={() => {
                                    track(
                                      1,
                                      141,
                                      topicDescriptionData.id,
                                      topicDescriptionData.name
                                    );
                                  }}
                                  to={`/topics/reviewer/edit/${selectedTopicsListId}`}
                                >
                                  <button
                                    tabIndex={-1}
                                    type="button"
                                    className="approverApproveEditButton btn btn-primary"
                                  >
                                    Review &amp; Edit
                                  </button>
                                </Link>
                              </div>
                            ) : (
                              <>
                                {topicDescriptionData.topicType === 3 &&
                                  isApprover &&
                                  contributorData.stateId === 2 ? (
                                  <div className="approverMainWrapper">
                                    <div className="aprroverStatusIcon"></div>
                                    <p>You have content pending for approval</p>
                                    <Link
                                      tabIndex={0}
                                      onKeyPress={(e) => {
                                        if (e.which === 13) {
                                          track(
                                            1,
                                            142,
                                            topicDescriptionData.id,
                                            topicDescriptionData.name
                                          );
                                        }
                                      }}
                                      onClick={() => {
                                        track(
                                          1,
                                          142,
                                          topicDescriptionData.id,
                                          topicDescriptionData.name
                                        );
                                      }}
                                      to={`/topics/approver/edit/${selectedTopicsListId}`}
                                    >
                                      <button
                                        tabIndex={-1}
                                        type="button"
                                        className="approverApproveEditButton btn btn-primary"
                                      >
                                        Approve &amp; Edit
                                      </button>
                                    </Link>
                                  </div>
                                ) : null}
                              </>
                            )}
                            {topicDescriptionData.topicType === 3 &&
                              Number(contributorData.stateId) === 2 &&
                              isReviewer &&
                              !isApprover ? (
                              <div className="WarningMessageActivityPage">
                                {contributorData.lastContributorDescription ? (
                                  <MessageBar
                                    messageBarType={MessageBarType.warning}
                                    isMultiline={true}
                                  >
                                    You can not edit this content now. A newer
                                    version of the content is pending for
                                    approval. You can see a&nbsp;
                                    <Link
                                      tabIndex={0}
                                      onKeyPress={(e) => {
                                        if (e.which === 13) {
                                          e.preventDefault();
                                          showHistoryPreview();
                                        }
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        showHistoryPreview();
                                      }}
                                      to={"/"}
                                    >
                                      Historical Preview here
                                    </Link>
                                  </MessageBar>
                                ) : (
                                  <MessageBar
                                    messageBarType={MessageBarType.warning}
                                    isMultiline={true}
                                  >
                                    You can not edit this content now. A newer
                                    version of the content is pending for
                                    approval. There is no history available for
                                    this Topic since the contribution made by a
                                    reviewer.
                                  </MessageBar>
                                )}
                              </div>
                            ) : (
                              <>
                                {topicDescriptionData.topicType === 3 &&
                                  Number(contributorData.stateId) === 2 &&
                                  !isApprover ? (
                                  <div className="WarningMessageActivityPage">
                                    <MessageBar
                                      messageBarType={MessageBarType.warning}
                                      isMultiline={true}
                                    >
                                      You can not edit this content now. A newer
                                      version of the content is pending for
                                      approval . Please check later for editing.
                                    </MessageBar>
                                  </div>
                                ) : null}
                              </>
                            )}
                            {publishScheduledDateTime && isAdminOrOwner && (
                              <div className="topicScheduledMessageBar">
                                <MessageBar
                                  messageBarType={MessageBarType.warning}
                                  isMultiline={true}
                                >
                                  <p>
                                    Changes made to this topic has been scheduled
                                    to be published at{" "}
                                    {formatPublishDate(publishScheduledDateTime)}{" "}
                                    (Local). you can cancel here if you want to
                                  </p>
                                  <button
                                    onClick={() => {
                                      setShowTopicPublishCancelModal(true);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </MessageBar>
                              </div>
                            )}
                            <div className="topicDesc">
                              {getUrlTopicsType()}
                            </div>
                            { }
                          </div>
                          {parentTopic !== undefined && parentTopic !== 0 ?
                            <div className="topicRightDescriptionDiv">
                              <div className="topicsOwner">
                                {topicDescriptionData.id !== 1001 &&
                                  topicDescriptionData.id !== 1002 &&
                                  topicDescriptionData.id !== 1003 &&
                                  topicDescriptionData.id !== 1004 &&
                                  topicDescriptionData.id !== 1005 &&
                                  topicDescriptionData.id !== 1006 &&
                                  topicDescriptionData.id !== 1007 &&
                                  topicDescriptionData.id !== 272 ? (
                                  <>

                                    <div className="topicsOwnerEditDetails">
                                      {topicDescriptionData.topicType === 3 &&
                                        isAdminOrOwner ? (
                                        <span
                                          className="topicsOwnerHistoryIcon"
                                          title="View Edit History"
                                          onClick={() => {
                                            setShowTopicsVersionHistory(true);
                                          }}
                                          onKeyPress={(e) => {
                                            if (e.which === 13) {
                                              setShowTopicsVersionHistory(true);
                                            }
                                          }}
                                          tabIndex={0}
                                          aria-label={`click to view edit history for ${topicDescriptionData.name} topic`}
                                          role="button"
                                        ></span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {topicOwner.mail ? (
                                  <div
                                    className={
                                      topicDescriptionData.id !== 1001 &&
                                        topicDescriptionData.id !== 1002 &&
                                        topicDescriptionData.id !== 1003 &&
                                        topicDescriptionData.id !== 1004 &&
                                        topicDescriptionData.id !== 1005 &&
                                        topicDescriptionData.id !== 1006 &&
                                        topicDescriptionData.id !== 1007 &&
                                        topicDescriptionData.id !== 272
                                        ? "topicsOwnerDetails topicsOwnerDetailsNewView"
                                        : "topicsOwnerDetailsPM topicsOwnerDetailsNewView"
                                    }
                                  >
                                    <img
                                      src={topicOwner.photo}
                                      alt="topicOwnerImage"
                                    />
                                    <div className="topicsOwnerPersonalDetails" tabIndex={0} >
                                      <p>Owner</p>
                                      <a href={`mailto:${topicOwner.mail}`}>
                                        <span className="alternativeOfh6">
                                          {topicOwner.firstName +
                                            " " +
                                            topicOwner.surname}
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="topicOwnerCollaborators topicOwnerCollaboratorsNewView">
                                  <span className="newCollaboratorsImg">
                                    <span
                                      className="collaboratorsImg"
                                      title="Edit Approvers/Reviewers"
                                      tabIndex={0}
                                      onClick={() => {
                                        setShowTopicsOwnerCollaborators(true);
                                      }}
                                      onKeyPress={(e) => {
                                        if (e.which === 13) {
                                          setShowTopicsOwnerCollaborators(true);
                                        }
                                      }}
                                      aria-label={`click to edit approvers/reviewers for ${topicDescriptionData.name} topic`}
                                      role="button"
                                    ></span>
                                  </span>
                                </div>
                              </div>
                              {topicsData.length > 0 ?
                                <div className="polygonShape">
                                  <Shapes
                                    count={count}
                                    selectedPath={selectedPath}
                                    topicsData={topicsData}
                                    saveNextTopic={saveNextTopic}
                                    showLinking={showLinking}
                                    setLinking={setLinking}
                                  />
                                </div>
                                :
                                <></>
                              }

                            </div>
                            :
                            <></>
                          }
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
                {showHistory ? (
                  <HistoryPreview
                    contriutorLastSuggestions={
                      contributorData.resultCalloutJson
                    }
                    reviewedSuggestions={contributorData.resultReviewedJson}
                    contributorContent={
                      contributorData.lastContributorDescription
                    }
                    topicDescriptionData={contributorData}
                    users={ckEditorUsers}
                    currentUser={CkEditorCurrentUser}
                    existingHashtags={existingHashtags}
                  />
                ) : null}
                <TopicYammer
                  topicName={topicDescriptionData.name}
                  commentType={
                    selectedProcedures === 0 && selectedReadiness === 0
                      ? "topic"
                      : selectedProcedures !== 0
                        ? "job aide"
                        : "readiness"
                  }
                  isAdminOrOwner={isAdminOrOwner}
                  userIsOwner={userIsOwner}
                  endUserView={endUserView}
                  businessRelatedContent={businessRelatedContent}
                  sdmContentErrorMessage={sdmContentErrorMessage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showRatingConfirmationPopUp ? (
        <SupportConfirmationModal
          showRatingConfirmationPopUp={showRatingConfirmationPopUp}
          setShowRatingConfirmationPopUp={setShowRatingConfirmationPopUp}
        />
      ) : (
        <></>
      )}
      {showTopicsVersionHistory ? (
        <TopicsVersionHistory
          showTopicsVersionHistory={showTopicsVersionHistory}
          setShowTopicsVersionHistory={setShowTopicsVersionHistory}
          showTopicsContributionHistory={showTopicsContributionHistory}
          setShowTopicsContributionHisotry={setShowTopicsContributionHisotry}
          topicsId={topicsId}
          topicTitle={topicDescriptionData.name}
        />
      ) : (
        <></>
      )}
      {showTopicsOwnerCollaborators ? (
        <TopicCollabrators
          topicId={topicsId}
          setShowTopicsOwnerCollaborators={setShowTopicsOwnerCollaborators}
          topicName={topicDescriptionData.name}
          setTopicOwner={setTopicOwner}
          topicOwner={topicOwner}
          showOwnerForm={showOwnerForm}
          setShowOwnerForm={setShowOwnerForm}
          isAdminOrOwner={isAdminOrOwner}
          leftMenuSelectedId={leftMenuSelectedId}
        />
      ) : (
        <></>
      )}
      {showTopicPublishCancelModal ? (
        <TopicPublishCancelModal
          topicId={topicsId}
          publishScheduledDateTime={publishScheduledDateTime}
          name={topicDescriptionData.name}
          showPublishCancelModal={showTopicPublishCancelModal}
          setShowPublishCancelModal={setShowTopicPublishCancelModal}
          formatPublishDate={formatPublishDate}
          setPublishScheduledDateTime={setPublishScheduledDateTime}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TopicsDetailPage;
