import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { Icon } from '@fluentui/react/lib/Icon';
import fileIcons from '../../static/TemplateIcons';
import $ from 'jquery';
import { Label, Pivot, PivotItem } from '@fluentui/react';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { valid } from 'semver';
import { useAxios } from "../../context/AxiosContext";
function SearchSuggestion({
	data,
	validInput,
	errorMessage,
	searchText,
	routeToSearchResults,
	selectedKeys,
	selectedKeysData,
	recentSearchHistory,
	setShowSearchPopUp,
	popupRef,
	setSearchText,
	searchLoader,
	addSearchHistory,
	setRecentSearchHistory
}) {
	const history = useHistory();
	const { breadcrumbs, setBreadcrumbs } = useLeftMenuCollapse();
	const { track } = useAppInsights();
	const { axiosDeleteService } = useAxios();

	const handleRemoveSearchHistory = async (e, id) => {
		e.stopPropagation();
		try {
			const response = await axiosDeleteService(`api/deletesearchhistory?id=${id}`);
			if (response.data) {
				setRecentSearchHistory(prevState => prevState.filter(item => item.id !== id));
			}
		} catch (error) {
			console.error(`Error removing item with id ${id}:`, error);
		}
	};

	const rendorSearchResults = () => {
		const sortedSearchHistory = [...recentSearchHistory].sort((a, b) => b.sortBy - a.sortBy);
		let filteredResults = sortedSearchHistory.filter(item =>
			item.title.toLowerCase().includes(searchText.toLowerCase())
		).slice(0, 3);
		return (
			<>
				<span className="recentSearchHeading">Recent searches</span>
				<ul>
					{filteredResults.map((item, index) => (
						<li
							className="recentSearchList"
							key={index}
							onClick={() => setSearchText(item.title)}
						>
							{/* <span className="HistoryIcon"></span> */}
							{item.title}
							<span className="closeButton" onClick={(e) => handleRemoveSearchHistory(e, item.id)}>×</span>
						</li>
					))}
				</ul>
			</>
		);
	};

	const topicBreadcrumb = (topic) => {
		const newBreadcrumbs = breadcrumbs.slice(0, 1);
		newBreadcrumbs.push({
			title: topic.title,
			path: topic.fileLocationNew,
			parent: ['topicType', 'search'],
			type: 'topic',
		});
		setBreadcrumbs(newBreadcrumbs);
	};

	const onClickactivitySearchSuggestion = (e, item) => {
		if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
			setBreadcrumbs(breadcrumbs.slice(0, 1));
			if (item.isExternal) {
				window.location.replace(item.fileLocation);
			} else {
				history.push(`/${item.fileLocationNew}`);
			}
		}
	};

	const onClickinProgressSearchSuggestion = (e, item) => {
		if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
			setBreadcrumbs(breadcrumbs.slice(0, 1));
			history.push(`/template/${item.id}?complexityId=${item.complexityId}`);
		}
	};

	const onClickPhaseSearchSuggestion = (e, item) => {
		if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
			setBreadcrumbs(breadcrumbs.slice(0, 1));
			history.push(`/${item.fileLocationNew}`);
		}
	};

	const onClickfileSearchSuggestion = (e, item) => {
		if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
			if (item.itemTypeId === 10) {
				topicBreadcrumb(item);
			} else {
				setBreadcrumbs(breadcrumbs.slice(0, 1));
			}
			history.push(`/${item.fileLocationNew}`);
		}
	};
	const getSelectedTabData = (list) => {
		return (

			list.map((item, index) => {

				if (item.itemTypeId === 2) {
					return (
						<li
							key={item.id}
							tabIndex={0}
							aria-setsize={list.length - 1}
							aria-posinset={index + 1}
							role="listitem"
							onClick={(e) => {
								track(1, 1000, item.id, item.title, {
									complexityId: item.complexityId,
								});
								onClickactivitySearchSuggestion(e, item);
								addSearchHistory(item.title);
							}}
							onKeyPress={(e) => {
								track(1, 1000, item.id, item.title, {
									complexityId: item.complexityId,
								});
								onClickactivitySearchSuggestion(e, item);
							}}
						>
							<img
								src={fileIcons.activity}
								alt="fileType"
								className="suggestionImg"
							/>
							<span className="suggestion-title">{item.title}</span>
						</li>
					);
				}
				if (item.itemTypeId === 1) {
					return (
						<li
							key={item.id}
							tabIndex={0}
							aria-setsize={list.length - 1}
							aria-posinset={index + 1}
							role="listitem"
							onClick={(e) => {
								track(1, 1004, item.id, item.title, {
									complexityId: item.complexityId,
								});
								onClickinProgressSearchSuggestion(e, item);
								addSearchHistory(item.title);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									track(1, 1004, item.id, item.title, {
										complexityId: item.complexityId,
									});
									onClickinProgressSearchSuggestion(e, item);
								}
							}}
						>
							<img
								src={
									item.inProgress === "true"
										? fileIcons['inProgress']
										: item.fileType !== null
											? fileIcons[item.fileType.toLowerCase()]
												? fileIcons[item.fileType.toLowerCase()]
												: fileIcons['defaultTemplate']
											: fileIcons['defaultTemplate']
								}
								alt="fileType"
								className="suggestionImg"
							/>
							<span className="suggestion-title">{item.title}</span>
						</li>
					);
				}
				if (item.itemTypeId === 10 || item.itemTypeId === 15) {
					return (
						<li
							key={item.id}
							tabIndex={0}
							onClick={(e) => {
								track(
									1,
									item.itemTypeId === 10 ? 1003 : 1002,
									item.id,
									item.title
								);
								onClickfileSearchSuggestion(e, item);
								addSearchHistory(item.title);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									track(
										1,
										item.itemTypeId === 10 ? 1003 : 1002,
										item.id,
										item.title
									);
									onClickfileSearchSuggestion(e, item);
								}
							}}
						>
							<img
								src={
									item.fileType !== null
										? fileIcons[item.fileType]
											? fileIcons[item.fileType]
											: fileIcons['defaultTemplate']
										: fileIcons['defaultTemplate']
								}
								alt="fileType"
								className="suggestionImg"
							/>
							<span className="suggestion-title">{item.title}</span>
						</li>
					);
				}
				if (item.itemTypeId === 25) {
					return (
						<li
							key={item.id}
							tabIndex={0}
							onClick={(e) => {
								track(1, 1001, item.id, item.title);
								onClickPhaseSearchSuggestion(e, item);
								addSearchHistory(item.title);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									track(1, 1001, item.id, item.title);
									onClickPhaseSearchSuggestion(e, item);
								}
							}}
						>
							<img
								src={fileIcons.phase}
								alt="fileType"
								className="suggestionImg"
							/>
							<span className="suggestion-title">{item.title}</span>
						</li>
					);
				}
				if (item.itemTypeId === 81) {
					return (
						<li
							key={item.id}
							tabIndex={0}
							onClick={(e) => {
								track(1, 1005, item.id, item.title);
								onClickfileSearchSuggestion(e, item);
								addSearchHistory(item.title);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									track(1, 1005, item.id, item.title);
									onClickfileSearchSuggestion(e, item);
								}
							}}
						>
							<img
								src={fileIcons[item.fileType]}
								alt="fileType"
								className="suggestionImg"
							/>
							<span className="suggestion-title">{item.title}</span>
						</li>
					);
				}
				if (item.itemTypeId === 82) {
					return (
						<li
							key={item.id}
							tabIndex={0}
							onClick={(e) => {
								track(1, 1006, item.id, item.title);
								onClickfileSearchSuggestion(e, item);
								addSearchHistory(item.title);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									track(1, 1007, item.id, item.title);
									onClickfileSearchSuggestion(e, item);
								}
							}}
						>
							<img
								src={fileIcons[item.fileType]}
								alt="fileType"
								className="suggestionImg"
							/>
							<span className="suggestion-title">{item.title}</span>
						</li>
					);
				}

			})
		)

	};

	$(document).on('click', '.searchSuggestionResults', function (e) {
		e.stopPropagation();
		e.preventDefault();
	});

	const suggestionContainerRef = useRef(null);
	useEffect(() => {
		if (suggestionContainerRef.current) {
			// suggestionContainerRef.current.focus();
			suggestionContainerRef.current.scrollTop = 0;

		}
	}, [data]);

	// if (data.length === 0 && validInput && searchText) {
	// 	return (
	// 		<div className="loadingSpinner">
	// 			<Spinner animation="border" size="sm" />{' '}
	// 		</div>
	// 	);
	// }

	return (
		<div ref={popupRef} className={`${!validInput ? " " : "searchSuggestionResults"}`}>

			{validInput && data.hashTagsList && data.hashTagsList.length > 0 ? (

				<ul className="searchSuggestionsHashtags">
					<div className='hashtagHeading'>
						<span className='tags'>Tags</span>
						<span className='resultsFound'>Results found</span>
					</div>
					{data.hashTagsList.map((item) => (
						<li
							onClick={() => {
								routeToSearchResults({ which: 13 }, item.name);
							}}
							key={`${item.name}${item.count}`}
						>
							<span>{item.name}</span>
							<span>{item.count} Result(s)</span>
						</li>
					))}
				</ul>
			) : (
				<></>
			)}
			{!validInput ? (
				<div>
				</div>
			) : (
				<>
					{searchLoader ? (
						<div className="loadingSpinner searchSpinner">
							<div class="spinner-border spinner-border-sm"></div>
						</div>
					) : (
						<>
							{recentSearchHistory.length ? rendorSearchResults() : ''}
							<ul
								ref={suggestionContainerRef}
								role="list"
								tabIndex="0"
								aria-live="polite"
								className="searchSuggestionKeywords"
							>

								{data?.searchList?.length > 1 && searchText.length > 2 ?
									// data.searchList.map((item) => {

									// 	return 
									(
										<div id="split-suggestion-tabs">

											{/* <div className='full-screen-icon'
										tabIndex={0}
										aria-label="expand"
										role="button"
										onKeyDown={(event) => {
											if (event.which === 13) {

												routeToSearchResults({ which: 13 }, searchText)
											}
										}}
										onClick={(event) => {

											routeToSearchResults({ which: 13 }, searchText)
										}}></div> */}
											<div className='split-suggestion-tabs-section'>
												< Pivot aria-label="Search suggestion tabs">
													{selectedKeys?.length > 0 ? (selectedKeysData?.map((item, index) => {
														return (


															data.searchList.filter(({ itemTypeId }) => itemTypeId === item.key)?.length > 0 ?

																<PivotItem
																	headerText={`${item.text}(${data.searchList.filter(({ itemTypeId }) => itemTypeId === item.key).length - 1})`} 
																	headerButtonProps={{
																		'data-order': index,
																		'data-title': `${item.text}`,
																	}}
																>
																	<Label>{getSelectedTabData(data.searchList.filter(({ itemTypeId }) => itemTypeId === item.key))}</Label>
																</PivotItem> :
																<PivotItem
																	headerText={item.text}
																	headerButtonProps={{
																		'data-order': index,
																		'data-title': `${item.text}`,
																	}}
																>
																	<Label><div className='errMsg' tabIndex={0}>No results found</div></Label>
																</PivotItem>
															// <div className='errMsg'>No data found</div>
														)


													})) :

														<PivotItem
															headerText={`All(${data.searchList.length -1})`}
															headerButtonProps={{
																'data-order': 1,
																'data-title': `All`,
															}}
														>
															<Label>{getSelectedTabData(data.searchList)}</Label>
														</PivotItem>

													}
												</Pivot>
											</div>

										</div>


									) : (
										<>
											{searchText.length > 2 && data && data.length < 1 ? (
												<div className="loadingSpinner searchSpinner">
													<Spinner animation="border" size="sm" />{' '}
												</div>
											) : (
												searchText.length > 2 && <div className='errMsg' tabIndex={0}>No results found</div>)
											}
										</>
									)

								}
							</ul>

						</>
					)}
				</>
			)}

			<hr />


		</div>
	);
}

export default SearchSuggestion;
