import React, { useEffect, useState } from 'react';
import { useAppInsights } from '../../context/TelemetryContext';
import { useAxios } from '../../context/AxiosContext';
import { toast } from 'react-toastify';
import { Container, Row, Col, Card } from "react-bootstrap";
import './AddResources.css';
import $ from "jquery";
function AddResources() {
	const { track } = useAppInsights();
	const { axiosGetService, userAlias } = useAxios();
    const [data, setData] = useState([]);
    const [phaseId, setPhaseId] = useState(0);
    const [complexityId, setComplexityId] = useState(0);
    const [quickLinkTypeId, setQuickLinkTypeId] = useState(3);


    const getLeftMenuHomeData = async () => {
        await axiosGetService(
            `api/sdmquicklink/gethomepagegroupsquicklinks/${quickLinkTypeId}/${complexityId}/${phaseId}`
        ).then((response) => {
            setData(response.data);
        });
    };


    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getLeftMenuHomeData();
        },100);
        return () => {
            clearTimeout(timeOutId);
        };
    }, []);
	
$('#rightPanel .rightPanelTitle').css('padding','16px 18px');

	return (
        <Container fluid className="px-3 py-0 mt-n2">
        <Row>
          <Col xs={12} md={12} className='_additionalResourceBox'>
            {data?.map((items) => (
              <div className="" key={items?.quickLinkGroupId}>
                <h6 className="_groupHeader">{items?.quickLinkGroupName}</h6>
                <hr className='_horizontalLine' />
                <div className="d-flex flex-column">
                  {items.quickLinkActivities
                    ?.sort((a, b) => parseFloat(a.sequenceNumber) - parseFloat(b.sequenceNumber))
                    .map((value) => (
                      <a
                        href={value?.linkUrl}
                        key={value?.quickLinkActivityId}
                        className="_groupHeaderLinks mb-2"
                        tabIndex={0}
                        aria-label={value?.linkName}
                        target="_blank"
                        onClick={() => {
                          track(1, 2111, value?.quickLinkActivityId, value?.linkName);
                        }}
                        rel="noopener noreferrer"
                      >
                        {value?.linkName}
                      </a>
                    ))}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
	);
}

export default AddResources;
