import React, { memo } from 'react';
import { useRaciGrid } from './RaciContext';
const complexityCountToClassMapper = {
	1: 'complexity1',
	2: 'complexity2',
	3: 'complexity3',
	4: 'complexity4',
};
const GridRolesData = memo(({ activity }) => {
	const { roleGroupsData } = useRaciGrid();
	const complexityCount = Object.keys(roleGroupsData).reduce(
		(currentVal, complexityId) => {
			if (roleGroupsData[complexityId].isSelected) {
				return currentVal + 1;
			} else {
				return currentVal;
			}
		},
		0
	);
	return (
		<div className="genericTableComplexityRaciShowColumns">
			<div
				className={`genericTableComplexityRaciShow ${complexityCountToClassMapper[complexityCount]}`}
			>
				{Object.keys(roleGroupsData).map(
					(complexityId) =>
						roleGroupsData[complexityId].isSelected && (
							<ComplexityRolesData
								activity={activity}
								complexityId={complexityId}
								key={`complexity_${complexityId}`}
							/>
						)
				)}
			</div>
		</div>
	);
});

const ComplexityRolesData = memo(({ activity, complexityId }) => {
	const { roleGroupsData } = useRaciGrid();
	const { roleGroups, roles, isExpanded } = roleGroupsData[complexityId];
	return (
		<div
			className={`genericTableComplexityMenu
			${isExpanded ? '' : 'raciComplexityCollapsed'}`}
			id={`complexity_${complexityId}`}
		>

			{isExpanded &&
				Object.keys(roleGroups).map((roleGroupId) => {
					if (roleGroups[roleGroupId].isSelected)
						return (
							<RoleGroupsRolesData
								activity={activity}
								complexityId={complexityId}
								roleGroupId={roleGroupId}
								key={`roleGroup_${complexityId}_${roleGroupId}`}
								complexityExpanded={isExpanded}
							/>
						);
				})}

			{isExpanded ? (
				<div
					className={`raciRolesGroupUi ${roles.length <= 1 ? 'shortOneRoleMenu' : ''
						} 
							${roles.length == 2 ? 'shortTwoRoleMenu' : ''}`}
				>
					{roles.map((role) => (
						<div
							key={`role_${role.roleId}`}
							className={`genericTablePreview`}
						>
							{activity.raciData[complexityId]?.roles[role.roleId]?.raciValue}
						</div>
					))}
				</div>
			) : (
				<div
					className={`genericTablePreview raciRoleGroupCollapsed 
					${roles.length <= 1 ? 'shortOneRoleMenu' : ''} 
					${roles.length == 2 ? 'shortTwoRoleMenu' : ''}
					${isExpanded === false ? 'firstCollapsed' : ''}`}
				></div>
			)}
		</div>
	);
});

const RoleGroupsRolesData = memo(
	({ activity, complexityId, roleGroupId, complexityExpanded }) => {
		const { roleGroupsData } = useRaciGrid();
		const { roles, isExpanded } =
			roleGroupsData[complexityId].roleGroups[roleGroupId];
		return (
			<>
				{isExpanded ? (
					<div
						className={`raciRolesGroupUi ${roles.length <= 1 ? 'shortOneRoleMenu' : ''
							} 
							${roles.length == 2 ? 'shortTwoRoleMenu' : ''}`}
					>
						{roles.map((role) => (
							<div
								key={`role_${role.roleId}`}
								className={`genericTablePreview`}
								role="cell"
							>
								{
									activity.raciData[complexityId]?.roleGroups[roleGroupId]
										?.roles?.[role.roleId]?.raciValue
								}
							</div>
						))}
					</div>
				) : (
					<div
						className={`genericTablePreview raciRoleGroupCollapsed 
					${roles.length <= 1 ? 'shortOneRoleMenu' : ''} 
					${roles.length == 2 ? 'shortTwoRoleMenu' : ''}
					${complexityExpanded === false ? 'firstCollapsed' : ''}`}
					></div>
				)}
			</>
		);
	}
);

export default GridRolesData;
