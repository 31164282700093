import React, { useState, useEffect } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useAppInsights } from '../../context/TelemetryContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAxios } from '../../context/AxiosContext';
import PhaseCreationModal from './PhaseAddition/CreatePhase';
import PhaseUpdateModal from './PhaseAddition/UpdatePhase';
import UpdatePhasePopUp from './PhaseAddition/UpdatePhasePopUp';
import PhaseLockModal from './LockModal';
import AddBreadCrumb from '../../helpers/BreadcrumbService';
import MethodOwnerCollaborator from '../PhaseLeftMenu/MethodOwnerCollaborator';
import {TabTitle} from '../../utils/GeneralFunctions'

import './PhaseLeftMenu.css';
import { Icon } from '@fluentui/react';

function PhaseLeftMenu({
	phasesList,
	solutionMethodName,
	projectTypeId,
	solutionMethodId,
	setPhaseCreated,
	coOwners,
	setDeletePhase,
	showUpdatePhasePopUp,
	setShowPhaseUpdatePopUp,
	isRetired,
	setShowDeletePhaseModal,
	getSolutionMethodData,
	phaseCreated,
	showPhaseUpdateModal,
	setShowPhaseUpdateModal,
	updatePhaseId,
	setUpdatePhaseId,
	selectedTab,
	setSelectedTab,
	selectedPhase,
	setSelectedPhase,
	isSolutionCollaboratorsEdited,
    setIsSolutionCollaboratorsEdited,
	setExistingApprovers,
    setExistingCoOwners, 
    setExistingReviewers,
	isPhaseEdited,
	setIsPhaseEdited,
	updateSvgFileName,
	handleSvgClick
}) {
	const location = window.location.pathname;
	const { axiosGetService, userAlias } = useAxios();
	const { url } = useRouteMatch();
	const { track } = useAppInsights();
	const {
		phase,
		parentPhase,
		setParentPhase,
		setPhase,
		setSvg,
		svg,
		setSkipNavigationId,
		breadcrumbs,
		setBreadcrumbs,
		endUserView,
	} = useLeftMenuCollapse();
	const [showPhaseLockModal, setShowPhaseLockModal] = useState(false);
	const [showPhaseCreationModal, setShowPhaseCreationModal] = useState(false);
	const [lockEmailId, setLockEmailId] = useState(null);
	const [showPhaseMethoOwnerModal, setShowPhaseMethoOwnerModal] =
		useState(false);

	const handleSvgLoad = () => {
		if (phase.id !== null && svg.enable && svg.type === 'phase') {
			track(2, 151, phase.id, phase.title);
			setSvg({
				...svg,
				enable: true,
				type: 'phase',
				url: phase.svgFileName,
			});
		} else if (svg.source !== 'matrixView') {
			setSvg({
				enable: false,
				url: null,
				type: null,
			});
		}
	};

	

	const loadBreadcrumb = async (phaseId) => {
		await axiosGetService(
			`api/sdmuser/getbreadcrumb?itemId=${phaseId}&itemTypeId=25&
			isEdit=false&isAutoComplete=false&isSearchResultsHome=false&
			isSearchResults=false&isMyContributions=false&documentStatus=0&
			isNotifications=false&solutionMethodId=${solutionMethodId}&phaseId=${phaseId}`
		).then((response) => {
			const breadcrumbTitle = response.data
				.split('>')
				.map((item) => item.trim());
			if (isRetired) {
				const solutionMethodBreadcrumb = {
					title: breadcrumbTitle[2],
					type: 'solutionMethod',
					path: `/project/${projectTypeId}/method/${solutionMethodId}`,
					parent: ['home'],
				};
				const phaseBreadcrumb = {
					title: breadcrumbTitle[3],
					type: 'Phase',
					path: window.location.url,
					parent: 'solutionMethod',
				};
				const newBreadcrumbs = AddBreadCrumb(breadcrumbs.slice(0, 1), [
					solutionMethodBreadcrumb,
					phaseBreadcrumb,
				]);
				setBreadcrumbs(newBreadcrumbs);
			} else {
				const projectBreadcrumb = {
					title: breadcrumbTitle[1],
					type: 'projectType',
					path: `/project/${projectTypeId}`,
					parent: ['home', 'search'],
				};
				const solutionMethodBreadcrumb = {
					title: breadcrumbTitle[2],
					type: 'solutionMethod',
					path: `/project/${projectTypeId}/method/${solutionMethodId}`,
					parent: 'projectType',
				};
				const phaseBreadcrumb = {
					title: breadcrumbTitle[3],
					type: 'Phase',
					path: window.location.url,
					parent: 'solutionMethod',
				};
				const newBreadcrumbs = AddBreadCrumb(
					breadcrumbs.slice(
						0,
						breadcrumbs.length > 1 && breadcrumbs[1].type === 'search' ? 2 : 1
					),
					[projectBreadcrumb, solutionMethodBreadcrumb, phaseBreadcrumb]
				);
				setBreadcrumbs(newBreadcrumbs);
				TabTitle(breadcrumbTitle[3]);
			}
		});
	};

	useEffect(() => {
		handleSvgLoad();
	}, [phase]);

	useEffect(() => {
		setSkipNavigationId('toggleView');
	}, []);

	useEffect(() => {
		if (location === url) {
			if (svg.source !== 'matrixView') {
				setSvg({
					enable: false,
					type: null,
					url: null,
				});
			}
		}
	}, [location]);
	return (
		<div
			className={
				solutionMethodName === 'Delivery Management'
					? 'phaseLeftMenu phaseLeftMenuDeliveryManagement phaseLeftMenuNewBladeView'
					: 'phaseLeftMenu phaseLeftMenuNewBladeView'
			}
		>
			<div
				className={
					coOwners.includes(userAlias) && !endUserView
						? 'solutionMethodTitle coOwnersLeftMenu'
						: 'solutionMethodTitle'
				}
			>
				<h4>{solutionMethodName}</h4>
				<div className="solutionMethodTitleDetals">
					{coOwners.includes(userAlias) && !endUserView && (
						<>
							<span
								className="phaseCollaboratorDiv"
								title="Edit Approvers/Reviewers"
							>
								<span
									className="phaseCollaboratorAddIcon"
									tabIndex="0"
									onClick={() => {
										setShowPhaseMethoOwnerModal(true);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setShowPhaseMethoOwnerModal(true);
										}
									}}
									aria-label={`${solutionMethodName} edit approvers/reviewers`}
									role="button"
								></span>
							</span>
							<span className="addNewPhaseDiv" title="Add New Phase">
								<span
									className="phaseAddIcon"
									tabIndex="0"
									onClick={() => {
										setShowPhaseCreationModal(true);
										track(1, 800, 'Phase_Create', solutionMethodName);
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											setShowPhaseCreationModal(true);
											track(1, 800, 'Phase_Create', solutionMethodName);
										}
									}}
									aria-label={`${solutionMethodName} add a new phase`}
									role="button"
								></span>
							</span>
						</>
					)}
					<button
						//type="button"
						onClick={() => {
							handleSvgClick();
							updateSvgFileName();
						}}
						className="toggleView"
					>
						<span
							onKeyPress={(e) => {
								if (e.which === 13) {
									handleSvgClick();
									updateSvgFileName();
								}
							}}
							className={
								svg.type !== 'phase' ? 'swimlineIcon' : 'swimlineIconactive'
							}
							role="button"
							aria-pressed={svg.type !== 'phase' ? 'false' : 'true'}
							aria-label="visio and blade view"
							title="Toggle between Visio and Blade View"
						></span>
					</button>
				</div>
			</div>
			{phasesList.length === 0 || phasesList === null ? (
				<></>
			) : (
				<ul
					className={
						coOwners.includes(userAlias)
							? ' phaseLeftNavMenu coOwner'
							: 'phaseLeftNavMenu nonOwner'
					}
				>
					{phasesList.map((item) => (
						<>
							{item.isDrafted && coOwners.includes(userAlias) ? (
								<li

									key={item.phaseId}
									className={`${phase.title===item.phaseName?'activePhaseBox':''} ${
										item.isDrafted && item.isRefered
											? 'draftPhase isRefferedPhase'
											: 'nonDraftPhase'
										
									}`}
								>
									<div>
										<NavLink
											to={`/project/${1}/method/${solutionMethodId}/phase/${item.phaseId}/parentPhase/${parentPhase.id}`}
											key={`phase_${item.phaseId}`}
											activeClassName="activePhase"
											tabIndex={0}
											onClick={(e) => {
												if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
													track(1, 25, `${item.phaseId}`, item.phaseName);
													setPhase({
														...phase,
														id: Number(item.phaseId),
														svgFileName: item.svgFileName,
													});
													setParentPhase({
														id: null,
														title: null,
													});

													if (svg.type === 'phase') {
														loadBreadcrumb(item.phaseId);
													}
												}
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
														track(1, 25, `${item.phaseId}`, item.phaseName);
														setPhase({
															...phase,
															id: Number(item.phaseId),
															svgFileName: item.svgFileName,
														});
														setParentPhase({
															id: null,
															title: null,
														});
														if (svg.type === 'phase') {
															loadBreadcrumb(item.phaseId);
														}
													}
												}
											}}
											role="link"
											title={item.phaseName}
										>
											<span>{item.phaseName}</span>
										</NavLink>
										{(!coOwners.includes(userAlias) || endUserView) &&
										item.isRefered ? (
											<div className="phaseReferredDiv">
												<span
													className="phaseReferred"
													tabIndex="0"
													title={`Linked Phase from solution method ${item.referedFromSolutionMethodName}`}
												></span>
											</div>
										) : (
											<></>
										)}
										{coOwners.includes(userAlias) && !endUserView && (
											<>
												<span
													className={
														item.isDrafted ? 'draftPhaseText' : 'nonDraftPhase'
													}
												>
													Draft
												</span>
												{item.isRefered ? (
													<div className="phaseReferredDiv">
														<span
															tabIndex="0"
															className="phaseReferred"
															title={`Linked Phase from solution method ${item.referedFromSolutionMethodName}`}
														></span>
													</div>
												) : (
													<></>
												)}
												<span
													className="phaseEditDeletMore"
													tabIndex="0"
													onClick={() => {
														setUpdatePhaseId(item.phaseId);
														setShowPhaseUpdatePopUp(true);
														setSelectedPhase(item);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															setUpdatePhaseId(item.phaseId);
															setShowPhaseUpdatePopUp(true);
															setSelectedPhase(item);
														}
													}}
													role="button"
													aria-label={`${item.phaseName} phase`}
													aria-expanded={
														updatePhaseId === item.phaseId &&
														showUpdatePhasePopUp
															? 'true'
															: 'false'
													}
												>
													<Icon iconName="MoreVertical" />
													{updatePhaseId === item.phaseId &&
													showUpdatePhasePopUp ? (
														<UpdatePhasePopUp
															setShowPhaseUpdateModal={setShowPhaseUpdateModal}
															setShowPhaseUpdatePopUp={setShowPhaseUpdatePopUp}
															solutionMethodId={solutionMethodId}
															phaseId={updatePhaseId}
															setDeletePhase={setDeletePhase}
															phaseName={item.phaseName}
															projectTypeId={projectTypeId}
															parentPhaseId={parentPhase.id}
															setShowDeletePhaseModal={setShowDeletePhaseModal}
															setUpdatePhaseId={setUpdatePhaseId}
															className="parentPhaseUpdatePopUp"
															selectedPhase={selectedPhase}
														/>
													) : (
														<></>
													)}
												</span>
											</>
										)}
									</div>
									<hr tabIndex={-1} aria-hidden="true" />
								</li>
							) : (
								<></>
							)}
							{!item.isDrafted ? (
								<li
									key={item.phaseId}
									className={`${phase.title===item.phaseName?'activePhaseBox':''} ${
										coOwners.includes(userAlias) &&
										!endUserView &&
										item.isRefered
											? 'coOwner isRefferedPhase'
											: item.isRefered
											? 'isRefferedPhase'
											: 'nonOwner'
									}`}
								>
									<div>
										<NavLink
											to={`/project/${1}/method/${solutionMethodId}/phase/${item.phaseId}/parentPhase/${parentPhase.id}`}
											key={`phase_${item.phaseId}`}
											activeClassName="activePhase"
											onClick={(e) => {
												if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
													track(1, 25, `${item.phaseId}`, item.phaseName);
													setPhase({
														...phase,
														id: Number(item.phaseId),
														svgFileName: item.svgFileName,
													});
													setParentPhase({
														id: null,
														title: null,
													});
													if (svg.type === 'phase') {
														loadBreadcrumb(item.phaseId);
													}
												}
											}}
											onKeyPress={(e) => {
												if (e.which === 13) {
													if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
														track(1, 25, `${item.phaseId}`, item.phaseName);
														setPhase({
															...phase,
															id: Number(item.phaseId),
															svgFileName: item.svgFileName,
														});
														setParentPhase({
															id: null,
															title: null,
														});
														if (svg.type === 'phase') {
															loadBreadcrumb(item.phaseId);
														}
													}
												}
											}}
											role="link"
											title={item.phaseName}
										>
											<span>{item.phaseName}</span>
										</NavLink>
										{(!coOwners.includes(userAlias) || endUserView) &&
										item.isRefered ? (
											<div className="phaseReferredDiv">
												<span
													tabIndex="0"
													className="phaseReferred"
													title={`Linked Phase from solution method ${item.referedFromSolutionMethodName}`}
												></span>
											</div>
										) : (
											<></>
										)}
										{coOwners.includes(userAlias) && !endUserView && (
											<>
												<span
													className={
														item.isDrafted ? 'draftPhaseText' : 'nonDraftPhase'
													}
												>
													Draft
												</span>
												{item.isRefered ? (
													<div className="phaseReferredDiv">
														<span
															tabIndex="0"
															className="phaseReferred"
															title={`Linked Phase from solution method ${item.referedFromSolutionMethodName}`}
														></span>
													</div>
												) : (
													<></>
												)}
												<span
													className="phaseEditDeletMore"
													tabIndex="0"
													onClick={() => {
														setUpdatePhaseId(item.phaseId);
														setShowPhaseUpdatePopUp(true);
														setSelectedPhase(item);
													}}
													onKeyPress={(e) => {
														if (e.which === 13) {
															setUpdatePhaseId(item.phaseId);
															setShowPhaseUpdatePopUp(true);
															setSelectedPhase(item);
														}
													}}
													role="button"
													aria-label={`${item.phaseName} options`}
													aria-expanded={
														updatePhaseId === item.phaseId &&
														showUpdatePhasePopUp
															? 'true'
															: 'false'
													}
												>
													<Icon iconName="MoreVertical" />
													{updatePhaseId === item.phaseId &&
													showUpdatePhasePopUp ? (
														<UpdatePhasePopUp
															setShowPhaseUpdateModal={setShowPhaseUpdateModal}
															setShowPhaseUpdatePopUp={setShowPhaseUpdatePopUp}
															solutionMethodId={solutionMethodId}
															phaseId={updatePhaseId}
															setDeletePhase={setDeletePhase}
															phaseName={item.phaseName}
															projectTypeId={projectTypeId}
															parentPhaseId={parentPhase.id}
															setShowDeletePhaseModal={setShowDeletePhaseModal}
															setUpdatePhaseId={setUpdatePhaseId}
															className="parentPhaseUpdatePopUp"
															selectedPhase={selectedPhase}
														/>
													) : (
														<></>
													)}
												</span>
											</>
										)}
									</div>
									<hr tabIndex={-1} aria-hidden="true" />
								</li>
							) : (
								<></>
							)}
						</>
					))}
				</ul>
			)}
			{showPhaseLockModal ? (
				<PhaseLockModal
					showPhaseLockModal={showPhaseLockModal}
					setShowPhaseLockModal={setShowPhaseLockModal}
					lockEmailId={lockEmailId}
					setLockEmailId={setLockEmailId}
				/>
			) : (
				<></>
			)}
			{showPhaseCreationModal && (
				<PhaseCreationModal
					showPhaseCreationModal={showPhaseCreationModal}
					setShowPhaseCreationModal={setShowPhaseCreationModal}
					projectTypeId={projectTypeId}
					solutionMethodId={solutionMethodId}
					setPhaseCreated={setPhaseCreated}
					type="phase"
					getSolutionMethodData={getSolutionMethodData}
					methodOwners={coOwners}
					phaseCreated={phaseCreated}
					phaseBuilderType="create"
				/>
			)}
			{showPhaseUpdateModal && (
				<PhaseUpdateModal
					showPhaseUpdateModal={showPhaseUpdateModal}
					setShowPhaseUpdateModal={setShowPhaseUpdateModal}
					projectTypeId={projectTypeId}
					solutionMethodId={solutionMethodId}
					setPhaseCreated={setPhaseCreated}
					phaseCreated={phaseCreated}
					updatePhaseId={updatePhaseId}
					showPhaseLockModal={showPhaseLockModal}
					setShowPhaseLockModal={setShowPhaseLockModal}
					setLockEmailId={setLockEmailId}
					type="phase"
					getSolutionMethodData={getSolutionMethodData}
					methodOwners={coOwners}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
					phaseBuilderType="update"
					isPhaseEdited={isPhaseEdited}
					setIsPhaseEdited={setIsPhaseEdited}
				/>
			)}
			{showPhaseMethoOwnerModal && (
				<MethodOwnerCollaborator
					showPhaseMethoOwnerModal={showPhaseMethoOwnerModal}
					setShowPhaseMethoOwnerModal={setShowPhaseMethoOwnerModal}
					solutionMethodId={solutionMethodId}
					isSolutionCollaboratorsEdited={isSolutionCollaboratorsEdited}
					setIsSolutionCollaboratorsEdited={setIsSolutionCollaboratorsEdited}
					setExistingCollaboratorsApprovers={setExistingApprovers} 
					setExistingCollaboratorsCoOwners={setExistingCoOwners} 
					setExistingCollaboratorsReviewers={setExistingReviewers}
				/>
			)}
		</div>
	);
}

export default PhaseLeftMenu;
