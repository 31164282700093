import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import "./RaciGrid.css";
import Breadcrumbs from "../Breadcrumbs";
import { useAxios } from "../../context/AxiosContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import RaciGridHeader from "./RaciGridHeader";
import Spinner from "../../helpers/Spinner";
import ActivityGrid from "./ActivityGrid";
import { useRaciGrid } from "./RaciContext";
import RaciGridFilters from "./RaciGridFilters";
import axios from "axios";
import $ from "jquery";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { useAppInsights } from "../../context/TelemetryContext";
import { TabTitle } from "../../utils/GeneralFunctions";
import { handleTernaryCondition } from "../../helpers/Validation";
import { responsiveFontSizes } from "@material-ui/core";

const Grid = () => {
  const timeoutIdRef = useRef(null);
  const { axiosGetService, axiosGetServiceWithCancel } = useAxios();
  const { track } = useAppInsights();
  const { breadcrumbs, setBreadcrumbs, appState } = useLeftMenuCollapse();
  const [complexities, setComplexities] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [dynamicFilterTagMapping, setDynamicFilterTagMapping] = useState({});
  const [currentAxiosCancelToken, setCurrentAxiosCancelToken] = useState(null);
  const [defaultMethodIds, setDefaultMethodIds] = useState("");
  const [defaultRoleGroupIds, setDefaultRoleGroupIds] = useState("");
  const [roleGroupsDataWithoutFilter, setRoleGroupsDataWithoutFilter] = useState([]);
  const [defaultRaciComplexity, setDefaultRaciComplexity] = useState(3);
  const [applyFilter, setApplyFilter] = useState(false);
  const {
    selectedComplexites,
    raciGridData,
    setRaciGridData,
    roleGroupsData,
    setRoleGroupsData,
    setSelectedComplexites,
    dynamicFilterTag,
    setDynamicFilterTag,
  } = useRaciGrid();

  const getDefaultdynamiccomplexity = () => {
    axiosGetService(
      `api/sdmsolutionmethod/defaultdynamiccomplexityraci`
    ).then((response) => {
      setDefaultRaciComplexity(response.data);
    });
  };
  const getSelectedFiltersIds = () => {
    const selectedFiltersIds = [];
    const filterIds = Object.keys(dynamicFilterTag);

    for (const id of filterIds) {
      if (dynamicFilterTag[Number(id)].selectedTagId.length > 0) {
        selectedFiltersIds.push(
          `${Number(id)}` +
          "-" +
          dynamicFilterTag[Number(id)].selectedTagId.toString()
        );
      }
    }
    return selectedFiltersIds;
  };
  const loadBreadcrumb = () => {
    const pageBreadcrumb = {
      title: "RACI Grid",
      parent: "Home",
      type: "raciGrid",
      path: window.location.pathname,
    };
    const newBreadcrumbs = breadcrumbs.slice(0, 1);
    newBreadcrumbs.push(pageBreadcrumb);
    setBreadcrumbs(newBreadcrumbs);
    TabTitle("RACI Grid");
  };
  const getComplexities = async () => {
    await axiosGetService(`api/activities/projectcomplexity`).then(
      (response) => {
        setComplexities(response.data);
      }
    );
  };
  const getRaciDataForActivity = (activityId, raciData) => {
    const rolesData = {};
    const activityRaciComplexites = raciData.filter(
      (activity) => activity.activityId === activityId
    );
    for (const complexityData of activityRaciComplexites) {
      if (complexityData && !rolesData[complexityData.complexityId]) {
        const { complexityId, roleGroups } = complexityData;
        rolesData[complexityId] = {
          roles: [],
          roleGroups: {},
        };
        for (const roleGroupData of roleGroups) {
          const { roleGroupName, roleGroupId, roles } = roleGroupData;
          if (!roleGroupId) {
            rolesData[complexityId].roles = {};
            roles.forEach((role) => {
              if (role?.roleId) {
                rolesData[complexityId].roles[role.roleId] = role;
              }
            });
          } else {
            rolesData[complexityId].roleGroups[roleGroupId] = {
              name: roleGroupName,
              roles: {},
              isSelected: true,
              isExpanded: true,
            };
            roles.forEach((role) => {
              if (role?.roleId) {
                rolesData[complexityId].roleGroups[roleGroupId].roles[
                  role.roleId
                ] = role;
              }
            });
          }
        }
      }
    }
    return rolesData;
  };
  const handleRoleGroupData = (roleGroupData, complexityId, rolesData) => {
    const { roleGroupName, roleGroupId, roles } = roleGroupData;
    if (!roleGroupId) {
      rolesData[complexityId].roles = roles;
    } else {
      rolesData[complexityId].roleGroups[roleGroupId] = {
        name: roleGroupName,
        roles: roles,
        isSelected: true,
        isExpanded: handleTernaryCondition(
          rolesData[complexityId],
          rolesData[complexityId].isExpanded,
          true
        ),
      };
    }
  };
  const getRaciGridData = async () => {
    const selectedFiltersIds = getSelectedFiltersIds();
    if (selectedFiltersIds.length === 0) {
      if (defaultMethodIds?.length > 0) {
        setDynamicFilterTag((prevValue) => {
          return {
            ...prevValue,
            15: {
              ...prevValue[15],
              selectedTagId: defaultMethodIds
                .split(",")
                ?.map((solutionMethodId) => Number(solutionMethodId)),
            },
          };
        });
      }
      if (defaultRoleGroupIds?.length > 0) {
        setDynamicFilterTag((prevValue) => {
          return {
            ...prevValue,
            86: {
              ...prevValue[86],
              selectedTagId: defaultRoleGroupIds
                .split(",")
                ?.map((roleGroupId) => Number(roleGroupId)),
            },
          };
        });
      }
      return;
    }
    let gridData = [];
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (currentAxiosCancelToken) {
      currentAxiosCancelToken.cancel();
    }
    setCurrentAxiosCancelToken(source);
    setShowSpinner(true);
    const headerResult = await axiosGetServiceWithCancel(
      `api/sdmactivity/getraciviewrolegroupsdata?complexityIds=${selectedComplexites.length > 0 && selectedComplexites[0] !== ''
        ? selectedComplexites.toString()
        : defaultRaciComplexity
      }&filterIds=${selectedFiltersIds.length > 0 ? `${selectedFiltersIds.join("|")}` : 0
      }`,
      source.token
    );

    const rolesData = {};
    if (headerResult.data?.length > 0) {
      for (const complexityData of headerResult.data) {
        const { complexityId, complexityName, roleGroups } = complexityData;
        rolesData[complexityId] = {
          complexityName: complexityName,
          roles: [],
          roleGroups: {},
          isSelected: true,
          isExpanded: roleGroupsData[complexityId]
            ? roleGroupsData[complexityId].isExpanded
            : true,
        };
        for (const roleGroupData of roleGroups) {
          const { roleGroupName, roleGroupId, roles } = roleGroupData;
          if (!roleGroupId) {
            rolesData[complexityId].roles = roles;
          } else {
            rolesData[complexityId].roleGroups[roleGroupId] = {
              name: roleGroupName,
              roles: roles,
              isSelected: true,
              isExpanded: roleGroupData[complexityId]?.roleGroups?.[roleGroupId]
                ? roleGroupData[complexityId].roleGroups[roleGroupId].isExpanded
                : true,
            };
          }
        }
      }
      setRoleGroupsData(rolesData);
    } else {
      setShowSpinner(false);
      setRoleGroupsData({});
      return;
    }
    const getRefredMethodData = async (
      solutionMethodId,
      parent,
      previousParent
    ) => {
      const referedFiltersIds = [];
      const filterIds = Object.keys(dynamicFilterTag);
      for (const id of filterIds) {
        if (
          dynamicFilterTag[Number(id)].selectedTagId.length > 0 &&
          Number(id) !== 15 &&
          Number(id) !== 25
        ) {
          referedFiltersIds.push(
            `${Number(id)}` +
            "-" +
            dynamicFilterTag[Number(id)].selectedTagId.toString()
          );
        }
      }

      try {
        const { data: methodsData } = await axiosGetServiceWithCancel(
          `api/sdmactivity/getraciviewmethoddata?complexityIds=${selectedComplexites.length > 0 && selectedComplexites[0] !== ''
            ? selectedComplexites.toString()
            : defaultRaciComplexity
          }&filterIds=${referedFiltersIds.length > 0
            ? `15-${solutionMethodId}|${referedFiltersIds.join("|")}`
            : `15-${solutionMethodId}`
          }`,
          source.token
        );
        const { data: raciData } = await axiosGetServiceWithCancel(
          `api/sdmactivity/getraciviewactivityracidata?complexityIds=${selectedComplexites.length > 0 && selectedComplexites[0] !== ''
            ? selectedComplexites.toString()
            : defaultRaciComplexity
          }&filterIds=${referedFiltersIds.length > 0
            ? `15-${solutionMethodId}|${referedFiltersIds.join("|")}`
            : `15-${solutionMethodId}`
          }`,
          source.token
        );
        for (const solutionMethod of methodsData) {
          const {
            phases,
            solutionMethodId,
            solutionMethodName,
            projectTypeId,
          } = solutionMethod;
          const previousSolutionMethod = previousParent?.activities?.find(
            (method) => method.solutionMethodId === solutionMethodId
          );
          const gridMethod = {
            solutionMethodId: solutionMethodId,
            name: solutionMethodName,
            raciData: new Set(),
            isExpanded: previousSolutionMethod
              ? previousSolutionMethod.isExpanded
              : true,
            isSelected: true,
            isRefered: true,
            phases: [],
          };
          setSolutionMethodData(
            phases,
            gridMethod,
            raciData,
            previousSolutionMethod,
            true,
            solutionMethodId,
            projectTypeId
          );
          parent.activities.push(gridMethod);
        }
        setRaciGridData(JSON.parse(JSON.stringify(gridData)));
      } catch { }
    };
    const setGridData = async () => {
      try {
        const methodPromise = axiosGetServiceWithCancel(`api/sdmactivity/getraciviewmethoddata?complexityIds=${selectedComplexites.length > 0 && selectedComplexites[0] !== ''
          ? selectedComplexites.toString()
          : defaultRaciComplexity
          }&filterIds=${selectedFiltersIds.length > 0
            ? `${selectedFiltersIds.join("|")}`
            : 0
          }`,
          source.token
        );
        const raciPromise = axiosGetServiceWithCancel(`api/sdmactivity/getraciviewactivityracidata?complexityIds=${selectedComplexites.length > 0 && selectedComplexites[0] !== ''
          ? selectedComplexites.toString()
          : defaultRaciComplexity
          }&filterIds=${selectedFiltersIds.length > 0
            ? `${selectedFiltersIds.join("|")}`
            : 0
          }`,
          source.token
        );
        const [{ data: methodsData }, { data: raciData }] = await Promise.all([
          methodPromise,
          raciPromise,
        ]);
        for (const solutionMethod of methodsData) {
          const {
            phases,
            solutionMethodId,
            solutionMethodName,
            projectTypeId,
          } = solutionMethod;
          const previousSolutionMethod = raciGridData.find(
            (method) => method.solutionMethodId === solutionMethodId
          );
          const gridMethod = {
            solutionMethodId: solutionMethodId,
            name: solutionMethodName,
            raciData: new Set(),
            isExpanded: previousSolutionMethod
              ? previousSolutionMethod.isExpanded
              : true,
            isSelected: true,
            isRefered: false,
            phases: [],
          };
          setSolutionMethodData(
            phases,
            gridMethod,
            raciData,
            previousSolutionMethod,
            false,
            solutionMethodId,
            projectTypeId
          );
          gridData = [...gridData, gridMethod];
        }
        setRaciGridData(JSON.parse(JSON.stringify(gridData)));
      } catch { }
    };

    const setSolutionMethodData = (
      phases,
      gridMethod,
      raciData,
      previousSolutionMethod,
      refredMethod,
      solutionMethodId,
      projectTypeId
    ) => {
      for (const phase of phases) {
        const { phaseId, name: phaseName, activities } = phase;
        let previousPhase;
        if (previousSolutionMethod) {
          previousPhase = previousSolutionMethod.phases.find(
            (_phase) => _phase.phaseId === phaseId
          );
        }
        const gridPhase = {
          name: phaseName,
          activities: [],
          isRefered: phase.isRefered,
          isExpanded: previousPhase ? previousPhase.isExpanded : true,
          isSelected: true,
          phaseId: phaseId,
          referedFromSolutionMethodName:
            phase.referedFromSolutionMethodName.length === 0
              ? null
              : phase.referedFromSolutionMethodName,
        };
        for (const phaseActivity of activities) {
          setPhaseData(
            phaseActivity,
            gridPhase,
            raciData,
            previousPhase,
            refredMethod,
            solutionMethodId,
            phaseId,
            projectTypeId
          );
        }
        gridMethod.phases = [...gridMethod.phases, gridPhase];
      }
    };

    const setPhaseData = (
      phaseActivity,
      gridPhase,
      raciData,
      previousPhase,
      refredMethod,
      solutionMethodId,
      phaseId,
      projectTypeId
    ) => {
      const { subGroupName, subGroupId, groupName, groupId, isGroupRefered, isProcessStep } =
        phaseActivity;
      if (groupName) {
        let group = gridPhase.activities.find(
          (group) => group.groupId === groupId
        );
        let previousGroup;
        if (previousPhase) {
          previousGroup = previousPhase.activities.find(
            (_group) => _group.groupId === groupId
          );
        }
        if (!group) {
          group = {
            groupId,
            name: groupName,
            activities: [],
            isRefered: isGroupRefered,
          };
          gridPhase.activities = [...gridPhase.activities, group];
        }
        if (subGroupName) {
          let subGroup = group.activities.find(
            (subGroup) => subGroup.subGroupId === subGroupId
          );
          let previousSubGroup;
          if (previousGroup) {
            previousSubGroup = previousGroup.activities.find(
              (_subGroup) => _subGroup.subGroupId === subGroupId
            );
          }
          if (!subGroup) {
            subGroup = {
              subGroupId,
              name: subGroupName,
              activities: [],
              isRefered: isGroupRefered,
            };
            group.activities = [...group.activities, subGroup];
          }
          setActivityData(
            subGroup,
            phaseActivity,
            raciData,
            previousSubGroup,
            refredMethod,
            solutionMethodId,
            phaseId,
            projectTypeId
          );
        } else {
          setActivityData(
            group,
            phaseActivity,
            raciData,
            previousGroup,
            refredMethod,
            solutionMethodId,
            phaseId,
            projectTypeId
          );
        }
      } else {
        setActivityData(
          gridPhase,
          phaseActivity,
          raciData,
          previousPhase,
          refredMethod,
          solutionMethodId,
          phaseId,
          projectTypeId
        );
      }
    };

    const setActivityData = (
      parent,
      phaseActivity,
      raciData,
      previousParent,
      refredMethod,
      solutionMethodId,
      phaseId,
      projectTypeId
    ) => {
      const {
        title: activityName,
        activityId: actvityIdentifier,
        id: activityId,
        isRefered,
        referedSolutionMethodId,
        referedFromSolutionMethodName,
        referedFromPhaseName,
        referedFromPhaseId,
        referedFromSolutionMethodId,
        isProcessStep,
        hasBusinessRule
      } = phaseActivity;
      if (!referedSolutionMethodId) {
        let activity = parent.activities.find(
          (activity) => activity.activityId === activityId
        );
        if (!activity) {
          activity = {
            activityId,
            name: activityName,
            isRefered,
            isProcessStep,
            hasBusinessRule,
            raciData: {},
            referedFromSolutionMethodName:
              referedFromSolutionMethodName.length === 0
                ? null
                : referedFromSolutionMethodName,
            referedFromPhaseName:
              referedFromPhaseName.length === 0 ? null : referedFromPhaseName,
            solutionMethodId: solutionMethodId,
          };
          activity.raciData = getRaciDataForActivity(activityId, raciData);
          activity.link = `/project/${projectTypeId}/method/${referedFromSolutionMethodId
            ? referedFromSolutionMethodId
            : solutionMethodId
            }/phase/${referedFromPhaseId ? referedFromPhaseId : phaseId
            }/parentPhase/${null}/activity/${actvityIdentifier}?complexity=${Object.keys(activity.raciData).includes("3")
              ? 3
              : Object.keys(activity.raciData)[0] ?? 0
            }`;
          parent.activities.push(activity);
        }
      } else if (!refredMethod) {
        getRefredMethodData(referedSolutionMethodId, parent, previousParent);
      }
    };
    await setGridData();
    setShowSpinner(false);
  };
  const convertStringToJSON = (input) => {
    const result = {};
    const entries = input.split('|');

    entries.forEach(entry => {
      const [key, values] = entry.split('-');
      const selectedTagIds = values.split(',').map(Number);
      result[key] = {
        selectedTagId: selectedTagIds
      };
    });
    return result;
  }

  const getDefaultFilters = async () => {
    try {
      const response = await axiosGetService(`api/sdmusersettings/getusersettings/${16}/false/0`);
      if (response.data) {
        const filterTag = convertStringToJSON(response.data[0].filters);
        setDynamicFilterTag(filterTag);
        setSelectedComplexites(response.data[0].complexities.toString());
        setApplyFilter(!applyFilter);
      }
    } catch (error) {
      console.error("Error fetching default filters", error);
    }
  }

  const clearFilters = () => {
    setSelectedComplexites([appState.defaultComplexity.id]);
    const newDynamicFilterTag = { ...dynamicFilterTag };
    const filterIds = Object.keys(newDynamicFilterTag);
    for (const id of filterIds) {
      newDynamicFilterTag[Number(id)].selectedTagId = [];
    }
    setDynamicFilterTag(newDynamicFilterTag);
    getDefaultFilters();
  };

  useEffect(() => {
    timeoutIdRef.current = setTimeout(getDefaultFilters, 1500);
    return () => clearTimeout(timeoutIdRef.current);
  }, []);

  useEffect(() => {
    track(2, 2101, 0, "Raci Grid");
    loadBreadcrumb();
    getComplexities();
  }, []);

  useEffect(() => {
    if (document?.location?.pathname === "/racigrid") {
      $("#topMenu_5").addClass("topMenuItemActive");
      $("#topMenuMob_5").addClass("topMenuItemActive");
    }
    return () => {
      $("#topMenu_5").removeClass("topMenuItemActive");
      $("#topMenuMob_5").removeClass("topMenuItemActive");
    };
  }, [raciGridData]);

  useEffect(() => {
    getDefaultdynamiccomplexity();
  }, [])

  useEffect(() => {
    const timoutId = setTimeout(getRaciGridData, 2000);
    return () => clearTimeout(timoutId);
  }, [applyFilter, defaultRaciComplexity]);

  useEffect(() => {
    $(".genericTableComplexityMenuSub").each((_index, complexityHeader) => {
      $(complexityHeader).on("scroll", () => {
        const complexityId = $(complexityHeader).attr("data-complexityid");
        let headerScrollPos = $(complexityHeader).scrollLeft();
        $(`.genericTableComplexityMenu`).each(
          (_index, complexityRoleElement) => {
            if (
              $(complexityRoleElement).attr("id") ===
              `complexity_${complexityId}`
            ) {
              complexityRoleElement.scrollLeft = headerScrollPos;
            }
          }
        );
      });
    });
  });
  return (
    <div className="raciGridViewMainContainer">
      <div className="raciGridBreadcrumb" role="navigation" aria-label="Breadcrumb">
        <Breadcrumbs />
        <div className="raciMessageBar">
          <div className="raciFiltersPart">
            <span>Revert to Default</span>

            <span
              role="button"
              aria-label="Clear"
              className="ClearFiltersButton"
              onClick={clearFilters}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  clearFilters();
                }
              }}
            ></span>
          </div>
          <button
            role="button"
            aria-label="Filters"
            className="filtersButton"
            onClick={() => setShowFilters(!showFilters)}
          >
            Filters
            <Icon
              aria-hidden="true"
              iconName="FilterSolid"
              title="Filters"
              className="searchIcon"
            />
          </button>
        </div>
        <RaciGridFilters
          applyFilter={applyFilter}
          setApplyFilter={setApplyFilter}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          complexities={complexities}
          setDynamicFilterTagMapping={setDynamicFilterTagMapping}
          dynamicFilterTag={dynamicFilterTag}
          setDynamicFilterTag={setDynamicFilterTag}
          roleGroupsData={roleGroupsDataWithoutFilter}
        />
      </div>
      <div className="raciGridViewContainer" role="main">
        {showSpinner ? (
          <Spinner />
        ) : Object.keys(roleGroupsData).length > 0 ? (
          <>
            (
            <div className="raciGridView">
              <div className="genericTableList">
                <RaciGridHeader
                  raciGridData={raciGridData}
                  roleGroupsData={roleGroupsData}
                  setRoleGroupsData={setRoleGroupsData}
                />
              </div>
            </div>
            )
            <div className="raciGridView">
              <ActivityGrid />
            </div>
          </>
        ) : (
          <div className="topicsListContainer">
            <p className="noWorkInstructions">
              No Activites found with current filters.Please try changing filter
              selections!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Grid;
