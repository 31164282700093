import * as React from 'react'
import {
  InAppFeedBackInfo,
  initializeInAppFeedback,
  updateFeedbackOptions,
  FeedbackUiType,
  IFeedbackInitOptions,
  defaultFeedbackOptions,
  InAppFeedbackScenarioType,
  FeedbackType
} from './inapp-feedback'
import urlContains from '../../../config';
import { useAuth } from '../../../context/UserAuthContext';

let feedbackData = defaultFeedbackOptions

const feedbackOptions: IFeedbackInitOptions = defaultFeedbackOptions
const hostName: string = feedbackOptions.clientName ?? 'OcvFeedbackDemoHostingApp'
const language: string = 'en'
const feedbackInfo: InAppFeedBackInfo = {
  hostName: hostName,
  language: language
}

const { 
  ocvFeedbackCentroUrlProd,
  ocvFeedbackAppId,
  ocvFeedbackCentroUrlCI,
  ocvFeedbackForumUrl,
  ocvMyFeedbackUrl,
  ocvPrivacyUrl,
  ocvIsProduction,
  ocvFeatureArea,
  ocvTenantId
} = urlContains(window.location.hostname);

if (ocvFeedbackCentroUrlProd) {
  initializeInAppFeedback(feedbackInfo, ocvFeedbackCentroUrlProd)
  feedbackData.feedbackConfig!.isDisplayed = true
  feedbackData.appId = ocvFeedbackAppId
  feedbackData.feedbackConfig!.feedbackForumUrl = ocvFeedbackForumUrl
  feedbackData.feedbackConfig!.myFeedbackUrl = ocvMyFeedbackUrl
  feedbackData.feedbackConfig!.privacyUrl = ocvPrivacyUrl
  feedbackData.tenantId = ocvTenantId
  feedbackData.isProduction = ocvIsProduction === 'true' ? true : false 
  feedbackData.telemetry!.featureArea = ocvFeatureArea
  feedbackData.feedbackConfig!.isEmailCollectionEnabled = true
}

export default function OCVFeedback({ isFeedbackPositive, objectId, aiResponse }) {
  
  const { user } = useAuth();
  
  function updateFeedbackUiTypeToThumbsDown(aiResponse) {
    feedbackData.userId = objectId
    feedbackData.feedbackConfig!.email = user.profile.email
    feedbackData.feedbackConfig!.initialFeedbackType = FeedbackType.Frown
    feedbackData.feedbackConfig!.isScreenRecordingEnabled = false
    feedbackData.feedbackConfig!.isScreenshotEnabled = false
    feedbackData.feedbackConfig!.isFileUploadEnabled = false
    feedbackData.feedbackConfig!.feedbackUiType = FeedbackUiType.Modal
    feedbackData.feedbackConfig!.supportUrl = undefined
    feedbackData.feedbackConfig!.appData = JSON.stringify({ 'Conversation Id': aiResponse?.conversationId, 'Message Id': aiResponse?.id, 'Page Name': document.title, 'Question': aiResponse?.question, 'Answer': aiResponse?.message })
    feedbackData.feedbackConfig!.scenarioConfig = {
      scenarioType: InAppFeedbackScenarioType.AIThumbsDown,
      isScenarioEnabled: false
    }
    updateFeedbackOptions(feedbackData)
  }

  React.useEffect(() => {
    if (isFeedbackPositive === false) {
      updateFeedbackUiTypeToThumbsDown(aiResponse)
    }
  }, [isFeedbackPositive])

  return (
    <span></span>
  )
}