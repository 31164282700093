import React, { useState, useEffect } from 'react';
import { useAxios } from '../../../context/AxiosContext';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHistory, useLocation } from 'react-router';
import { Icon } from '@fluentui/react/lib/Icon';
import { handleTernaryCondition } from '../../../helpers/Validation';
import Spinner from '../../../helpers/Spinner';

const TutorialCategoryAndTutorialSequence = () =>{
    const { pathname } = useLocation();
	const history = useHistory();
    const [showSpinner, setShowSpinner] = useState(true);
    const { axiosGetService, axiosPostService } = useAxios();
    const [tutorialCategoryData, setTutorialCategoryData] = useState([]);
    const [expandedTutorialCategory, setExpandedTutorialCategory] = useState([]);
    const [disableBtn, setDisableBtn] = useState(true);

    const getCateoriesWithTutorialVideos = async () => {
        try{
            await axiosGetService(`api/sdmtutorials/gettutorialsandcategorywithsequence`).then((response) => {
                if(response.data){
                    setTutorialCategoryData(response.data);
                    setShowSpinner(false);
                }
            });
        }catch(e){
            console.log("Error",e);
        }
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
    
        if (result.type === 'Category') {
            // Reorder Category
            const newCategory = reorder(
                tutorialCategoryData,
                result.source.index,
                result.destination.index
            );
            newCategory.forEach((item, index) => {
                item.categorySequence = index+1;
            })
            setTutorialCategoryData(newCategory);
            setDisableBtn(false);
        } else {
            const tutorialCategory = tutorialCategoryData.find(video => video.categoryId === result.type);
            if (!tutorialCategory) {
                return;
            }
            const newTutorialVideo = reorder(
                tutorialCategory.tutorials,
                result.source.index,
                result.destination.index
            );
            newTutorialVideo.forEach((item, index) => {
                item.tutorialSequence = index+1;
            })
            setTutorialCategoryData(
                tutorialCategoryData.map(g =>
                g.categoryId === tutorialCategory.categoryId
                    ? { ...g, tutorials: newTutorialVideo }
                    : g
                )
            );
            setDisableBtn(false);
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const saveUpdatedSequence = async () => {
        try{
            await axiosPostService(`api/sdmtutorials/updateVideoCategoriesAndTutorialSequence`,tutorialCategoryData).then((response)=>{
                if(response.data){
                    toast.info(`You have successfully sequenced the video tutorials and video tutorial categories.`, {
						position: 'top-right',
					});
                    history.push(pathname)
                }
            })
        }catch(error){
            console.log("Error",error)
        }
    }

    useEffect(()=> {
        getCateoriesWithTutorialVideos();
    },[]);

    const handleCategoryExpand = (categoryId, type) => {
        setExpandedTutorialCategory(prevState => {
            const isExpanded = prevState.includes(categoryId);
            if (type !== 'filterUpdate' && isExpanded) {
                // Collapse the group
                return prevState.filter(id => id !== categoryId);
            } else if (!isExpanded) {
                // Expand the group
                return [...prevState, categoryId];
            }
            return prevState;
        });
    };

    return(
        <>
            {showSpinner ? (
                <Spinner />
            ) : (
                <>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className='tutorialCategoryContainer'>
                            <div className="sequentialTutorialCategoryList">
                                <Droppable droppableId="all-category" type="Category">
                                    {(provided) => (
                                        <div className='sequentialdraggbleTutorialCategoryList'
                                            ref={provided.innerRef} {...provided.droppableProps}>
                                            {tutorialCategoryData?.map((data, index)=> (
                                                <Draggable key={String(data.categoryTitle)} draggableId={data.categoryTitle} index={index}>
                                                    {(provided) => (
                                                        <div className='tutorialCategorySequenceContainer'
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <div className='sequentialTutorialCategoryItem'
                                                                tabIndex={0}
                                                                key={`${data.categoryTitle}_${data.categoryId}`}
                                                                onKeyPress={(e) => {
                                                                    if (e.which === 13) {
                                                                        handleCategoryExpand(`${data.categoryTitle}_${data.categoryId}`, 'categoryUpdate');
                                                                    }
                                                                }}
                                                                onClick={() => handleCategoryExpand(`${data.categoryTitle}_${data.categoryId}`, 'categoryUpdate')}
                                                                aria-expanded={expandedTutorialCategory.includes(`${data.categoryTitle}_${data.categoryId}`)
                                                                    ? 'true'
                                                                    : 'false'
                                                                }
                                                                role="tree"
                                                            >
                                                                <Icon iconName="GlobalNavButton" />
                                                                <span className='tutorialCategoryTitleSpan'>
                                                                    {data.categoryTitle}
                                                                </span>
                                                                <Icon className='expandTutorialCategory' iconName={expandedTutorialCategory.includes(`${data.categoryTitle}_${data.categoryId}`) ?
                                                                    'ChevronDown'
                                                                    : 'ChevronRight'
                                                                } />
                                                            </div>
                                                            <Droppable droppableId={data.categoryId} type={data.categoryId}>
                                                                {(provided) => (
                                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                        {expandedTutorialCategory.includes(`${data.categoryTitle}_${data.categoryId}`) && 
                                                                            (data.tutorials !== null ? (
                                                                                data.tutorials.map((item, videoIndex) => (
                                                                                    <Draggable key={item.tutorialId} draggableId={String(item.tutorialId)} index={videoIndex}>
                                                                                        {(provided) => (
                                                                                            <div className='sequentialTutorialVideoContainer'
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                            >
                                                                                                <div key={`${item?.tutorialTitle}_${item?.tutorialId}`} className="sequentialTutorialVideoItem" >
                                                                                                    <Icon iconName="GlobalNavButton" />
                                                                                                    <span className='tutorialVideoTitleSpan'>
                                                                                                        {item?.tutorialTitle}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                            ))
                                                                        ) : (
                                                                            <div className="sequentialTutorialVideoContainer">
                                                                                    <span className="tutorialVideoTitleSpan">No Tutorial Video Found</span>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>
                    </DragDropContext>
                    <div className='saveTutorialsSequenceContainer' onClick={saveUpdatedSequence}>
                        <button className='saveTutorialSequenceBtn' disabled={disableBtn} tabIndex={0}>
                            <span className='saveTutorialSequenceBtnSpan'>
                                Save
                            </span>
                        </button>
                    </div>
                </>
            )}
        </>
    );
}

export default TutorialCategoryAndTutorialSequence;