import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';

import { useAppInsights } from '../../context/TelemetryContext';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { useAxios } from '../../context/AxiosContext';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import Collaborators from '../TopicsOwner/Collaborators';

const TopicCollabrators = ({
	topicId,
	setShowTopicsOwnerCollaborators,
	topicName,
	setTopicOwner,
	showOwnerForm,
	setShowOwnerForm,
	isAdminOrOwner,
	leftMenuSelectedId,
}) => {
	const { track } = useAppInsights();
	const { axiosGetService, axiosPostService } = useAxios();
	const [coOwners, setCoOwners] = useState([]);
	const [reviewers, setReviewers] = useState([]);
	const [approvers, setApprovers] = useState([]);
	const [existingCoOwners, setExistingCoOwners] = useState([]);
	const [existingReviewers, setExistingReviewers] = useState([]);
	const [existingApprovers, setExistingApprovers] = useState([]);
	const [showSpinner, setshowSpinner] = useState(true);
	const [apiCalled, setApiCalled] = useState(false);
	const [previousPostData, setPreviousPostData] = useState({});
	const [topicOwnerInfo, setTopicOwnerInfo] = useState({});
	const [editingMode,setEditingMode] = useState(false);
	const [categoryOwner,setCategoryOwner] = useState({})
	const [categoryOwnerOrCoowner,setcategoryOwnerOrCoowner] = useState(false)

	const getTopicOwnerInfo = async (email,isInhertedValue) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, email).then((response) => {
			const modifiedResponse = { ...response, isInhertedValues: isInhertedValue };
			setTopicOwnerInfo(modifiedResponse);
		});
	};
	const getCategoryOwnerInfo = async (email) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, email).then((response) => {
			setCategoryOwner(response);
		});
	};
    
	const getUser = async (userEmail, setValue,isInhertedValue) => {
		setshowSpinner(true);
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			const modifiedResponse = { ...response, isInhertedValues: isInhertedValue };
    
			setValue((prevState) => [...prevState, modifiedResponse]);
		});
		setTimeout(() => {
			setshowSpinner(false);
		}, 1000);
	};

	const getCollaboratorsInfo = async (membersList, setValue,isInhertedValue) => {
		for (const userEmail of membersList) {
			getUser(userEmail, setValue,isInhertedValue);
		}
	};

	const getCollaborators = async () => {
		await axiosGetService(
			`api/sdmtopics/getTopicCollaborators/${topicId}`
		).then((response) => {
			getCollaboratorsInfo(
				response.data.coOwnerMembersList,
				setExistingCoOwners,
				response.data.isInheritedCoOwnerMembers
			);
			getCollaboratorsInfo(
				response.data.reviewerMembersList,
				setExistingReviewers,
				response.data.isInheritedReviewers
			);
			getCollaboratorsInfo(
				response.data.approverMembersList,
				setExistingApprovers,
				response.data.isInheritedApprovers
			);
			if (response.data.owner) {
				getTopicOwnerInfo(response.data.owner,response.data.isInheritedOwner);
			}
			if(response.data.categoryOwner){
				getCategoryOwnerInfo(response.data.categoryOwner)
			}
			setPreviousPostData({
				coOwnerMembersList: response.data.coOwnerMembersList.sort(),
				approverMembersList: response.data.approverMembersList.sort(),
				reviewerMembersList: response.data.reviewerMembersList.sort(),
				owner: response.data.owner,
			});
			setShowOwnerForm(showOwnerForm || response.data.isCategoryOwnerOrCoOwner);
			setcategoryOwnerOrCoowner(response.data.isCategoryOwnerOrCoOwner);
			if (
				response.data.coOwnerMembersList.length === 0 &&
				response.data.reviewerMembersList.length === 0 &&
				response.data.approverMembersList.length === 0
			) {
				setshowSpinner(false);
			}
		});
	};

	const resetStates = () => {
		setShowTopicsOwnerCollaborators(false);
	};

	const saveDetails = async () => {
		const reviewersEmails = reviewers
		.filter(item => !item.isInhertedValues || item.isInhertedValues === undefined)
		.map(({ mail }) => mail);
		
		const approversEmails = approvers
		.filter(item => !item.isInhertedValues || item.isInhertedValues === undefined)
		.map(({ mail }) => mail);
		
		const coOwnersEmails = coOwners
		.filter(item => !item.isInhertedValues || item.isInhertedValues === undefined)
		.map(({ mail }) => mail);
		
		const topicOwnerEmail = (!topicOwnerInfo.isInhertedValues || topicOwnerInfo.isInhertedValues === undefined) ? topicOwnerInfo.mail:null;
		

		const postData = {
			coOwnerMembersList: coOwnersEmails.sort(),
			approverMembersList: approversEmails.sort(),
			reviewerMembersList: reviewersEmails.sort(),
			owner: topicOwnerEmail,
		};
		if (JSON.stringify(previousPostData) !== JSON.stringify(postData)) {
			postData.TopicId = topicId;
			setApiCalled(true);
			await axiosPostService(
				`api/sdmtopics/savetopiccollaborations`,
				postData
			).then((response) => {
				if (response.data.status) {
					toast.info('You have successfully updated the collaborators', {
						position: 'top-right',
					});
					track(1, 1803, topicId, 'Topic collaborators update', {
						posData: postData,
					});
				} else {
					toast.error(response.data.errorMessage, {
						position: 'top-right',
					});
				}
				setApiCalled(false);
				setTopicOwner(Object.keys(topicOwnerInfo).length != 0 ? topicOwnerInfo : categoryOwner);
			});
			resetStates();
		}
	};

	useEffect(() => {
		getCollaborators();
	}, []);

	useEffect(() => {
		setCoOwners(existingCoOwners);
	}, [existingCoOwners]);

	useEffect(() => {
		setReviewers(existingReviewers);
	}, [existingReviewers]);

	useEffect(() => {
		setApprovers(existingApprovers);
	}, [existingApprovers]);

	return (
		<Modal
			className="phaseCreationRightPopup"
			show={true}
			onHide={() => {
				// Don't do anything.
			}}
		>
			<Modal.Header>
				<Modal.Title> Collaborators	</Modal.Title>
				<Icon
					iconName="ChromeClose"
					title="Close"
					tabIndex={0}
					role="button"
					aria-label={`Click to close the ${topicName} topic collaborators modal`}
					onClick={() => {
						setShowTopicsOwnerCollaborators(false);
					}}
					onKeyPress={(e) => {
						if (e.which === 13) {
							setShowTopicsOwnerCollaborators(false);
						}
					}}
				/>
			</Modal.Header>
			<Modal.Body>
				<div className="methodOwnerMainContainer">
					<div className="methodOwnerPage">
						<div className="methodOwnerContainer">
							<div className={isAdminOrOwner ? "methodLeftContainer":"methodLeftContainerEndUserView"}>
								<ul className="methodPhaseLeftMenu">
									<li className="activePhaseTab">
										{isAdminOrOwner && <span>Collaborators </span>}
									</li>
								</ul>
							</div>
							<div className="middleDetailsContainer">
								<div className="methodOwnerMiddleContainer">
									<Collaborators
										coOwners={coOwners}
										setCoOwners={setCoOwners}
										reviewers={reviewers}
										setReviewers={setReviewers}
										approvers={approvers}
										setApprovers={setApprovers}
										showSpinner={showSpinner}
										topicOwnerInfo={topicOwnerInfo}
										setTopicOwnerInfo={setTopicOwnerInfo}
										showOwnerForm={true}
										isAdminOrOwner={isAdminOrOwner}
										artifactName={topicName}
										editingMode={editingMode}
										setEditingMode={setEditingMode}
										leftMenuSelectedId={leftMenuSelectedId}
										categoryOwnerOrCoowner={categoryOwnerOrCoowner}

									/>
								</div>
							</div>
							{editingMode &&
							<div className="saveContinueDetailsContainer newTopicCollaboratorsButtons">
								<button onClick={resetStates}>
									Cancel
								</button>
								<button
									onClick={() => {
										saveDetails();
									}}
									tabIndex={0}
									onKeyPress={(e) => {
										if (e.which === 13) {
											saveDetails();
										}
									}}
									disabled={apiCalled}
									className="forwardButton"
								>
									<span className="saveContinueButton  newSaveContinueSpan">
										Save  
									</span>
								</button>
							</div>
                            } 
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TopicCollabrators;
