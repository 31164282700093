import React, { useState, useEffect } from 'react';

import { Icon } from '@fluentui/react';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAxios } from '../../context/AxiosContext';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import { getUsersList } from '../../helpers/GraphService';

import SolutionMethodForm from './Form';
import SolutionMethodSequence from './Sequence';
import { useHistory, useLocation } from 'react-router-dom';

const AdminEditSolutionMethod = ({
	showEditSolutionMethodlogy,
	setShowEditSolutionMethodlogy,
	selectedSolutionMethod,
	setSelectedSolutionMethod,
	methods,
	isAdminMenu,
	isEditSolutionMethod,
	setIsEditSolutionMethod,
	setIsSolutionCollaboratorsEdited,
	isSolutionCollaboratorsEdited
}) => {
	const tabs = [
		{
			id: 1,
			title: 'Solution Method Details',
		},
		{ id: 2, title: 'Sequence' },
	];
	const [selectedComplexities, setSelectedComplexities] = useState([])
	let [dynamicComplexities, setDynamicComplexities] = useState([])
	const [dynamicComplexityIds, setDynamicComplexityIds] = useState([])
	const defaultComplexity = { id: 3, isSelected: false, name: 'None' };
	const { axiosGetService, axiosPostService, userAlias } = useAxios();
	const { getSolutionMethodsByProjectId, endUserView } = useLeftMenuCollapse();
	const [currentTab, setCurrentTab] = useState(1);
	const [methodName, setMethodName] = useState('');
	const [methodType, setMethodType] = useState({ id: 3, name: 'None' });
	const [parentMethod, setParentMethod] = useState({});
	const [acronym, setAcronym] = useState('');
	const [diagram, setDiagram] = useState('');
	const [shortName, setShortName] = useState('');
	const [isProcessStep, setIsProcessStep] = useState(false);
	const [enableComplexity, setEnableComplexity] = useState(false);
	const [isRetired, setIsRetired] = useState(false);
	const [isMerged, setIsMerged] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [isShowInLeftMenu, setIsShowInLeftMenu] = useState(false);
	const [complexityCritical, setComplexityCritical] = useState({});
	const [complexityHigh, setComplexityHigh] = useState({});
	const [complexityMedium, setComplexityMedium] = useState({});
	const [complexityLow, setComplexityLow] = useState({});
	// const [complexityPDoc, setComplexityPDoc] = useState({});
	const [owner, setOwner] = useState('');
	const [ownerValue, setOwnerValue] = useState('');
	const [coOwners, setCoOwners] = useState([]);
	const [coOwnerValue, setCoOwnerValue] = useState('');
	const [approvers, setApprovers] = useState([]);
	const [approverValue, setApproverValue] = useState('');
	const [reviewers, setReviewers] = useState([]);
	const [reviewerValue, setReviewerValue] = useState('');
	const [description, setDescription] = useState('');
	const [methodData, setMethodData] = useState({});
	const [methodId, setMethodId] = useState(selectedSolutionMethod);
	const { pathname } = useLocation();
	const history = useHistory();
	const [usersList, setUsersList] = useState({
		coOwner: [],
		reviewer: [],
		approver: [],
		methodOwner: [],
	});
	const [complexities, setComplexities] = useState([])
	const [error, setError] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		methodOwner: false,
	});
	const [emailFound, setEmailFound] = useState({
		coOwner: false,
		approver: false,
		reviewer: false,
		methodOwner: false,
	});
	// sequence
	const [sequence, setSequence] = useState(
		methods.map((item) => {
			return {
				id: item.solutionMethodId.toString(),
				name: item.solutionMethodName,
				type:
					item.solutionMethodId === 0 ||
						item.solutionMethodId === selectedSolutionMethod
						? 'new'
						: 'old',
			};
		})
	);

	//spinner
	const [showSpinner, setShowSpinner] = useState(true);
	const [newMethod, setNewMethod] = useState(
		selectedSolutionMethod ? false : true
	);
	const [projectComplexity, setProjectComplexity] = useState([]);
	const [pc, setPc] = useState([])
	const [defaultCompDD,setDefaultCompDD] = useState([]);
	const [defCompId,setDefCompId] = useState('');
	const [defCompData,setDefCompData] = useState([])
	const [selectedGovernanceId, setSelectedGovernanceId] = useState([])
	const [tempPc,setTempPc] = useState([]);
	useEffect(() => { getComplexities() }, [])

	useEffect(()=>{
		if(enableComplexity === false){
			let tempData = pc;
			setTempPc(tempData)
			let defComp1 = defComp.map(item => ({
				...item,
				complexityGovernanceList: item.complexityGovernanceList.map(governance => ({
				  ...governance,
				  isSelected: governance.id === selectedGovernanceId[2]
				}))
			  }));
			setPc(defComp1)
		}
		else{
			let tempData = tempPc;
			setPc(tempData)
		}
	},[enableComplexity,selectedGovernanceId])
	const getOwnerInfo = async (userEmail, setValue) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue(response);
		});
	};

	const defComp = [{ id: 100, name: "Default", complexityGovernanceList: [{ id: 0, isSelected: false, name: "MethodOnly" }, { id: 1, isSelected: false, name: "Merge" }, { id: 2, isSelected: false, name: "GovernanceOnly" }, { id: 3, isSelected: true, name: "None" }] }];
	const getUser = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};

	const removeCoOwner = (emailId, users, setUsers) => {
		const filteredOwners = users.filter((user) => user.mail !== emailId);
		setUsers(filteredOwners);
	};

	const getUserDetails = async (userEmail, setValue) => {
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue((prevState) => [...prevState, response]);
		});
	};

	const getCollaboratorsInfo = async (membersList, setValue) => {
		for (const userEmail of membersList) {
			getUserDetails(userEmail, setValue);
		}
	};

	const getMethodDetails = async () => {
		await axiosGetService(
			`api/sdmsolutionmethod/getsolutionmethod?solutionMethodId=${selectedSolutionMethod}`
		).then((response) => {
			const data = response.data;
			setMethodName(data.solutionMethodName ?? '');
			setMethodType(
				data.solutionMethodologyTypes.find(
					(item) => item.id === data.solutionMethodTypeId
				) ?? { id: 3, name: 'None' }
			);
			setParentMethod(
				data.parentSolutionMethods.find(
					(item) => item.id == data.parentSolutionMethodId
				) ?? { id: 0, name: 'None' }
			);
			setAcronym(data.acronym ? data.acronym : '');
			setDiagram(data.diagramName ? data.diagramName : '');
			setShortName(data.shortName ? data.shortName : '');
			setIsProcessStep(data.isProcessStep ?? false);
			setEnableComplexity(data.isComplexity ?? false);
			setIsRetired(data.isRetired ?? false);
			setIsMerged(data.isMerged ?? false);
			setIsVisible(data.isVisible ?? false);
			if (data.methodOwner) {
				getOwnerInfo(data.methodOwner, setOwner);
			}
			setDescription(data.description ?? '');
			getCollaboratorsInfo(data.coOwnerMembersList, setCoOwners);
			getCollaboratorsInfo(data.reviewerMembersList, setReviewers);
			getCollaboratorsInfo(data.approverMembersList, setApprovers);
			setMethodData(data);
			setIsShowInLeftMenu(data.isShowInLeftMenu ?? false);
			setShowSpinner(false);
			setProjectComplexity(data.projectComplexity);
			setDynamicComplexities(data.dynamicComplexityIds ?? data.projectComplexity);
			const selectedIds = data.projectComplexity.map(item => {
				const selectedGovernance = item.complexityGovernanceList.find(governance => governance.isSelected);
				return selectedGovernance ? selectedGovernance.id : 3;
			});
			setSelectedGovernanceId(selectedIds)
			setDefCompId(data.defaultDynamicComplexityId ?? 3)
		});
	};

	const updateSequence = () => {
		const updatedMethods = sequence.map((item) => {
			if (item.id === '0' || item.id === methodId.toString()) {
				return { id: methodId.toString(), name: methodName, type: item.type };
			}
			return { id: item.id, name: item.name, type: item.type };
		});
		setSequence(updatedMethods);
	};

	const getMethodSequence = async () => {
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytype?methodTypeId=${methodType.id
			}&isSvgRequired=${false}&endUserview=${endUserView}`
		).then((response) => {
			const data = response.data.map((item) => {
				return {
					id: item.solutionMethodId.toString(),
					name: item.solutionMethodName,
					type: 'old',
				};
			});
			const isMethodExist = response.data.find(
				(item) => item.solutionMethodId === methodId
			);
			if (!isMethodExist) {
				data.push({ id: methodId.toString(), name: methodName, type: 'new' });
			}
			setSequence(data);
		});
	};
	const saveMethod = async () => {
		if (typeof dynamicComplexities === "string" && !dynamicComplexities.length == 1) {
			let arr = dynamicComplexities.split(",");
			arr = arr.join(",");
			dynamicComplexities = arr;
		}
		if (typeof dynamicComplexities === "object") {
			let arr = dynamicComplexities.join(",");
			dynamicComplexities = arr;
		}
		const hasId100 = pc.some((item)=>item.id === 100)
		if(enableComplexity === true && hasId100){
			toast.error('Please select complexities for the solution method', {
				position: 'top-right',
			});
		}else{
			if(enableComplexity === true && defCompId === ''){
				toast.error('Please select a default complexity for the solution method', {
					position: 'top-right',
				});
			}else{
				const postData = {
					solutionMethodId: methodId,
					solutionMethodName: methodName,
					solutionMethodTypeId: 1, 
					parentSolutionMethodId: parentMethod.id,
					acronym: acronym,
					diagramName: diagram,
					description: description,
					shortName: shortName,
					isRetired: isRetired,
					isProcessStep: isProcessStep,
					isComplexity: enableComplexity,
					isMerged: isMerged,
					methodOwner: owner.mail ?? '',
					governanceMergeInputs: selectedGovernanceId,
					//
					singularPhaseId: '',
					isShowInLeftMenu: isShowInLeftMenu,
					isVisible: isVisible,
					dynamicComplexityIds: dynamicComplexities,
					coOwnerMembersList: coOwners.map((item) => {
						return item.mail;
					}),
					reviewerMembersList: reviewers.map((item) => {
						return item.mail;
					}),
					approverMembersList: approvers.map((item) => {
						return item.mail;
					}),
					defaultDynamicComplexityId: enableComplexity === false ? 3 : defCompId
				};
				await axiosPostService(
					`api/sdmsolutionmethod/${methodId ? 'updatesolutionmethod' : 'addsolutionmethod'
					}`,
					postData
				).then((response) => {
					if (response.data.status) {
						if (currentTab === 2) {
							saveSequence(response.data.solutionMethodId);
						} else {
							const updatedMethods = sequence.map((item) => {
								if (
									item.id === '0' ||
									item.id === response.data.solutionMethodId.toString()
								) {
									return {
										id: response.data.solutionMethodId.toString(),
										name: methodName,
										type: item.type,
									};
								}
								return { id: item.id, name: item.name, type: item.type };
							});
							setSequence(updatedMethods);
							setCurrentTab(2);
							setMethodId(response.data.solutionMethodId);
							if(setIsSolutionCollaboratorsEdited){
								setIsSolutionCollaboratorsEdited((prevState) => !prevState);
							}
						}
					} else {
						toast.error(response.data.errorMsg, {
							position: 'top-right',
						});
					}
				});
			}
			
		}
		
	};

	useEffect(() => {
		if (dynamicComplexities == '') {
			setPc(defComp)
		}
		else {
			let p = projectComplexity.filter((item) => {
				if (dynamicComplexities.includes(item.id)) {
					return item
				}
			})
			setPc(p)
			setTempPc(p)
			setDefaultCompDD(p);
		}

	}, [dynamicComplexities])
	const saveSequence = async (methodId) => {
		const methodIdIds = sequence.map((item) => {
			if (item.id === methodId.toString() || item.id === '0') {
				return methodId;
			} else {
				return Number(item.id);
			}
		});
		const postData = {
			solutionMethodIdsInSequence: methodIdIds.toString(),
		};
		await axiosPostService(
			`api/sdmsolutionmethod/updateSolutionMethodSequence`,
			postData
		).then((response) => {
			if (response.data.status) {
				if (currentTab === 2) {
					toast.info('You have successfully updated the method', {
						position: 'top-right',
					});
					getSolutionMethodsByProjectId(methodType.id);
					setShowEditSolutionMethodlogy(false);
					setIsEditSolutionMethod(!isEditSolutionMethod);
				}
			} else {
				toast.error(response.data.errorMsg, {
					position: 'top-right',
				});
			}
		});
	};
	const getComplexities = async () => {
		await axiosGetService("api/activities/projectcomplexity").then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
					type: "option",
				};
			});
			setComplexities(data);
		});
	};

	const components = {
		1: (
			<SolutionMethodForm
				methodName={methodName}
				setMethodName={setMethodName}
				methodType={methodType}
				setMethodType={setMethodType}
				parentMethod={parentMethod}
				setParentMethod={setParentMethod}
				acronym={acronym}
				setAcronym={setAcronym}
				diagram={diagram}
				setDiagram={setDiagram}
				shortName={shortName}
				setShortName={setShortName}
				isProcessStep={isProcessStep}
				setIsProcessStep={setIsProcessStep}
				enableComplexity={enableComplexity}
				setEnableComplexity={setEnableComplexity}
				isRetired={isRetired}
				setIsRetired={setIsRetired}
				isMerged={isMerged}
				setIsMerged={setIsMerged}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
				complexityCritical={complexityCritical}
				setComplexityCritical={setComplexityCritical}
				complexityHigh={complexityHigh}
				setComplexityHigh={setComplexityHigh}
				complexityMedium={complexityMedium}
				setComplexityMedium={setComplexityMedium}
				complexityLow={complexityLow}
				setComplexityLow={setComplexityLow}
				owner={owner}
				setOwner={setOwner}
				ownerValue={ownerValue}
				setOwnerValue={setOwnerValue}
				coOwners={coOwners}
				setCoOwners={setCoOwners}
				coOwnerValue={coOwnerValue}
				setCoOwnerValue={setCoOwnerValue}
				approvers={approvers}
				setApprovers={setApprovers}
				approverValue={approverValue}
				setApproverValue={setApproverValue}
				reviewers={reviewers}
				setReviewers={setReviewers}
				reviewerValue={reviewerValue}
				setReviewerValue={setReviewerValue}
				description={description}
				setDescription={setDescription}
				methodData={methodData}
				emailFound={emailFound}
				setEmailFound={setEmailFound}
				usersList={usersList}
				setUsersList={setUsersList}
				error={error}
				setError={setError}
				removeCoOwner={removeCoOwner}
				isShowInLeftMenu={isShowInLeftMenu}
				setIsShowInLeftMenu={setIsShowInLeftMenu}
				newMethod={newMethod}
				projectComplexity={projectComplexity}
				setProjectComplexity={setProjectComplexity}
				dynamicComplexities={dynamicComplexities}
				setDynamicComplexities={setDynamicComplexities}
				selectedComplexities={selectedComplexities}
				setSelectedComplexities={setSelectedComplexities}
				pc={pc}
				setPc={setPc}
				complexities={complexities}
				selectedGovernanceId={selectedGovernanceId}
				setSelectedGovernanceId={setSelectedGovernanceId}
				defaultCompDD={defaultCompDD}
				setDefaultCompDD={setDefaultCompDD}
                defCompId={defCompId}
				setDefCompId={setDefCompId}
				defCompData={defCompData}
				setDefCompData={setDefCompData}
				defComp={defComp}
				setTempPc={setTempPc}
			// complexityPDoc={complexityPDoc}
			// setComplexityPDoc={setComplexityPDoc}
			/>
		),
		2: <SolutionMethodSequence sequence={sequence} setSequence={setSequence} />,
	};

	useEffect(() => {
		getMethodDetails();
		return () => {
			setSelectedSolutionMethod(0);
		};
	}, []);

	useEffect(() => {
		updateSequence();
	}, [methodName]);

	useEffect(() => {
		const selectedItem = projectComplexity.find(item => item.id === defCompId);  
		setDefCompData(selectedItem || {});
	  }, [defCompId, projectComplexity]);
	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (coOwnerValue) {
				getUser(coOwnerValue, 'coOwner');
			} else {
				setError({ ...error, ['coOwner']: false });
				setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [coOwnerValue]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerValue) {
				getUser(reviewerValue, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerValue]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverValue) {
				getUser(approverValue, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverValue]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (ownerValue) {
				getUser(ownerValue, 'methodOwner');
			} else {
				setUsersList((prevState) => ({ ...prevState, methodOwner: [] }));
				setError({ ...error, ['methodOwner']: false });
			}
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [ownerValue]);

	useEffect(() => {
		if (methodType.id) {
			getMethodSequence();
		}
	}, [methodType]);
	
	return (
		<Modal
			dialogClassName="phaseCreationRightPopup manageRoles addSolMethod"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={() => {
				isAdminMenu && history.push(pathname);
				setShowEditSolutionMethodlogy(false);
			}}
			show={true}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Solution Method Builder
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{showSpinner ? (
					<div className="spinner">
						<Spinner animation="border" />
					</div>
				) : (
					<div className="methodOwnerMainContainer">
						<div className="methodOwnerPage">
							<div className="methodOwnerContainer">
								<div className="methodLeftContainer">
									<ul className="methodPhaseLeftMenu">
										{tabs.map((item) => (
											<li
												key={item.title}
												className={
													currentTab === item.id ? 'activePhaseTab' : ''
												}
												onClick={() => {
													setCurrentTab(item.id);
												}}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setCurrentTab(item.id);
													}
												}}
												tabIndex={0}
												role="button"
											>
												<span>{item.title}</span>
											</li>
										))}
									</ul>
								</div>
								{components[currentTab]}
								<div id="SaveBackSolutionMethodId" className="saveContinueDetailsContainer">
									{isAdminMenu ? (
										<>
											<button
												className="backwardButton"
												onClick={() => {
													setShowEditSolutionMethodlogy(false);
												}}
												onKeyPress={(e) => {
													if (e.which === 13) {
														setShowEditSolutionMethodlogy(false);
													}
												}}
											>
												Back to Manage Solution Methods
											</button>
											<button
												disabled={
													!methodName ||
													!acronym ||
													!diagram ||
													!shortName ||
													methodType.name === 'None' ||
													!description ||
													 !owner.mail||coOwners.length ==0|| approvers.length == 0||
													reviewers.length == 0
												}
												className="forwardButton"
											>
												<span
													className="saveContinueButton"
													onClick={() => {
														saveMethod();
													}}
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															saveMethod();
														}
													}}
												>
													Save and Continue
												</span>
											</button>
										</>
									) : (
										<button
											disabled={
												!methodName ||
												!acronym ||
												!diagram ||
												!shortName ||
												methodType.name === 'None' ||
												!description || !owner.mail|| coOwners.length==0|| approvers.length == 0||
												reviewers.length == 0						}
											className="forwardButton"
										>
											<span
												className="saveContinueButton"
												onClick={() => {
													saveMethod();
												}}
											>
												Save
											</span>
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default AdminEditSolutionMethod;
