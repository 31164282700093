import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';

import ActivityClone from './ActivityClone';
import Dropdown from '../../Dropdown';
import HashtagSuggestions from '../../HashtagSuggestions';
import { useAxios } from '../../../context/AxiosContext';
import { getUsersList } from '../../../helpers/GraphService';
import { axiosTokenService } from '../../CkEditor/api/axiosBaseUrl';
import UserSelectionDropdown from '../PhaseAddition/UserSelectionDropdown';
import ActivityRefer from './ActivityRefer';
import SolutionMethodRefer from './ReferSolutionMethod';
import './ActivityAddition.css';
import OwnerSelectionDropdown from "../../TopicsBuilder/OwnerSelectionDropdown";


const ActivityDetails = ({
	activityGroups,
	addToGroup,
	setAddToGroup,
	activityTitleError,
	showAddActivityForm,
	setShowAddActivityForm,
	activityTitle,
	setActivityTitle,
	selectedGroup,
	setSelectedGroup,
	saveActivityDetails,
	changeToDeafultState,
	setActivityGroups,
	projectTypeId,
	solutionMethodId,
	phaseId,
	existingActivityTitles,
	setDisableSaveAndContinue,
	hashtags,
	setHashtags,
	hashtagsInput,
	setHashtagsInput,
	userAddedHashtags,
	setUserAddedHashtags,
	hashtagSuggestionInput,
	setHashtagSuggestionInput,
	type,
	parentId,
	disableSaveAndContinue,
	reviewers,
	setReviewers,
	approvers,
	setApprovers,
	complexityId,
	dynamicComplexities,
	selectedComplexityId,
	setSelectedComplexityId,
	isEnableComplexity,
	owner,
    setOwner,
    coOwners,
    setCoOwners
}) => {
	const titleLength = 120;
	const { axiosGetService } = useAxios();
	const [showCloneActivityForm, setShowCloneActivityForm] = useState(false);
	const [showCloneFilter, setShowCloneFilter] = useState(false);
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [reviewerEmail, setReviewerEmail] = useState('');
	const [approverEmail, setApproverEmail] = useState('');
	const [coOwnerEmail, setCoOwnerEmail] = useState('');
    const [activityOwnerEmail, setActivityOwnerEmail] = useState("")
	const [updateApiCall, setUpdateApiCall] = useState(false);
	const [error, setError] = useState({
		approver: false,
		reviewer: false,
	});
	const [usersList, setUsersList] = useState({
		reviewer: [],
		approver: [],
		coOwner:[],
		owner: []
	});
	const [emailFound, setEmailFound] = useState({
		approver: false,
		reviewer: false,
		coOwner:false,
		owner:false
	});
	const [showReferActivityForm, setShowReferActivityForm] = useState(false);
	const [showSolutionMethodReferForm, setShowSolutionMethodReferForm] =
		useState(false);

	const getUser = async (input, type) => {
		const accessToken = await axiosTokenService();
		await getUsersList(accessToken, input).then((response) => {
			if (!response.error) {
				setUsersList((prevState) => ({ ...prevState, [type]: response.data }));
			} else {
				setError({ ...error, [type]: true });
			}
		});
	};

	const removeCoOwner = (emailId, users, setUsers) => {
		const filteredOwners = users.filter((user) => user.mail !== emailId);
		setUsers(filteredOwners);
	};

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const groupDropdown = activityGroups
		.map((item) => {
			return { id: item.groupId, name: item.groupName };
		})
		.filter(({ id }) => id !== 0);

	const removeHashtag = (tag) => {
		const updatedHashtag = hashtags.filter((item) => tag !== item);
		setHashtags(updatedHashtag);
	};

	const updateHashtag = () => {
		setHashtags([...userAddedHashtags, ...hashtags]);
		setHashtagSuggestionInput('');
		setUserAddedHashtags([]);
	};

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (reviewerEmail) {
				getUser(reviewerEmail, 'reviewer');
			} else {
				setUsersList((prevState) => ({ ...prevState, reviewer: [] }));
				setError({ ...error, ['reviewer']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [reviewerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (approverEmail) {
				getUser(approverEmail, 'approver');
			} else {
				setUsersList((prevState) => ({ ...prevState, approver: [] }));
				setError({ ...error, ['approver']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [approverEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
		  if (activityOwnerEmail) {
			getUser(activityOwnerEmail, "activityOwner");
		  } else {
			setUsersList((prevState) => ({ ...prevState, activityOwner: [] }));
			setError({ ...error, ["activityOwner"]: false });
		  }
		}, 1000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [activityOwnerEmail])

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (coOwnerEmail) {
				getUser(coOwnerEmail, 'coOwner');
			} else {
				setUsersList((prevState) => ({ ...prevState, coOwner: [] }));
				setError({ ...error, ['coOwner']: false });
			}
		}, 1000);

		return () => {
			clearTimeout(timeOutId);
		};
	}, [coOwnerEmail]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	useEffect(() => {
		if (approvers.length === 0) {
			setApproverEmail('');
		}
		if (reviewers.length === 0) {
			setReviewerEmail('');
		}
	}, [approvers, reviewers]);
	return (
		<div class="activityAdditionDiv">
			<h6>
				Add a new activity, select an existing one or link a Solution Method to
				continue
			</h6>

			<div class="activityAdditionCont referActivityContainer referSoloMethod">
				<div class="cloneActivityButtonAction referSolMethod">
					<div
						class="selectAddActivityButton"
						onClick={() =>
							setShowSolutionMethodReferForm(!showSolutionMethodReferForm)
						}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setShowSolutionMethodReferForm(!showSolutionMethodReferForm);
							}
						}}
						title="Link solution Method(s)"
						tabIndex={0}
						aria-label="click to Link solution Method(s)"
						role="button"
						aria-expanded={showSolutionMethodReferForm ? 'true' : 'false'}
					>
						+&nbsp;Link solution Method(s)
					</div>
					{showSolutionMethodReferForm ? (
						<SolutionMethodRefer
							setShowSolutionMethodReferForm={setShowSolutionMethodReferForm}
							activityGroups={activityGroups}
							setActivityGroups={setActivityGroups}
							projectTypeId={projectTypeId}
							solutionMethodId={solutionMethodId}
							phaseId={phaseId}
							setDisableSaveAndContinue={setDisableSaveAndContinue}
							type={type}
							parentId={parentId}
							complexityId={complexityId}
						/>
					) : (
						<></>
					)}
				</div>
			</div>
			<div class="activityAdditionCont">
				<div class="cloneActivityButtonAction">
					<div
						class="selectAddActivityButton"
						onClick={() => setShowCloneActivityForm(!showCloneActivityForm)}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setShowCloneActivityForm(!showCloneActivityForm);
							}
						}}
						title="Clone an existing Activity"
						tabIndex={0}
						aria-label="Click to Clone an existing Activity"
						role="button"
						aria-expanded={showCloneActivityForm ? 'true' : 'false'}
					>
						+&nbsp;Clone an existing Activity
					</div>
					{showCloneActivityForm ? (
						<ActivityClone
							setShowCloneActivityForm={setShowCloneActivityForm}
							activityGroups={activityGroups}
							setActivityGroups={setActivityGroups}
							showCloneFilter={showCloneFilter}
							setShowCloneFilter={setShowCloneFilter}
							projectTypeId={projectTypeId}
							solutionMethodId={solutionMethodId}
							phaseId={phaseId}
							setDisableSaveAndContinue={setDisableSaveAndContinue}
							type={type}
							parentId={parentId}
						/>
					) : (
						<></>
					)}
				</div>
			</div>

			<div class="activityAdditionCont referActivityContainer">
				<div class="cloneActivityButtonAction">
					<div
						class="selectAddActivityButton"
						onClick={() => setShowReferActivityForm(!showReferActivityForm)}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setShowReferActivityForm(!showReferActivityForm);
							}
						}}
						title="Link existing Activities"
						tabIndex={0}
						aria-label="click to Link existing Activity(ies)"
						role="button"
						aria-expanded={showReferActivityForm ? 'true' : 'false'}
					>
						+&nbsp;Link existing Activity(ies)
					</div>
					{showReferActivityForm ? (
						<ActivityRefer
							setShowReferActivityForm={setShowReferActivityForm}
							activityGroups={activityGroups}
							setActivityGroups={setActivityGroups}
							showCloneFilter={showCloneFilter}
							setShowCloneFilter={setShowCloneFilter}
							projectTypeId={projectTypeId}
							solutionMethodId={solutionMethodId}
							phaseId={phaseId}
							setDisableSaveAndContinue={setDisableSaveAndContinue}
							type={type}
							parentId={parentId}
						/>
					) : (
						<></>
					)}
				</div>
			</div>
			<div class="activityAdditionCont">
				<div class="addActivityButtonAction">
					<div
						class="selectAddActivityButton"
						onClick={() => setShowAddActivityForm(!showAddActivityForm)}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setShowAddActivityForm(!showAddActivityForm);
							}
						}}
						title="Add a New Activity"
						tabIndex={0}
						aria-label="Click to Add a New Activity"
						role="button"
						aria-expanded={showAddActivityForm ? 'true' : 'false'}
					>
						+&nbsp;Add a New Activity
					</div>
					{showAddActivityForm ? (
						<div class="addActivityFields">
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Activity *</span>
								<input
									title={activityTitle}
									placeholder="Activity name"
									value={activityTitle}
									onChange={(e) => setActivityTitle(e.target.value)}
									autoFocus={true}
								/>
								{activityTitleError ? (
									<p className="errorMsg">Activity name is required</p>
								) : (
									<></>
								)}
								{existingActivityTitles.includes(
									activityTitle.trim().toLowerCase()
								) ? (
									<p className="errorMsg"> Duplicate activity name</p>
								) : (
									<></>
								)}
								{activityTitle.length > titleLength ? (
									<p className="errorMsg">Name length can not exceeds 120</p>
								) : (
									<></>
								)}
								{activityTitle &&
									(activityTitle.includes('>') || activityTitle.includes('<')) ? (
									<p className="errorMsg">Invalid name</p>
								) : (
									<></>
								)}
							</div>
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Owner</span>
								<OwnerSelectionDropdown
                                 className="owner"
                                 ownerRadioButton='CustomCheckmarkActivityOwnerCreation'
                                 enableDropdown={true}
                                 data={usersList}
                                 value={owner}
                                 setValue={setOwner}
                                 type="activityOwner"
                                 searchValue={activityOwnerEmail}
                                 setSearchValue={setActivityOwnerEmail}
                                 error={error}
                                 emailFound={emailFound["owner"]}
                               /> 
								{owner.mail  && (
									<div className="collabaratorsChildContainers">
										<ul>
												<li key={owner.mail}>
													<span className="collabaratorPersonaImage">
														<img src={owner.photo} alt="img" />
													</span>
													<span className="collabaratorPersonaTitle">
														{owner.firstName + ' ' + owner.surname}
													</span>
													<span>
														<Icon
															iconName="Cancel"
															title="Remove Co-owner"
															onClick={() => { 
																setOwner({})	
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	removeCoOwner(
																		owner.mail,
																		coOwners,
																		setCoOwners
																	);
																}
															}}
															role="button"
															tabIndex={0}
															aria-label={`Remove ${owner.firstName} ${owner.surname}`}
														/>
													</span>
												</li>
										</ul>
									</div>
								)}
							</div>
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Co-owner</span>
								<UserSelectionDropdown
									className="coOwner"
									enableDropdown={true}
									data={usersList}
									value={coOwners}
									setValue={setCoOwners}
									type="coOwner"
									searchValue={coOwnerEmail}
									setSearchValue={setCoOwnerEmail}
									error={error}
									emailFound={emailFound['coOwner']}
								/>
								{coOwners.length > 0 && (
									<div className="collabaratorsChildContainers">
										<ul>
											{coOwners.map((user) => (
												<li key={user.mail}>
													<span className="collabaratorPersonaImage">
														<img src={user.photo} alt="img" />
													</span>
													<span className="collabaratorPersonaTitle">
														{user.firstName + ' ' + user.surname}
													</span>
													<span>
														<Icon
															iconName="Cancel"
															title="Remove Co-owner"
															onClick={() => {
																removeCoOwner(
																	user.mail,
																	coOwners,
																	setCoOwners
																);
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	removeCoOwner(
																		user.mail,
																		coOwners,
																		setCoOwners
																	);
																}
															}}
															role="button"
															tabIndex={0}
															aria-label={`Remove ${user.firstName} ${user.surname}`}
														/>
													</span>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Approvers</span>
								<UserSelectionDropdown
									className="approver"
									enableDropdown={true}
									data={usersList}
									value={approvers}
									setValue={setApprovers}
									type="approver"
									searchValue={approverEmail}
									setSearchValue={setApproverEmail}
									error={error}
									emailFound={emailFound['approver']}
								/>
								{approvers.length > 0 && (
									<div className="collabaratorsChildContainers">
										<ul>
											{approvers.map((user) => (
												<li key={user.mail}>
													<span className="collabaratorPersonaImage">
														<img src={user.photo} alt="img" />
													</span>
													<span className="collabaratorPersonaTitle">
														{user.firstName + ' ' + user.surname}
													</span>
													<span>
														<Icon
															iconName="Cancel"
															title="Remove approver"
															onClick={() => {
																removeCoOwner(
																	user.mail,
																	approvers,
																	setApprovers
																);
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	removeCoOwner(
																		user.mail,
																		approvers,
																		setApprovers
																	);
																}
															}}
															role="button"
															tabIndex={0}
															aria-label={`Remove ${user.firstName} ${user.surname}`}
														/>
													</span>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Reviewers</span>
								<UserSelectionDropdown
									className="reviewer"
									enableDropdown={true}
									data={usersList}
									value={reviewers}
									setValue={setReviewers}
									type="reviewer"
									searchValue={reviewerEmail}
									setSearchValue={setReviewerEmail}
									error={error}
									emailFound={emailFound['reviewer']}
								/>
								{reviewers.length > 0 && (
									<div className="collabaratorsChildContainers">
										<ul>
											{reviewers.map((user) => (
												<li key={user.mail}>
													<span className="collabaratorPersonaImage">
														<img src={user.photo} alt="img" />
													</span>
													<span className="collabaratorPersonaTitle">
														{user.firstName + ' ' + user.surname}
													</span>
													<span>
														<Icon
															iconName="Cancel"
															title="Remove reviewer"
															onClick={() => {
																removeCoOwner(
																	user.mail,
																	reviewers,
																	setReviewers
																);
															}}
															onKeyPress={(e) => {
																if (e.which === 13) {
																	removeCoOwner(
																		user.mail,
																		reviewers,
																		setReviewers
																	);
																}
															}}
															role="button"
															tabIndex={0}
															aria-label={`Remove ${user.firstName} ${user.surname}`}
														/>
													</span>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
							<div class="entryCriteriaName">
								<span class="AddActivityFieldLabel">Add Hashtags</span>
								<HashtagSuggestions
									className="newActivityHashtagsInput"
									enableDropdown={true}
									data={hashtagSuggestions}
									setUpdateApiCall={setUpdateApiCall}
									setHashtagSuggestions={setHashtagSuggestions}
									searchValue={hashtagsInput}
									setSearchValue={setHashtagsInput}
									existingHashtags={hashtags}
									userAddedHashtags={userAddedHashtags}
									setUserAddedHashtags={setUserAddedHashtags}
									hashtagSuggestionInput={hashtagSuggestionInput}
									setHashtagSuggestionInput={setHashtagSuggestionInput}
									closeHashtagForm={() => {
										setHashtagsInput('');
										setUserAddedHashtags([]);
									}}
									submitHashtags={updateHashtag}
									autoFocus={false}
									showSubmitButton={false}
								/>
								{hashtags.length > 0 ? (
									<div className="hashTagsContainer">
										{hashtags.map((item) => (
											<span key={item}>
												{item}{' '}
												<Icon
													iconName="Cancel"
													onClick={() => removeHashtag(item)}
													tabIndex={0}
													onKeyPress={(e) => {
														if (e.which === 13) {
															removeHashtag(item);
														}
													}}
													aria-label={`click to Remove ${item} Hashtag`}
													title="Cancel"
													role="Button"
												/>
											</span>
										))}
									</div>
								) : (
									<></>
								)}
							</div>
							{isEnableComplexity && <div className="entryCriteriaName">
								<span className="AddActivityFieldLabel">Select Complexity</span>
								<Dropdown
									data={dynamicComplexities}
									value={selectedComplexityId}
									setValue={setSelectedComplexityId}
									enableDropdown={true}
									className={'selectComplexity'}
								/>
							</div>}
							<div className="otherDetailsAddActivity">
								<label className="NotificationChkboxContainer CheckboxForActivityCreate">
									<input
										name="complexity"
										type="checkbox"
										checked={addToGroup}
										onChange={() => setAddToGroup(!addToGroup)}
									/>
									<span className="CustomCheckmark checkboxForActivity"></span>
									<span className="complexityLabel">Add to group</span>
								</label>
								<Dropdown
									data={groupDropdown}
									value={selectedGroup}
									setValue={setSelectedGroup}
									enableDropdown={addToGroup}
									className={'addToGroup'}
								/>
							</div>

							<div class="criteriaButtons">
								<button
									onClick={() => {
										changeToDeafultState();
										setShowAddActivityForm(false);
									}}
								>
									Cancel
								</button>
								<button
									onClick={() => {
										saveActivityDetails('save');
									}}
									disabled={
										existingActivityTitles.includes(
											activityTitle.trim().toLowerCase()
										) ||
										activityTitle.length > titleLength ||
										activityTitle.includes('>') ||
										activityTitle.includes('<') ||
										activityTitle.trim().length === 0 ||
										disableSaveAndContinue
									}
								>
									Create Activity
								</button>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default ActivityDetails;
