import React, { useEffect, useState } from 'react';
import { CSTCopilot } from "cst-web-chat";
import "cst-web-chat/style.css";
import CstCopilotLogo from "../../assets/Copilot/CopilotLogo.svg";
import { copilotTokenService } from '../CkEditor/api/axiosBaseUrl';
import { useAuth } from '../../context/UserAuthContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useConfig } from '../../context/ConfigContext';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useAxios } from '../../context/AxiosContext';
import OCVFeedback from './OCVFeedback';
import { CopilotConfig } from './Copilot';
import { jwtDecode } from "jwt-decode";
import { IrisWebChat, IIrisBotProps } from '@iris/react-webchat';
import '@iris/react-webchat/dist/index.css';
import IrisCopilot from '../IrisCopilot';

const Copilot = (props) => {
    const [showCopilot, setShowCopilot] = useState(true);
    const { user } = useAuth();
    const { copilotApiURL } = useConfig();
    const { appState, setAppState } = useLeftMenuCollapse();
    const [message, setMessage] = useState({});
    const [isFeedbackPositiveData, setisFeedbackPositiveData] = useState();
    const { axiosGetService } = useAxios();
    const [instrumentationKey, setInstrumentationKey] = useState();
    const [copilotName, setCopilotName] = useState();
    const [appInsights, setAppInsights] = useState();
    const [objectId, setObjectId] = useState();
    const [showIrisCopilot, setShowIrisCopilot] = useState(false);
    const [irisSupportTicket, setIrisSupportTicket] = useState(false);

    const getInstrumentationKey = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=VirtuosoAppInstrumentationKey`).then(
			(response) => {
                if(response.data && response.data.length > 0){
                    setInstrumentationKey(response.data[0].value);
                }
			}
		);
	};
    
    const getCopilotTeam = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=SDMCopilotName`).then(
			(response) => {
                if(response.data && response.data.length > 0){
                    setCopilotName(response.data[0].value);
                }
			}
		);
	};

    useEffect(()=>{
        getInstrumentationKey();
        getCopilotTeam();
    },[]);

    useEffect(() => {
        if(instrumentationKey !== undefined){
             // Appinsight configurations for tracking events and data.
            const reactPlugin = new ReactPlugin();
            const ai = new ApplicationInsights({
                config: {
                    instrumentationKey: instrumentationKey,
                    autoTrackPageVisitTime: true,
                    overridePageViewDuration: true,
                    extensions: [reactPlugin]
                }
            })
            ai.loadAppInsights()
            setAppInsights(ai.appInsights);
        }
    },[instrumentationKey]);

    const trackCustomEvents = (name, key, value) => {
        // To track the custom events for eg like/dislike of the response.
        // failure in copilot as well as starting of the copilot.
        // Basically this function is to track the events data performed on copilot or by copilot on appinsight.
        if(appInsights){
            appInsights.trackEvent(
                {
                    name: name,
                    properties:
                    {
                        CopilotClient : 'SDM Copilot',          
                        ActionKey: key,
                        ActionValue: value,
                        URL: window.location.href,
                        UserId: user.profile.email,
                    }
                }
            );
        }
        
    }

    const closeMCAPSBot = () => {
        // To close the chat bot(copilot);
        setShowCopilot(false);
        setAppState({
			...appState,
			rightPanelContent: null,
			parentRightPanelComponent: null,
		});
    }

    const yourSupportTicketFunction = () => {
        setShowIrisCopilot(!showIrisCopilot);
        setIrisSupportTicket(!irisSupportTicket);
    }

    const ocvFeedback = (isFeedbackPositive, message) => {
        setMessage(message)
        setisFeedbackPositiveData(isFeedbackPositive)
    }
    const generateToken = () => new Promise((resolve) => {
        // To give the authentication token to copilot team
        return resolve (copilotTokenService());
    });

    const token  = generateToken().then((result) => {
        const decodedValue = jwtDecode(result);
        setObjectId(decodedValue.oid);
    });

    const renderCopilot = () =>{
        const copilotProps = {...CopilotConfig, 
            applicationName: copilotName,
            // onShowIrisBot: {yourSupportTicketFunction},
            supportTicket: yourSupportTicketFunction,
            userName: user.profile.firstName +" "+ user.profile.surname,
            closeBot: closeMCAPSBot,
            isVisible: showCopilot,
            image: CstCopilotLogo,
            trackCustomEvents: trackCustomEvents,
            getBearerTokenCallback: generateToken,
            apiUrls: {questionApi:copilotApiURL},
            appInsightConfig: { instrumentationKey: instrumentationKey },
            ocvData: ocvFeedback
        }
        return (
            <CSTCopilot
            {...copilotProps}
            />
        );
    }

    // const getIrisBotTokenCallback = () => {
    //     return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ikg5bmo1QU9Tc3dNcGhnMVNGeDdqYVYtbEI5dyIsImtpZCI6Ikg5bmo1QU9Tc3dNcGhnMVNGeDdqYVYtbEI5dyJ9.eyJhdWQiOiIzYTI2OWMxZS0yYTJkLTRmNWQtOTIyYS0yNDE3OWQyYjFhYWEiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC83MmY5ODhiZi04NmYxLTQxYWYtOTFhYi0yZDdjZDAxMWRiNDcvIiwiaWF0IjoxNzI3MDg1MTc0LCJuYmYiOjE3MjcwODUxNzQsImV4cCI6MTcyNzA5MDY4NiwiYWNyIjoiMSIsImFpbyI6IkFaUUFhLzhZQUFBQU9vTWdEd3NyUDFQNVZ3dzVhbW84L1doL2JaZHhhRzZQWE1jNG5NUlVFZUpmU1F1Z0tvdUhSWkZwRXh6OEFiQlk2SjJBUFpVRHhYZWUraU5MUEV0RzZaNVV6SnR5SFhkNU1MYjVRNTRrdjRpMllNVGhPeWRtY1ZiUWRtdHg3Z0Yrc0JqaEtyNytaMW1KNE44eDFDWU1ER2NabzhRR1J2Mm5IL3NhY2lXTjlUeExrcXo0Vi92S1RLWWNmUWtpRjR3TiIsImFtciI6WyJyc2EiLCJtZmEiXSwiYXBwaWQiOiI3MjhiMTllZi0yNjE5LTQ3ZjktODUyNC1hNzdlNTQ2ZWQ5MzYiLCJhcHBpZGFjciI6IjIiLCJkZXZpY2VpZCI6IjllMTExYWVhLTY1MWEtNGE0ZC04NWEzLTI0ODk5MWJlOTFmZiIsImZhbWlseV9uYW1lIjoiS2FyZ3dhbCIsImdpdmVuX25hbWUiOiJTdXNoYW50IiwiaXBhZGRyIjoiMTIyLjE3My4yNy4xODEiLCJuYW1lIjoiU3VzaGFudCBLYXJnd2FsIChXSVBSTyBMSU1JVEVEKSIsIm9pZCI6IjcyNDhhNGU4LWQxNjUtNDE2ZS04YzVjLWJmODQ4NGJhZjYzNyIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS0yMTI3NTIxMTg0LTE2MDQwMTI5MjAtMTg4NzkyNzUyNy03MjkxMzU3NSIsInJoIjoiMS5BUm9BdjRqNWN2R0dyMEdScXkxODBCSGJSeDZjSmpvdEtsMVBraW9rRjUwckdxb2FBTFVhQUEuIiwic2NwIjoidXNlcl9pbXBlcnNvbmF0aW9uIiwic3ViIjoid21RbnV0dm9XRGtoV3pkdE5WSzZ5a3JWUTdzWE5DcEdGVGh5ekdRQzdhVSIsInRpZCI6IjcyZjk4OGJmLTg2ZjEtNDFhZi05MWFiLTJkN2NkMDExZGI0NyIsInVuaXF1ZV9uYW1lIjoidi1za2FyZ3dhbEBtaWNyb3NvZnQuY29tIiwidXBuIjoidi1za2FyZ3dhbEBtaWNyb3NvZnQuY29tIiwidXRpIjoiOTBFVDMtcjJIRWVleDlLQjFPRWJBQSIsInZlciI6IjEuMCJ9.N5ng8zERolOHHj2Nd-zVBPkM6RK6y-B0M8PJqTdDQq-pXPKyW7FWL4rCX1VmkDwE2R13xmyFAJyv2G_Bf48V2Bhto-lrVit8jgr1YIkmXB5M3LPcVVxD7sgkxQtWlbFYnhw1S5QvaWbQkp-V_u_1xRDEu9GDEuSKjOj2nakr3xHpie4hRw1nGDCoJAjK5RVJJeAcqQOF8OZ-DeWBMAoLQQmOI9yb7V4wrQfztwo3skkQQkTGYmBO3t0BPvgwqx2YS7nv96ViLy0oYCpQ_rzvuY3otthitKhOxwkn9O_io8KxmmQMtwpVGrON1IFHeWhIHTUr_V6stmYmxXCpBZ_cfA";
    //     // return new Promise((resolve) => {
    //     //   resolve("eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ikg5bmo1QU9Tc3dNcGhnMVNGeDdqYVYtbEI5dyIsImtpZCI6Ikg5bmo1QU9Tc3dNcGhnMVNGeDdqYVYtbEI5dyJ9.eyJhdWQiOiIzYTI2OWMxZS0yYTJkLTRmNWQtOTIyYS0yNDE3OWQyYjFhYWEiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC83MmY5ODhiZi04NmYxLTQxYWYtOTFhYi0yZDdjZDAxMWRiNDcvIiwiaWF0IjoxNzI3MDg1MTc0LCJuYmYiOjE3MjcwODUxNzQsImV4cCI6MTcyNzA5MDY4NiwiYWNyIjoiMSIsImFpbyI6IkFaUUFhLzhZQUFBQU9vTWdEd3NyUDFQNVZ3dzVhbW84L1doL2JaZHhhRzZQWE1jNG5NUlVFZUpmU1F1Z0tvdUhSWkZwRXh6OEFiQlk2SjJBUFpVRHhYZWUraU5MUEV0RzZaNVV6SnR5SFhkNU1MYjVRNTRrdjRpMllNVGhPeWRtY1ZiUWRtdHg3Z0Yrc0JqaEtyNytaMW1KNE44eDFDWU1ER2NabzhRR1J2Mm5IL3NhY2lXTjlUeExrcXo0Vi92S1RLWWNmUWtpRjR3TiIsImFtciI6WyJyc2EiLCJtZmEiXSwiYXBwaWQiOiI3MjhiMTllZi0yNjE5LTQ3ZjktODUyNC1hNzdlNTQ2ZWQ5MzYiLCJhcHBpZGFjciI6IjIiLCJkZXZpY2VpZCI6IjllMTExYWVhLTY1MWEtNGE0ZC04NWEzLTI0ODk5MWJlOTFmZiIsImZhbWlseV9uYW1lIjoiS2FyZ3dhbCIsImdpdmVuX25hbWUiOiJTdXNoYW50IiwiaXBhZGRyIjoiMTIyLjE3My4yNy4xODEiLCJuYW1lIjoiU3VzaGFudCBLYXJnd2FsIChXSVBSTyBMSU1JVEVEKSIsIm9pZCI6IjcyNDhhNGU4LWQxNjUtNDE2ZS04YzVjLWJmODQ4NGJhZjYzNyIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS0yMTI3NTIxMTg0LTE2MDQwMTI5MjAtMTg4NzkyNzUyNy03MjkxMzU3NSIsInJoIjoiMS5BUm9BdjRqNWN2R0dyMEdScXkxODBCSGJSeDZjSmpvdEtsMVBraW9rRjUwckdxb2FBTFVhQUEuIiwic2NwIjoidXNlcl9pbXBlcnNvbmF0aW9uIiwic3ViIjoid21RbnV0dm9XRGtoV3pkdE5WSzZ5a3JWUTdzWE5DcEdGVGh5ekdRQzdhVSIsInRpZCI6IjcyZjk4OGJmLTg2ZjEtNDFhZi05MWFiLTJkN2NkMDExZGI0NyIsInVuaXF1ZV9uYW1lIjoidi1za2FyZ3dhbEBtaWNyb3NvZnQuY29tIiwidXBuIjoidi1za2FyZ3dhbEBtaWNyb3NvZnQuY29tIiwidXRpIjoiOTBFVDMtcjJIRWVleDlLQjFPRWJBQSIsInZlciI6IjEuMCJ9.N5ng8zERolOHHj2Nd-zVBPkM6RK6y-B0M8PJqTdDQq-pXPKyW7FWL4rCX1VmkDwE2R13xmyFAJyv2G_Bf48V2Bhto-lrVit8jgr1YIkmXB5M3LPcVVxD7sgkxQtWlbFYnhw1S5QvaWbQkp-V_u_1xRDEu9GDEuSKjOj2nakr3xHpie4hRw1nGDCoJAjK5RVJJeAcqQOF8OZ-DeWBMAoLQQmOI9yb7V4wrQfztwo3skkQQkTGYmBO3t0BPvgwqx2YS7nv96ViLy0oYCpQ_rzvuY3otthitKhOxwkn9O_io8KxmmQMtwpVGrON1IFHeWhIHTUr_V6stmYmxXCpBZ_cfA")
    //     // });
    // }

    // const closeIrisBot = () => {
    //     console.log("Close Copilot")
    // }


    // const renderIrisCopilot = () => {
    //     const IrisCopilotConfig = {
    //         acquireToken: getIrisBotTokenCallback,
    //         closeBot: closeIrisBot,
    //         appName: 'Virtuoso',
    //         platform: 'Virtuoso',
    //         enableAutoSuggest: true, // true to enable auto suggestion
    //         hideCloseButton: false, // true to hide the close button
    //         enableWindowResizing: false, // true to enable window resizing
    //         selfHelpContext: supportTicket ? {
    //             selfHelpEventName: "DEEPLINK",
    //             messages: ['Create a Support Ticket'],
    //             activityContext: {
    //               'Name': 'Virtuoso'
    //             }
    //           } :  null,
    //         irisApiBaseUrl: 'https://iris-api-dev.azurewebsites.net',
    //         style: { 
    //             avatarIconUrl: 'https://iris-cdn.azureedge.net/iris-ux/assets/images/iris-copilot-icon.svg'
    //         }    
    //     }
    //     console.log("bi",showIrisCopilot);
    //     return(
    //         <>
    //             <IrisWebChat 
    //                 {...IrisCopilotConfig}
    //             />
    //         </>
    //     )
    // }
    
    return (
        <>
            {(showCopilot && instrumentationKey !== undefined && copilotName!== undefined) && renderCopilot()}
            {objectId && <OCVFeedback isFeedbackPositive={isFeedbackPositiveData} objectId={objectId} aiResponse={message}/>}

            {showIrisCopilot &&  
                <IrisCopilot  
                    setShowIrisCopilot={setShowIrisCopilot} 
                    showIrisCopilot={showIrisCopilot}
                    irisSupportTicket={irisSupportTicket}
                    setIrisSupportTicket={setIrisSupportTicket}
                />
            }
        </>
    );
}
export default Copilot;