import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Link from "@ckeditor/ckeditor5-link/src/link";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
const editorConfiguration = {
  plugins: [Essentials, Link, Paragraph],
  toolbar: ["link", "|", "undo", "redo"],
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: "https://",
  },
};
const TasksCKEditorLink = ({ value, setValue, pageType = "default"  }) => {
return (
    <div  className= {pageType === 'editactivity' ?"taskcustomheight" :"my-unique-editor"}
      style={{
        width: pageType ==="editactivity"?"auto": "518px",
        wordBreak: "break-word",
        overflow: "auto",
        height: "auto",
        overflowY: pageType ==="editactivity"?"scroll": "hidden",
        minHeight: "80px", // Ensures minimum height
      }}
    >
        <CKEditor
        onChange={(event, editor) => setValue(editor.getData())}
        config={editorConfiguration}
        editor={ClassicEditor}
        data={value}
      />
    </div>
  );
};

export default TasksCKEditorLink;

