import React, { useState, useContext, createContext, useEffect } from "react";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { useAxios } from "../../context/AxiosContext";
const RaciGridContext = createContext();

const RaciGridProvider = ({ children }) => {
  const { axiosGetService } = useAxios();
  const [raciGridData, setRaciGridData] = useState([]);
  const [roleGroupsData, setRoleGroupsData] = useState([]);
  const { appState, breadcrumbs } = useLeftMenuCollapse();
  const [selectedComplexites, setSelectedComplexites] = useState([
    appState.defaultComplexity.id,
  ]);
  const [dynamicFilterTag, setDynamicFilterTag] = useState({});
  const [dynamicFilterData, setDynamicFilterData] = useState([]);

  useEffect(() => {
    if (breadcrumbs.some(({ type }) => type === "raciGrid")) {
      if ([appState.defaultComplexity.id.length] == 0) {
        axiosGetService(
          `api/sdmsolutionmethod/defaultdynamiccomplexityraci`
        ).then((response) => {
          setSelectedComplexites([response.data]);
        });
      } else {
        setSelectedComplexites([appState.defaultComplexity.id]);
      }
      setDynamicFilterTag({});
      setDynamicFilterData([]);
    }
  }, [breadcrumbs]);
  return (
    <RaciGridContext.Provider
      value={{
        raciGridData,
        setRaciGridData,
        roleGroupsData,
        setRoleGroupsData,
        selectedComplexites,
        setSelectedComplexites,
        dynamicFilterTag,
        setDynamicFilterTag,
        dynamicFilterData,
        setDynamicFilterData,
      }}
    >
      {children}
    </RaciGridContext.Provider>
  );
};

const useRaciGrid = () => useContext(RaciGridContext);

export { RaciGridProvider, useRaciGrid };
