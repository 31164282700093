import React, { useState, useEffect, useMemo } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { useDropzone } from 'react-dropzone';

import { templateFileTypes } from '../../static/TemplateIcons';

import HashtagSuggestions from '../HashtagSuggestions';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	padding: '122px 10px',
	marginBottom: '4px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#F0F2F8',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	width: '90%',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

const UploadTemplate = ({
	hashtagSuggestions,
	setHashtagSuggestions,
	searchValue,
	setSearchValue,
	existingHashtags,
	userAddedHashtags,
	setUserAddedHashtags,
	hashtagSuggestionInput,
	setHashtagSuggestionInput,
	closeHashtagForm,
	submitHashtags,
	removeHashtag,
	templateTitle,
	setTemplateTitle,
	templateFileSize,
	templateFile,
	setTemplateFile,
	errorMessage,
	setErrorMessage,
	fileTypeError,
	setFileTypeError,
	sizeError,
	setSizeError,
	titleInputRef
}) => {
	const {
		getRootProps,
		getInputProps,
		open,
		acceptedFiles,
		isDragActive,
		isDragAccept,
		isDragReject,
		rejectedFiles,
	} = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: false,
		maxSize: templateFileSize,
	});

	const filterFileType = (file) => {
		if (file.name.split(".").length > 2) {
			setFileTypeError(true);
		}
		else {
			const selectedFileType = file.name.split('.').slice(-1).pop().toLowerCase();
			if (templateFileTypes.includes(selectedFileType)) {
				setTemplateFile(file);
				setFileTypeError(false);
				setSizeError(false);
				setErrorMessage(null);
			} else {
				setTemplateFile(null);
				setFileTypeError(true);
				setSizeError(false);
				setErrorMessage(null);
			}
		}
	};

	const [updateApiCall, setUpdateApiCall] = useState(false);

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject]
	);

	useEffect(() => {
		if (rejectedFiles.length && rejectedFiles[0].size > templateFileSize) {
			setFileTypeError(false);
			setSizeError(true);
			setErrorMessage(null);
		} else if (rejectedFiles.length) {
			filterFileType(rejectedFiles[0]);
		}
	}, [rejectedFiles]);

	useEffect(() => {
		if (acceptedFiles.length == 0) {
			setTemplateFile(null);
		} else {
			filterFileType(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	return (
		<>
				<span style={{marginTop: '-10px', marginBottom: '10px'}}>Asterisk (<b>*</b>) denotes Required fields</span>
			<div class="collabaratorsParentContainers">
				<h6
					aria-label='Template Title'
					tabIndex={0}>Template Title <b>*</b></h6>
				<div>
					<input
						ref={titleInputRef}
						placeholder="Enter Title"
						aria-label="Template title"
						aria-required="true"
						role="textbox"
						value={templateTitle}
						onChange={(e) => {
							setTemplateTitle(e.target.value);
						}}
					/>
				</div>
			</div>
			<div class="collabaratorsParentContainers">
				<h6
					aria-label='Add #hashtags'
					tabIndex={0}>Add #hashtags</h6>
				<HashtagSuggestions
					className="topicsHashtagsInput"
					enableDropdown={true}
					data={hashtagSuggestions}
					setUpdateApiCall={setUpdateApiCall}
					setHashtagSuggestions={setHashtagSuggestions}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					existingHashtags={existingHashtags}
					userAddedHashtags={userAddedHashtags}
					setUserAddedHashtags={setUserAddedHashtags}
					hashtagSuggestionInput={hashtagSuggestionInput}
					setHashtagSuggestionInput={setHashtagSuggestionInput}
					closeHashtagForm={closeHashtagForm}
					submitHashtags={submitHashtags}
					autoFocus={false}
					showSubmitButton={false}
				/>
			</div>
			{existingHashtags.length > 0 ? (
				<div className="hashTagsContainer">
					{existingHashtags.map((item) => (
						<span key={item}>
							{item}{' '}
							<Icon
								iconName="Cancel"
								onClick={() => removeHashtag(item)}
								onKeyPress={(e) => {
									if (e.which === 13) {
										removeHashtag(item);
									}
								}}
								tabIndex={0}
								aria-label={`click to Remove ${item} Hashtag`}
								title="Cancel"
								role="Button"
							/>
						</span>
					))}
				</div>
			) : (
				<></>
			)}
			<div class="collabaratorsParentContainers">
				<div {...getRootProps({ style })} className="addtemplateDragDrop">
					<input {...getInputProps()}  aria-required="true"/>
					{templateFile === null ? (
						<div>
							<span>
								Drag and drop a file here <br /> or
							</span>
							<button
								id="dragDropButtonId"
								title="Choose File"
								className="dragDropButton"
								type="button"
								onClick={open}
								tabIndex={0}
								onKeyPress={(e) => {
									if (e.which === 13) {
										open();
									}
								}}
							>
								Choose File <b>*</b>
							</button>
						</div>
					) : (
						<>
							<div className="templateContributionFileNameContainer">
								<div
									key={templateFile.path}
									className="templateContributionFileName"
								>
									{templateFile.path}
									<button
										onClick={() => setTemplateFile(null)}
										tabIndex={0}
										onKeyPress={(e) => {
											if (e.which === 13) {
												setTemplateFile(null);
												setTimeout(() => {
													document.getElementById('dragDropButtonId')?.focus();
												}, 1000);
											}
										}}
										title="Remove Uploaded File"
										role="Button"
										aria-label="Remove"
									>
										X
									</button>
								</div>
							</div>
						</>
					)}
				</div>
				{errorMessage ? (
					<span className="errorMsg">All input are required</span>
				) : (
					<></>
				)}
				{sizeError ? (
					<span className="errorMsg">
						File should be less than {templateFileSize / 1048576} MB
					</span>
				) : (
					<></>
				)}
				{fileTypeError ? (
					<span className="errorMsg">
						Invalid file Format. Only "xlsx, pptx, potx, ppt, pdf, doc, docx,
						dotx, zip, xlsm, aspx, msg, mpp, mpt" are allowed.
					</span>
				) : (
					<>
						<p className="uploadTemplateInst mb-0">
							Accept only "xlsx, pptx, potx, ppt, pdf, doc, docx,
							dotx, zip, xlsm, aspx, msg, mpp, mpt".
						</p>
					</>
				)}
			</div>
		</>
	);
};

export default UploadTemplate;
